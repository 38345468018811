import {
  GRN_FAIL,
  GRN_REQUEST,
  GRN_SUCCESS,
  GRN_NUMBER_REQUEST,
  GRN_NUMBER_SUCCESS,
  GRN_NUMBER_FAIL,
  CLEAR_ERRORS,
  NEW_GRN_REQUEST,
  NEW_GRN_SUCCESS,
  NEW_GRN_FAIL,
  NEW_GRN_RESET,
  GRN_PRINT_SUCCESS,
  GRN_PRINT_REQUEST,
} from "../constants/GrnConstants";

// Get a purchase order grns;
export const grnNumberReducer = (state = { grnNumber: [] }, action) => {
  switch (action.type) {
    case GRN_NUMBER_REQUEST:
      return { loading: true, grnNumber: [] };
    case GRN_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        grnNumber: action.payload,
      };

    case GRN_NUMBER_FAIL:
      return {
        ...state,
        loading: false,
        grnNumber: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Get a purchase order grns;
export const grnReducer = (state = { grn: [] }, action) => {
  switch (action.type) {
    case GRN_REQUEST:
      return { loading: true, grn: [] };
    case GRN_SUCCESS:
      return {
        ...state,
        loading: false,
        grn: action.payload,
      };

    case GRN_FAIL:
      return {
        ...state,
        loading: false,
        grn: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Create a GRN
export const newGrnReducer = (state = { newGrn: {} }, action) => {
  switch (action.type) {
    case NEW_GRN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_GRN_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newGrn: action.payload.response,
      };
    case NEW_GRN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_GRN_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};



// Get a purchase order grns;
export const grnPrintReducer = (state = { grns: [] }, action) => {
  switch (action.type) {
    case GRN_PRINT_REQUEST:
      return { loading: true, grns: [] };
    case GRN_PRINT_SUCCESS:
      return {
        ...state,
        loading: false,
        grns: action.payload,
      };
    default:
      return state;
  }
};