import {
  WAREHOUSE_REQUEST,
  WAREHOUSE_SUCCESS,
  WAREHOUSE_FAIL,
  NEW_WAREHOUSE_REQUEST,
  NEW_WAREHOUSE_SUCCESS,
  NEW_WAREHOUSE_FAIL,
  UPDATE_WAREHOUSE_REQUEST,
  UPDATE_WAREHOUSE_SUCCESS,
  UPDATE_WAREHOUSE_FAIL,
  DELETE_WAREHOUSE_REQUEST,
  DELETE_WAREHOUSE_SUCCESS,
  DELETE_WAREHOUSE_FAIL,
  CLEAR_ERRORS,
} from "../constants/WarehouseConstants";
import axios from "axios";

//   import axios from "axios";

// Get warehouse
export const getWarehouse = () => async (dispatch) => {
  try {
    dispatch({ type: WAREHOUSE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/warehouse`,
      config
    );
    dispatch({ type: WAREHOUSE_SUCCESS, payload: data.warehouse });
  } catch (error) {
    dispatch({ type: WAREHOUSE_FAIL, payload: error.response.data.message });
  }
};

// Update warehouse
export const updateWarehouse = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_WAREHOUSE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    console.log(id, Data);

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/warehouse/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_WAREHOUSE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_WAREHOUSE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete warehouse
export const deleteWarehouse = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_WAREHOUSE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/warehouse/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_WAREHOUSE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_WAREHOUSE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a warehouse
export const createWarehouse = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_WAREHOUSE_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/warehouse`,
      Data,
      config
    );

    dispatch({
      type: NEW_WAREHOUSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_WAREHOUSE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
