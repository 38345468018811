import { Grid, Typography, Paper, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate, Link } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import { clearErrors } from "../../../Redux/actions/MastersActions";
import { Add, Cancel } from "@mui/icons-material";
import Features from "./Features";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { TextFieldComponent } from "../../Common/FormikComponent/TextFieldComponent";
import AutoCompleteSelect from "../../Common/FormikComponent/AutoCompleteSelect";
import {
  getProductCategory,
  getProductGroup,
  getProductType,
  getProductAccount,
  getProductUnit,
  getBrands,
  getProductSegment,
  getProductSpecifications,
  createProductList,
} from "../../../Redux/actions/InventryActions";
import MultiSelect from "../../Common/MultiSelect";
import StockPrices from "./Stock&Prices";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import {
  NEW_ADD_PRODUCT_RESET,
  NEW_BRAND_RESET,
  NEW_PRODUCTACCOUNT_RESET,
  NEW_PRODUCTGROUP_RESET,
  NEW_PRODUCTSEGMENT_RESET,
  NEW_PRODUCTTYPE_RESET,
  NEW_PRODUCTUNIT_RESET,
  NEW_PRODUCT_CATEGORY_RESET,
  NEW_PRODUCT_SPECIFICATIONS_RESET,
} from "../../../Redux/constants/InventryConstant";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const AddProduct = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error: adminError, productType } = useSelector(
    (state) => state.productType
  );
  
   const [button_disabled, setButtonDisabled] = React.useState(false);



  const { error: adminError2, productGroup } = useSelector(
    (state) => state.productGroup
  );
  const { error: adminError3, productCategory } = useSelector(
    (state) => state.productCategory
  );
  const { error: adminError4, productUnit } = useSelector(
    (state) => state.productUnit
  );
  const { error: adminError5, brands } = useSelector((state) => state.brands);
  const { error, success } = useSelector((state) => state.newProduct);
  const { error: adminError6, productSegmente } = useSelector(
    (state) => state.productSegment
  );
  const { error: adminError7, productSpecification } = useSelector(
    (state) => state.productSpecification
  );
  const { error: adminError8, productAccount } = useSelector(
    (state) => state.productAccount
  );
  let productUnit_success = useSelector(
    (state) => state.newproductUnit.success
  );
  let brands_success = useSelector((state) => state.newBrands.success);
  let productType_success = useSelector(
    (state) => state.newProductType.success
  );
  let productSegment_success = useSelector(
    (state) => state.newProductSegment.success
  );
  let productSpecification_success = useSelector(
    (state) => state.newProductSpecification.success
  );
  let productGroup_success = useSelector(
    (state) => state.newProductGroup.success
  );
  let productCategory_success = useSelector(
    (state) => state.newProductCategory.success
  );
  let productAccount_success = useSelector(
    (state) => state.newProductAccount.success
  );
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getProductGroup());
      dispatch(getProductCategory());
      dispatch(getBrands());
      dispatch(getProductType());
      dispatch(getProductUnit());
      dispatch(getProductSegment());
      dispatch(getProductSpecifications());
      dispatch(getProductAccount());
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      alert.success("Product Added Successfully");
	   setButtonDisabled(false);
	  
      dispatch({ type: NEW_ADD_PRODUCT_RESET });
      navigate("/inventory/products");
    }
    if (brands_success) {
      alert.success("Brand Added Successfully");
      dispatch({ type: NEW_BRAND_RESET });
      dispatch(getBrands());
    }
    if (productUnit_success) {
      alert.success("Product Unit Added Successfully");
      dispatch({ type: NEW_PRODUCTUNIT_RESET });
      dispatch(getProductUnit());
    }
    if (productType_success) {
      alert.success("Product Type Added Successfully");
      dispatch({ type: NEW_PRODUCTTYPE_RESET });
      dispatch(getProductType());
    }
    if (productSpecification_success) {
      alert.success("Product Specification Added Successfully");
      dispatch({ type: NEW_PRODUCT_SPECIFICATIONS_RESET });
      dispatch(getProductSpecifications());
    }
    if (productSegment_success) {
      alert.success("Product Segment Added Successfully");
      dispatch({ type: NEW_PRODUCTSEGMENT_RESET });
      dispatch(getProductSegment());
    }
    if (productGroup_success) {
      alert.success("Product Group Added Successfully");
      dispatch({ type: NEW_PRODUCTGROUP_RESET });
      dispatch(getProductGroup());
    }
    if (productCategory_success) {
      alert.success("Product Category Added Successfully");
      dispatch({ type: NEW_PRODUCT_CATEGORY_RESET });
      dispatch(getProductCategory());
    }
    if (productAccount_success) {
      alert.success("Product Account Added Successfully");
      dispatch({ type: NEW_PRODUCTACCOUNT_RESET });
      dispatch(getProductAccount());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [
    dispatch,
    navigate,
    error,
    success,
    alert,
    brands_success,
    productUnit_success,
    isAuthenticated,
    productType_success,
    productSegment_success,
    productSpecification_success,
    productGroup_success,
    productCategory_success,
    productAccount_success,
  ]);

  // Multiselect product specifiaction
  const [personName, setPersonName] = React.useState([]);
  const handleChangeSpecification = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  // Multiselect product Segment
  const [productSegment, setProductSegment] = React.useState([]);
  const handleChangeSegment = (event) => {
    const {
      target: { value },
    } = event;
    setProductSegment(typeof value === "string" ? value.split(",") : value);
  };
  // Stock & price state and function
  const [addChild, setAddChild] = React.useState([
    { value: 1, Stocks: 0, weightSelect: "gram" },
  ]);
  const addChildInputField = (event) => {
    const id = addChild.length + 1;
    setAddChild((oldValue) => [
      ...oldValue,
      { value: id, Stocks: 0, weightSelect: "gram" },
    ]);
    event.preventDefault();
  };
  const deleteRow = (id) => {
	  
	  if(window.confirm('Are you sure?'))
	  {
    let updatedArray = addChild.filter((r) => r.value !== id);
    setAddChild(updatedArray);
	  }	
	
  };
  const onchangeInput = (val, index, outerIndex) => {
    let updated = addChild.map((r, ind) => {
      return ind === outerIndex
        ? {
            ...r,
            [index]: val.target.value,
          }
        : r;
    });
    let updatedNew;
    if (
      val.target.name === "H" ||
      val.target.name === "B" ||
      val.target.name === "L"
    ) {
      updatedNew = updated.map((r, ind) => {
        return ind === outerIndex
          ? {
              ...r,
              ["volume"]: r.L * r.B * r.H,
            }
          : r;
      });
    } else {
      updatedNew = updated;
    }
    setAddChild(updatedNew);
  };
  console.log(addChild, "======================");
  // Image Upload functions
  const [serviceList, setServiceList] = useState([{ service: 1 }]);
  const uploadImageField = (e, index) => {
    const id = serviceList.length + 1;
    setServiceList((oldValue) => [...oldValue, { service: id }]);
    e.preventDefault();
  };
  const onchangeInputImage = async (e, index) => {
    let data = new FormData();
    data.append("name", e.target.files[0].name);
    data.append("file", e.target.files[0]);
    const image = await fetch(
      `https://erpapi.flaskitsolutions.com/api/v1/upload/user-image`,
      {
        method: "POST",
        body: data,
      }
    );
    const json = await image.json();
    let updated = serviceList.map((r) =>
      r.service === index ? { ...r, url: json } : r
    );
    setServiceList(updated);
  };
  const handleServiceRemove = (id) => {
    let updatedArray = serviceList.filter((r) => r.service !== id);
    setServiceList(updatedArray);
  };

  // Features

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
  const onEditorStateChange2 = (editorState2) => {
    setEditorState2(editorState2);
  };
  // console.log(convertToRaw(editorState2.getCurrentContent()).blocks,"--------");
  // console.log(convertToRaw(editorState.getCurrentContent()).blocks);

  const validationSchema = Yup.object({
    brand: Yup.string().required("Required"),
    product_title: Yup.string().required("Required"),
    product_suffix: Yup.string().required("Required"),
    hsn_code: Yup.string().required("Required"),
    remark: Yup.string(),
    product_account: Yup.string().required("Required"),
    gst: Yup.string().required("Required"),
    product_group: Yup.string().required("Required"),
    product_category: Yup.string().required("Required"),
    product_unit: Yup.string().required("Required"),
    product_type: Yup.string().required("Required"),
  });
  const initialValues = {
    product_type: "",
    product_group: "",
    product_category: "",
    product_unit: "",
    brand: "",
    product_title: "",
    product_suffix: "",
    hsn_code: "",
    gst: "",
    product_account: "",
    remark: "",
  };
  const onSubmit = (values) => {
    const EditorValues = convertToRaw(editorState.getCurrentContent());
    const content = draftToHtml(EditorValues);
    const EditorValues1 = convertToRaw(editorState2.getCurrentContent());
    const content1 = draftToHtml(EditorValues1);
	
	setButtonDisabled(true);
	
    dispatch(
      createProductList({
        ...values,
        account_information: {
          gst: values.gst,
          product_account: values.product_account,
          remark: values.remark,
        },
        product_specification: personName,
        product_segment: productSegment,
        images: serviceList,
        stock_and_prices: addChild,
        uses_and_features: content1,
        description: content,
      })
    );
    // navigate("/inventory/products");
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Add Product
            </Typography>
          </Grid>
        </Grid>
        {adminError ||
        adminError2 ||
        adminError3 ||
        adminError4 ||
        adminError5 ||
        adminError6 ||
        adminError7 ||
        adminError8 ? (
          <Grid container xs={12} justifyContent="center" alignItems="center">
            <Typography variant="h5" sx={{ fontWeight: "bold", color: "red" }}>
              You don't have the required permissions.
            </Typography>
          </Grid>
        ) : (
          <Grid container sx={{ padding: "0 28px", width: "100%", mt: "0" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ errors, handleChange, values, touched, setFieldValue }) => (
                <Form>
                  <Grid
                    container
                    style={{
                      background: "#fff",
                      padding: "18px",
                      borderRadius: "10px",
                    }}
                    lg={12}
                    justifyContent="space-between"
                  >
                    <Grid lg={12} container mb={3}>
                      <Grid item lg={3}>
                        <AutoCompleteSelect
                          name="product_type"
                          data={productType}
                          icon={true}
                          error={errors.product_type && touched.product_type}
                          values={values.product_type}
                          handleChange={handleChange}
                          heading="Product Type"
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item lg={3}>
                        <AutoCompleteSelect
                          name="product_group"
                          data={productGroup}
                          error={errors.product_group && touched.product_group}
                          values={values.product_group}
                          handleChange={handleChange}
                          icon={true}
                          heading="Product Group"
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item lg={2}>
                        <AutoCompleteSelect
                          name="product_category"
                          data={productCategory}
                          error={
                            errors.product_category && touched.product_category
                          }
                          icon={true}
                          values={values.product_category}
                          handleChange={handleChange}
                          heading="Product Category"
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item lg={2}>
                        <AutoCompleteSelect
                          name="product_unit"
                          data={productUnit}
                          error={errors.product_unit && touched.product_unit}
                          values={values.product_unit}
                          handleChange={handleChange}
                          icon={true}
                          heading="Product Unit"
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item lg={2}>
                        <AutoCompleteSelect
                          name="brand"
                          data={brands}
                          icon={true}
                          error={errors.brand && touched.brand}
                          values={values.brand}
                          handleChange={handleChange}
                          heading="Brand"
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                    </Grid>

                    <Grid lg={12} container mb={4}>
                      <Grid item lg={3}>
                        <TextFieldComponent
                          heading="Product Title"
                          name="product_title"
                          type="text"
                          error={errors.product_title && touched.product_title}
                        />
                      </Grid>
                      <Grid item lg={3}>
                        <TextFieldComponent
                          heading="Title Surfix"
                          name="product_suffix"
                          type="text"
                          error={
                            errors.product_suffix && touched.product_suffix
                          }
                        />
                      </Grid>
                      <Grid item lg={2}>
                        <TextFieldComponent
                          heading="HSN Code"
                          name="hsn_code"
                          type="text"
                          error={errors.hsn_code && touched.hsn_code}
                        />
                      </Grid>
                      <Grid item lg={2}>
                        <MultiSelect
                          product={productSegmente}
                          title="Product Segment"
                          size="90%"
                          icon={true}
                          handleChange={handleChangeSegment}
                          personName={productSegment}
                        />
                      </Grid>
                      <Grid item lg={2}>
                        <MultiSelect
                          product={productSpecification}
                          title="Product Specifications"
                          size="90%"
                          icon={true}
                          handleChange={handleChangeSpecification}
                          personName={personName}
                        />
                      </Grid>
                    </Grid>

                    <Grid container lg={12}>
                      <StockPrices
                        productSpecification={personName}
                        addChild={addChild}
                        addChildInputField={addChildInputField}
                        deleteRow={deleteRow}
                        onchangeInput={onchangeInput}
                        setAddChild={setAddChild}
                      />
                    </Grid>

                    <Grid
                      lg={12}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid
                        container
                        lg={3}
                        sx={{ mt: 3, mb: 2 }}
                        justifyContent="flex-start"
                      >
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          Account Information
                        </Typography>
                      </Grid>
                      <Grid container lg={12} spacing={3}>
                        <Grid item lg={3} md={4}>
                          <TextFieldComponent
                            heading="Gst"
                            name="gst"
                            type="number"
                            error={errors.gst && touched.gst}
                          />
                        </Grid>
                        <Grid item lg={3} md={4}>
                          <AutoCompleteSelect
                            name="product_account"
                            data={productAccount}
                            error={
                              errors.product_account && touched.product_account
                            }
                            icon={true}
                            values={values.product_account}
                            handleChange={handleChange}
                            heading="Product Account"
                            setFieldValue={setFieldValue}
                          />
                        </Grid>
                        <Grid item lg={3} md={4}>
                          <TextFieldComponent
                            heading="Remark"
                            name="remark"
                            type="text"
                            error={errors.remark && touched.remark}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      lg={12}
                      sx={{ mt: 3 }}
                      justifyContent="flex-start"
                    >
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Upload Images
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      lg={12}
                      sx={{ mt: 3 }}
                      justifyContent="flex-start"
                    >
                      <Button
                        variant="outlined"
                        sx={{ mr: 2 }}
                        color="primary"
                        onClick={(e) => uploadImageField(e)}
                      >
                        <Add sx={{ mr: 1 }} />
                        Add
                      </Button>
                    </Grid>
                    {serviceList.map((singleService, index) => (
                      <Grid
                        key={index}
                        container
                        lg={12}
                        sx={{ mt: 2, width: "100%" }}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <input
                              name="Upload Image"
                              type="file"
                              id="service"
                              onChange={(e) =>
                                onchangeInputImage(e, singleService.service)
                              }
                            />
                            <Button
                              variant="outlined"
                              size="small"
                              color="error"
                              sx={{ pl: 3, pr: 3 }}
                              onClick={() =>
                                handleServiceRemove(singleService.service)
                              }
                            >
                              <Cancel sx={{ mr: 1 }} />
                              Remove
                            </Button>
                          </div>
                          <img
                            src={singleService.url}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                            alt="image"
                          />
                        </Grid>
                      </Grid>
                    ))}
                    <Features
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                      editorState2={editorState2}
                      onEditorStateChange2={onEditorStateChange2}
                    />

                    <Grid container justifyContent="flex-end" sx={{ mt: 7 }}>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ mr: 2 }}
                        color="secondary"
						
						disabled={button_disabled}
						
						
                      >
                        <Add sx={{ mr: 1 }} />
                        Add
                      </Button>
                      <Link
                        to="/inventory/products"
                        style={{ textDecoration: "none" }}
                      >
                        <Button variant="contained" color="error">
                          <Cancel sx={{ mr: 1 }} />
                          Cancel
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        )}
      </Box>
    </Box>
  );
};
export default AddProduct;
