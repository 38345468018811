import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RadioButton({name,change}) {
 
  return (
    <div style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column"}}>
      <FormLabel id="demo-controlled-radio-buttons-group">Deliver To:</FormLabel>
      <RadioGroup
         row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={name}
        size="small"
        onChange={change}
      >
        <FormControlLabel value="Warehouse"  control={<Radio size="small"/>} label="Warehouse" />
        <FormControlLabel value="Customer" control={<Radio size="small"/>} label="Customer" />
        <FormControlLabel value="other" control={<Radio size="small"/>} label="other" />
      </RadioGroup>
    </div>
  );
}