import { Grid, Typography, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Link, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getLocationBarcode } from "../../Redux/actions/LocationBarCodeActions";
import Loader from "../Common/Loader";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const LocationBarCode = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, locationBarcode, loading } = useSelector(
    (state) => state.locationBarcode
  );
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getLocationBarcode());
    }

    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [dispatch, navigate, alert, isAuthenticated]);

  console.log(locationBarcode, "============");
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        {error ? (
          <Grid container xs={12} justifyContent="center" alignItems="center">
            <Typography variant="h5" sx={{ fontWeight: "bold", color: "red" }}>
              You don't have the required permissions.
            </Typography>
          </Grid>
        ) : (
          <Grid container sx={{ padding: "0 24px", mt: 2 }}>
            {loading ? (
              <Loader />
            ) : (
              <Grid item xs={12}>
                <Item>
                  <Grid xs={12} style={{ background: "#B4B4E2" }}>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "800", padding: "20px 0" }}
                    >
                      On Location
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    style={{ background: "#DCDCDC", padding: "30px 5px" }}
                  >
                    {locationBarcode.map((r) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginBottom: "10px",
                            padding: "0 20px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: "#4F5689",
                            borderRadius: "20px",
                          }}
                        >
                          <div
                            style={{
                              color: "#fff",
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "start",
                            }}
                          >
                            {r.invoice_number ? (
                              <p>Invoice Number: {r.invoice_number}</p>
                            ) : (
                              <p>
                                Performa Invoice Number:{" "}
                                {r.performa_invoice_number}
                              </p>
                            )}
                            <p>
                              Invoice Date:{" "}
                              {new Date(r.date).toLocaleDateString("en-GB", 2)}
                            </p>
                          </div>
                          <Link to={`/locationBarcode/${r._id}`}>
                            <ArrowForwardIcon
                              style={{
                                background: "black",
                                color: "#fff",
                                fontSize: "25px",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                            />
                          </Link>
                        </div>
                      );
                    })}
                  </Grid>
                </Item>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
};
export default LocationBarCode;
