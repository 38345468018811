import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import PersonIcon from "@mui/icons-material/Person";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import StoreIcon from "@mui/icons-material/Store";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import Accordion from "../Common/Accordion";

export const mainListItems = (
	<div>
		<Link
			to={"/dashboard"}
			style={{ textDecoration: "none", color: "inherit" }}
		>
			<ListItem button>
				<ListItemIcon>
					<HomeIcon />
				</ListItemIcon>
				<ListItemText primary="Dashboard" />
			</ListItem>
		</Link>

		<Accordion
			main="Masters"
			first="Country"
			second="State"
			third="City"
			fourth="Source of Supply"
			fifth="Payment Terms"
			six="GST Treatments"
			seven="GST Classification"
			eight="Bank Account"
			nine="Dispatch App User"
			ten="Module Master"
			Eleven="Transport Charges" 
			twelve="Product Terms" 
		/>

		<Accordion
			main="Account Masters"
			first="Adevertisement"
			second="Bank Accounts"
			third="Bank OCC A/C"
			fourth="Bank OD A/C"
			fifth="Branch/ Division"
			six="Business Promotion"
			seven="Capital Accounts"
			eight="Cash-in-hand"
			nine="Courier Charges"
			ten="Creditors for Expenses"
			eleven="Current Assets"
			twelve="Current Liabilities"
			thirteen="Deposits (Assets)"
			fourteen="Direct Expense"
			fifteen="Duties & Taxes"
			sixteen="Expenses(Direct)"
			seventeen="Expenses(Indirect)"
			eighteen="Fixed Assets"
			nineteen="GST Payable"
			twenty="GST Receivable"
			twentyone="Income(Indirect)"
			twentytwo="Indirect Incomes"
			twentythree="Indirect Expenses"
			twentyfour="investments"
			twentyfive="Legal Fees"
			twentysix="Loans & Advances (Asset)"
			twentyseven="Misc. Expenses (Asset)"
			twentyeight="Office Expenses"
			twentynine="Other Liabilities"
			thirty="Provisions"
			thirtyone="Purchase Accounts"
			thirtytwo="Rent A/C"
			thirtythree="Reservers & Surplus"
			thirtyfour="Retained Earnings"
			thirtyfive="Salaries & Bonus"
			thirtysix="Salex SEZ"
			thirtyseven="Secured Loans"
			thirtyeight="Staff Training Expenses"
			thirtynine="Staff Welfare"
			fourty="Stock-in-hand"
			fourtyone="Sundry Creditors"
			fourtytwo="Sundry Creditors ONM-ONM"
			fourtythree="Sundry Creditors NM"
			fourtyfour="Sundry Creditors ONM0"
			fourtyfive="Sundry Debtors"
			fourtysix="Sundry Debtors NM"
			fourtyseven="Sundry Debtors ONM-ONM"
			fourtyeight="Sundry Debtors ONM"
			fourtynine="suspense A/C"
			fifty="telephone Charges"
			fiftyone="Unsecured Loans"
			fiftytwo="Vehicle Expenses"
		/>

		<Link
			to={"/warehouses"}
			style={{ textDecoration: "none", color: "inherit" }}
		>
			<ListItem button>
				<ListItemIcon>
					<OtherHousesIcon />
				</ListItemIcon>
				<ListItemText primary="Warehouses" />
			</ListItem>
		</Link>
		{/* <Link to={"/vendors"} style={{ textDecoration: "none", color: "inherit" }}>
      <ListItem button>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Vendors" />
      </ListItem>
    </Link> */}
		{/* <Accordion
			main="Vendor"
			first="Add Vendor"
			second="Vendor Contact Persons"
			third="Vendor Shipping Address"
			fourth="Vendor Billing Address"
		/> */}
		<Link
			to={"/vendor/vendors"}
			style={{ textDecoration: "none", color: "inherit" }}
		>
			<ListItem button>
				<ListItemIcon>
					<StoreIcon />
				</ListItemIcon>
				<ListItemText primary="Vendor" />
			</ListItem>
		</Link>
		{/* <Accordion
			main="Customers"
			first="Add Customers"
			second="Customer Contact Persons"
			third="Customer Shipping Address"
			fourth="Customer Billing Address"
		/> */}
		<Link
			to={"/customers/add_customers"}
			style={{ textDecoration: "none", color: "inherit" }}
		>
			<ListItem button>
				<ListItemIcon>
					<StoreIcon />
				</ListItemIcon>
				<ListItemText primary="Customer" />
			</ListItem>
		</Link>

		<Accordion
			main="Inventory"
			first="Brands"
			second="Product Unit"
			third="Product Type"
			fourth="Product Account"
			fifth="Product Specification"
			six="Product Segment"
			seven="Product Group"
			eight="Product Category"
			nine="Add Product"
			ten="Products"
			eleven="End Product List"
		/>

		{/* <Link
      to={"/inventory"}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemIcon>
          <FormatListBulletedIcon />
        </ListItemIcon>
        <ListItemText primary="Inventory" />
      </ListItem>
    </Link> */}
		<Link
			to={"/purchase-orders"}
			style={{ textDecoration: "none", color: "inherit" }}
		>
			<ListItem button>
				<ListItemIcon>
					<StoreIcon />
				</ListItemIcon>
				<ListItemText primary="Purchase Invoice" />
			</ListItem>
		</Link>
		<Link to={"/grn"} style={{ textDecoration: "none", color: "inherit" }}>
			<ListItem button>
				<ListItemIcon>
					<FileDownloadIcon />
				</ListItemIcon>
				<ListItemText primary="GRN" />
			</ListItem>
		</Link>
		{/* <Link to={"/sales"} style={{ textDecoration: "none", color: "inherit" }}>
      <ListItem button>
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>
        <ListItemText primary="Sales" />
      </ListItem>
    </Link> */}
		<Accordion
			main="Sales"
			first="Add Tax Invoice"
			second="Tax Invoice"
			third="Add Performa Invoice"
			fourth="Performa Invoice"
		/>
		<Link
			to={"/location-barcode-scan-outer"}
			style={{ textDecoration: "none", color: "inherit" }}
		>
			<ListItem button>
				<ListItemIcon>
					<QrCodeIcon />
				</ListItemIcon>
				<ListItemText primary="Location Barcode Scan" />
			</ListItem>
		</Link>
		<Link
			to={"/stock-report"}
			style={{ textDecoration: "none", color: "inherit" }}
		>
			<ListItem button>
				<ListItemIcon>
					<FileDownloadIcon />
				</ListItemIcon>
				<ListItemText primary="Stock Report" />
			</ListItem>
		</Link>
		<Link
			to={"/sale-report-outer"}
			style={{ textDecoration: "none", color: "inherit" }}
		>
			<ListItem button>
				<ListItemIcon>
					<FileDownloadIcon />
				</ListItemIcon>
				<ListItemText primary="Sale Report" />
			</ListItem>
		</Link>
		<Link
			to={"/purchase-report"}
			style={{ textDecoration: "none", color: "inherit" }}
		>
			<ListItem button>
				<ListItemIcon>
					<FileDownloadIcon />
				</ListItemIcon>
				<ListItemText primary="Purchase Registered" />
			</ListItem>
		</Link>
		<Link
			to={"/ecommerce/dashboard"}
			style={{ textDecoration: "none", color: "inherit" }}
		>
			<ListItem button>
				<ListItemIcon>
					<ShoppingCartIcon />
				</ListItemIcon>
				<ListItemText primary="Ecommerce" />
			</ListItem>
		</Link>
		<Link to={"/users"} style={{ textDecoration: "none", color: "inherit" }}>
			<ListItem button>
				<ListItemIcon>
					<GroupsIcon />
				</ListItemIcon>
				<ListItemText primary="Users" />
			</ListItem>
		</Link>
	</div>
);
