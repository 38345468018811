//slider Constants

export const SLIDER_REQUEST = "SLIDER_REQUEST";
export const SLIDER_SUCCESS = "SLIDER_SUCCESS";
export const SLIDER_FAIL = "SLIDER_FAIL";

export const NEW_SLIDER_REQUEST = "NEW_SLIDER_REQUEST";
export const NEW_SLIDER_SUCCESS = "NEW_SLIDER_SUCCESS";
export const NEW_SLIDER_FAIL = "NEW_SLIDER_FAIL";
export const NEW_SLIDER_RESET = "NEW_SLIDER_RESET";

export const UPDATE_SLIDER_REQUEST = "UPDATE_SLIDER_REQUEST";
export const UPDATE_SLIDER_SUCCESS = "UPDATE_SLIDER_SUCCESS";
export const UPDATE_SLIDER_FAIL = "UPDATE_SLIDER_FAIL";
export const UPDATE_SLIDER_RESET = "UPDATE_SLIDER_RESET";

export const DELETE_SLIDER_REQUEST = "DELETE_SLIDER_REQUEST";
export const DELETE_SLIDER_SUCCESS = "DELETE_SLIDER_SUCCESS";
export const DELETE_SLIDER_FAIL = "DELETE_SLIDER_FAIL";
export const DELETE_SLIDER_RESET = "DELETE_SLIDER_RESET";


//CUST_SUB_GROUP Constants

export const CUST_SUB_GROUP_REQUEST = "CUST_SUB_GROUP_REQUEST";
export const CUST_SUB_GROUP_SUCCESS = "CUST_SUB_GROUP_SUCCESS";
export const CUST_SUB_GROUP_FAIL = "CUST_SUB_GROUP_FAIL";

export const NEW_CUST_SUB_GROUP_REQUEST = "NEW_CUST_SUB_GROUP_REQUEST";
export const NEW_CUST_SUB_GROUP_SUCCESS = "NEW_CUST_SUB_GROUP_SUCCESS";
export const NEW_CUST_SUB_GROUP_FAIL = "NEW_CUST_SUB_GROUP_FAIL";
export const NEW_CUST_SUB_GROUP_RESET = "NEW_CUST_SUB_GROUP_RESET";

export const UPDATE_CUST_SUB_GROUP_REQUEST = "UPDATE_CUST_SUB_GROUP_REQUEST";
export const UPDATE_CUST_SUB_GROUP_SUCCESS = "UPDATE_CUST_SUB_GROUP_SUCCESS";
export const UPDATE_CUST_SUB_GROUP_FAIL = "UPDATE_CUST_SUB_GROUP_FAIL";
export const UPDATE_CUST_SUB_GROUP_RESET = "UPDATE_CUST_SUB_GROUP_RESET";

export const DELETE_CUST_SUB_GROUP_REQUEST = "DELETE_CUST_SUB_GROUP_REQUEST";
export const DELETE_CUST_SUB_GROUP_SUCCESS = "DELETE_CUST_SUB_GROUP_SUCCESS";
export const DELETE_CUST_SUB_GROUP_FAIL = "DELETE_CUST_SUB_GROUP_FAIL";
export const DELETE_CUST_SUB_GROUP_RESET = "DELETE_CUST_SUB_GROUP_RESET";

//CUST_GROUP Constants

export const CUST_GROUP_REQUEST = "CUST_GROUP_REQUEST";
export const CUST_GROUP_SUCCESS = "CUST_GROUP_SUCCESS";
export const CUST_GROUP_FAIL = "CUST_GROUP_FAIL";

export const NEW_CUST_GROUP_REQUEST = "NEW_CUST_GROUP_REQUEST";
export const NEW_CUST_GROUP_SUCCESS = "NEW_CUST_GROUP_SUCCESS";
export const NEW_CUST_GROUP_FAIL = "NEW_CUST_GROUP_FAIL";
export const NEW_CUST_GROUP_RESET = "NEW_CUST_GROUP_RESET";

export const UPDATE_CUST_GROUP_REQUEST = "UPDATE_CUST_GROUP_REQUEST";
export const UPDATE_CUST_GROUP_SUCCESS = "UPDATE_CUST_GROUP_SUCCESS";
export const UPDATE_CUST_GROUP_FAIL = "UPDATE_CUST_GROUP_FAIL";
export const UPDATE_CUST_GROUP_RESET = "UPDATE_CUST_GROUP_RESET";

export const DELETE_CUST_GROUP_REQUEST = "DELETE_CUST_GROUP_REQUEST";
export const DELETE_CUST_GROUP_SUCCESS = "DELETE_CUST_GROUP_SUCCESS";
export const DELETE_CUST_GROUP_FAIL = "DELETE_CUST_GROUP_FAIL";
export const DELETE_CUST_GROUP_RESET = "DELETE_CUST_GROUP_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";