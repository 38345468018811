import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import CustomerTableRow from "./CustomerTableRow";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(181,201,255)",
    color: theme.palette.common.white,
    borderRight: "1px solid #fff",
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "2px",
  },
}));

function CustomerRow({
  main,
  addChildInputField,
  deleteRow,
  deleteRow3,
  defaultData,
  name,
  onchangeInput,
}) {
  return (
    <TableContainer
      component={Paper}
      style={{ width: "100%", overflow: "hidden" }}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {defaultData.map((item) => (
              <StyledTableCell align="center">{item.value}</StyledTableCell>
            ))}
            {name === "shipping" ? (
              ""
            ) : (
              <StyledTableCell align="center">
                <AddIcon onClick={(e) => addChildInputField(e)} />
              </StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <CustomerTableRow
          deleteRow={deleteRow}
          main={main}
          onchangeInput={onchangeInput}
          defaultData={defaultData}
          name={name}
          deleteRow3={deleteRow3}
        />
      </Table>
    </TableContainer>
  );
}
export default CustomerRow;
