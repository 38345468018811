import axios from "axios";
import {
  NEW_PERFORMA_INVOICE_FAIL,
  NEW_PERFORMA_INVOICE_REQUEST,
  NEW_PERFORMA_INVOICE_SUCCESS,
  NEW_TAX_INVOICE_FAIL,
  NEW_TAX_INVOICE_REQUEST,
  NEW_TAX_INVOICE_SUCCESS,
  PERFORMA_INVOICE_FAIL,
  PERFORMA_INVOICE_REQUEST,
  PERFORMA_INVOICE_SUCCESS,
  TAX_INVOICE_FAIL,
  TAX_INVOICE_REQUEST,
  TAX_INVOICE_SUCCESS,
  UPDATE_PERFORMA_INVOICE_FAIL,
  UPDATE_PERFORMA_INVOICE_REQUEST,
  UPDATE_PERFORMA_INVOICE_SUCCESS,
  UPDATE_TAX_INVOICE_FAIL,
  UPDATE_TAX_INVOICE_REQUEST,
  UPDATE_TAX_INVOICE_SUCCESS,
} from "../constants/SalesConstant";

export const getSalesInvoice = () => async (dispatch) => {
  try {
    dispatch({ type: TAX_INVOICE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    let link = `https://erpapi.flaskitsolutions.com/api/v1/admin/add_sales_order`;
    const { data } = await axios.get(link, config);
    dispatch({ type: TAX_INVOICE_SUCCESS, payload: data.salesOrders });
  } catch (error) {
    dispatch({
      type: TAX_INVOICE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const createSalesInvoice = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_TAX_INVOICE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    console.log(Data, "data");
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/add_sales_order`,
      Data,
      config
    );
    dispatch({
      type: NEW_TAX_INVOICE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_TAX_INVOICE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updateSalesInvoice = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TAX_INVOICE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/add_sales_order/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_TAX_INVOICE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_TAX_INVOICE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getPerformaInvoice = () => async (dispatch) => {
  try {
    dispatch({ type: PERFORMA_INVOICE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    let link = `https://erpapi.flaskitsolutions.com/api/v1/admin/add_performa_order`;
    const { data } = await axios.get(link, config);
    dispatch({
      type: PERFORMA_INVOICE_SUCCESS,
      payload: data.performaInvoices,
    });
  } catch (error) {
    dispatch({
      type: PERFORMA_INVOICE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const createPerformaInvoice = (Data) => async (dispatch) => {
  console.log(Data, "data");
  try {
    dispatch({ type: NEW_PERFORMA_INVOICE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/add_performa_order`,
      Data,
      config
    );
    dispatch({
      type: NEW_PERFORMA_INVOICE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PERFORMA_INVOICE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updatePerformaInvoice = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PERFORMA_INVOICE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/add_performa_order/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_PERFORMA_INVOICE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PERFORMA_INVOICE_FAIL,
      payload: error.response.data.message,
    });
  }
};
