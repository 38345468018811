import "./Features.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Grid, Typography } from "@mui/material";
import { useState } from "react";

function Features({
  editorState,
  onEditorStateChange,
  editorState2,
  onEditorStateChange2,
}) {
  return (
    <>
      {/* Description Editor */}
      <Grid className="container">
        <Grid className="row">
          <Grid className="col align-self-center">
            <Grid
              container
              lg={3}
              sx={{ mt: 6, mb: 3 }}
              justifyContent="flex-start"
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Description
              </Typography>
            </Grid>

            <Editor
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              handlePastedText={() => false}
              wrapperClassName="wrapper"
              editorClassName="editor"
              toolbarClassName="toolbar"
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Uses and Features Editor */}
      <Grid className="container">
        <Grid className="row">
          <Grid className="col align-self-center">
            <Grid
              container
              lg={3}
              sx={{ mt: 6, mb: 3 }}
              justifyContent="flex-start"
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Uses and Features
              </Typography>
            </Grid>
            <Editor
              editorState={editorState2}
              onEditorStateChange={onEditorStateChange2}
              handlePastedText={() => false}
              wrapperClassName="wrapper"
              editorClassName="editor"
              toolbarClassName="toolbar"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Features;
