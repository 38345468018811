import {
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAIL,
  NEW_USER_REQUEST,
  NEW_USER_SUCCESS,
  NEW_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CLEAR_ERRORS,
} from "../constants/UserConstants";
import axios from "axios";

// GET all USER
export const getUser = () => async (dispatch) => {
  try {
    dispatch({ type: USER_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/get/admin_all/users`,
      config
    );
    dispatch({ type: USER_SUCCESS, payload: data.users });
  } catch (error) {
    dispatch({ type: USER_FAIL, payload: error.response.data.message });
  }
};

// Create a USER
export const createUser = (Data) => async (dispatch) => {
  console.log("Data", Data);
  try {
    dispatch({ type: NEW_USER_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/create/admin`,
      Data,
      config
    );
    dispatch({
      type: NEW_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update USER
export const updateUser = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/update/admin/users/${id}`,
      Data.name,
      config
    );
    console.log(data);
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete USER
export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/delete/admin/users/${id}`,
      config
    );

    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
