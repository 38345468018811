import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import DesignationRowTable from "./DesignationRowTable";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(181,201,255)",
    color: theme.palette.common.white,
    borderRight: "1px solid #fff",
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "2px",
  },
}));

function DesignationRow({
  addChildInputField,
  addChild,
  defaultData,
  deleteRow,
  onchangeInput,
}) {
  return (
    <TableContainer
      component={Paper}
      style={{ width: "100%", overflow: "hidden" }}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {defaultData.map((item) => (
              <StyledTableCell align="center">{item.value}</StyledTableCell>
            ))}
            <StyledTableCell align="center">
              <AddIcon onClick={(e) => addChildInputField(e)} />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <DesignationRowTable
          addChildInputField={addChildInputField}
          addChild={addChild}
          defaultData={defaultData}
          deleteRow={deleteRow}
          onchangeInput={onchangeInput}
        />
      </Table>
    </TableContainer>
  );
}
export default DesignationRow;
