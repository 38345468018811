import { Grid, Typography, Paper, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Link, useNavigate, useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import { clearErrors } from "../../../Redux/actions/MastersActions";
import { Add, Cancel } from "@mui/icons-material";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import Features from "../AddProduct/Features";
import {
  getProductCategory,
  getProductGroup,
  getProductType,
  getProductAccount,
  getProductUnit,
  getBrands,
  getProductSegment,
  getProductSpecifications,
  getProductList,
  updateProductList,
} from "../../../Redux/actions/InventryActions";

import { getProductTerms } from "../../../Redux/actions/MastersActions";


import MultiSelect from "../../Common/MultiSelect";
import StockPrices from "../AddProduct/Stock&Prices";
import { UPDATE_ADD_PRODUCT_RESET } from "../../../Redux/constants/InventryConstant";
import axios from "axios";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { TextFieldComponent } from "../../Common/FormikComponent/TextFieldComponent";
import AutoCompleteSelect from "../../Common/FormikComponent/AutoCompleteSelect";
import Loader from "../../Common/Loader";
import draftToHtml from "draftjs-to-html";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const EditProduct = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { isAuthenticated, error } = useSelector((state) => state.admin);
  const { productType } = useSelector((state) => state.productType);
  const { productGroup } = useSelector((state) => state.productGroup);
  const { productCategory } = useSelector((state) => state.productCategory);
  const { productUnit } = useSelector((state) => state.productUnit);
  const { productTerms } = useSelector((state) => state.productTerms);
  const { brands } = useSelector((state) => state.brands);
  const { productSegmente } = useSelector((state) => state.productSegment);
  const { productSpecification } = useSelector(
    (state) => state.productSpecification
  );
  const { productAccount } = useSelector((state) => state.productAccount);
  let { id } = useParams();
  const {
    error: deleteError,
    isDeleted,
    isUpdated,
  } = useSelector((state) => state.updateProduct);
  const [demo, setDemo] = React.useState("");
  // Multiselect product specifiaction
  const [personName, setPersonName] = React.useState([]);
  const handleChangeSpecification = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };


 // Multiselect product Segment
  const [productTermsS, setProductTerms] = React.useState([]);
  const handleChangeProductTerms = (e) => {
    setProductTerms(e.target.value);
  };
  


  // Multiselect product Segment
  const [productSegment, setProductSegment] = React.useState([]);
  const handleChangeSegment = (e) => {
    setProductSegment(e.target.value);
  };
  // Stock & price state and function
  const [addChild, setAddChild] = React.useState([{ weightSelect: "gram" }]);
  const addChildInputField = (event) => {
    const id = addChild.length + 1;
    setAddChild((oldValue) => [...oldValue, { weightSelect: "gram" }]);
    event.preventDefault();
  };
  const deleteRow = (id) => {
	  
	    if(window.confirm('Are you sure?'))
	  {
    console.log(id);
    let updatedArray = addChild.filter((r, ind) => ind !== id);
    setAddChild(updatedArray);
	
	  }
	  
	  
  };
  const onchangeInput = (val, index, outerIndex) => {
    let updated2 = addChild.map((r, ind) =>
      ind === outerIndex
        ? {
            ...r,
            [index]: val.target.value,
          }
        : r
    );
	
	
	
	let updatedNew;
    if (
      val.target.name === "H" ||
      val.target.name === "B" ||
      val.target.name === "L"
    ) {
      updatedNew = updated2.map((r, ind) => {
        return ind === outerIndex
          ? {
              ...r,
              ["volume"]: r.L * r.B * r.H,
            }
          : r;
      });
    } else {
      updatedNew = updated2;
    }
	
	
	  
    setAddChild(updatedNew);
  };
  // Features

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    // let data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    // console.log(data, "qqqqqqqqqq");
    // console.log(convertToRaw(editorState.getCurrentContent()), "aseweeewew");
  };
  const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
  const onEditorStateChange2 = (editorState2) => {
    setEditorState2(editorState2);
    // let data = draftToHtml(convertToRaw(editorState2.getCurrentContent()));
    // console.log(data, "qqqqqqqqqq");
    // console.log(convertToRaw(editorState2.getCurrentContent()), "aseweeewew");
  };
  // console.log(convertToRaw(editorState.getCurrentContent()), ">>>>>>>>>>>>");

  // Image Upload functions
  const [serviceList, setServiceList] = useState([{ service: 1 }]);
  const uploadImageField = (e, index) => {
    const id = serviceList.length + 1;
    setServiceList((oldValue) => [...oldValue, { service: id }]);
    e.preventDefault();
  };
  const onchangeInputImage = async (e, index) => {
    let data = new FormData();
    data.append("name", e.target.files[0].name);
    data.append("file", e.target.files[0]);
    const image = await fetch(
      `https://erpapi.flaskitsolutions.com/api/v1/upload/user-image`,
      {
        method: "POST",
        body: data,
      }
    );
    const json = await image.json();
    console.log(serviceList);
    let updated = serviceList.map((r) =>
      r._id === index ? { ...r, url: json } : r
    );
    setServiceList(updated);
  };
  const handleServiceRemove = (id) => {
    console.log(id);
    let updatedArray = serviceList.filter((r) => r._id !== id);
    setServiceList(updatedArray);
  };
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getProductGroup());
      dispatch(getProductList());
      dispatch(getProductCategory());
      dispatch(getBrands());
      dispatch(getProductType());
      dispatch(getProductUnit());
      dispatch(getProductSegment());
      dispatch(getProductSpecifications());
      dispatch(getProductAccount());
      dispatch(getProductTerms());
    }
    if (id) {
      const get = async () => {
        const { data } = await axios.get(
          `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/add_product_details/${id}`
        );
        const DescriptionData = data.product.description[0];
        const contentDataState = ContentState.createFromBlockArray(
          convertFromHTML(DescriptionData)
        );
        const editorDataState = EditorState.createWithContent(contentDataState);
        const DescriptionData2 = data.product.uses_and_features[0];
        const contentDataState2 = ContentState.createFromBlockArray(
          convertFromHTML(DescriptionData2)
        );
        const editorDataState2 =
          EditorState.createWithContent(contentDataState2);
        setEditorState(editorDataState);
        setEditorState2(editorDataState2);
        setDemo(data.product);
        let updated = data.product.stock_and_prices.map((r) => {
          return r.weightSelect === "gram"
            ? {
                ...r,
                Weight: r.Weight.replace("gram", ""),
              }
            : {
                ...r,
                Weight: r.Weight.replace("kg", ""),
              };
        });
        setPersonName(data.product.product_specification);
        setProductSegment(data.product.product_segment);
        setProductTerms(data.product.product_terms);
        setAddChild(updated);
        setServiceList(data.product.images);
      };
      get();
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("Product Updated Successfully");
      dispatch({ type: UPDATE_ADD_PRODUCT_RESET });
      dispatch(getProductList());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [dispatch, navigate, error, alert, isAuthenticated, isUpdated, id]);

  const validationSchema = Yup.object({
    brand: Yup.string().required("Required"),
    product_title: Yup.string().required("Required"),
    product_suffix: Yup.string().required("Required"),
    hsn_code: Yup.string().required("Required"),
    remark: Yup.string(),
    product_account: Yup.string().required("Required"),
    gst: Yup.string().required("Required"),
    product_group: Yup.string().required("Required"),
    product_category: Yup.string().required("Required"),
    product_unit: Yup.string().required("Required"),
    product_type: Yup.string().required("Required"),
  });
  const initialValues = {
    product_type: demo.product_type ? demo.product_type : "",
    product_group: demo.product_group ? demo.product_group : "",
    product_category: demo.product_category ? demo.product_category : "",
    product_unit: demo.product_unit ? demo.product_unit : "",
    brand: demo.brand ? demo.brand : "",
    product_title: demo.product_title ? demo.product_title : "",
    product_suffix: demo.product_suffix ? demo.product_suffix : "",
    hsn_code: demo.hsn_code ? demo.hsn_code : "",
    gst: demo ? demo?.account_information?.gst : "",
    product_account: demo ? demo?.account_information?.product_account : "",
    remark: demo ? demo?.account_information?.remark : "",
  };
  const onSubmit = async(values) => {
    const EditorValues = convertToRaw(editorState.getCurrentContent());
    const content = draftToHtml(EditorValues);
    const EditorValues1 = convertToRaw(editorState2.getCurrentContent());
    const content1 = draftToHtml(EditorValues1);
    await dispatch(
      updateProductList(id, {
        ...values,
        account_information: {
          gst: values.gst,
          product_account: values.product_account,
          remark: values.remark,
        },
        product_specification: personName,
        product_segment: productSegment,
        product_terms: productTermsS,
        images: serviceList,
        stock_and_prices: addChild,
        uses_and_features: content1,
        description: content,
      })
    );
    navigate("/inventory/products");
    // alert.success("Product Updated Successfully");
    dispatch({ type: UPDATE_ADD_PRODUCT_RESET });
    dispatch(getProductList());
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Edit Product
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "0 24px", mt: "0" }}>
          {!demo ? (
            <Loader />
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ errors, handleChange, values, touched, setFieldValue }) => (
                  <Form>
                    <Grid
                      container
                      style={{
                        background: "#fff",
                        padding: "18px",
                        borderRadius: "10px",
                      }}
                      lg={12}
                      justifyContent="space-between"
                    >
                      <Grid lg={12} container mb={3}>
                        <Grid item lg={3}>
                          <AutoCompleteSelect
                            name="product_type"
                            data={productType}
                            error={errors.product_type && touched.product_type}
                            values={values.product_type}
                            handleChange={handleChange}
                            heading="Product Type"
                            setFieldValue={setFieldValue}
                          />
                        </Grid>
                        <Grid item lg={3}>
                          <AutoCompleteSelect
                            name="product_group"
                            data={productGroup}
                            error={
                              errors.product_group && touched.product_group
                            }
                            values={values.product_group}
                            handleChange={handleChange}
                            heading="Product Group"
                            setFieldValue={setFieldValue}
                          />
                        </Grid>
                        <Grid item lg={2}>
                          <AutoCompleteSelect
                            name="product_category"
                            data={productCategory}
                            error={
                              errors.product_category &&
                              touched.product_category
                            }
                            values={values.product_category}
                            handleChange={handleChange}
                            heading="Product Category"
                            setFieldValue={setFieldValue}
                          />
                        </Grid>
                        <Grid item lg={2}>
                          <AutoCompleteSelect
                            name="product_unit"
                            data={productUnit}
                            error={errors.product_unit && touched.product_unit}
                            values={values.product_unit}
                            handleChange={handleChange}
                            heading="Product Unit"
                            setFieldValue={setFieldValue}
                          />
                        </Grid>
                        <Grid item lg={2}>
                          <AutoCompleteSelect
                            name="brand"
                            data={brands}
                            error={errors.brand && touched.brand}
                            values={values.brand}
                            handleChange={handleChange}
                            heading="Brand"
                            setFieldValue={setFieldValue}
                          />
                        </Grid>
                      </Grid>

                      <Grid lg={12} container mb={4}>
                        <Grid item lg={3}>
                          <TextFieldComponent
                            heading="Product Title"
                            name="product_title"
                            type="text"
                            error={
                              errors.product_title && touched.product_title
                            }
                          />
                        </Grid>
                        <Grid item lg={3}>
                          <TextFieldComponent
                            heading="Title Surfix"
                            name="product_suffix"
                            type="text"
                            error={
                              errors.product_suffix && touched.product_suffix
                            }
                          />
                        </Grid>
                        <Grid item lg={2}>
                          <TextFieldComponent
                            heading="HSN Code"
                            name="hsn_code"
                            type="text"
                            error={errors.hsn_code && touched.hsn_code}
                          />
                        </Grid>
                        <Grid item lg={2}>
                          <MultiSelect
                            product={productSegmente}
                            title="Product Segment"
                            size="90%"
                            handleChange={handleChangeSegment}
                            personName={productSegment}
                          />
                        </Grid>
                        <Grid item lg={2}>
                          <MultiSelect
                            product={productSpecification}
                            title="Product Specifications"
                            size="90%"
                            handleChange={handleChangeSpecification}
                            personName={personName}
                          />
                        </Grid>
						
					
						
						
                      </Grid>

                      <Grid lg={12} container>
                        <StockPrices
                          productSpecification={personName}
                          addChild={addChild}
                          addChildInputField={addChildInputField}
                          deleteRow={deleteRow}
                          onchangeInput={onchangeInput}
                          setAddChild={setAddChild}
                        />
                      </Grid>

                      <Grid
                        lg={12}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Grid
                          container
                          lg={3}
                          sx={{ mt: 3, mb: 2 }}
                          justifyContent="flex-start"
                        >
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            Account Information
                          </Typography>
                        </Grid>
                        <Grid container lg={12} spacing={3}>
                          <Grid item lg={3} md={4}>
                            <TextFieldComponent
                              heading="Gst"
                              name="gst"
                              type="number"
                              error={errors.gst && touched.gst}
                            />
                          </Grid>
                          <Grid item lg={3} md={4}>
                            <AutoCompleteSelect
                              name="product_account"
                              data={productAccount}
                              error={
                                errors.product_account &&
                                touched.product_account
                              }
                              values={values.product_account}
                              handleChange={handleChange}
                              heading="Product Account"
                              setFieldValue={setFieldValue}
                            />
                          </Grid>
                          <Grid item lg={3} md={4}>
                            <TextFieldComponent
                              heading="Remark"
                              name="remark"
                              type="text"
                              error={errors.remark && touched.remark}
                            />
                          </Grid>
						   
						  	<Grid item lg={8}>
                          <MultiSelect
                            product={productTerms}
                            title="Product Terms"
                            size="90%"
                            handleChange={handleChangeProductTerms}
                            personName={productTermsS}
                          />
                        </Grid>
						
						
						
                        </Grid>
                      </Grid>
                      <Grid
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Grid
                          container
                          lg={12}
                          sx={{ mt: 6 }}
                          justifyContent="flex-start"
                        >
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            Upload Images
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          lg={12}
                          sx={{ mt: 3 }}
                          justifyContent="flex-start"
                        >
                          <Button
                            variant="outlined"
                            sx={{ mr: 2 }}
                            color="primary"
                            onClick={(e) => uploadImageField(e)}
                          >
                            <Add sx={{ mr: 1 }} />
                            Add
                          </Button>
                        </Grid>
                        {serviceList?.map((singleService, index) => (
                          <Grid
                            key={index}
                            container
                            lg={12}
                            sx={{ mt: 2, width: "100%" }}
                          >
                            <Grid
                              style={{
                                display: "flex",
                                flexDirection: "column",

                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "10px",
                                }}
                              >
                                <input
                                  name="Upload Image"
                                  type="file"
                                  id="service"
                                  onChange={(e) =>
                                    onchangeInputImage(e, singleService._id)
                                  }
                                />
                                <Button
                                  variant="outlined"
                                  size="small"
                                  sx={{ pl: 3, pr: 3 }}
                                  color="error"
                                  onClick={() =>
                                    handleServiceRemove(singleService._id)
                                  }
                                >
                                  <Cancel sx={{ mr: 1 }} />
                                  Remove
                                </Button>
                              </div>
                              <img
                                src={singleService.url}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                }}
                                alt="image"
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Features
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                        editorState2={editorState2}
                        onEditorStateChange2={onEditorStateChange2}
                      />

                      <Grid container justifyContent="flex-end" sx={{ mt: 7 }}>
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{ mr: 2 }}
                          color="secondary"
                        >
                          <Add sx={{ mr: 1 }} />
                          Update
                        </Button>
                        <Link to="/inventory/products">
                          <Button variant="contained" color="error">
                            <Cancel sx={{ mr: 1 }} />
                            Cancel
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default EditProduct;
