import React, { useEffect } from "react";
import BG from "../../Images/Login.png";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { login, clearErrors } from "../../Redux/actions/adminActions";
import { useAlert } from "react-alert";
import { useNavigate, useLocation } from "react-router-dom";

const theme = createTheme();

export default function Login() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const location = useLocation();

  const { error, loading, isAuthenticated, admin } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (localStorage.getItem('user')) {
      navigate("/dashboard");
    }
  }, [dispatch, navigate, error, alert, isAuthenticated]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console

    dispatch(login(data.get("username"), data.get("password")));
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        justifyContent="center"
        sx={{
          background: `url(${BG}) `,
          backgroundRepeat: " no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          minHeight: "100vh",
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              //   marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, .15)",
              backdropFilter: " blur(20px)",
              borderRadius: "10px",
              color: "white",
              padding: 3,
            }}
          >
            <Typography
              component="h1"
              variant="h4"
              sx={{ color: "Black", fontWeight: "bold" }}
            >
              Erp
            </Typography>
            <Typography component="h1" variant="body1" sx={{ color: "gray" }}>
              Log In
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                sx={{ textColor: "white" }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Log In
              </Button>
            </Box>
          </Box>
        </Container>
      </Grid>
    </ThemeProvider>
  );
}
