import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Input,
  DialogContentText,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate, useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import { Add, Cancel } from "@mui/icons-material";
import PurchaseOrderInput from "../../Common/PurchaseOrderInput";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import PurchaseOrderDropdown from "../../Common/PurchaseOrderDropdown";
import { getPaymentTerms } from "../../../Redux/actions/MastersActions";
import { getVendor } from "../../../Redux/actions/VendorAction";
import { getWarehouse } from "../../../Redux/actions/WarehouseActions";
import { getCustomers } from "../../../Redux/actions/CustomerActions";
import AddPurchaseOrderTable from "../../PurchaseOrders/AddPurchaseOrderTable";
import { getEndProductList } from "../../../Redux/actions/InventryActions";
import AddPurchaseOrderItem from "../../PurchaseOrders/AddPurchaseOrderItem";
import axios from "axios";
import {
  getSalesInvoice,
  updatePerformaInvoice,
} from "../../../Redux/actions/SalesActions";
import Loader from "../../Common/Loader";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Formik, Form } from "formik";
import { TextFieldComponent } from "../../Common/FormikComponent/TextFieldComponent";
import AutoCompleteSelect from "../../Common/FormikComponent/AutoCompleteSelect";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const EditPerfromaInvoice = () => {
  let { id } = useParams();
  const [demo, setDemo] = useState("");
  const [value, setValue] = React.useState(new Date());
  const [delivreyDate, setDeliveryDate] = React.useState(new Date());
  const [PO, setPO] = React.useState("");
  const [refrence, setRefrence] = React.useState("");
  const [shippingPrefrence, setShippingPrefrence] = React.useState("");
  const [paymentterms, setPaymentterms] = React.useState("");
  const [delivery, setDelivery] = React.useState("");
  const [deliveryNote, setDeliveryNote] = React.useState("");
  const [billing, setBilling] = React.useState("");
  const [buyerOrder, setBuyerOrder] = React.useState("");
  const [dispatchNo, setDispatchNo] = React.useState("");
  const [destination, setDestination] = React.useState("");
  const [terms, setTerms] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [isModal, setIsModal] = useState(false);
  const [post, setPost] = useState("");
  const [subTotal, setSubTotal] = useState("");

  const [item, setItem] = useState("");
  const [cust, setCust] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const handleChange2 = (newValue) => {
    setDeliveryDate(newValue);
  };
  const roal = (first, id) => {
    setIsModal(first);
    setItem(id);
  };
  const handleChange1 = (newValue) => {
    setValue(newValue);
  };
  const [details, setDetails] = useState({
    adjustment: "",
    total: "",
    forwarding_charges: "",
    company_name: "",
  });
  // Table Data
  const [addChild, setAddChild] = React.useState([
    { value: 1, discount_selected: "Rs" },
  ]);
  const addChildInputField = (event) => {
    const id = addChild.length + 1;
    setAddChild((oldValue) => [
      ...oldValue,
      { value: id, discount_selected: "Rs" },
    ]);
    event.preventDefault();
  };
  const deleteRow = (id) => {
    let updatedArray = addChild.filter((r, ind) => ind !== id);
    setAddChild(updatedArray);
    let sum = updatedArray.reduce((a, v) => (a = a + v.amount), 0);
    setSubTotal(sum);
    let addSum = 0;
    let TotalAddSum = 0;
    updatedArray.map((item, ind) => {
      return (addSum = addSum + (item?.amount * 100) / (100 + item?.tax));
    });
    updatedArray.map((item, ind) => {
      let my =
        ((Number(details.forwarding_charges) / addSum) * (item?.amount * 100)) /
          (100 + item?.tax) +
        (item?.amount * 100) / (100 + item?.tax);
      return (TotalAddSum += my + (my * item?.tax) / 100);
    });
    setDetails((old) => ({
      ...old,
      forwarding_charges: Number(details.forwarding_charges),
      total: TotalAddSum,
    }));
  };
  const onchangeInput = (val, index, outerIndex) => {
    let updated = addChild.map((r, ind) =>
      ind === outerIndex
        ? {
            ...r,
            [index]: val.target.value,
            ["amount"]:
              !(val.target.value === "Rs") &&
              (val.target.value === "%" || r.discount_select === "%")
                ? index === "quantity"
                  ? (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (r.discount === undefined ? 0 : r.discount / 100)
                  : index === "rate"
                  ? (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.quantity === undefined ? 0 : r.quantity)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.quantity === undefined ? 0 : r.quantity)) *
                      (r.discount === undefined ? 0 : r.discount / 100)
                  : index === "discount"
                  ? (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (val.target.value / 100)
                  : (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (r.discount === undefined ? 0 : r.discount / 100)
                : val.target.value === "Rs"
                ? (r.rate === undefined ? 0 : r.rate) *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  (r.discount === undefined ? 0 : r.discount)
                : index === "quantity"
                ? val.target.value * (r.rate === undefined ? 0 : r.rate) -
                  (r.discount === undefined ? 0 : r.discount)
                : index === "discount"
                ? (r.rate === undefined ? 0 : r.rate) *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  val.target.value
                : val.target.value *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  (r.discount === undefined ? 0 : r.discount),
            ["ordered"]: r.quantity,
          }
        : r
    );
    setAddChild(updated);
    let sum = updated.reduce((a, v) => (a = a + v.amount), 0);
    setSubTotal(sum);

    let addSum = 0;
    let TotalAddSum = 0;
    updated.map((item, ind) => {
      return (addSum = addSum + (item?.amount * 100) / (100 + item?.tax));
    });
    updated.map((item, ind) => {
      let my =
        (((val.target.name === "forwarding"
          ? val.target.value
          : details.forwarding_charges) /
          addSum) *
          (item?.amount * 100)) /
          (100 + item?.tax) +
        (item?.amount * 100) / (100 + item?.tax);
      return (TotalAddSum += my + (my * item?.tax) / 100);
    });
    setDetails((old) => ({
      ...old,
      forwarding_charges:
        val.target.name === "forwarding"
          ? val.target.value
            ? Number(val.target.value)
            : ""
          : Number(details.forwarding_charges),
      total: TotalAddSum,
    }));
    if (details.forwarding_charges === "") {
      setDetails((old) => ({
        ...old,
        total: sum,
      }));
    }
  };
  const { isAuthenticated } = useSelector((state) => state.admin);
  const { paymentTerms } = useSelector((state) => state.paymentTerms);
  const { endProductList } = useSelector((state) => state.endProductList);
  const [obj, setObj] = React.useState({
    email: "",
    mobile: "",
    gst_number: "",
    company_name: "",
  });

  const [getCust, setgeCustomer] = React.useState("");
  const [personName, setPersonName] = React.useState([]);
  const handleChangeMulti = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };
  const [bank, setBank] = React.useState("");
  const [getBank, setBankAcoount] = React.useState("");

  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getPaymentTerms());
      dispatch(getVendor());
      dispatch(getWarehouse());
      dispatch(getSalesInvoice());
      dispatch(getCustomers());
      dispatch(getEndProductList());
      axios
        .get("https://erpapi.flaskitsolutions.com/api/v1/admin/bank_account/details")
        .then((res) => {
          setBankAcoount(res.data.BankAccountDetails);
        });
      axios
        .get(
          "https://erpapi.flaskitsolutions.com/api/v1/admin/customer/shipping_billing/address"
        )
        .then((res) => {
          setgeCustomer(res.data.data);
        });
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
    if (id) {
      const get = async () => {
        const { data } = await axios.get(
          `https://erpapi.flaskitsolutions.com/api/v1/admin/add_performa_order/details/${id}`
        );
        setDemo(data.performaInvoice);
        setBank(data.performaInvoice.bank_account_details);
        setRefrence(data.performaInvoice.ref);
        setShippingPrefrence(data.performaInvoice.shipment_preference);
        setPaymentterms(data.performaInvoice.payment_terms);
        setSubTotal(data.performaInvoice.sub_total);
        setPost(data.performaInvoice.performa_invoice_number);
        setTerms(data.performaInvoice.terms_and_condition);
        setNotes(data.performaInvoice.notes);
        setValue(data.performaInvoice.createdAt);
        setDeliveryDate(data.performaInvoice.delivery_note_date);
        setAddChild(data.performaInvoice.item_details);
        setDeliveryNote(data.performaInvoice.delivery_note);
        setDispatchNo(data.performaInvoice.dispatch_doc_no);
        setBuyerOrder(data.performaInvoice.buyers_order_no);
        setDestination(data.performaInvoice.destination);
        setContactPerson(data.performaInvoice.contact_persons);
        setDetails((old) => ({
          ...old,
          total: data.performaInvoice.total,
          forwarding_charges: data.performaInvoice.forwarding_charges,
        }));
        setCust(data.performaInvoice.customer);
        setDelivery(data.performaInvoice.delivery_to);
        setBilling(data.performaInvoice.billing_to);
        setPersonName(data.performaInvoice.contact_persons);
      };
      get();
    }
    if (details.forwarding_charges === "") {
      setDetails((old) => ({
        ...old,
        total: subTotal,
      }));
    }
  }, [dispatch, isAuthenticated, id, details.forwarding_charges]);
  const [contactPerson, setContactPerson] = React.useState("");
  const custFunc = (v) => {
    setCust(v.target.value);
    let update = getCust?.find((r) => r.name === v.target.value);
    setBilling(update.customer_billing_address);
    setDelivery(update.customer_shipping_address);
    setObj({
      email: update.email,
      mobile: update.mobile,
      gst_number: update.gst_number,
      company_name: update.company_name,
    });
    setContactPerson(update.contact_persons);
  };

  const validationSchema = Yup.object({
    notes: Yup.string(),
    terms_and_condition: Yup.string(),
    ref: Yup.string(),
    payment_terms: Yup.string(),
    shipment_preference: Yup.string(),
    delivery_note: Yup.string(),
    buyers_order_no: Yup.string(),
    dispatch_doc_no: Yup.string(),
    destination: Yup.string(),
  });
  const initialValues = {
    payment_terms: demo.payment_terms ? demo.payment_terms : "",
    shipment_preference: demo.shipment_preference
      ? demo.shipment_preference
      : "",
    ref: demo.ref ? demo.ref : "",
    delivery_note: demo.delivery_note ? demo.delivery_note : "",
    terms_and_condition: demo.terms_and_condition
      ? demo.terms_and_condition
      : "",
    notes: demo.notes ? demo.notes : "",
    buyers_order_no: demo.buyers_order_no ? demo.buyers_order_no : "",
    dispatch_doc_no: demo.dispatch_doc_no ? demo.dispatch_doc_no : "",
    destination: demo.destination ? demo.destination : "",
  };

  const onSubmit = (values) => {
    dispatch(
      updatePerformaInvoice(id, {
        ...values,
        sub_total: subTotal,
        item_details: addChild,
        invoice_number: post,
        date: value,
        delivery_note_date: delivreyDate,
        customer: cust,
        delivery_to: delivery,
        billing_to: billing,
        email: obj.email,
        mobile: obj.mobile,
        gst_number: obj.gst_number,
        contact_persons: personName,
        adjustment: details.adjustment,
        total: details.total,
        forwarding_charges: details.forwarding_charges,
        company_name: obj.company_name,
        bank_account_details: bank,
      })
    );
    navigate("/sales/performa-invoices");
  };

  const returnHandle = () => {
    navigate("/sales/performa-invoices");
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <DrawerErp />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Grid container sx={{ p: 3 }}>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Add Sale Order
              </Typography>
            </Grid>
          </Grid>

          {!demo ? (
            <Loader />
          ) : (
            <Grid container sx={{ padding: "0 24px", mt: "0" }}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ errors, handleChange, values, touched, setFieldValue }) => (
                  <Form>
                    <Grid item xs={12}>
                      <Item>
                        {/* <AccountInfo /> */}
                        <Grid container lg={12} justifyContent="space-between">
                          <Grid
                            container
                            lg={4.9}
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: "10px",
                              paddingLeft: "8px",
                              paddingTop: "8px",
                            }}
                          >
                            <Grid container lg={12} spacing={2} mb={2}>
                              <Grid xs={12} item>
                                <PurchaseOrderDropdown
                                  size="100%"
                                  heading="Select Customer"
                                  data={getCust}
                                  setInput={custFunc}
                                  cu="cust"
                                  name={cust}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <PurchaseOrderInput
                                  heading="Billing To:"
                                  name={billing}
                                  setInput={setBilling}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <PurchaseOrderInput
                                  heading="Delivery To:"
                                  name={delivery}
                                  setInput={setDelivery}
                                />
                              </Grid>
                              <Grid item lg={6}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "90%",
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      sx={{ mb: 1 }}
                                    >
                                      Contact Person
                                    </Typography>
                                  </div>
                                  <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple
                                    sx={{ width: "100%" }}
                                    size="small"
                                    value={personName}
                                    onChange={handleChangeMulti}
                                  >
                                    {contactPerson
                                      ? contactPerson.map((name) => (
                                          <MenuItem key={name} value={name}>
                                            {name}
                                          </MenuItem>
                                        ))
                                      : ""}
                                  </Select>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* Second Row */}

                          <Grid
                            container
                            lg={7}
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: "10px",
                              paddingLeft: "8px",
                              paddingTop: "8px",
                            }}
                          >
                            <Grid container lg={12} sx={{ mb: 2 }} spacing={1}>
                              <Grid item xs={4}>
                                <PurchaseOrderInput
                                  heading="Invoice#"
                                  name={post}
                                  post={post}
                                  setInput={setPO}
                                  placeholder="Invoice#"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextFieldComponent
                                  heading="Refrence#"
                                  name="ref"
                                  sales={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  type="text"
                                  error={errors.ref && touched.ref}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  flexDirection: "column",
                                }}
                              >
                                <DialogContentText
                                  style={{ marginBottom: "5px" }}
                                >
                                  Date
                                </DialogContentText>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DesktopDatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={value}
                                    disabled={
                                      demo.dispatch_status ===
                                      "Order Dispatched"
                                        ? true
                                        : false
                                    }
                                    onChange={handleChange1}
                                    renderInput={(params) => (
                                      <TextField {...params} size="small" />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>

                            <Grid container lg={12} sx={{ mb: 2 }} spacing={1}>
                              <Grid item xs={4}>
                                <TextFieldComponent
                                  heading="Buyer's Order No"
                                  name="buyers_order_no"
                                  type="text"
                                  sales={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  error={
                                    errors.buyers_order_no &&
                                    touched.buyers_order_no
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <AutoCompleteSelect
                                  name="payment_terms"
                                  data={paymentTerms}
                                  error={
                                    errors.payment_terms &&
                                    touched.payment_terms
                                  }
                                  values={values.payment_terms}
                                  handleChange={handleChange}
                                  sales={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  heading="Payment Terms"
                                  setFieldValue={setFieldValue}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextFieldComponent
                                  heading="Disptach Doc No."
                                  name="dispatch_doc_no"
                                  type="text"
                                  sales={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  error={
                                    errors.dispatch_doc_no &&
                                    touched.dispatch_doc_no
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid container lg={12} sx={{ mb: 2 }} spacing={1}>
                              <Grid item xs={3}>
                                <TextFieldComponent
                                  heading="Destination"
                                  name="destination"
                                  type="text"
                                  sales={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  error={
                                    errors.destination && touched.destination
                                  }
                                />
                              </Grid>

                              <Grid item xs={3}>
                                <TextFieldComponent
                                  heading="Shipping Preference"
                                  name="shipment_preference"
                                  type="text"
                                  sales={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  error={
                                    errors.shipment_preference &&
                                    touched.shipment_preference
                                  }
                                />
                              </Grid>

                              <Grid item xs={3}>
                                <TextFieldComponent
                                  heading="Delivery Note"
                                  name="delivery_note"
                                  type="text"
                                  sales={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  disabled={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  error={
                                    errors.delivery_note &&
                                    touched.delivery_note
                                  }
                                />
                              </Grid>

                              <Grid item xs={3}>
                                <DialogContentText
                                  style={{
                                    marginBottom: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Delivery Note Date
                                </DialogContentText>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DesktopDatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={delivreyDate}
                                    disabled={
                                      demo.dispatch_status ===
                                      "Order Dispatched"
                                        ? true
                                        : false
                                    }
                                    onChange={handleChange2}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        style={{ width: "80%" }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 3 }}>
                          <AddPurchaseOrderTable
                            addChild={addChild}
                            onchangeInput={onchangeInput}
                            deleteRow={deleteRow}
                            addChildInputField={addChildInputField}
                            setAddChild={setAddChild}
                            setIsModal={roal}
                            defaultData={defaultData}
                            sales="sales"
                            di={
                              demo.dispatch_status === "Order Dispatched"
                                ? true
                                : false
                            }
                            data={demo}
                          />
                        </Grid>
                        <Grid container sx={{ mt: 3 }}>
                          {isModal === false ? (
                            ""
                          ) : (
                            <AddPurchaseOrderItem
                              addChild={addChild}
                              setAddChild={setAddChild}
                              data={endProductList}
                              setIsModal={setIsModal}
                              index={item}
                              sales="sales"
                            />
                          )}
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                          sx={{ mt: 3 }}
                        >
                          <Grid xs={4}>
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography fontWeight="700" marginRight="10px">
                                Sub Total :
                              </Typography>
                              <TextField
                                type={"number"}
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="outlined-read-only-input"
                                size="small"
                                placeholder="0.0"
                                value={subTotal}
                                variant="outlined"
                                sx={{ width: "70%", background: "#fff" }}
                                onChange={(e) => setSubTotal(e.target.value)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                          sx={{ mt: 3 }}
                        >
                          <Grid xs={4}>
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography fontWeight="700" marginRight="10px">
                                forwarding Charges :
                              </Typography>
                              <TextField
                                type={"number"}
                                size="small"
                                placeholder="forwarding"
                                variant="outlined"
                                name="forwarding"
                                value={details.forwarding_charges}
                                sx={{ width: "70%", background: "#fff" }}
                                onChange={(e) => onchangeInput(e)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                          sx={{ mt: 3 }}
                        >
                          <Grid xs={4}>
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography fontWeight="700" marginRight="10px">
                                Total :
                              </Typography>
                              <TextField
                                type={"number"}
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="outlined-read-only-input"
                                size="small"
                                placeholder="0.0"
                                variant="outlined"
                                name="total"
                                value={details.total}
                                sx={{ width: "70%", background: "#fff" }}
                                onChange={(e) => onchangeInput(e)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid lg={7} md={12} item>
                          <div
                            className="form-control"
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <DialogContentText mb={2}>
                              Select Bank Account
                            </DialogContentText>
                            <Autocomplete
                              size="small"
                              value={bank}
                              options={getBank}
                              getOptionLabel={(option) => option}
                              // defaultValue={bank}
                              onChange={(e, v) => setBank(v)}
                              sx={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  width="100%"
                                  onChange={({ target }) =>
                                    setBank(target.value)
                                  }
                                  variant="outlined"
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid container sx={{ mt: 3 }}>
                          <Grid container lg={12}>
                            <TextFieldComponent
                              heading="Terms and Condition"
                              name="terms_and_condition"
                              type="text"
                              sales={
                                demo.dispatch_status === "Order Dispatched"
                                  ? true
                                  : false
                              }
                              error={
                                errors.terms_and_condition &&
                                touched.terms_and_condition
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 3 }}>
                          <Grid container lg={12}>
                            <TextFieldComponent
                              heading="Notes"
                              name="notes"
                              type="text"
                              sales={
                                demo.dispatch_status === "Order Dispatched"
                                  ? true
                                  : false
                              }
                              error={errors.notes && touched.notes}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          justifyContent="flex-end"
                          sx={{ mt: 3 }}
                        >
                          <Button
                            variant="contained"
                            sx={{ mr: 2 }}
                            color="secondary"
                            type="submit"
                          >
                            <Add sx={{ mr: 1 }} />
                            Update
                          </Button>
                          <Button
                            variant="contained"
                            onClick={returnHandle}
                            color="error"
                          >
                            <Cancel sx={{ mr: 1 }} />
                            Cancel
                          </Button>
                        </Grid>
                      </Item>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default EditPerfromaInvoice;

const defaultData = [
  {
    value: "item",
  },
  {
    value: "add_Desc",
  },
  {
    value: "quantity",
  },
  {
    value: "rate",
  },
  {
    value: "discount",
  },
  {
    value: "tax",
  },
  {
    value: "amount",
  },
];
