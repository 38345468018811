import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { TextField } from "@mui/material";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(181,201,255)",
    color: theme.palette.common.white,
    borderRight: "1px solid #fff",
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "2px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const defaultData = [
  {
    value: "Document",
  },
  {
    value: "Image",
  },
];
const DocumentTable = ({
  addDocumentInputField,
  addDocument,
  deleteRowDocument,
  onchangeInputDoucment,
}) => {
  return (
    <TableContainer
      component={Paper}
      style={{ width: "100%", overflow: "hidden" }}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {defaultData.map((item) => (
              <StyledTableCell align="center">{item.value}</StyledTableCell>
            ))}
            <StyledTableCell align="center">
              <AddIcon onClick={(e) => addDocumentInputField(e)} />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addDocument?.map((item, index) => {
            return (
              <StyledTableRow>
                <StyledTableCell align="center">
                  <TextField
                    type="text"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder={"doc_name"}
                    value={addDocument[index]["doc_name"]}
                    sx={{
                      width: "100%",
                      mr: 1,
                      background: "#fff",
                    }}
                    onChange={(val) =>
                      onchangeInputDoucment(val, "doc_name", index)
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <input
                    accept="image/*"
                    style={{
                      border: "1px solid gray",
                      padding: "6px",
                      width: "100%",
                      background: "#fff",
                      borderRadius: "5px",
                    }}
                    type="file"
                    id="fileInput"
                    onChange={(e) =>
                      onchangeInputDoucment(e.target.files[0], "image",index)
                    }
                  />
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  onClick={() => deleteRowDocument(index)}
                >
                  <RemoveIcon />
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentTable;
