import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import StockReportDrawerTable from "./StockReportDrawerTable";
import Loading from "../../Common/Loader";

const StockReportDrawer = ({ toggleDrawer, code, name }) => {
  const [demo, setDemo] = React.useState("");
  const [purchaseSummaryData, setPurchaseSummaryData] = React.useState([]);
  const [saleSummaryData, setSaleSummaryData] = React.useState([]);
  React.useEffect(() => {
    const get = async () => {
      const { data } = await axios.get(
        `https://erpapi.flaskitsolutions.com/api/v1/admin/stock/report/summary?product_code_unique=${code}`
      );
      setPurchaseSummaryData(data.purchaseSummary);
      setSaleSummaryData(data.salesSummary);
      setDemo(data.allGrns);
    };
    get();
  }, []);
  return (
    <>
      {!demo ? (
        <Loading />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "6px 35px",
              fontSize: "18px",
              borderBottom: "1px solid gray",
            }}
          >
            <p>Summary Details</p>
            <CloseIcon onClick={toggleDrawer("right", false)} />
          </div>
          <p style={{ padding: "0 10px", fontWeight: "700", fontSize: "18px" }}>
            {name}
          </p>
          <div>
            <StockReportDrawerTable
              purchaseSummaryData={purchaseSummaryData}
              saleSummaryData={saleSummaryData}
            />
          </div>
          <div style={{ padding: "0 10px" }}>
            <p>
              ALL GRN Barode:
              <span
                style={{
                  wordBreak: "break-all",
                  fontWeight: "700",
                }}
              >
                {demo[0]?.product_codes?.map((r) => r)}
                {demo[0]?.scanned_barcode?.map((r) => r)}
              </span>{" "}
            </p>
          </div>

          <div style={{ padding: "0 10px" }}>
            <p>
              ALL Scan Barode:
              <span
                style={{
                  wordBreak: "break-all",
                  fontWeight: "700",
                }}
              >
                {demo[0]?.scanned_barcode.map((r) => r)}
              </span>{" "}
            </p>
          </div>

          <div style={{ padding: "0 10px" }}>
            <p>
              Remain Scan :
              <span
                style={{
                  wordBreak: "break-all",
                  fontWeight: "700",
                }}
              >
                {demo[0]?.product_codes?.map((r) => r)}
              </span>{" "}
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default StockReportDrawer;
