import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useReactToPrint } from "react-to-print";
import { Grid } from "@mui/material";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { getStockReportSummary } from "../../../";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
export default function StockReportDrawerTable({
  purchaseSummaryData,
  saleSummaryData,
}) {
  return (
    <>
      <Grid
        container
        lg={12}
        display="flex"
        justifyContent="space-around"
        marginTop={2}
      >
        <Grid container style={{display:"flex",flexDirection:"column" ,justifyContent:"flex-start"}} lg={5}>
          <Typography
            sx={{
              fontWeight: "bolder",
              fontSize: "larger",
              color: "red",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              alignSelf:"flex-start"
            }}
          >
            Purchase
            <ArrowDownwardIcon />
          </Typography>
          {purchaseSummaryData.map((item) => {
            let OrderedTotal = 0;
            return (
              <Grid
                container
                flexDirection="column"
                style={{ width: "100%", paddingLeft: "4px",marginBottom:"10px" }}
              >
                <Typography style={{ fontSize: "15px" }}>
                  PO No:<span style={{ fontWeight: "700" }}>{item.po}</span>{" "}
                </Typography>
                {item.item_details.map((subItem) => {
                  OrderedTotal += subItem.ordered;
                })}
                <Typography style={{ fontSize: "15px" }}>
                  QTY: <span style={{ fontWeight: "700" }}>{OrderedTotal}</span>
                </Typography>
                <Typography style={{ fontSize: "15px" }}>
                  Purchased At:{" "}
                  <span style={{ fontWeight: "700" }}>
                    {" "}
                    {new Date(item.date).toLocaleDateString("en-GB", 2)}
                  </span>
                </Typography>
                <Typography style={{ fontSize: "15px" }}>
                  From: <span style={{ fontWeight: "700" }}>{item.vendor}</span>
                </Typography>
                <Typography style={{ fontSize: "15px" }}>GRN:</Typography>
                <Grid sx={{ pl: 3 }}>
                  <Typography variant="subtitle2" style={{ fontSize: "15px" }}>
                    QTY: <span style={{ fontWeight: "700" }}>{OrderedTotal}</span>
                  </Typography>
                  <Typography variant="subtitle2" style={{ fontSize: "15px" }}>
                    Received At:{" "}
                    <span style={{ fontWeight: "700" }}>
                      {" "}
                      {new Date(item.date).toLocaleDateString("en-GB", 2)}
                    </span>
                  </Typography>
                  <Typography variant="subtitle2" style={{ fontSize: "15px" }}>
                    BarCode:
                  </Typography>
                </Grid>
                <Divider sx={{ mt: 2 }} />
              </Grid>
            );
          })}
        </Grid>
        <Divider orientation="vertical"  flexItem />
        <Grid container lg={6} style={{display:"flex",flexDirection:"column" ,justifyContent:"flex-start"}} >
          <Typography
            sx={{
              fontWeight: "bolder",
              fontSize: "larger",
              color: "green",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              alignSelf:"flex-start"
              ,marginBottom:"10px"
            }}
          >
            Sale
            <ArrowUpwardIcon />
          </Typography>
          {saleSummaryData.map((item) => {
            let OrderedTotal = 0;
            return (
              <Grid
                container
                flexDirection="column"
                style={{ width: "100%", paddingLeft: "4px" }}
              >
                <Typography style={{ fontSize: "15px" }}>
                  Invoice No:
                  <span style={{ fontWeight: "700" }}>
                    {" "}
                    {item.invoice_number}
                  </span>
                </Typography>
                {item.item_details.map((subItem) => {
                  OrderedTotal += subItem.quantity;
                })}
                <Typography style={{ fontSize: "15px" }}>
                  QTY:<span style={{ fontWeight: "700" }}>{OrderedTotal}</span>{" "}
                </Typography>
                <Typography style={{ fontSize: "15px" }}>
                  Sale At:{" "}
                  <span style={{ fontWeight: "700" }}>
                    {new Date(item.date).toLocaleDateString("en-GB", 2)}
                  </span>
                </Typography>
                <Typography style={{ fontSize: "15px" }}>
                  To:<span style={{ fontWeight: "700" }}>{item.customer}</span>{" "}
                </Typography>
                <Typography style={{ fontSize: "15px" }}>
                  Location Scan:
                  <span
                    style={{
                      wordBreak: "break-all",
                      fontWeight: "700",
                    }}
                  >
                    {" "}
                    {`${item.scanned_barcode},`}
                  </span>
                </Typography>
                <Typography style={{ fontSize: "15px" }}>
                  Dispatch Scan:
                  <span
                    style={{
                      wordBreak: "break-all",
                      fontWeight: "700",
                    }}
                  >
                    {" "}
                    {`${item.scanned_barcode},`}
                  </span>
                </Typography>
                <Divider sx={{ mt: 2 }} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}
