import {
    STOCK_REPORT_REQUEST,
    STOCK_REPORT_SUCCESS,
    STOCK_REPORT_FAIL,
    STOCK_REPORT_SUMMARY_REQUEST,
    STOCK_REPORT_SUMMARY_SUCCESS,
    STOCK_REPORT_SUMMARY_FAIL
} from "../constants/StockReportConstants";
import {
    CLEAR_ERRORS
} from "../constants/MastersConstants";







// Stock Report Reduceres
 export const stockReportReducer = (state = { stockReport: [] }, action) => {
    switch (action.type) {
      case STOCK_REPORT_REQUEST:
        return { loading: true, stockReport: [] };
      case STOCK_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
          stockReport: action.payload,
        };
      case STOCK_REPORT_FAIL:
        return {
          ...state,
          loading: false,
          stockReport: null,
          error: action.payload,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };


  // Stock Report Summary Reduceres
 export const stockReportSummaryReducer = (state = { stockReportSummary: [] }, action) => {
  switch (action.type) {
    case STOCK_REPORT_SUMMARY_REQUEST:
      return { loading: true, stockReportSummary: [] };
    case STOCK_REPORT_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        stockReportSummary: action.payload,
      };
    case STOCK_REPORT_SUMMARY_FAIL:
      return {
        ...state,
        loading: false,
        stockReportSummary: null,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};