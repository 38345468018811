export const PERFORMA_INVOICE_REQUEST = "PERFORMA_INVOICE_REQUEST";
export const PERFORMA_INVOICE_SUCCESS = "PERFORMA_INVOICE_SUCCESS";
export const PERFORMA_INVOICE_FAIL = "PERFORMA_INVOICE_FAIL";

export const NEW_PERFORMA_INVOICE_REQUEST = "NEW_PERFORMA_INVOICE_REQUEST";
export const NEW_PERFORMA_INVOICE_SUCCESS = "NEW_PERFORMA_INVOICE_SUCCESS";
export const NEW_PERFORMA_INVOICE_FAIL = "NEW_PERFORMA_INVOICE_FAIL";
export const NEW_PERFORMA_INVOICE_RESET = "NEW_PERFORMA_INVOICE_RESET";

export const UPDATE_PERFORMA_INVOICE_REQUEST = "UPDATE_PERFORMA_INVOICE_REQUEST";
export const UPDATE_PERFORMA_INVOICE_SUCCESS = "UPDATE_PERFORMA_INVOICE_SUCCESS";
export const UPDATE_PERFORMA_INVOICE_FAIL = "UPDATE_PERFORMA_INVOICE_FAIL";
export const UPDATE_PERFORMA_INVOICE_RESET = "UPDATE_PERFORMA_INVOICE_RESET";

export const TAX_INVOICE_REQUEST = "TAX_INVOICE_REQUEST";
export const TAX_INVOICE_SUCCESS = "TAX_INVOICE_SUCCESS";
export const TAX_INVOICE_FAIL = "TAX_INVOICE_FAIL";

export const NEW_TAX_INVOICE_REQUEST = "NEW_TAX_INVOICE_REQUEST";
export const NEW_TAX_INVOICE_SUCCESS = "NEW_TAX_INVOICE_SUCCESS";
export const NEW_TAX_INVOICE_FAIL = "NEW_TAX_INVOICE_FAIL";
export const NEW_TAX_INVOICE_RESET = "NEW_TAX_INVOICE_RESET";

export const UPDATE_TAX_INVOICE_REQUEST = "UPDATE_TAX_INVOICE_REQUEST";
export const UPDATE_TAX_INVOICE_SUCCESS = "UPDATE_TAX_INVOICE_SUCCESS";
export const UPDATE_TAX_INVOICE_FAIL = "UPDATE_TAX_INVOICE_FAIL";
export const UPDATE_TAX_INVOICE_RESET = "UPDATE_TAX_INVOICE_RESET";
