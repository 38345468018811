import {
    PURCHASE_REPORT_FAIL,
    PURCHASE_REPORT_REQUEST,
    PURCHASE_REPORT_SUCCESS,
  } from "../constants/PurchaseReportConstants";

export const PurchaseReportReducer = (state = { purchaseReport:[]}, action) => {
    switch (action.type) {
      case PURCHASE_REPORT_REQUEST:
        return {
          ...state,
          purchaseReport:[],
          loading: true,

        };
      case PURCHASE_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
          purchaseReport: action.payload,            
        };
  
      case PURCHASE_REPORT_FAIL:
        return {
          ...state,
          loading: false,
          purchaseReport: null,
          error: action.payload,

        };
  
      default:
        return state;
    }
  };