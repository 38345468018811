import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../Redux/actions/MastersActions";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import GRNDrawer from "./GRNDrawer/GRNDrawer";
import { getReceiveGrn } from "../../Redux/actions/GrnActions";
import Loader from "../Common/Loader";
import { SwipeableDrawer } from "@mui/material";
import { getPurchaseOrder } from "../../Redux/actions/PurchaseOrderAction";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "received_date",
    numeric: false,
    disablePadding: true,
    label: "Received Date",
  },
  {
    id: "grn",
    numeric: false,
    disablePadding: true,
    label: "GRN NO",
  },
  {
    id: "vendor",
    numeric: false,
    disablePadding: true,
    label: "Vendor",
  },
  {
    id: "warehouse",
    numeric: false,
    disablePadding: true,
    label: "Warehouse",
  },
  {
    id: "notes",
    numeric: false,
    disablePadding: true,
    label: "Notes",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "1px" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ReceivedGRN() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("brand");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, loading, grn } = useSelector((state) => state.grn);
  const { error: adminError, purchaseOrders } = useSelector(
    (state) => state.purchaseOrders
  );
  const purchaseOrdersId = purchaseOrders.map((item) => `${item._id}`);
  // console.log(grn,"grn")
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    // if(isAuthenticated){
    //   dispatch(getPurchaseOrder())
    // }
    if (purchaseOrders) {
      dispatch(getReceiveGrn(purchaseOrdersId[0]));
    }

    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [dispatch, navigate, error, alert, isAuthenticated, purchaseOrders]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = grn.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - grn.length) : 0;

  var perPageData = [];
  var length = Math.ceil(grn.length / 3);

  for (var i = 1; i <= length; i++) {
    perPageData.push(i * 3);
  }
  const [state, setState] = React.useState({
    right: false,
  });
  const [data, setData] = React.useState("");
  const toggleDrawer = (anchor, open, item) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    // setData(item);
  };
  const list = (anchor, item) => (
    <Box
      sx={{ width: 1200 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
    >
      <GRNDrawer id={data} item={data} toggleDrawer={toggleDrawer} />
    </Box>
  );
  return (
    <>
      {adminError ? (
        <Grid container xs={12} justifyContent="center" alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: "bold", color: "red" }}>
            You don't have the required permissions.
          </Typography>
        </Grid>
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <Box sx={{ width: "100%", border: "solid black", p: 2 }}>
              <Paper
                sx={{ width: "100%", mb: 2, boxShadow: "none", border: "none" }}
              >
                <Grid
                  container
                  lg={3}
                  sx={{ mt: 2, ml: 2 }}
                  justifyContent="flex-start"
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Received
                  </Typography>
                </Grid>
                <EnhancedTableToolbar numSelected={selected?.length} />
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected?.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={grn?.length}
                    />

                    <TableBody>
                      {stableSort(grn, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, index) => {
                          const isItemSelected = isSelected(item?.vendor);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={item?._id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  onClick={(event) =>
                                    handleClick(event, item?.vendor)
                                  }
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                // component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                // component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {new Date(
                                  item?.received_date
                                ).toLocaleDateString("en-GB", 2)}
                              </TableCell>

                              <TableCell
                                // component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                <div>
                                  <Typography
                                    style={{
                                      cursor: "pointer",
                                      color: "#1976d2",
                                    }}
                                    onClick={toggleDrawer("right", true, item)}
                                  >
                                    <div onClick={() => setData(item?._id)}>
                                      {item?.grn}
                                    </div>
                                  </Typography>
                                  <SwipeableDrawer
                                    anchor="right"
                                    open={state["right"]}
                                    onClose={toggleDrawer("right", false)}
                                    onOpen={toggleDrawer("right", true)}
                                  >
                                    {list("right", item)}
                                  </SwipeableDrawer>
                                </div>
                              </TableCell>
                              <TableCell
                                // component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {item?.vendor}
                              </TableCell>
                              <TableCell
                                // component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {item?.warehouse}
                              </TableCell>
                              <TableCell
                                // component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {item?.note}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={perPageData}
                  component="div"
                  count={grn.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          )}
        </>
      )}
    </>
  );
}
