import {
  ACCORDION_REQUEST,
  ACCORDION_RESET,
  ACCORDION_SUCCESS,
} from "../constants/AccordionConstants";

// Create Customers
export const accordionReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCORDION_REQUEST:
      return {
        ...state,
        expanded: "panel1",
      };
    case ACCORDION_SUCCESS:
      return {
        ...state,
        expanded: action.payload,
      };
    default:
      return state;
  }
};
