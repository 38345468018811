import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
const TableExpand = ({ expandData, text }) => {
  return (
    <Box>
    <Table>
      <TableHead>
        <TableRow>
          {(text === "comman" ? headCells : headCells2).map((r) => {
            return <TableCell>{r.label}</TableCell>;
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {expandData.map((row, index) => {
          return (
            <TableRow
              // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{index + 1}</TableCell>
              {text === "comman" ? (
                <>
                  <TableCell align="left">{row.street_1}</TableCell>
                  <TableCell align="left">{row.street_2}</TableCell>
                  <TableCell align="left">{row.city}</TableCell>
                  <TableCell align="left">{row.state}</TableCell>
                  <TableCell align="left">{row.country}</TableCell>
                  <TableCell align="left">{row.zip_code}</TableCell>
                  <TableCell align="left">{row.phone}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                </>
              ) : (
                <>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.phone}</TableCell>
                  <TableCell align="left">{row.department}</TableCell>
                  <TableCell align="left">{row.designation}</TableCell>
                </>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
    </Box>
  );
};

export default TableExpand;

const headCells = [
  {
    label: "Sr no.",
  },
  {
    label: "street 1",
  },
  {
    label: "street 2",
  },
  {
    label: "City",
  },
  {
    label: "State",
  },
  {
    label: "Country",
  },
  {
    label: "Zip Code",
  },
  {
    label: "Phone",
  },
  {
    label: "Email",
  },
];
const headCells2 = [
  {
    label: "Sr no.",
  },
  {
    label: "Name",
  },
  {
    label: "Phone",
  },
  {
    label: "Email",
  },
  {
    label: "Department",
  },
  {
    label: "Designation",
  },
];