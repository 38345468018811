import { Grid, Typography, Paper, Button } from "@mui/material";
import React, { useEffect } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Link, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { getCustomers } from "../../../Redux/actions/CustomerActions";
import {
  clearErrors,
  getGstTreatment,
  getPaymentTerms,
  getSourceOfSupply,
} from "../../../Redux/actions/MastersActions";
import {
  getCustGroup,
  getCustSubGroup,
} from "../../../Redux/actions/EcommerceAction";
import { NEW_ADDCUSTOMERS_RESET } from "../../../Redux/constants/CustomerConstants";
import AddCustomerTable from "./AddCustomerTable";
import "../../Common/style.css";
import Excle from "../../Common/Excle";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#e7ebf0",
  "&:hover": {
    backgroundColor: "#e7ebf0",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: center;
      padding: 6px;
    }
  
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
      padding:16px
    }
    `
);

const AddCustomers = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = React.useState("");

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, success } = useSelector((state) => state.newCustomers);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getCustomers());
      // dispatch(getSourceOfSupply());
      // dispatch(getGstTreatment());
      // dispatch(getPaymentTerms());
      // dispatch(getCustGroup());
      // dispatch(getCustSubGroup());
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
    // if (success) {
    //   alert.success("Customer Added Successfully");
    //   dispatch({ type: NEW_ADDCUSTOMERS_RESET });
    //   dispatch(getCustomers());
    //   setOpen(false);
    // }
  }, [dispatch, navigate, error, alert, isAuthenticated, success]);

  const { error: adminError, customers } = useSelector(
    (state) => state.customers
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Customers
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "0 24px", mt: "0" }}>
          {adminError ? (
            <Grid container xs={12} justifyContent="center" alignItems="center">
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "red" }}
              >
                You don't have the required permissions.
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Item>
                <Grid container justifyContent="flex-end">
                  <Link
                    to="/customers/add-customer-page"
                    style={{ textDecoration: "none" }}
                  >
                    <Button variant="contained">
                      <AddIcon />
                      Add
                    </Button>
                  </Link>
                </Grid>
                <Grid container justifyContent="space-between" sx={{ mt: 8 }}>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="contained"
                      id="copy_btn"
                      sx={{ textTransform: "none" }}
                    >
                      Copy
                    </Button>
                    <Excle
                      headCells={headCells}
                      headCells2={headCells2}
                      defaultData2={defaultData2}
                      data={customers}
                      name="customer"
                      btn="CSV"
                      select="Excle"
                    />
                    <Excle
                      headCells={headCells}
                      headCells2={headCells2}
                      defaultData2={defaultData2}
                      data={customers}
                      name="customer"
                      btn="Export"
                      select="Excle"
                    />
                    <Button variant="contained">PDF</Button>
                    <Excle
                      headCells={headCells}
                      headCells2={headCells2}
                      data={customers}
                      defaultData2={defaultData2}
                      name="customer"
                      select="print"
                    />
                  </Stack>
                  <Stack>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => setSearchInput(e.target.value)}
                        value={searchInput}
                      />
                    </Search>
                  </Stack>
                </Grid>
                <Root sx={{ mt: 2 }}>
                  <AddCustomerTable searchInput={searchInput} />
                </Root>
              </Item>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default AddCustomers;

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "mobile",
    numeric: true,
    disablePadding: true,
    label: "Mobile",
  },
  {
    id: "Group",
    numeric: true,
    disablePadding: true,
    label: "Group",
  },
  {
    id: "GST Treatment",
    numeric: true,
    disablePadding: true,
    label: "GST Treatment",
  },
  {
    id: "GST NO.",
    numeric: true,
    disablePadding: true,
    label: "GST NO.",
  },
  {
    id: "Source",
    numeric: true,
    disablePadding: true,
    label: "Source",
  },

  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];

const headCells2 = [
  {
    id: "sr",
    numeric: false,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "Street 1",
    numeric: false,
    disablePadding: true,
    label: "Street 1",
  },
  {
    id: "Street 2",
    numeric: false,
    disablePadding: true,
    label: "Street 2",
  },
  {
    id: "City",
    numeric: false,
    disablePadding: true,
    label: "City",
  },
  {
    id: "State",
    numeric: false,
    disablePadding: true,
    label: "State",
  },
  {
    id: "Country",
    numeric: false,
    disablePadding: true,
    label: "Country",
  },
  {
    id: "zip_code",
    numeric: false,
    disablePadding: true,
    label: "Zip code",
  },
  {
    id: "Phone",
    numeric: false,
    disablePadding: true,
    label: "Phone",
  },
  {
    id: "Email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
];

const defaultData2 = [
  {
    value: "Sr no.",
    label: "Sr no.",
  },
  {
    value: "Name",
    label: "name",
  },
  {
    value: "Email",
    label: "email",
  },
  {
    value: "Phone",
    label: "phone",
  },
  {
    value: "Designation",
    label: "designation",
  },
  {
    value: "Department",
    label: "department",
  },
];