import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../../Redux/actions/EcommerceAction";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import Loader from "../../Common/Loader";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "user name",
    numeric: false,
    disablePadding: true,
    label: "User Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "mobile",
    numeric: false,
    disablePadding: true,
    label: "Mobile",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: "Address",
  },
  {
    id: "product",
    numeric: false,
    disablePadding: true,
    label: "Product",
  },
  {
    id: "size",
    numeric: false,
    disablePadding: true,
    label: "Size",
  },
  {
    id: "date time",
    numeric: false,
    disablePadding: true,
    label: "Date Time",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort,change } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={headCell.numeric ? "right" : "left"}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={orderBy === headCell.id ? order : false}
          className={change ? "pop" : ""}
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        EnquiryReport
      </Typography>
    </Toolbar>
  );
};

export default function SliderTable({ searchInput, componentRef, change }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("state");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, loading, slider } = useSelector((state) => state.slider);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [dispatch, navigate, error, alert, isAuthenticated]);

  let updatedArray = slider.filter(
    (e) => e.name.toLowerCase().search(searchInput.toLowerCase().trim()) !== -1
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - updatedArray.length) : 0;
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  var perPageData = [];
  var length = Math.ceil(updatedArray.length / 3);

  for (var i = 1; i <= length; i++) {
    perPageData.push(i * 3);
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{ width: "100%", mb: 2, boxShadow: "none", border: "none" }}
          >
            <EnhancedTableToolbar />
            <TableContainer>
            <div
                ref={componentRef}
                id="content"
                className={change ? "content" : ""}
              >
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
                id="table-to-xls"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  change={change}
                  onRequestSort={handleRequestSort}
                  rowCount={updatedArray.length}
                />
                <TableBody>
                  {stableSort(updatedArray, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((slider, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow>
                          <TableCell  className={change ? "non2" : ""} id={labelId} scope="row" padding="none">
                            {index + 1}
                          </TableCell>
                          <TableCell  className={change ? "pop" : ""} id={labelId} scope="row" padding="none">
                            {slider.name}
                          </TableCell>
                          <TableCell  className={change ? "pop" : ""} id={labelId} scope="row" padding="none">
                            {slider.description}
                          </TableCell>
                          <TableCell  className={change ? "pop" : ""} id={labelId} scope="row" padding="none">
                            {slider.description}
                          </TableCell>
                          <TableCell  className={change ? "pop" : ""} id={labelId} scope="row" padding="none">
                            {slider.description}
                          </TableCell>
                          <TableCell  className={change ? "pop" : ""} id={labelId} scope="row" padding="none">
                            {slider.description}
                          </TableCell>
                          <TableCell  className={change ? "pop" : ""} id={labelId} scope="row" padding="none">
                            {slider.description}
                          </TableCell>
                          <TableCell  className={change ? "pop" : ""} id={labelId} scope="row" padding="none">
                            {slider.description}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              </div>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={perPageData}
              component="div"
              count={updatedArray.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  );
}
