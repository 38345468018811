
export const END_PRODUCT_REQUEST = "END_PRODUCT_REQUEST";
export const END_PRODUCT_SUCCESS = "END_PRODUCT_SUCCESS";
export const END_PRODUCT_FAIL = "END_PRODUCT_FAIL";

export const UPDATE_END_PRODUCT_REQUEST = "UPDATE_END_PRODUCT_REQUEST";
export const UPDATE_END_PRODUCT_SUCCESS = "UPDATE_END_PRODUCT_SUCCESS";
export const UPDATE_END_PRODUCT_FAIL = "UPDATE_END_PRODUCT_FAIL";
export const UPDATE_END_PRODUCT_RESET = "UPDATE_END_PRODUCT_RESET";

export const DELETE_END_PRODUCT_REQUEST = "DELETE_END_PRODUCT_REQUEST";
export const DELETE_END_PRODUCT_SUCCESS = "DELETE_END_PRODUCT_SUCCESS";
export const DELETE_END_PRODUCT_FAIL = "DELETE_END_PRODUCT_FAIL";
export const DELETE_END_PRODUCT_RESET = "DELETE_END_PRODUCT_RESET";

export const NEW_END_PRODUCT_REQUEST = "NEW_END_PRODUCT_REQUEST";
export const NEW_END_PRODUCT_SUCCESS = "NEW_END_PRODUCT_SUCCESS";
export const NEW_END_PRODUCT_FAIL = "NEW_END_PRODUCT_FAIL";
export const NEW_END_PRODUCT_RESET = "NEW_END_PRODUCT_RESET";


export const BRAND_REQUEST = "BRAND_REQUEST";
export const BRAND_SUCCESS = "BRAND_SUCCESS";
export const BRAND_FAIL = "BRAND_FAIL";

export const UPDATE_BRAND_REQUEST = "UPDATE_BRAND_REQUEST";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_FAIL = "UPDATE_BRAND_FAIL";
export const UPDATE_BRAND_RESET = "UPDATE_BRAND_RESET";

export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAIL = "DELETE_BRAND_FAIL";
export const DELETE_BRAND_RESET = "DELETE_BRAND_RESET";

export const NEW_BRAND_REQUEST = "NEW_BRAND_REQUEST";
export const NEW_BRAND_SUCCESS = "NEW_BRAND_SUCCESS";
export const NEW_BRAND_FAIL = "NEW_BRAND_FAIL";
export const NEW_BRAND_RESET = "NEW_BRAND_RESET";

export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";

export const UPDATE_ADD_PRODUCT_REQUEST = "UPDATE_ADD_PRODUCT_REQUEST";
export const UPDATE_ADD_PRODUCT_SUCCESS = "UPDATE_ADD_PRODUCT_SUCCESS";
export const UPDATE_ADD_PRODUCT_FAIL = "UPDATE_ADD_PRODUCT_FAIL";
export const UPDATE_ADD_PRODUCT_RESET = "UPDATE_ADD_PRODUCT_RESET";

export const EDIT_PRODUCT_LIST = "EDIT_PRODUCT_LIST";

export const DELETE_ADD_PRODUCT_REQUEST = "DELETE_ADD_PRODUCT_REQUEST";
export const DELETE_ADD_PRODUCT_SUCCESS = "DELETE_ADD_PRODUCT_SUCCESS";
export const DELETE_ADD_PRODUCT_FAIL = "DELETE_ADD_PRODUCT_FAIL";
export const DELETE_ADD_PRODUCT_RESET = "DELETE_ADD_PRODUCT_RESET";

export const NEW_ADD_PRODUCT_REQUEST = "NEW_ADD_PRODUCT_REQUEST";
export const NEW_ADD_PRODUCT_SUCCESS = "NEW_ADD_PRODUCT_SUCCESS";
export const NEW_ADD_PRODUCT_FAIL = "NEW_ADD_PRODUCT_FAIL";
export const NEW_ADD_PRODUCT_RESET = "NEW_ADD_PRODUCT_RESET";

export const PRODUCT_SPECIFICATIONS_REQUEST = "PRODUCT_SPECIFICATIONS_REQUEST";
export const PRODUCT_SPECIFICATIONS_SUCCESS = "PRODUCT_SPECIFICATIONS_SUCCESS";
export const PRODUCT_SPECIFICATIONS_FAIL = "PRODUCT_SPECIFICATIONS_FAIL";

export const UPDATE_PRODUCT_SPECIFICATIONS_REQUEST =
  "UPDATE_PRODUCT_SPECIFICATIONS_REQUEST";
export const UPDATE_PRODUCT_SPECIFICATIONS_SUCCESS =
  "UPDATE_PRODUCT_SPECIFICATIONS_SUCCESS";
export const UPDATE_PRODUCT_SPECIFICATIONS_FAIL =
  "UPDATE_PRODUCT_SPECIFICATIONS_FAIL";
export const UPDATE_PRODUCT_SPECIFICATIONS_RESET =
  "UPDATE_PRODUCT_SPECIFICATIONS_RESET";

export const DELETE_PRODUCT_SPECIFICATIONS_REQUEST =
  "DELETE_PRODUCT_SPECIFICATIONS_REQUEST";
export const DELETE_PRODUCT_SPECIFICATIONS_SUCCESS =
  "DELETE_PRODUCT_SPECIFICATIONS_SUCCESS";
export const DELETE_PRODUCT_SPECIFICATIONS_FAIL =
  "DELETE_PRODUCT_SPECIFICATIONS_FAIL";
export const DELETE_PRODUCT_SPECIFICATIONS_RESET =
  "DELETE_PRODUCT_SPECIFICATIONS_RESET";

export const NEW_PRODUCT_SPECIFICATIONS_REQUEST =
  "NEW_PRODUCT_SPECIFICATIONS_REQUEST";
export const NEW_PRODUCT_SPECIFICATIONS_SUCCESS =
  "NEW_PRODUCT_SPECIFICATIONS_SUCCESS";
export const NEW_PRODUCT_SPECIFICATIONS_FAIL =
  "NEW_PRODUCT_SPECIFICATIONS_FAIL";
export const NEW_PRODUCT_SPECIFICATIONS_RESET =
  "NEW_PRODUCT_SPECIFICATIONS_RESET";

export const PRODUCTUNIT_REQUEST = "PRODUCTUNIT_REQUEST";
export const PRODUCTUNIT_SUCCESS = "PRODUCTUNIT_SUCCESS";
export const PRODUCTUNIT_FAIL = "PRODUCTUNIT_FAIL";

export const NEW_PRODUCTUNIT_REQUEST = "NEW_PRODUCTUNIT_REQUEST";
export const NEW_PRODUCTUNIT_SUCCESS = "NEW_PRODUCTUNIT_SUCCESS";
export const NEW_PRODUCTUNIT_FAIL = "NEW_PRODUCTUNIT_FAIL";
export const NEW_PRODUCTUNIT_RESET = "NEW_PRODUCTUNIT_RESET";

export const UPDATE_PRODUCTUNIT_REQUEST = "UPDATE_PRODUCTUNIT_REQUEST";
export const UPDATE_PRODUCTUNIT_SUCCESS = "UPDATE_PRODUCTUNIT_SUCCESS";
export const UPDATE_PRODUCTUNIT_FAIL = "UPDATE_PRODUCTUNIT_FAIL";
export const UPDATE_PRODUCTUNIT_RESET = "UPDATE_PRODUCTUNIT_RESET";

export const DELETE_PRODUCTUNIT_REQUEST = "DELETE_PRODUCTUNIT_REQUEST";
export const DELETE_PRODUCTUNIT_SUCCESS = "DELETE_PRODUCTUNIT_SUCCESS";
export const DELETE_PRODUCTUNIT_FAIL = "DELETE_PRODUCTUNIT_FAIL";
export const DELETE_PRODUCTUNIT_RESET = "DELETE_PRODUCTUNIT_RESET";


export const PRODUCTTYPE_REQUEST = "PRODUCTTYPE_REQUEST";
export const PRODUCTTYPE_SUCCESS = "PRODUCTTYPE_SUCCESS";
export const PRODUCTTYPE_FAIL = "PRODUCTTYPE_FAIL";

export const NEW_PRODUCTTYPE_REQUEST = "NEW_PRODUCTTYPE_REQUEST";
export const NEW_PRODUCTTYPE_SUCCESS = "NEW_PRODUCTTYPE_SUCCESS";
export const NEW_PRODUCTTYPE_FAIL = "NEW_PRODUCTTYPE_FAIL";
export const NEW_PRODUCTTYPE_RESET = "NEW_PRODUCTTYPE_RESET";

export const UPDATE_PRODUCTTYPE_REQUEST = "UPDATE_PRODUCTTYPE_REQUEST";
export const UPDATE_PRODUCTTYPE_SUCCESS = "UPDATE_PRODUCTTYPE_SUCCESS";
export const UPDATE_PRODUCTTYPE_FAIL = "UPDATE_PRODUCTTYPE_FAIL";
export const UPDATE_PRODUCTTYPE_RESET = "UPDATE_PRODUCTTYPE_RESET";

export const DELETE_PRODUCTTYPE_REQUEST = "DELETE_PRODUCTTYPE_REQUEST";
export const DELETE_PRODUCTTYPE_SUCCESS = "DELETE_PRODUCTTYPE_SUCCESS";
export const DELETE_PRODUCTTYPE_FAIL = "DELETE_PRODUCTTYPE_FAIL";
export const DELETE_PRODUCTTYPE_RESET = "DELETE_PRODUCTTYPE_RESET";


export const PRODUCTSEGMENT_REQUEST = "PRODUCTSEGMENT_REQUEST";
export const PRODUCTSEGMENT_SUCCESS = "PRODUCTSEGMENT_SUCCESS";
export const PRODUCTSEGMENT_FAIL = "PRODUCTSEGMENT_FAIL";

export const NEW_PRODUCTSEGMENT_REQUEST = "NEW_PRODUCTSEGMENT_REQUEST";
export const NEW_PRODUCTSEGMENT_SUCCESS = "NEW_PRODUCTSEGMENT_SUCCESS";
export const NEW_PRODUCTSEGMENT_FAIL = "NEW_PRODUCTSEGMENT_FAIL";
export const NEW_PRODUCTSEGMENT_RESET = "NEW_PRODUCTSEGMENT_RESET";

export const UPDATE_PRODUCTSEGMENT_REQUEST = "UPDATE_PRODUCTSEGMENT_REQUEST";
export const UPDATE_PRODUCTSEGMENT_SUCCESS = "UPDATE_PRODUCTSEGMENT_SUCCESS";
export const UPDATE_PRODUCTSEGMENT_FAIL = "UPDATE_PRODUCTSEGMENT_FAIL";
export const UPDATE_PRODUCTSEGMENT_RESET = "UPDATE_PRODUCTSEGMENT_RESET";

export const DELETE_PRODUCTSEGMENT_REQUEST = "DELETE_PRODUCTSEGMENT_REQUEST";
export const DELETE_PRODUCTSEGMENT_SUCCESS = "DELETE_PRODUCTSEGMENT_SUCCESS";
export const DELETE_PRODUCTSEGMENT_FAIL = "DELETE_PRODUCTSEGMENT_FAIL";
export const DELETE_PRODUCTSEGMENT_RESET = "DELETE_PRODUCTSEGMENT_RESET";

export const PRODUCT_CATEGORY_REQUEST = "PRODUCT_CATEGORY_REQUEST";
export const PRODUCT_CATEGORY_SUCCESS = "PRODUCT_CATEGORY_SUCCESS";
export const PRODUCT_CATEGORY_FAIL = "PRODUCT_CATEGORY_FAIL";

export const NEW_PRODUCT_CATEGORY_REQUEST = "NEW_PRODUCT_CATEGORY_REQUEST";
export const NEW_PRODUCT_CATEGORY_SUCCESS = "NEW_PRODUCT_CATEGORY_SUCCESS";
export const NEW_PRODUCT_CATEGORY_FAIL = "NEW_PRODUCT_CATEGORY_FAIL";
export const NEW_PRODUCT_CATEGORY_RESET = "NEW_PRODUCT_CATEGORY_RESET";

export const UPDATE_PRODUCT_CATEGORY_REQUEST = "UPDATE_PRODUCT_CATEGORY_REQUEST";
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = "UPDATE_PRODUCT_CATEGORY_SUCCESS";
export const UPDATE_PRODUCT_CATEGORY_FAIL = "UPDATE_PRODUCT_CATEGORY_FAIL";
export const UPDATE_PRODUCT_CATEGORY_RESET = "UPDATE_PRODUCT_CATEGORY_RESET";

export const DELETE_PRODUCT_CATEGORY_REQUEST = "DELETE_PRODUCT_CATEGORY_REQUEST";
export const DELETE_PRODUCT_CATEGORY_SUCCESS = "DELETE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_FAIL = "DELETE_PRODUCT_CATEGORY_FAIL";
export const DELETE_PRODUCT_CATEGORY_RESET = "DELETE_PRODUCT_CATEGORY_RESET";



export const PRODUCTACCOUNT_REQUEST = "PRODUCTACCOUNT_REQUEST";
export const PRODUCTACCOUNT_SUCCESS = "PRODUCTACCOUNT_SUCCESS";
export const PRODUCTACCOUNT_FAIL = "PRODUCTACCOUNT_FAIL";

export const NEW_PRODUCTACCOUNT_REQUEST = "NEW_PRODUCTACCOUNT_REQUEST";
export const NEW_PRODUCTACCOUNT_SUCCESS = "NEW_PRODUCTACCOUNT_SUCCESS";
export const NEW_PRODUCTACCOUNT_FAIL = "NEW_PRODUCTACCOUNT_FAIL";
export const NEW_PRODUCTACCOUNT_RESET = "NEW_PRODUCTACCOUNT_RESET";

export const UPDATE_PRODUCTACCOUNT_REQUEST = "UPDATE_PRODUCTACCOUNT_REQUEST";
export const UPDATE_PRODUCTACCOUNT_SUCCESS = "UPDATE_PRODUCTACCOUNT_SUCCESS";
export const UPDATE_PRODUCTACCOUNT_FAIL = "UPDATE_PRODUCTACCOUNT_FAIL";
export const UPDATE_PRODUCTACCOUNT_RESET = "UPDATE_PRODUCTACCOUNT_RESET";

export const DELETE_PRODUCTACCOUNT_REQUEST = "DELETE_PRODUCTACCOUNT_REQUEST";
export const DELETE_PRODUCTACCOUNT_SUCCESS = "DELETE_PRODUCTACCOUNT_SUCCESS";
export const DELETE_PRODUCTACCOUNT_FAIL = "DELETE_PRODUCTACCOUNT_FAIL";
export const DELETE_PRODUCTACCOUNT_RESET = "DELETE_PRODUCTACCOUNT_RESET";



export const ADDPRODUCT_REQUEST = "PRODUCTACCOUNT_REQUEST";
export const ADDPRODUCT_SUCCESS = "ADDPRODUCT_SUCCESS";
export const ADDPRODUCT_FAIL = "ADDPRODUCT_FAIL";

export const NEW_ADDPRODUCT_REQUEST = "NEW_ADDPRODUCT_REQUEST";
export const NEW_ADDPRODUCT_SUCCESS = "NEW_ADDPRODUCT_SUCCESS";
export const NEW_ADDPRODUCT_FAIL = "NEW_ADDPRODUCT_FAIL";
export const NEW_ADDPRODUCT_RESET = "NEW_ADDPRODUCT_RESET";

export const UPDATE_ADDPRODUCT_REQUEST = "UPDATE_ADDPRODUCT_REQUEST";
export const UPDATE_ADDPRODUCT_SUCCESS = "UPDATE_ADDPRODUCT_SUCCESS";
export const UPDATE_ADDPRODUCT_FAIL = "UPDATE_ADDPRODUCT_FAIL";
export const UPDATE_ADDPRODUCT_RESET = "UPDATE_ADDPRODUCT_RESET";

export const DELETE_ADDPRODUCT_REQUEST = "DELETE_ADDPRODUCT_REQUEST";
export const DELETE_ADDPRODUCT_SUCCESS = "DELETE_ADDPRODUCT_SUCCESS";
export const DELETE_ADDPRODUCT_FAIL = "DELETE_ADDPRODUCT_FAIL";
export const DELETE_ADDPRODUCT_RESET = "DELETE_ADDPRODUCT_RESET";
export const PRODUCTGROUP_REQUEST = "PRODUCTGROUP_REQUEST";
export const PRODUCTGROUP_SUCCESS = "PRODUCTGROUP_SUCCESS";
export const PRODUCTGROUP_FAIL = "PRODUCTGROUP_FAIL";

export const NEW_PRODUCTGROUP_REQUEST = "NEW_PRODUCTGROUP_REQUEST";
export const NEW_PRODUCTGROUP_SUCCESS = "NEW_PRODUCTGROUP_SUCCESS";
export const NEW_PRODUCTGROUP_FAIL = "NEW_PRODUCTGROUP_FAIL";
export const NEW_PRODUCTGROUP_RESET = "NEW_PRODUCTGROUP_RESET";

export const UPDATE_PRODUCTGROUP_REQUEST = "UPDATE_PRODUCTGROUP_REQUEST";
export const UPDATE_PRODUCTGROUP_SUCCESS = "UPDATE_PRODUCTGROUP_SUCCESS";
export const UPDATE_PRODUCTGROUP_FAIL = "UPDATE_PRODUCTGROUP_FAIL";
export const UPDATE_PRODUCTGROUP_RESET = "UPDATE_PRODUCTGROUP_RESET";

export const DELETE_PRODUCTGROUP_REQUEST = "DELETE_PRODUCTGROUP_REQUEST";
export const DELETE_PRODUCTGROUP_SUCCESS = "DELETE_PRODUCTGROUP_SUCCESS";
export const DELETE_PRODUCTGROUP_FAIL = "DELETE_PRODUCTGROUP_FAIL";
export const DELETE_PRODUCTGROUP_RESET = "DELETE_PRODUCTGROUP_RESET";
