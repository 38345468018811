import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { getEndProductList } from "../../../Redux/actions/InventryActions";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../Common/Loader";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "product name",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
  },
  {
    id: "product image",
    numeric: false,
    disablePadding: true,
    label: "Product Image",
  },
  {
    id: "question",
    numeric: false,
    disablePadding: true,
    label: "Question",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "Mobile",
    numeric: false,
    disablePadding: true,
    label: "Mobile",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "date time",
    numeric: false,
    disablePadding: true,
    label: "Date Time",
  },
  {
    id: "answer",
    numeric: false,
    disablePadding: true,
    label: "Answer",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={headCell.numeric ? "right" : "left"}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Questionnier
      </Typography>
    </Toolbar>
  );
};

export default function SliderTable(props) {
  console.log(props.Data);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("state");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [open, setOpen] = React.useState(false);
  const [updatedata, setUpdatedata] = React.useState({
    name: "",
    image: "",
    date: "",
    questionnier: "",
  });
  const [udateId, setUpdateId] = React.useState("");

  const handleClickOpen = (state, id) => {
    setOpen(true);
    setUpdatedata(state);
    setUpdateId(id);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { loading, endProductList } = useSelector(
    (state) => state.endProductList
  );

  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getEndProductList());
    }

    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - endProductList.length)
      : 0;

  var perPageData = [];
  var length = Math.ceil(endProductList.length / 3);

  for (var i = 1; i <= length; i++) {
    perPageData.push(i * 3);
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{ width: "100%", mb: 2, boxShadow: "none", border: "none" }}
          >
            <EnhancedTableToolbar />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={endProductList.length}
                />
                <TableBody>
                  {stableSort(endProductList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow>
                          <TableCell id={labelId} scope="row" padding="none">
                            {index + 1}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.data.product_title}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            <img
                              src={item.data.images[0].url}
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                            />
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.data.product_title}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {new Date(item.data.date).toLocaleDateString(
                              "en-GB",
                              2
                            )}
                          </TableCell>
                          <TableCell scope="row" padding="none">
                            <Dialogcountry
                              open={open}
                              setOpen={setOpen}
                              udateId={udateId}
                              updatedata={updatedata}
                              setUpdatedata={setUpdatedata}
                            />
                            <Button
                              variant="contained"
                              onClick={() =>
                                handleClickOpen(
                                  {
                                    name: item.name,
                                    description: item.description,
                                  },
                                  item._id
                                )
                              }
                            >
                              Write Answer
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={perPageData}
              component="div"
              count={endProductList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  );
}

//Update a slider Dialog
const Dialogcountry = ({
  open,
  setOpen,
  udateId,
  updatedata,
  setUpdatedata,
}) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
    setUpdatedata({ ...updatedata, [e.target.name]: e.target.value });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth="true">
      <DialogTitle>
        <Toolbar style={{ padding: 0 }}>
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            Write Answer
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ ml: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>

      <Divider />
      <DialogContent>
        <DialogContentText>Write Answer</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id=""
          label=""
          name=""
          type="text"
          fullWidth
          variant="outlined"
          sx={{ mt: 2 }}
          defaultvalue={updatedata.name}
          onChange={(e) => handleChange(e)}
        />
      </DialogContent>
      <Divider />
      <Grid
        container
        sx={{ mt: 1, mb: 1, p: 1 }}
        justifyContent="space-between"
      >
        <Button variant="contained">submit</Button>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </Grid>
    </Dialog>
  );
};
