import { Grid, Typography, Paper, Button } from "@mui/material";
import React, { useEffect } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { clearErrors } from "../../../Redux/actions/MastersActions";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import BasicAutoComplete from "../../Common/AutoComplete";
import {
  createProductCategory,
  getProductCategory,
  getProductGroup,
} from "../../../Redux/actions/InventryActions";
import { NEW_PRODUCT_CATEGORY_RESET } from "../../../Redux/constants/InventryConstant";
import ProductCategoryTable from "./ProductCategoryTable";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import "../../Common/style.css";
import jsPDF from "jspdf";
import Excle from "../../Common/Excle";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#e7ebf0",
  "&:hover": {
    backgroundColor: "#e7ebf0",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: center;
      padding: 6px;
    }
  
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
      padding:16px
    }
    `
);

const ProductCategory = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
 const [message_dis, setMessage] = React.useState("");

  const [name, setName] = React.useState({
    name: "",
    item_group: "",
    image: "",
  });
  
 const [imageName, setImageName] = React.useState("");

  const [productGroupName, setProductGroupName] = React.useState("");
  const [searchInput, setSearchInput] = React.useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error: productGropuAdminError, productGroup } = useSelector(
    (state) => state.productGroup
  );
  const { error, success } = useSelector((state) => state.newProductCategory);
  const { error: adminError } = useSelector((state) => state.productCategory);
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getProductCategory());
      dispatch(getProductGroup());
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
    if (success) {
      dispatch({ type: NEW_PRODUCT_CATEGORY_RESET });
      dispatch(getProductCategory());
      setOpen(false);
      alert.success("Product Category Added Successfully");
      setName("");
      setProductGroupName("");
    }
  }, [dispatch, navigate, error, alert, isAuthenticated, success]);

  // const initialPost = {
  //   image: "",
  // };
  const [file, setFile] = React.useState("");
  // const [post, setPost] = React.useState(initialPost);
  const handleCreateProductCategory = (e) => {
    e.preventDefault();
    dispatch(
      createProductCategory(
        JSON.stringify({
          name: name.name,
          item_group: productGroupName,
          image: imageName,
        })
      )
    );
  };

  const handleChange = (e) => {
    setName({ ...name, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const getImage = async () => {
      if (file) {
		      setMessage('Wait image uploading....');
        let data = new FormData();
        data.append("name", file.name);
        data.append("file", file);
        const image = await fetch(
          `https://erpapi.flaskitsolutions.com/api/v1/upload/user-image`,
          {
            method: "POST",
            body: data,
          }
        );
        const json = await image.json();
		
		setImageName(json);
        name.image = json;
		setMessage('Image uploaded.');
		
      }
    };
    getImage();
  }, [file]);

  // Multiple Button Functions
  const [change, setChange] = React.useState(false);
  const generatePDF = () => {
    setChange(true);
    var doc = new jsPDF("p", "pt", "a4");
    doc.html(document.querySelector("#content"), {
      callback: function (pdf) {
        // var pageCount = doc.internal.getNumberOfPages();
        // pdf.deletePage(pageCount)
        pdf.save("data.pdf");
        setChange(false);
      },
    });
  };
  const ref = React.createRef();
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }, [setLoading]);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "data",
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);
  const { productCategory } = useSelector((state) => state.productCategory);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Product Category Master Image/Test
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "0 24px", mt: "0" }}>
          {adminError || productGropuAdminError ? (
            <Grid container xs={12} justifyContent="center" alignItems="center">
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "red" }}
              >
                You don't have the required permissions.
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Item>
                <Grid container justifyContent="flex-end">
                  <Button variant="contained" onClick={handleClickOpen}>
                    <AddIcon />
                    Add
                  </Button>
                  <Dialog open={open} onClose={handleClose} fullWidth="true">
                    <DialogTitle>
                      <Toolbar style={{ padding: 0 }}>
                        <Typography
                          sx={{ flex: 1 }}
                          variant="h6"
                          component="div"
                        >
                          Add New  {message_dis}
                        </Typography>

                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={handleClose}
                          aria-label="close"
                          sx={{ ml: 0 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Toolbar>
                    </DialogTitle>

                    <Divider />
                    <DialogContent>
                      <DialogContentText>Name</DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2 }}
                        onChange={(e) => handleChange(e)}
                      />
                      <DialogContentText>Product Group</DialogContentText>
                      <BasicAutoComplete
                        data={productGroup.map((data) => data.name)}
                        name="product group"
                        setInput={setProductGroupName}
                        val={productGroupName}
                      />
                      <Grid container sx={{ mt: 2 }}>
                        <input
                          accept="image/*"
                          type="file"
                          id="fileInput"
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                      </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                      <Button
                        onClick={handleCreateProductCategory}
                        variant="contained"
                      >
                        Add
                      </Button>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                      >
                        Close
                      </Button>
                    </DialogActions>
                    <Divider />
                  </Dialog>
                </Grid>
                <Grid container justifyContent="space-between" sx={{ mt: 8 }}>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="contained"
                      id="copy_btn"
                      sx={{ textTransform: "none" }}
                    >
                      Copy
                    </Button>

                    <Excle
                      headCells={headCells}
                      data={productCategory}
                      name="productCategory"
                      btn="CSV"
                      select="Excle"
                    />
                    <Excle
                      headCells={headCells}
                      data={productCategory}
                      name="productCategory"
                      btn="Export"
                      select="Excle"
                    />
                    <Button variant="contained" onClick={generatePDF}>
                      PDF
                    </Button>
                    <Excle
                      headCells={headCells}
                      data={productCategory}
                      name="productCategory"
                      btn="Export"
                      select="print"
                    />
                  </Stack>
                  <Stack>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => setSearchInput(e.target.value)}
                        value={searchInput}
                      />
                    </Search>
                  </Stack>
                </Grid>
                <Root sx={{ mt: 2 }}>
                  <ProductCategoryTable
                    searchInput={searchInput}
                    change={change}
                    componentRef={componentRef}
                  />
                </Root>
              </Item>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default ProductCategory;

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "name",
  },
  {
    id: "item_group",
    numeric: false,
    disablePadding: true,
    label: "Product Group",
  },
  {
    id: "Image",
    numeric: false,
    disablePadding: true,
    label: "Image",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];
