import {
  BRAND_FAIL,
  BRAND_REQUEST,
  BRAND_SUCCESS,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  DELETE_BRAND_FAIL,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_REQUEST,
  NEW_BRAND_REQUEST,
  NEW_BRAND_SUCCESS,
  NEW_BRAND_FAIL,
  PRODUCT_SPECIFICATIONS_REQUEST,
  PRODUCT_SPECIFICATIONS_SUCCESS,
  PRODUCT_SPECIFICATIONS_FAIL,
  UPDATE_PRODUCT_SPECIFICATIONS_REQUEST,
  UPDATE_PRODUCT_SPECIFICATIONS_SUCCESS,
  UPDATE_PRODUCT_SPECIFICATIONS_FAIL,
  DELETE_PRODUCT_SPECIFICATIONS_REQUEST,
  DELETE_PRODUCT_SPECIFICATIONS_SUCCESS,
  DELETE_PRODUCT_SPECIFICATIONS_FAIL,
  NEW_PRODUCT_SPECIFICATIONS_REQUEST,
  NEW_PRODUCT_SPECIFICATIONS_SUCCESS,
  NEW_PRODUCT_SPECIFICATIONS_FAIL,
  NEW_PRODUCTUNIT_REQUEST,
  NEW_PRODUCTUNIT_SUCCESS,
  PRODUCTUNIT_FAIL,
  PRODUCTUNIT_REQUEST,
  PRODUCTUNIT_SUCCESS,
  UPDATE_PRODUCTUNIT_FAIL,
  UPDATE_PRODUCTUNIT_REQUEST,
  UPDATE_PRODUCTUNIT_SUCCESS,
  NEW_PRODUCTUNIT_FAIL,
  DELETE_PRODUCTUNIT_SUCCESS,
  DELETE_PRODUCTUNIT_FAIL,
  DELETE_PRODUCTUNIT_REQUEST,
  PRODUCTTYPE_REQUEST,
  PRODUCTTYPE_SUCCESS,
  PRODUCTTYPE_FAIL,
  NEW_PRODUCTTYPE_REQUEST,
  NEW_PRODUCTTYPE_SUCCESS,
  NEW_PRODUCTTYPE_FAIL,
  UPDATE_PRODUCTTYPE_REQUEST,
  UPDATE_PRODUCTTYPE_SUCCESS,
  UPDATE_PRODUCTTYPE_FAIL,
  DELETE_PRODUCTTYPE_REQUEST,
  DELETE_PRODUCTTYPE_SUCCESS,
  DELETE_PRODUCTTYPE_FAIL,
  PRODUCTSEGMENT_REQUEST,
  PRODUCTSEGMENT_SUCCESS,
  PRODUCTSEGMENT_FAIL,
  NEW_PRODUCTSEGMENT_REQUEST,
  NEW_PRODUCTSEGMENT_SUCCESS,
  NEW_PRODUCTSEGMENT_FAIL,
  UPDATE_PRODUCTSEGMENT_REQUEST,
  UPDATE_PRODUCTSEGMENT_SUCCESS,
  UPDATE_PRODUCTSEGMENT_FAIL,
  DELETE_PRODUCTSEGMENT_REQUEST,
  DELETE_PRODUCTSEGMENT_SUCCESS,
  DELETE_PRODUCTSEGMENT_FAIL,
  PRODUCTACCOUNT_REQUEST,
  PRODUCTACCOUNT_SUCCESS,
  PRODUCTACCOUNT_FAIL,
  NEW_PRODUCTACCOUNT_REQUEST,
  NEW_PRODUCTACCOUNT_SUCCESS,
  NEW_PRODUCTACCOUNT_FAIL,
  UPDATE_PRODUCTACCOUNT_REQUEST,
  UPDATE_PRODUCTACCOUNT_SUCCESS,
  UPDATE_PRODUCTACCOUNT_FAIL,
  DELETE_PRODUCTACCOUNT_REQUEST,
  DELETE_PRODUCTACCOUNT_SUCCESS,
  DELETE_PRODUCTACCOUNT_FAIL,
  PRODUCT_CATEGORY_REQUEST,
  PRODUCT_CATEGORY_SUCCESS,
  PRODUCT_CATEGORY_FAIL,
  NEW_PRODUCT_CATEGORY_REQUEST,
  NEW_PRODUCT_CATEGORY_SUCCESS,
  NEW_PRODUCT_CATEGORY_FAIL,
  UPDATE_PRODUCT_CATEGORY_REQUEST,
  UPDATE_PRODUCT_CATEGORY_SUCCESS,
  UPDATE_PRODUCT_CATEGORY_FAIL,
  DELETE_PRODUCT_CATEGORY_REQUEST,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  DELETE_PRODUCT_CATEGORY_FAIL,
  PRODUCTGROUP_REQUEST,
  PRODUCTGROUP_SUCCESS,
  PRODUCTGROUP_FAIL,
  NEW_PRODUCTGROUP_REQUEST,
  NEW_PRODUCTGROUP_SUCCESS,
  NEW_PRODUCTGROUP_FAIL,
  UPDATE_PRODUCTGROUP_REQUEST,
  UPDATE_PRODUCTGROUP_SUCCESS,
  UPDATE_PRODUCTGROUP_FAIL,
  DELETE_PRODUCTGROUP_REQUEST,
  DELETE_PRODUCTGROUP_FAIL,
  DELETE_PRODUCTGROUP_SUCCESS,
  ADDPRODUCT_REQUEST,
  ADDPRODUCT_SUCCESS,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  UPDATE_ADD_PRODUCT_REQUEST,
  UPDATE_ADD_PRODUCT_SUCCESS,
  UPDATE_ADD_PRODUCT_FAIL,
  DELETE_ADD_PRODUCT_REQUEST,
  DELETE_ADD_PRODUCT_SUCCESS,
  DELETE_ADD_PRODUCT_FAIL,
  NEW_ADD_PRODUCT_REQUEST,
  NEW_ADD_PRODUCT_SUCCESS,
  NEW_ADD_PRODUCT_FAIL,
  EDIT_PRODUCT_LIST,
  DELETE_END_PRODUCT_REQUEST,
  DELETE_END_PRODUCT_SUCCESS,
  DELETE_END_PRODUCT_FAIL,
  UPDATE_END_PRODUCT_REQUEST,
  UPDATE_END_PRODUCT_SUCCESS,
  UPDATE_END_PRODUCT_FAIL,
  END_PRODUCT_REQUEST,
  END_PRODUCT_SUCCESS,
  END_PRODUCT_FAIL,
} from "../constants/InventryConstant";
import axios from "axios";

// Get brand
export const getBrands = () => async (dispatch) => {
  try {
    dispatch({ type: BRAND_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/brand`,
      config
    );
    dispatch({ type: BRAND_SUCCESS, payload: data.brand });
  } catch (error) {
    dispatch({ type: BRAND_FAIL, payload: error.response.data.message });
  }
};

// Update brands
export const updateBrands = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_BRAND_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    console.log(id, Data);

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/brand/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_BRAND_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_BRAND_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete brands
export const deleteBrands = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_BRAND_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/brand/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_BRAND_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_BRAND_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Brand
export const createBand = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_BRAND_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/brand`,
      Data,
      config
    );

    dispatch({
      type: NEW_BRAND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_BRAND_FAIL,
      payload: error.response.data.message,
    });
  }
};

//Product Specifications

// Get Product Specifications
export const getProductSpecifications = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_SPECIFICATIONS_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_specification`,
      config
    );
    dispatch({
      type: PRODUCT_SPECIFICATIONS_SUCCESS,
      payload: data.productSpecification,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_SPECIFICATIONS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Product Specifications
export const updateProductSpecifications = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCT_SPECIFICATIONS_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_specification/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_PRODUCT_SPECIFICATIONS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_SPECIFICATIONS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product Specifications
export const deleteProductSpecifications = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCT_SPECIFICATIONS_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_specification/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_PRODUCT_SPECIFICATIONS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_SPECIFICATIONS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Product Specifications
export const createProductSpecifications = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PRODUCT_SPECIFICATIONS_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_specification`,
      Data,
      config
    );

    dispatch({
      type: NEW_PRODUCT_SPECIFICATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRODUCT_SPECIFICATIONS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET all ProductUnit
export const getProductUnit = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCTUNIT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_unit`,
      config
    );
    dispatch({ type: PRODUCTUNIT_SUCCESS, payload: data.productUnit });
  } catch (error) {
    dispatch({ type: PRODUCTUNIT_FAIL, payload: error.response.data.message });
  }
};

// Create a ProductUnit
export const createProductUnit = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PRODUCTUNIT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_unit`,
      Data,
      config
    );
    dispatch({
      type: NEW_PRODUCTUNIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRODUCTUNIT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update ProductUnit
export const updateProductUnit = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCTUNIT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    console.log(id, Data);
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_unit/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_PRODUCTUNIT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCTUNIT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete ProductUnit
export const deleteProductUnit = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCTUNIT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_unit/delete/${id}`,
      config
    );
    dispatch({
      type: DELETE_PRODUCTUNIT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCTUNIT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET all ProductType
export const getProductType = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCTTYPE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_type`,
      config
    );
    dispatch({ type: PRODUCTTYPE_SUCCESS, payload: data.productType });
  } catch (error) {
    dispatch({ type: PRODUCTTYPE_FAIL, payload: error.response.data.message });
  }
};

// Create a ProductType
export const createProductType = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PRODUCTTYPE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_type`,
      Data,
      config
    );
    dispatch({
      type: NEW_PRODUCTTYPE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRODUCTTYPE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update ProductType
export const updateProductType = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCTTYPE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    console.log(id, Data);
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_type/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_PRODUCTTYPE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCTTYPE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete ProductType
export const deleteProductType = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCTTYPE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_type/delete/${id}`,
      config
    );
    dispatch({
      type: DELETE_PRODUCTTYPE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCTTYPE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET all ProductSegment
export const getProductSegment = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCTSEGMENT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_segment`,
      config
    );
    dispatch({ type: PRODUCTSEGMENT_SUCCESS, payload: data.productSegment });
  } catch (error) {
    dispatch({
      type: PRODUCTSEGMENT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a ProductSegment
export const createProductSegment = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PRODUCTSEGMENT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_segment`,
      Data,
      config
    );
    dispatch({
      type: NEW_PRODUCTSEGMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRODUCTSEGMENT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update ProductSegment
export const updateProductSegment = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCTSEGMENT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    console.log(Data, "=========");
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_segment/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_PRODUCTSEGMENT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCTSEGMENT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete ProductSegment
export const deleteProductSegment = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCTSEGMENT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_segment/delete/${id}`,
      config
    );
    dispatch({
      type: DELETE_PRODUCTSEGMENT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCTSEGMENT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET all Product Category
export const getProductCategory = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_CATEGORY_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_category`,
      config
    );
    dispatch({ type: PRODUCT_CATEGORY_SUCCESS, payload: data.productCategory });
  } catch (error) {
    dispatch({
      type: PRODUCT_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Product category
export const createProductCategory = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PRODUCT_CATEGORY_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    console.log(Data, "=============");
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_category`,
      Data,
      config
    );
    dispatch({
      type: NEW_PRODUCT_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRODUCT_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Product category
export const updateProductCategory = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCT_CATEGORY_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    console.log(id, Data);
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_category/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_PRODUCT_CATEGORY_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product category
export const deleteProductCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCT_CATEGORY_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_category/delete/${id}`,
      config
    );
    dispatch({
      type: DELETE_PRODUCT_CATEGORY_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET all ProductGroup
export const getProductGroup = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCTGROUP_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_group`,
      config
    );
    dispatch({ type: PRODUCTGROUP_SUCCESS, payload: data.productGroup });
  } catch (error) {
    dispatch({ type: PRODUCTGROUP_FAIL, payload: error.response.data.message });
  }
};

// Create a ProductGroup
export const createProductGroup = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PRODUCTGROUP_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_group`,
      Data,
      config
    );
    dispatch({
      type: NEW_PRODUCTGROUP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRODUCTGROUP_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update ProductGroup
export const updateProductGroup = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCTGROUP_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_group/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_PRODUCTGROUP_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCTGROUP_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete ProductGroup
export const deleteProductGroup = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCTGROUP_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_group/delete/${id}`,
      config
    );
    dispatch({
      type: DELETE_PRODUCTGROUP_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCTGROUP_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Product Account Actions

// GET all Product Account
export const getProductAccount = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCTACCOUNT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_account`,
      config
    );
    dispatch({ type: PRODUCTACCOUNT_SUCCESS, payload: data.productAccount });
  } catch (error) {
    dispatch({
      type: PRODUCTACCOUNT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Product Account
export const createProductAccount = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PRODUCTACCOUNT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_account`,
      Data,
      config
    );
    dispatch({
      type: NEW_PRODUCTACCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRODUCTACCOUNT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Product Account
export const updateProductAccount = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCTACCOUNT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_account/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_PRODUCTACCOUNT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCTACCOUNT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product Account
export const deleteProductAccount = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCTACCOUNT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/product_account/delete/${id}`,
      config
    );
    dispatch({
      type: DELETE_PRODUCTACCOUNT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCTACCOUNT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get brand
export const getProductList = () => async (dispatch) => {
  try {
    dispatch({ type: ADD_PRODUCT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/add_product`,
      config
    );
    dispatch({ type: ADD_PRODUCT_SUCCESS, payload: data.products });
  } catch (error) {
    dispatch({ type: ADD_PRODUCT_FAIL, payload: error.response.data.message });
  }
};

// Update brands
export const updateProductList = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ADD_PRODUCT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/add_product/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_ADD_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ADD_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete brands
export const deleteProductList = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ADD_PRODUCT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/add_product/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_ADD_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ADD_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Brand
export const createProductList = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_ADD_PRODUCT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/add_product`,
      Data,
      config
    );

    dispatch({
      type: NEW_ADD_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_ADD_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get brand
export const getEndProductList = () => async (dispatch) => {
  try {
    dispatch({ type: END_PRODUCT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/end_product_list`,
      config
    );
    dispatch({ type: END_PRODUCT_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: END_PRODUCT_FAIL, payload: error.response.data.message });
  }
};

// Update brands
export const updateEndProductList = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_END_PRODUCT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/end_product_list/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_END_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_END_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete brands
export const deleteEndProductList = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_END_PRODUCT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/add_product/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_END_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_END_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Brand
// export const createEndProductList = (Data) => async (dispatch) => {
//   try {
//     dispatch({ type: NEW_ADD_PRODUCT_REQUEST });

//     const config = {
//       headers: { "Content-Type": "application/json" },
//     };

//     const { data } = await axios.post(
//       `https://erpapi.flaskitsolutions.com/api/v1/admin/inventory/end_product_list`,
//       Data,
//       config
//     );

//     dispatch({
//       type: NEW_ADD_PRODUCT_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: NEW_ADD_PRODUCT_FAIL,
//       payload: error.response.data.message,
//     });
//   }
// };
