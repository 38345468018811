import {
  SALE_REPORT_REQUEST,
  SALE_REPORT_SUCCESS,
  SALE_REPORT_FAIL,
  CLEAR_ERRORS,
} from "../constants/SaleReportConstant";
import axios from "axios";
// GET SALE REPORT
export const getSaleReport = (Data) => async (dispatch) => {
  console.log(Data, "hhhhhhhhhhhhhhhhhh");
  try {
    console.log(Data.endDate, "@@@@@@@@@");
    dispatch({ type: SALE_REPORT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    let link = `https://erpapi.flaskitsolutions.com/api/v1/admin/sales/report?startDate=${Data.startDate}&endDate=${Data.endDate}&customer=${Data.customer}`;
    const { data } = await axios.get(link, config);
    dispatch({ type: SALE_REPORT_SUCCESS, payload: data.SalesReport });
    console.log(data, "hhhhsdfsdfsdfsdf");
  } catch (error) {
    dispatch({
      type: SALE_REPORT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
