import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Input,
  DialogContentText,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";

import BasicAutoComplete from "../Common/AutoComplete";
import { getCustomers } from "../../Redux/actions/CustomerActions";
import { getSaleReport } from "../../Redux/actions/saleReportActions";
import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const SaleReportTable = styled("div")`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #ddd;
  }
`;
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const SaleReport = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [show, setShow] = React.useState(false);

  const [customersName, setCustomersName] = React.useState("");
  const [saleReportName, setSaleReportName] = React.useState("");

  const viewTable = () => {
    setShow(true);
    const details = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      customer: customersName,
    };
    console.log(details, "000000");
    dispatch(getSaleReport(details));
  };

  const disableShow = () => {
    setShow(false);
  };

  const { isAuthenticated, error } = useSelector((state) => state.admin);
  const {
    error: adminError,
    customers,
    loading,
  } = useSelector((state) => state.customers);
  const { error: adminError2, saleReport } = useSelector(
    (state) => state.saleReport
  );
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getCustomers());
    }

    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [dispatch, navigate, error, alert, isAuthenticated]);
  const handleChange = (newValue) => {
    setStartDate(newValue);
  };
  const handleChange2 = (newValue) => {
    setEndDate(newValue);
  };
  let TotalAmount = 0.0;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Sale Report
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "0 24px", mt: "0" }}>
          {adminError || adminError2 ? (
            <Grid container xs={12} justifyContent="center" alignItems="center">
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "red" }}
              >
                You don't have the required permissions.
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Item>
                <Grid xs={11} display="flex">
                  <Grid
                    xs={3}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <Typography justifyContent="flex-start">
                      Select Customer
                    </Typography>
                    <BasicAutoComplete
                      data={customers.map((data) => data.name)}
                      name="product group"
                      setInput={setCustomersName}
                      size="90%"
                      textSize="small"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <DialogContentText style={{ marginBottom: "16px" }}>
                      From Date
                    </DialogContentText>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd/MM/yyyy"
                        value={startDate}
                        onChange={handleChange}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sx={{ ml: 2 }}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <DialogContentText style={{ marginBottom: "16px" }}>
                      To Date
                    </DialogContentText>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd/MM/yyyy"
                        value={endDate}
                        onChange={handleChange2}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid
                    item
                    xs={1}
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-end"
                    justifyContent="flex-start"
                    marginBottom="2px"
                  >
                    <Button
                      variant="contained"
                      style={{ background: "green" }}
                      sx={{ ml: 2, pt: 1, pb: 1 }}
                      size="small"
                      onClick={viewTable}
                    >
                      View
                    </Button>
                    <Button
                      variant="contained"
                      style={{ background: "orange" }}
                      sx={{ ml: 2, pt: 1, pb: 1 }}
                      size="small"
                      onClick={disableShow}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
                {show ? (
                  <Grid sx={{ mt: 5 }}>
                    <SaleReportTable>
                      <Table>
                        <thead>
                          <tr>
                            <th>Invoice Date</th>
                            <th>Invoice No</th>
                            <th>Customer</th>
                            <th
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {saleReport.map((item) => {
                            {
                              TotalAmount += item.total;
                            }
                            return (
                              <tr>
                                <TableCell>
                                  {new Date(item.date).toLocaleDateString(
                                    "en-GB",
                                    2
                                  )}
                                </TableCell>
                                <TableCell>{item.invoice_number}</TableCell>
                                <TableCell>{item.customer}</TableCell>
                                <TableCell>{item.total}</TableCell>
                              </tr>
                            );
                          })}

                          <tr>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell
                              sx={{
                                textAlign: "right !important",
                                color: "black",
                              }}
                            >
                              Total
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "left !important",
                                color: "black",
                              }}
                            >
                              {TotalAmount}
                            </TableCell>
                          </tr>
                        </tbody>
                      </Table>
                    </SaleReportTable>
                  </Grid>
                ) : (
                  ""
                )}
              </Item>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default SaleReport;
