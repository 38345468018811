import { CLEAR_ERRORS } from "../constants/AdminLoginConstants";
import {
  NEW_PURCHASE_ORDER_FAIL,
  NEW_PURCHASE_ORDER_REQUEST,
  NEW_PURCHASE_ORDER_RESET,
  NEW_PURCHASE_ORDER_SUCCESS,
  PURCHASE_ORDER_FAIL,
  PURCHASE_ORDER_REQUEST,
  PURCHASE_ORDER_SUCCESS,
  UPDATE_PURCHASE_ORDER_FAIL,
  UPDATE_PURCHASE_ORDER_REQUEST,
  UPDATE_PURCHASE_ORDER_RESET,
  UPDATE_PURCHASE_ORDER_SUCCESS,
} from "../constants/PurchaseOrderConstant";

// PRODUCTCATEGORY Reducers
export const purchaseOrderReducer = (
  state = { purchaseOrders: [] },
  action
) => {
  switch (action.type) {
    case PURCHASE_ORDER_REQUEST:
      return { loading: true, purchaseOrders: [] };
    case PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        purchaseOrders: action.payload,
      };

    case PURCHASE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        purchaseOrders: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Create PRODUCTCATEGORY
export const newPurchaseOrderReducer = (
  state = { newPurchaseOrder: {} },
  action
) => {
  switch (action.type) {
    case NEW_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_PURCHASE_ORDER_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newPurchaseOrder: action.payload.response,
      };
    case NEW_PURCHASE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_PURCHASE_ORDER_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Update Delete PRODUCT Category
export const updateDeletePurchaseOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };

    case UPDATE_PURCHASE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_PURCHASE_ORDER_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
