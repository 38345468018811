import { Grid, Typography, Paper, Button } from "@mui/material";
import React, { useEffect } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import WarehouseTable from "./WarehouseTable";
import BasicAutoComplete from "../Common/AutoComplete";
import {
  getCountrys,
  getStates,
  getCitys,
} from "../../Redux/actions/MastersActions";
import {
  createWarehouse,
  clearErrors,
  getWarehouse,
} from "../../Redux/actions/WarehouseActions";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { NEW_WAREHOUSE_RESET } from "../../Redux/constants/WarehouseConstants";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import "../Common/style.css";
import jsPDF from "jspdf";
import Excle from "../Common/Excle";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#e7ebf0",
  "&:hover": {
    backgroundColor: "#e7ebf0",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: center;
      padding: 6px;
    }
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
      padding:16px
    }
    `
);

const Warehouse = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [name, setName] = React.useState({
    warehouse_name: "",
    attention: "",
    street_1: "",
    street_2: "",
    city: "",
    country: "",
    state: "",
    zip_code: "",
    phone: "",
    email: "",
  });

  const [cntryName, setCntryName] = React.useState("");
  const [statesName, setStatesName] = React.useState("");
  const [cityName, setCityName] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error: cityAdminError, city } = useSelector((state) => state.city);
  const { error: countryAdminError, country } = useSelector(
    (state) => state.country
  );
  const { error: stateAdminError, states } = useSelector(
    (state) => state.states
  );
  const { error, success } = useSelector((state) => state.newWarehouse);
  const { error: adminError } = useSelector((state) => state.Warehouse);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getWarehouse());
      dispatch(getCitys());
      dispatch(getCountrys());
      dispatch(getStates());
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
    if (success) {
      alert.success("Warehouse added successfully");
      dispatch({ type: NEW_WAREHOUSE_RESET });
      dispatch(getWarehouse());
      setOpen(false);
      setName({
        warehouse_name: "",
        attention: "",
        street_1: "",
        street_2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone: "",
        email: "",
      });
    }
  }, [dispatch, navigate, error, alert, isAuthenticated, success]);
  const handleCreateWarehouse = (e) => {
    e.preventDefault();
    dispatch(
      createWarehouse({
        warehouse_name: name.warehouse_name,
        attention: name.attention,
        street_1: name.street_1,
        street_2: name.street_2,
        city: cityName,
        country: cntryName,
        state: statesName,
        zip_code: name.zip_code,
        phone: name.phone,
        email: name.email,
      })
    );
  };
  const handleChange = (e) => {
    setName({ ...name, [e.target.name]: e.target.value });
  };

  // Multiple Button Functions
  const [change, setChange] = React.useState(false);
  const generatePDF = () => {
    setChange(true);
    var doc = new jsPDF("p", "pt", "a4");
    doc.html(document.querySelector("#content"), {
      callback: function (pdf) {
        // var pageCount = doc.internal.getNumberOfPages();
        // pdf.deletePage(pageCount)
        pdf.save("data.pdf");
        setChange(false);
      },
    });
  };
  const ref = React.createRef();
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }, [setLoading]);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "data",
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);

  const { Warehouse } = useSelector((state) => state.Warehouse);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Warehouse
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "0 24px", mt: "0" }}>
          {adminError ||
          cityAdminError ||
          countryAdminError ||
          stateAdminError ? (
            <Grid container xs={12} justifyContent="center" alignItems="center">
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "red" }}
              >
                You don't have the required permissions.
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Item>
                <Grid container justifyContent="flex-end">
                  <Button variant="contained" onClick={handleClickOpen}>
                    <AddIcon />
                    Add
                  </Button>
                  <Dialog open={open} onClose={handleClose} fullWidth="true">
                    <DialogTitle>
                      <Toolbar style={{ padding: 0 }}>
                        <Typography
                          sx={{ flex: 1 }}
                          variant="h6"
                          component="div"
                        >
                          Add New
                        </Typography>

                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={handleClose}
                          aria-label="close"
                          sx={{ ml: 0 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Toolbar>
                    </DialogTitle>

                    <Divider />
                    <DialogContent>
                      <DialogContentText>Warehouse name</DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="warehouse_name"
                        name="warehouse_name"
                        label="Warehouse Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2 }}
                        onChange={(e) => handleChange(e)}
                      />
                      <DialogContentText sx={{ mt: 2 }}>
                        Attention
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="attention"
                        name="attention"
                        label="Attention"
                        type="text"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2 }}
                        onChange={(e) => handleChange(e)}
                      />
                      <DialogContentText sx={{ mt: 2 }}>
                        Street_1
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="street_1"
                        name="street_1"
                        label="Street_1"
                        type="text"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2 }}
                        onChange={(e) => handleChange(e)}
                      />
                      <DialogContentText sx={{ mt: 2 }}>
                        Street_2
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="street_2"
                        name="street_2"
                        label="Street_2"
                        type="text"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2 }}
                        onChange={(e) => handleChange(e)}
                      />

                      <DialogContentText sx={{ mt: 2 }}>City</DialogContentText>
                      <BasicAutoComplete
                        // heading=""
                        data={city.map((data) => data.city)}
                        name="city"
                        setInput={setCityName}
                      />

                      <DialogContentText sx={{ mt: 2 }}>
                        Country
                      </DialogContentText>
                      <BasicAutoComplete
                        // heading=""
                        data={country.map((data) => data.country)}
                        name="country"
                        setInput={setCntryName}
                      />
                      <DialogContentText sx={{ mt: 2 }}>
                        State
                      </DialogContentText>
                      <BasicAutoComplete
                        // heading=""
                        data={states.map((data) => data.state)}
                        name="state"
                        setInput={setStatesName}
                      />

                      <DialogContentText sx={{ mt: 2 }}>
                        Zip Code
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="zip_code"
                        name="zip_code"
                        label="Zip Code"
                        type="number"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2 }}
                        onChange={(e) => handleChange(e)}
                      />
                      <DialogContentText sx={{ mt: 2 }}>
                        Phone
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="phone"
                        name="phone"
                        label="Phone"
                        type="number"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2 }}
                        onChange={(e) => handleChange(e)}
                      />
                      <DialogContentText sx={{ mt: 2 }}>
                        Email
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        name="email"
                        label="Email"
                        type="text"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2 }}
                        onChange={(e) => handleChange(e)}
                      />
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                      <Button
                        onClick={handleCreateWarehouse}
                        variant="contained"
                      >
                        Add
                      </Button>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                      >
                        Close
                      </Button>
                    </DialogActions>
                    <Divider />
                  </Dialog>
                </Grid>
                <Grid container justifyContent="space-between" sx={{ mt: 8 }}>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="contained"
                      id="copy_btn"
                      sx={{ textTransform: "none" }}
                    >
                      Copy
                    </Button>
                    <Excle
                      headCells={headCells}
                      data={Warehouse}
                      name="warehouse"
                      btn="CSV"
                      select="Excle"
                    />
                    <Excle
                      headCells={headCells}
                      data={Warehouse}
                      name="warehouse"
                      btn="Export"
                      select="Excle"
                    />
                    <Button variant="contained" onClick={generatePDF}>
                      PDF
                    </Button>
                    <Excle
                      headCells={headCells}
                      data={Warehouse}
                      name="warehouse"
                      select="print"
                    />
                  </Stack>
                  <Stack>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => setSearchInput(e.target.value)}
                        value={searchInput}
                      />
                    </Search>
                  </Stack>
                </Grid>
                <Root sx={{ mt: 2 }}>
                  <WarehouseTable
                    searchInput={searchInput}
                    change={change}
                    componentRef={componentRef}
                  />
                </Root>
              </Item>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default Warehouse;

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "warehouse_name",
    numeric: false,
    disablePadding: true,
    label: "Warehouse name",
  },
  {
    id: "attention",
    numeric: false,
    disablePadding: true,
    label: "Attention",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: true,
    label: "City",
  },
  {
    id: "zip_code",
    numeric: true,
    disablePadding: true,
    label: "Zip code",
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: true,
    label: "Phone",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "email",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];
