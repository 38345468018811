import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  DialogContentText,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Link, useNavigate, useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const LocationBarcodeDeatils = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isAuthenticated } = useSelector((state) => state.admin);
  const [barcode, setBarcode] = useState("");
  const [endId, setEndId] = useState("");
  const [productId, setProductId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = useState("");
  const [data, setData] = React.useState("");
  const [name, setName] = React.useState("");
  useEffect(() => {
    if (success) {
      const get = async () => {
        const config = {
          headers: { "Content-Type": "application/json" },
        };
        const { data } = await axios.get(
          `https://erpapi.flaskitsolutions.com/api/v1/admin/add_sales_order/details/${id}`,
          config
        );
        setData(data.salesOrder);
      };
      get();
    }
    if (id) {
      const get = async () => {
        const config = {
          headers: { "Content-Type": "application/json" },
        };
        const { data } = await axios.get(
          `https://erpapi.flaskitsolutions.com/api/v1/admin/add_sales_order/details/${id}`,
          config
        );
        data.salesOrder
          ? setData(data.salesOrder)
          : setData(data.performaInvoice);
      };
      get();
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [navigate, isAuthenticated, success, id]);
  const barcodeHandle = (e, index) => {
    setBarcode(e.target.value);
    setEndId(data.item_details[index]._id);
    setProductId(data._id);
  };
  console.log(barcode, "bar");
  const handleClickOpen = async () => {
    setOpen(true);
    let Main = {
      scanned_code: barcode,
      endProductId: endId,
      salesProductId: productId,
    };
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/on_location/sales/barcode/scanned`,
      Main,
      config
    );
    console.log(data, "data");
    setSuccess(data.success);
    setName(data.message);
    setBarcode("");
  };
  const handleClose = () => {
    setOpen(false);
    setSuccess("");
    setName("");
    setBarcode("");
  };
  const completeHandle = () => {
    navigate("/location-barcode-scan-outer");
    alert.success("Invoice Completed");
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ padding: "0 24px", mt: 2 }}>
          <Grid item xs={12}>
            <Item>
              <Grid xs={12} style={{ background: "#B4B4E2" }}>
                <Typography
                  sx={{
                    fontWeight: "800",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "25px",
                    justifyContent: "center",
                    padding: "20px 0",
                  }}
                >
                  <Link to="/location-barcode-scan-outer">
                    <ArrowForwardIcon
                      style={{
                        color: "black",
                        marginRight: "13px",
                        transform: "rotate(180deg)",
                        fontWeight: "900",
                        fontSize: "25px",
                        padding: "0",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                  <span style={{ marginRight: "10px" }}>
                    {data?.invoice_number
                      ? data?.invoice_number
                      : data?.performa_invoice_number}
                  </span>
                  On Location
                </Typography>
              </Grid>
              <Grid
                xs={12}
                style={{
                  background: "#DCDCDC",
                  paddingTop: "30px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                {data?.item_details?.map((r, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        marginBottom: "10px",
                        padding: "0 20px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#4F5689",
                        borderRadius: "20px",
                      }}
                    >
                      <div
                        style={{
                          color: "#fff",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          justifyContent: "flex-start",
                          textAlign: "flex-start",
                          padding: "20px 0",
                        }}
                      >
                        <div style={{ marginBottom: "8px", display: "flex" }}>
                          Prouct:{" "}
                          <span
                            style={{
                              fontWeight: "700",
                              textAlign: "left",
                              marginLeft: "10px",
                            }}
                          >
                            {r?.item}
                          </span>
                        </div>
                        <div style={{ marginBottom: "8px" }}>
                          Qty:{" "}
                          <span style={{ fontWeight: "700" }}>
                            {r.quantity}
                          </span>
                        </div>
                        <div style={{ marginBottom: "8px" }}>
                          Location:{" "}
                          <span style={{ fontWeight: "700" }}>
                            {" "}
                            {data.warehouse_location}
                          </span>
                        </div>
                        <div
                          style={{
                            marginBottom: "8px",
                            display: "flex",
                          }}
                        >
                          <span style={{ marginRight: "8px" }}>
                            Scanned Barcode:
                          </span>
                          <span
                            style={{
                              fontWeight: "700",
                              whiteSpace: "initial",

                              wordBreak: "break-word",
                              textAlign: "left",
                            }}
                          >
                            {`${r.scanned_barcode},`}
                          </span>
                        </div>
                        <div style={{ marginBottom: "8px" }}>
                          Total L.Scanned:{" "}
                          <span style={{ fontWeight: "700" }}>
                            {" "}
                            {r.location_scanned}
                          </span>
                        </div>
                        {r.location_scanned === r.quantity ? (
                          ""
                        ) : (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <TextField
                              margin="dense"
                              variant="outlined"
                              size="small"
                              placeholder="Enter Barcode"
                              style={{
                                padding: "0",
                                marginRight: "20px",
                                background: "#fff",
                              }}
                              value={barcode}
                              onChange={(e) => barcodeHandle(e, index)}
                            />
                            <Button
                              style={{
                                color: "#fff",
                                background: "green",
                                border: "1px solid black",
                                marginRight: "20px",
                              }}
                              onClick={handleClickOpen}
                            >
                              Save
                            </Button>
                            {name ? (
                              <Dialog
                                open={open}
                                onClose={handleClose}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "20px 50px",
                                }}
                              >
                                <DialogContent>
                                  <DialogContentText variant="h5" color="black">
                                    {name}
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions style={{ alignSelf: "center" }}>
                                  <Button
                                    onClick={handleClose}
                                    style={{
                                      background: "blue",
                                      padding: "10px 30px",
                                      color: "#fff",
                                    }}
                                  >
                                    Ok
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            ) : (
                              ""
                            )}
                            <DocumentScannerIcon
                              style={{ color: "black", cursor: "pointer" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Grid>
              <Grid lg={12} marginTop={3} marginBottom={3}>
                <Button
                  style={{
                    background:
                      data?.total_location_scanned === data?.total_quantity
                        ? "blue"
                        : "lightgray",
                    width: "100%",
                    color: "#fff",
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                  onClick={completeHandle}
                  disabled={
                    data?.total_location_scanned === data?.total_quantity
                      ? false
                      : true
                  }
                >
                  Complete
                </Button>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LocationBarcodeDeatils;
