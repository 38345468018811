import React from "react";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import Pdf from "react-to-pdf";
import { useReactToPrint } from "react-to-print";
import Loader from "../../Common/Loader";
import GRNDrawerTable from "./GRNDrawerTable";
import axios from "axios";
import { Grid, TextField, Typography } from "@mui/material";
const GRNDrawer = ({ item, POid, anchor, toggleDrawer, id }) => {
  const ref = React.createRef();
  const [demo, setDemo] = React.useState("");
  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 2000);
    });
  }, [setLoading]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,

    removeAfterPrint: true,
  });
  React.useEffect(() => {
    const get = async () => {
      const { data } = await axios.get(
        `https://erpapi.flaskitsolutions.com/api/v1/admin/grn/get_a_single_grn/details/${id}`
      );
      setDemo(data.response);
    };
    get();
  }, []);
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "6px 35px",
          fontSize: "18px",
          borderBottom: "1px solid gray",
        }}
      >
        <p>GRN Details</p>
        <CloseIcon onClick={toggleDrawer("right", false)} />
      </div>
      <div
        style={{
          width: "100%",
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "lightblue",
          margin: "20px 0",
        }}
      >
        <span style={{ fontSize: "23px", textTransform: "capitalize" }}>
          {" "}
          {demo?.grn}
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "20%",
          }}
        >
          <PrintIcon onClick={handlePrint} style={{ fontSize: "30px" }} />
          <Pdf targetRef={ref} filename={item?.po}>
            {({ toPdf }) => (
              <PictureAsPdfIcon
                onClick={toPdf}
                style={{ fontSize: "30px", color: "red" }}
              />
            )}
          </Pdf>
        </div>
      </div>

      <div ref={componentRef} style={{ width: "95%" }}>
        <div ref={ref} style={{ padding: "0 25px " }}>
          <div style={{ border: "1px solid black", padding: "8px" }}>
            {!demo ? (
              <Loader />
            ) : (
              <>
                <Grid container marginBottom={3} lg={12}>
                  <Grid
                    container
                    lg={12}
                    sx={{ mt: 2, ml: 2 }}
                    justifyContent="space-between"
                  >
                    <Grid container lg={2}>
                      <Typography>Vendor</Typography>
                    </Grid>
                    <Grid container lg={9}>
                      <TextField
                        size="small"
                        justifyContent="center"
                        value={demo.vendor}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    lg={12}
                    sx={{ mt: 2, ml: 2 }}
                    justifyContent="space-between"
                  >
                    <Grid container lg={2}>
                      <Typography>WareHouse</Typography>
                    </Grid>
                    <Grid container lg={9}>
                      <TextField
                        size="small"
                        justifyContent="center"
                        value={demo.warehouse}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    lg={12}
                    sx={{ mt: 2, ml: 2 }}
                    justifyContent="space-between"
                  >
                    <Grid container lg={2}>
                      <Typography>GRN #</Typography>
                    </Grid>
                    <Grid container lg={9}>
                      <TextField
                        size="small"
                        justifyContent="center"
                        value={demo.grn}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    lg={12}
                    sx={{ mt: 2, ml: 2 }}
                    justifyContent="space-between"
                  >
                    <Grid container lg={2}>
                      <Typography>Received Date</Typography>
                    </Grid>
                    <Grid container lg={9}>
                      <TextField
                        size="small"
                        justifyContent="center"
                        value={new Date(demo.createdAt).toLocaleDateString(
                          "en-GB",
                          2
                        )}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <GRNDrawerTable item={demo} id={id} />
                <div>
                  <p>Note (For Internal Use): {item?.note}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GRNDrawer;
