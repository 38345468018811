import axios from "axios";
import {
  NEW_PURCHASE_ORDER_FAIL,
  NEW_PURCHASE_ORDER_REQUEST,
  NEW_PURCHASE_ORDER_SUCCESS,
  PURCHASE_ORDER_FAIL,
  PURCHASE_ORDER_REQUEST,
  PURCHASE_ORDER_SUCCESS,
  UPDATE_PURCHASE_ORDER_FAIL,
  UPDATE_PURCHASE_ORDER_REQUEST,
  UPDATE_PURCHASE_ORDER_SUCCESS,
} from "../constants/PurchaseOrderConstant";

// GET all PurchaseOrder
export const getPurchaseOrder = (po) => async (dispatch) => {
  try {
    dispatch({ type: PURCHASE_ORDER_REQUEST });
    let link = `https://erpapi.flaskitsolutions.com/api/v1/admin/add_purchase_order`;
    if (po) {
      link = `https://erpapi.flaskitsolutions.com/api/v1/admin/add_purchase_order/?po=${po}`;
    } else {
      link = `https://erpapi.flaskitsolutions.com/api/v1/admin/add_purchase_order`;
    }
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(link, config);
    dispatch({ type: PURCHASE_ORDER_SUCCESS, payload: data.purchaseOrders });
  } catch (error) {
    dispatch({
      type: PURCHASE_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a PurchaseOrder
export const createPurchseOrder = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PURCHASE_ORDER_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/add_purchase_order`,
      Data,
      config
    );
    dispatch({
      type: NEW_PURCHASE_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PURCHASE_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Purchase order
export const updatePurchaseOrder = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PURCHASE_ORDER_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/add_purchase_order/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_PURCHASE_ORDER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PURCHASE_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};
