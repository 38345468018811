export const WAREHOUSE_REQUEST = "WAREHOUSE_REQUEST";
export const WAREHOUSE_SUCCESS = "WAREHOUSE_SUCCESS";
export const WAREHOUSE_FAIL = "WAREHOUSE_FAIL";

export const NEW_WAREHOUSE_REQUEST = "NEW_WAREHOUSE_REQUEST";
export const NEW_WAREHOUSE_SUCCESS = "NEW_WAREHOUSE_SUCCESS";
export const NEW_WAREHOUSE_FAIL = "NEW_WAREHOUSE_FAIL";
export const NEW_WAREHOUSE_RESET = "NEW_WAREHOUSE_RESET";

export const UPDATE_WAREHOUSE_REQUEST = "UPDATE_WAREHOUSE_REQUEST";
export const UPDATE_WAREHOUSE_SUCCESS = "UPDATE_WAREHOUSE_SUCCESS";
export const UPDATE_WAREHOUSE_FAIL = "UPDATE_WAREHOUSE_FAIL";
export const UPDATE_WAREHOUSE_RESET = "UPDATE_WAREHOUSE_RESET";

export const DELETE_WAREHOUSE_REQUEST = "DELETE_WAREHOUSE_REQUEST";
export const DELETE_WAREHOUSE_SUCCESS = "DELETE_WAREHOUSE_SUCCESS";
export const DELETE_WAREHOUSE_FAIL = "DELETE_WAREHOUSE_FAIL";
export const DELETE_WAREHOUSE_RESET = "DELETE_WAREHOUSE_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
