import { Grid, Typography, Badge } from "@mui/material";
import {Link} from "react-router-dom";

import React from "react";
const Data = [
  { title: "Total Sales", num: 8, link:"/ecommerce/sales" },
  { title: "Total Reviews", num: 11 ,link:"/ecommerce/reviews"},
  { title: "Total Users", num: 186 ,link:"/ecommerce/users"},
  { title: "Total Questions", num: 9 ,link:"/ecommerce/questionnier"},
  { title: "Total Visitors", num: 10,link:"/ecommerce/visitors" },
  { title: "Total Enquiry", num: 31 ,link:"/ecommerce/enquiry"},
];

function DashboardContent() {
  return (
    
    <Grid container sx={{ m: 3 }}>
      {Data.map((data) => {
        return (
        
          <Grid container lg={3} sx={{ marginBottom: "40px"}}>
            <Grid
              item
              lg={11}
              container
              justifyContent="center"
              style={{
                backgroundImage: "linear-gradient(#d0c4c4, #f4f4f3)",
                borderRadius: "20px",
                width:"100%"
              }}
            >
              <Grid container xs={12} justifyContent="flex-end" sx={{ top: 0 }}>
                <Badge
                  badgeContent={1}
                  color="primary"
                  sx={{ color: "#7ebbc4" }}
                />
              </Grid>
              <Link to={data.link} style={{ textDecoration:"none" }}>
              <Grid container xs={12} justifyContent="center">
                <Typography sx={{ color: "#1976d2" }}>{data.title}</Typography>
              </Grid>
              <Grid container xs={12} justifyContent="center">
                <Typography sx={{ color: "#1976d2" }}>{data.num}</Typography>
              </Grid>
              </Link>
            </Grid>
          </Grid>
        
        );
      })}
    </Grid>
  );
}

export default DashboardContent;
