import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import { Routes } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import GroupsIcon from "@mui/icons-material/Groups";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import { Link } from "react-router-dom";
import InventoryIcon from "@mui/icons-material/Inventory";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export default function ControlledAccordions(props) {
	const {
		main,
		first,
		second,
		third,
		fourth,
		fifth,
		six,
		seven,
		eight,
		nine,
		ten,
		eleven,
		twelve,
		thirteen,
		fourteen,
		fifteen,
		sixteen,
		seventeen,
		eighteen,
		nineteen,
		twenty,
		twentyone,
		twentytwo,
		twentythree,
		twentyfour,
		twentyfive,
		twentysix,
		twentyseven,
		twentyeight,
		twentynine,
		thirty,
		thirtyone,
		thirtytwo,
    thirtythree,
		thirtyfour,
		thirtyfive,
		thirtysix,
		thirtyseven,
		thirtyeight,
		thirtynine,
		fourty,
		fourtyone,
		fourtytwo,
		fourtythree,
		fourtyfour,
		fourtyfive,
		fourtysix,
		fourtyseven,
		fourtyeight,
		fourtynine,
		fifty,
		fiftyone,
		fiftytwo,
		fiftythree,
	} = props;
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	return (
		<div>
			<Accordion
				expanded={expanded === "panel1"}
				onChange={handleChange("panel1")}
				sx={{ boxShadow: "none" }}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
				>
					{main === "Masters" ? (
						<FormatListBulletedIcon sx={{ mr: 4 }} />
					) : main === "Inventory" ? (
						<InventoryIcon sx={{ mr: 4, position: "fix" }} />
					) : (
						<PersonIcon sx={{ mr: 4 }} />
					)}
					<Typography sx={{ width: "33%", flexShrink: 0 }}>{main}</Typography>
				</AccordionSummary>
				{main === "Masters" ? (
					<>
						<Link
							to={"/masters/country_masters"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{first}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/masters/state_masters"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{second}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/masters/city_masters"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{third}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/masters/source_of_supply"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fourth}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/masters/payment_terms"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fifth}
								</Typography>
							</AccordionDetails>
						</Link>
						
						
						<Link
							to={"/masters/gst_treatment"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{six}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/masters/gst_classification"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{seven}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/masters/bank_account"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{eight}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/masters/dispatch_app_user"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{nine}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/masters/module_master"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{ten}
								</Typography>
							</AccordionDetails>
						</Link>

						<Link
							to={"/masters/distance"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									Transport Charges
								</Typography>
							</AccordionDetails>
						</Link>
						
						<Link
							to={"/masters/product_terms"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{twelve}
								</Typography>
							</AccordionDetails>
						</Link>
						
						
						
					</>
				) : main === "Account Masters" ? (
					<>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{first}
								</Typography>
							</AccordionDetails>
						</Link>

						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{second}
								</Typography>
							</AccordionDetails>
						</Link>

						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{third}
								</Typography>
							</AccordionDetails>
						</Link>

						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fourth}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fifth}
								</Typography>
							</AccordionDetails>
						</Link>

						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{six}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{seven}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{eight}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{nine}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{ten}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{eleven}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{twelve}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{thirteen}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fourteen}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fifteen}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{sixteen}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{seventeen}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{eighteen}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{nineteen}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{twenty}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{twentyone}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{twentytwo}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{twentythree}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{twentyfour}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{twentyfive}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{twentysix}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{twentyseven}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{twentyeight}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{twentynine}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{thirty}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{thirtyone}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{thirtytwo}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{thirtythree}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{thirtyfour}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{thirtyfive}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{thirtysix}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{thirtyseven}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{thirtyeight}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{thirtynine}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fourty}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fourtyone}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fourtytwo}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fourtythree}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fourtyfour}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fourtyfive}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fourtysix}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fourtyseven}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fourtyeight}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fourtynine}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fifty}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fiftyone}
								</Typography>
							</AccordionDetails>
						</Link>
            <Link to={""} style={{ textDecoration: "none", color: "inherit" }}>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fiftytwo}
								</Typography>
							</AccordionDetails>
						</Link>
           
					</>
				) : main === "Vendor" ? (
					<>
						<Link
							to={"/vendor/vendors"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<AddIcon sx={{ mr: 4 }} />
									{first}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/vendor/vendorsContactPerson/:id"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<GroupsIcon sx={{ mr: 1 }} />
									{second}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/vendor/vendorsShippingAddress/:id"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<ContactMailIcon sx={{ mr: 1 }} />
									{third}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/vendor/vendorsBillingAddress/:id"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<ContactMailIcon sx={{ mr: 1 }} />
									{fourth}
								</Typography>
							</AccordionDetails>
						</Link>
					</>
				) : main === "Sales" ? (
					<>
						<Link
							to={"/sales/add-text-invoices"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<AddIcon sx={{ mr: 4 }} />
									{first}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/sales/tax-invoices"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<GroupsIcon sx={{ mr: 1 }} />
									{second}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/sales/add-performa-invoices"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<ContactMailIcon sx={{ mr: 1 }} />
									{third}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/sales/performa-invoices"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<ContactMailIcon sx={{ mr: 1 }} />
									{fourth}
								</Typography>
							</AccordionDetails>
						</Link>
					</>
				) : main === "Customers" ? (
					<>
						<Link
							to={"/customers/add_customers"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<AddIcon sx={{ mr: 4 }} />
									{first}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/customers/customer_contact_persons"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<GroupsIcon sx={{ mr: 1 }} />
									{second}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/customers/customer_shipping_address/:id"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<ContactMailIcon sx={{ mr: 1 }} />
									{third}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/customers/customer_billing_address/:id"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<ContactMailIcon sx={{ mr: 1 }} />
									{fourth}
								</Typography>
							</AccordionDetails>
						</Link>
					</>
				) : main === "Inventory" ? (
					<>
						<Link
							to={"/inventory/brands"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{first}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/inventory/product_unit"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{second}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/inventory/product_type"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{third}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/inventory/product_account"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fourth}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/inventory/product_specifications"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{fifth}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/inventory/product_segment"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{six}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/inventory/product_group"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{seven}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/inventory/product_category"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{eight}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/inventory/add_product"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<AddIcon sx={{ mr: 4 }} />
									{nine}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/inventory/products"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{ten}
								</Typography>
							</AccordionDetails>
						</Link>
						<Link
							to={"/inventory/end_product_list"}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<AccordionDetails>
								<Typography>
									<BorderAllIcon sx={{ mr: 4 }} />
									{eleven}
								</Typography>
							</AccordionDetails>
						</Link>
					</>
				) : (
					""
				)}
			</Accordion>
		</div>
	);
}
