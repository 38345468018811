import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { TextField } from "@mui/material";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(181,201,255)",
    color: theme.palette.common.white,
    borderRight: "1px solid #fff",
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "5px",
  },
}));

const ShippingRowTable = ({
  addChildInputField,
  addChild,
  defaultData,
  onchangeInput,
}) => {
  return (
    <TableBody>
      {addChild.map((item, index) => {
        return (
          <StyledTableRow>
            {defaultData?.map((r) => (
              <StyledTableCell align="center">
                <TextField
                  type={
                    r.value === "Phone"
                      ? "number"
                      : r.value === "Zip Code"
                      ? "number"
                      : r.value === "Email"
                      ? "email"
                      : "text"
                  }
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  placeholder={r.label}
                  value={addChild[index][r.label]}
                  sx={{
                    width: "100%",
                    mr: 1,
                    background: "#fff",
                  }}
                  onChange={(val) => onchangeInput(val, r.label, index)}
                />
              </StyledTableCell>
            ))} 
          </StyledTableRow>
        );
      })}
    </TableBody>
  );
};

export default ShippingRowTable;
