import axios from "axios";
import {
  LOCATION_BARCODE_FAIL,
  LOCATION_BARCODE_REQUEST,
  LOCATION_BARCODE_SUCCESS,
} from "../constants/LocatinBarcodeConstant";
export const getLocationBarcode = () => async (dispatch) => {
  try {
    dispatch({ type: LOCATION_BARCODE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/on_location/sales/data`,
      config
    );
    dispatch({ type: LOCATION_BARCODE_SUCCESS, payload: data.OnLocationData });
  } catch (error) {
    dispatch({
      type: LOCATION_BARCODE_FAIL,
      payload: error.response.data.message,
    });
  }
};
