import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { adminReducer } from "./reducers/AdminLoginReducers";
import {
  WarehouseReducer,
  newWarehouseReducer,
  updateDeleteWarehouseReducer,
} from "./reducers/WarehouseReducers";
import {
  saleReportReducer,
 
} from "./reducers/SaleReportReducer";

import {
  dashboardReducer,
 
} from "./reducers/DashboardReducer";


import {
  dashboardListReducer,
 
} from "./reducers/DashboardListReducer";


import {
  sliderReducer,
  newSliderReducer,
  updateDeletSliderReducer,
  custGroupReducer,
  newCustGroupReducer,
  updateDeletCustGroupReducer,
  custSubGroupReducer,
  newCustSubGroupReducer,
  updateDeletCustSubGroupReducer,
} from "./reducers/EcommerceReducers";
import {
  userReducer,
  newUserReducer,
  updateDeletUserReducer,
} from "./reducers/UserReducers";
import {
  grnReducer,
  newGrnReducer,
  grnNumberReducer,
  grnPrintReducer,
} from "./reducers/GrnReduceres";
import {
  countryReducer,
  newCountryReducer,
  updateDeletCountryReducer,
  stateReducer,
  newStateReducer,
  updateDeletStateReducer,
  CityReducer,
  newCityReducer,
  updateDeletCityReducer,
  SourceOfSupplyReducer,
  newSourceOfSupplyReducer,
  updateDeletSourceOfSupplyReducer,
  
  PaymentTermsReducer,
  newPaymentTermsReducer,
  updateDeletPaymentTermsReducer,
  
    ProductTermsReducer,
  newProductTermsReducer,
  updateDeletProductTermsReducer,
   
  
  GstTreatmentReducer,
  newGstTreatmentReducer,
  updateDeletGstTreatmentReducer,
  BankAccountReducer,
  newBankAccountReducer,
  updateDeletBankAccountReducer,
  DispatchAppUserReducer,
  newDispatchAppUserReducer,
  updateDeletDispatchAppUserReducer,
  MasterModuleReducer,
  newMasterModuleReducer,
  updateDeletMasterModuleReducer,
  GstClassificationReducer,
  newGstClassificationReducer,
  updateDeletGstClassificationReducer,
  DistanceReducer,
  newDistanceReducer,
  updateDeletDistanceReducer,
} from "./reducers/MastersReducers";
import {
  brandReducer,
  newBrandReducer,
  updateDeleteBranchReducer,
  productSpecificationReducer,
  newProductSpecificationReducer,
  updateDeleteProductSpecificationReducer,
  updateDeleteProductUnitReducer,
  newProductUnitReducer,
  productUnitReducer,
  productTypeReducer,
  newProductTypeReducer,
  updateDeleteProductTypeReducer,
  productSegmentReducer,
  newProductSegmentReducer,
  updateDeleteProductSegmentReducer,
  productAccountReducer,
  newProductAccountReducer,
  updateDeleteProductAccountReducer,
  productCategoryReducer,
  newProductCategoryReducer,
  updateDeleteProductCategoryReducer,
  productGroupReducer,
  newProductGroupReducer,
  updateDeleteProductGroupReducer,
  productListReducer,
  newProductListReducer,
  updateDeleteProductListReducer,
  EditProductListReducer,
  EndproductListReducer,
  updateDeleteEndProductListReducer,
} from "../Redux/reducers/InventoryReducer";
import {
  customerReducer,
  newCustomerReducer,
  updateDeleteCustomerReducer,
  customerContactPersonReducer,
  newCustomerContactPersonReducer,
  updateDeleteCustomerContactPersonReducer,
  customerShippingAddressReducer,
  newCustomerShippingAddressReducer,
  updateDeleteCustomerShippingAddressReducer,
  customerBillingAddressReducer,
  newCustomerBillingAddressReducer,
  updateDeleteCustomerBillingAddressReducer,
} from "../Redux/reducers/CustomerReducers";

import {
  newVendorContactPersonReducer,
  newVendorReducer,
  newVendorShippingReducer,
  updateDeleteVendorContactPersonReducer,
  updateDeleteVendorReducer,
  updateDeleteVendorShippingReducer,
  vendorContactPersonReducer,
  vendorReducer,
  vendorShippingReducer,
  vendorBillingReducer,
  updateDeleteVendorBillingReducer,
  newVendorBillingReducer,
} from "./reducers/VendorReducers";
import {
  newPurchaseOrderReducer,
  purchaseOrderReducer,
  updateDeletePurchaseOrderReducer,
} from "./reducers/PurchaseOrderReducer";
import { newPerformaInvoiceReducer, newTaxInvoiceReducer, performaInvoiceReducer, taxInvoiceReducer, updatePerformaInvoiceReducer, updateTaxInvoiceReducer } from "./reducers/SalesReducers";
import {accordionReducer} from "./reducers/AccordionReducer";
import { LocationBarcodeReducer } from "./reducers/LocationBarcodeReducer";
import { stockReportReducer, stockReportSummaryReducer } from "./reducers/StockReportReduceres";
import { PurchaseReportReducer } from "./reducers/PurchaseReportReducer";

const reducer = combineReducers({
  accordion:accordionReducer,
  admin: adminReducer,
  country: countryReducer,
  newCountry: newCountryReducer,
  updateCountry: updateDeletCountryReducer,
  states: stateReducer,
  newState: newStateReducer,
  updateState: updateDeletStateReducer,
  city: CityReducer,
  brands: brandReducer,
  newBrands: newBrandReducer,
  updateBrands: updateDeleteBranchReducer,
  newCity: newCityReducer,
  updateCity: updateDeletCityReducer,
  sourceofsupply: SourceOfSupplyReducer,
  newSourceOfSupply: newSourceOfSupplyReducer,
  updateSourceOfSupply: updateDeletSourceOfSupplyReducer,
  paymentTerms: PaymentTermsReducer,
  newPaymentTerms: newPaymentTermsReducer,
  updatePaymentTerms: updateDeletPaymentTermsReducer,
  
    productTerms: ProductTermsReducer,
  newProductTerms: newProductTermsReducer,
  updateProductTerms: updateDeletProductTermsReducer,
  
  GstTreatment: GstTreatmentReducer,
  newGstTreatment: newGstTreatmentReducer,
  updateGstTreatment: updateDeletGstTreatmentReducer,
  BankAccount: BankAccountReducer,
  newBankAccount: newBankAccountReducer,
  updateBankAccount: updateDeletBankAccountReducer,
  DispatchAppUser: DispatchAppUserReducer,
  newDispatchAppUser: newDispatchAppUserReducer,
  updateDispatchAppUser: updateDeletDispatchAppUserReducer,
  MasterModule: MasterModuleReducer,
  newMasterModule: newMasterModuleReducer,
  updateMasterModule: updateDeletMasterModuleReducer,
  GstClassification: GstClassificationReducer,
  newGstClassification: newGstClassificationReducer,
  updateGstClassification: updateDeletGstClassificationReducer,
  newProductSpecifications: newProductSpecificationReducer,
  updateProductSpecifications: updateDeleteProductSpecificationReducer,
  productUnit: productUnitReducer,
  newproductUnit: newProductUnitReducer,
  updateProductUnit: updateDeleteProductUnitReducer,
  productType: productTypeReducer,
  newProductType: newProductTypeReducer,
  updateProductType: updateDeleteProductTypeReducer,
  productSegment: productSegmentReducer,
  newProductSegment: newProductSegmentReducer,
  updateProductSegment: updateDeleteProductSegmentReducer,
  productSpecification: productSpecificationReducer,
  newProductSpecification: newProductSpecificationReducer,
  updateProductSpecification: updateDeleteProductSpecificationReducer,
  productUnit: productUnitReducer,
  newproductUnit: newProductUnitReducer,
  updateProductUnit: updateDeleteProductUnitReducer,
  productAccount: productAccountReducer,
  newProductAccount: newProductAccountReducer,
  updateProductAccount: updateDeleteProductAccountReducer,
  productCategory: productCategoryReducer,
  newProductCategory: newProductCategoryReducer,
  updateProductCategory: updateDeleteProductCategoryReducer,
  productGroup: productGroupReducer,
  newProductGroup: newProductGroupReducer,
  updateProductGroup: updateDeleteProductGroupReducer,
  customers: customerReducer,
  newCustomers: newCustomerReducer,
  updateCustomers: updateDeleteCustomerReducer,
  contactPerson: customerContactPersonReducer,
  newContactPerson: newCustomerContactPersonReducer,
  updateContactPerson: updateDeleteCustomerContactPersonReducer,
  customerShippingAddress: customerShippingAddressReducer,
  newCustomerShippingAddress: newCustomerShippingAddressReducer,
  updateCustomerShippingAddress: updateDeleteCustomerShippingAddressReducer,
  customerBillingAddress: customerBillingAddressReducer,
  newCustomerBillingAddress: newCustomerBillingAddressReducer,
  updateCustomerBillingAddress: updateDeleteCustomerBillingAddressReducer,
  vendors: vendorReducer,
  newvendors: newVendorReducer,
  updateVendors: updateDeleteVendorReducer,
  vendorContact: vendorContactPersonReducer,
  updateVendorContactPerson: updateDeleteVendorContactPersonReducer,
  newVendorContact: newVendorContactPersonReducer,
  updateVendorShipping: updateDeleteVendorShippingReducer,
  vendorShipping: vendorShippingReducer,
  newVendorShipping: newVendorShippingReducer,
  Warehouse: WarehouseReducer,
  newWarehouse: newWarehouseReducer,
  updateWarehouse: updateDeleteWarehouseReducer,
  saleReport: saleReportReducer,
  dashboard: dashboardReducer,
  dashboardList: dashboardListReducer,

  vendorBilling: vendorBillingReducer,
  updateVendorBilling: updateDeleteVendorBillingReducer,
  newVendorBilling: newVendorBillingReducer,
  purchaseOrders: purchaseOrderReducer,
  newPurchaseOrder: newPurchaseOrderReducer,
  updatePurchaseOrder: updateDeletePurchaseOrderReducer,
  slider: sliderReducer,
  newSlider: newSliderReducer,
  updateSlider: updateDeletSliderReducer,
  product: productListReducer,
  newProduct: newProductListReducer,
  updateProduct: updateDeleteProductListReducer,
  endProductList: EndproductListReducer,
  updateEndProductList: updateDeleteEndProductListReducer,
  grn: grnReducer,
  newGrn: newGrnReducer,
  grnNumber: grnNumberReducer,
  taxInvoice:taxInvoiceReducer,
  newTaxInvoice:newTaxInvoiceReducer,
  updateTaxInvoice:updateTaxInvoiceReducer,
  grns:grnPrintReducer,
  user: userReducer,
  newUser: newUserReducer,
  updateUser: updateDeletUserReducer,
  custGroup: custGroupReducer,
  newCustGroup: newCustGroupReducer,
  updateCustGroup: updateDeletCustGroupReducer,
  custSubGroup: custSubGroupReducer,
  newCustSubGroup: newCustSubGroupReducer,
  updateCustSubGroup: updateDeletCustSubGroupReducer,
  performaInvoices:performaInvoiceReducer,
  updatePerforma:updatePerformaInvoiceReducer,
  newPerformaInvoice:newPerformaInvoiceReducer,
  locationBarcode:LocationBarcodeReducer,
  stockReport:stockReportReducer,
  stockReportSummary:stockReportSummaryReducer,
  purchaseReport:PurchaseReportReducer,
  distance:DistanceReducer,
  newdistance:newDistanceReducer,
  updateDistance:updateDeletDistanceReducer
});
const middleware = [thunk];

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
