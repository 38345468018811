import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Typography, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { Button, DialogContentText, Divider } from "@mui/material";
import {
  createProductSegment,
  createProductSpecifications,
} from "../../Redux/actions/InventryActions";
import { useDispatch } from "react-redux";
export default function MultiSelect(props) {
  const { product, title, size, personName, handleChange, icon, multi } = props;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
        }}
      >
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          {title}
        </Typography>
        {icon ? (
          <>
            {" "}
            <AddIcon
              style={{ color: "blue", cursor: "pointer" }}
              onClick={handleClickOpen}
            />
            {title === "Product Segment" ? (
              <DialogProductSegment open={open} setOpen={setOpen} />
            ) : title === "Product Specifications" ? (
              <DialogProductSpecification open={open} setOpen={setOpen} />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>

      <Select
        multiple
        displayEmpty
        value={personName}
        onChange={handleChange}
        sx={{ width: size }}
        size="small"
      >
        {product?.map((name) => (
          <MenuItem key={name._id} value={name.name}>
            {name.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

const DialogProductSpecification = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [name, setName] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleCreateBrand = (e) => {
    e.preventDefault();
    dispatch(createProductSpecifications({ name: name }));
    setOpen(false);
    setName("");
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText>Product Specifications</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Product Specifications"
          type="text"
          size="small"
          fullWidth
          variant="outlined"
          sx={{ mt: 2 }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={handleCreateBrand}>
          Add
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogProductSegment = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [name, setName] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const initialPost = {
    image: "",
  };
  const [file, setFile] = React.useState("");
  const [post, setPost] = React.useState(initialPost);
  React.useEffect(() => {
    const getImage = async () => {
      if (file) {
        let data = new FormData();
        data.append("name", file.name);
        data.append("file", file);
        const image = await fetch(
          `https://erpapi.flaskitsolutions.com/api/v1/upload/user-image`,
          {
            method: "POST",
            body: data,
          }
        );
        const json = await image.json();
        post.image = json;
      }
    };
    getImage();
  }, [file]);

  const handleCreateProductSegment = (e) => {
    e.preventDefault();
    dispatch(
      createProductSegment(
        JSON.stringify({
          name,
          image:
            post.image === ""
              ? "https://www.wallpapertip.com/wmimgs/0-2408_4k-wallpaper-for-pc-8-hd-wallpaper-4k.jpg"
              : post.image,
        })
      )
    );
    setOpen(false);
    setName("");
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText>Product Segment</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Product Segment"
          type="text"
          size="small"
          fullWidth
          variant="outlined"
          sx={{ mt: 2 }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          accept="image/*"
          type="file"
          id="fileInput"
          onChange={(e) => setFile(e.target.files[0])}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={handleCreateProductSegment}>
          Add
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
