import axios from "axios";
import {
  PURCHASE_REPORT_FAIL,
  PURCHASE_REPORT_REQUEST,
  PURCHASE_REPORT_SUCCESS,
} from "../constants/PurchaseReportConstants";

export const getPurchaseReport = (details) => async (dispatch) => {
  console.log(details, "hhhhhhhhhhhhhhhh");
  try {
    dispatch({ type: PURCHASE_REPORT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/purchase/report?startDate=${details.startDate}&endDate=${details.endDate}&vendor=${details.vendor}`,
      config
    );
    console.log(data, "chamka");
    dispatch({
      type: PURCHASE_REPORT_SUCCESS,
      payload: data.PurchaseReport,
    });
  } catch (error) {
    dispatch({
      type: PURCHASE_REPORT_FAIL,
      payload: error.response.data.message,
    });
  }
};
