import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../Redux/actions/MastersActions";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import AwaitingGRNTable from "./AwaitingGRNTable";
import Loader from "../Common/Loader";
import { getPurchaseOrder } from "../../Redux/actions/PurchaseOrderAction";
import { useLocation } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: center;
      padding: 6px;
    }
  
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
      padding:16px
    }
    `
);

export default function GRNTable({ name }) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const search = useLocation().search;
  const QuearyParams = new URLSearchParams(search).get("po");
  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, loading, purchaseOrders } = useSelector(
    (state) => state.purchaseOrders
  );
  const { grnNumber } = useSelector((state) => state.grnNumber);
  const [demo, setDemo] = useState("");
  useEffect(() => {
    if (localStorage.getItem('user')) {
    }
    if (QuearyParams) {
      // const get = async () => {
      //   const { data } = await axios.get(`https://erpapi.flaskitsolutions.com/api/v1/admin/add_purchase_order/?po=${QuearyParams}`);
      //   console.log(data,"datat")
      // }
      // get()
      dispatch(getPurchaseOrder(QuearyParams));
      purchaseOrders.map((r) => (r.po === name.po_number ? setDemo(r) : ""));
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [dispatch, navigate, error, alert, isAuthenticated, demo, QuearyParams]);

  const [grnReceivedData, setGrnReceivedData] = React.useState({
    common_id: "",
    vendor: "",
    warehouse: "",
    grn: "",
    item_details: [],
    total_ordered: "",
    total_received: "",
    note: "",
    received_date: new Date(),
  });
  const handleChangeNote = (e) => {
    setGrnReceivedData({ ...grnReceivedData, note: e.target.value });
  };
  const handleChange = (newValue) => {
    setGrnReceivedData({ ...grnReceivedData, received_date: newValue });
  };
  return (
    <>
      {error ? (
        <Grid container xs={12} justifyContent="center" alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: "bold", color: "red" }}>
            You don't have the required permissions.
          </Typography>
        </Grid>
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <Box sx={{ width: "100%", mt: 7, display: "flex" }}>
              <Paper
                sx={{
                  width: "100%",
                  mb: 2,
                  boxShadow: "none",
                  border: "solid black",
                }}
              >
                <Grid
                  container
                  lg={3}
                  sx={{ mt: 2, ml: 2 }}
                  justifyContent="flex-start"
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Awaiting
                  </Typography>
                </Grid>
                <Grid
                  container
                  lg={12}
                  sx={{ mt: 2, ml: 2 }}
                  justifyContent="space-between"
                >
                  <Grid container lg={2}>
                    <Typography>Vendor</Typography>
                  </Grid>
                  <Grid container lg={9}>
                    <TextField
                      size="small"
                      justifyContent="center"
                      value={
                        purchaseOrders[0]?.vendor
                          ? purchaseOrders[0]?.vendor
                          : ""
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  lg={12}
                  sx={{ mt: 2, ml: 2 }}
                  justifyContent="space-between"
                >
                  <Grid container lg={2}>
                    <Typography>WareHouse</Typography>
                  </Grid>
                  <Grid container lg={9}>
                    <TextField
                      size="small"
                      justifyContent="center"
                      value={
                        purchaseOrders[0]?.deliver_to
                          ? purchaseOrders[0]?.deliver_to
                          : ""
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  lg={12}
                  sx={{ mt: 2, ml: 2 }}
                  justifyContent="space-between"
                >
                  <Grid container lg={2}>
                    <Typography>GRN #</Typography>
                  </Grid>
                  <Grid container lg={9}>
                    <TextField
                      size="small"
                      justifyContent="center"
                      value={grnNumber}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  lg={12}
                  sx={{ mt: 2, ml: 2 }}
                  justifyContent="space-between"
                >
                  <Grid container lg={2}>
                    <Typography>Received Date</Typography>
                  </Grid>
                  <Grid container lg={9}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Date"
                        inputFormat="dd/MM/yyyy"
                        value={grnReceivedData?.received_date}
                        onChange={handleChange}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            sx={{ width: "25%" }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Root sx={{ mt: 2 }}>
                  <AwaitingGRNTable
                    grnReceivedData={grnReceivedData}
                    handleChangeNote={handleChangeNote}
                    setGrnReceivedData={setGrnReceivedData}
                    grnNumber={grnNumber}
                  />
                </Root>
              </Paper>
            </Box>
          )}
        </>
      )}
    </>
  );
}
