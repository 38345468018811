import {
  COUNTRY_REQUEST,
  COUNTRY_SUCCESS,
  COUNTRY_FAIL,
  NEW_COUNTRY_REQUEST,
  NEW_COUNTRY_SUCCESS,
  NEW_COUNTRY_FAIL,
  NEW_COUNTRY_RESET,
  UPDATE_COUNTRY_REQUEST,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAIL,
  UPDATE_COUNTRY_RESET,
  DELETE_COUNTRY_REQUEST,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAIL,
  DELETE_COUNTRY_RESET,
  STATE_REQUEST,
  STATE_SUCCESS,
  STATE_FAIL,
  NEW_STATE_REQUEST,
  NEW_STATE_SUCCESS,
  NEW_STATE_FAIL,
  NEW_STATE_RESET,
  UPDATE_STATE_REQUEST,
  UPDATE_STATE_SUCCESS,
  UPDATE_STATE_FAIL,
  UPDATE_STATE_RESET,
  DELETE_STATE_REQUEST,
  DELETE_STATE_SUCCESS,
  DELETE_STATE_FAIL,
  DELETE_STATE_RESET,
  CITY_REQUEST,
  CITY_SUCCESS,
  CITY_FAIL,
  NEW_CITY_REQUEST,
  NEW_CITY_SUCCESS,
  NEW_CITY_FAIL,
  NEW_CITY_RESET,
  UPDATE_CITY_REQUEST,
  UPDATE_CITY_SUCCESS,
  UPDATE_CITY_FAIL,
  UPDATE_CITY_RESET,
  DELETE_CITY_REQUEST,
  DELETE_CITY_SUCCESS,
  DELETE_CITY_FAIL,
  DELETE_CITY_RESET,
  SOURCE_OF_SUPPLY_REQUEST,
  SOURCE_OF_SUPPLY_SUCCESS,
  SOURCE_OF_SUPPLY_FAIL,
  NEW_SOURCE_OF_SUPPLY_REQUEST,
  NEW_SOURCE_OF_SUPPLY_SUCCESS,
  NEW_SOURCE_OF_SUPPLY_FAIL,
  NEW_SOURCE_OF_SUPPLY_RESET,
  UPDATE_SOURCE_OF_SUPPLY_REQUEST,
  UPDATE_SOURCE_OF_SUPPLY_SUCCESS,
  UPDATE_SOURCE_OF_SUPPLY_FAIL,
  UPDATE_SOURCE_OF_SUPPLY_RESET,
  DELETE_SOURCE_OF_SUPPLY_REQUEST,
  DELETE_SOURCE_OF_SUPPLY_SUCCESS,
  DELETE_SOURCE_OF_SUPPLY_FAIL,
  DELETE_SOURCE_OF_SUPPLY_RESET,
  
  
  PAYMENT_TERMS_REQUEST,
  PAYMENT_TERMS_SUCCESS,
  PAYMENT_TERMS_FAIL,
  NEW_PAYMENT_TERMS_REQUEST,
  NEW_PAYMENT_TERMS_SUCCESS,
  NEW_PAYMENT_TERMS_FAIL,
  NEW_PAYMENT_TERMS_RESET,
  UPDATE_PAYMENT_TERMS_REQUEST,
  UPDATE_PAYMENT_TERMS_SUCCESS,
  UPDATE_PAYMENT_TERMS_FAIL,
  UPDATE_PAYMENT_TERMS_RESET,
  DELETE_PAYMENT_TERMS_REQUEST,
  DELETE_PAYMENT_TERMS_SUCCESS,
  DELETE_PAYMENT_TERMS_FAIL,
  DELETE_PAYMENT_TERMS_RESET,
   
   
   
   
  PRODUCT_TERMS_REQUEST,
  PRODUCT_TERMS_SUCCESS,
  PRODUCT_TERMS_FAIL,
  NEW_PRODUCT_TERMS_REQUEST,
  NEW_PRODUCT_TERMS_SUCCESS,
  NEW_PRODUCT_TERMS_FAIL,
  NEW_PRODUCT_TERMS_RESET,
  UPDATE_PRODUCT_TERMS_REQUEST,
  UPDATE_PRODUCT_TERMS_SUCCESS,
  UPDATE_PRODUCT_TERMS_FAIL,
  UPDATE_PRODUCT_TERMS_RESET,
  DELETE_PRODUCT_TERMS_REQUEST,
  DELETE_PRODUCT_TERMS_SUCCESS,
  DELETE_PRODUCT_TERMS_FAIL,
  DELETE_PRODUCT_TERMS_RESET,
   
   
   
  

  PRSECTION_REQUEST,
  PRSECTION_SUCCESS,
  PRSECTION_FAIL,
  NEW_PRSECTION_REQUEST,
  NEW_PRSECTION_SUCCESS,
  NEW_PRSECTION_FAIL,
  NEW_PRSECTION_RESET,
  UPDATE_PRSECTION_REQUEST,
  UPDATE_PRSECTION_SUCCESS,
  UPDATE_PRSECTION_FAIL,
  UPDATE_PRSECTION_RESET,
  DELETE_PRSECTION_REQUEST,
  DELETE_PRSECTION_SUCCESS,
  DELETE_PRSECTION_FAIL,
  DELETE_PRSECTION_RESET,
  
  
  
  
  
  GST_TREATMENT_REQUEST,
  GST_TREATMENT_SUCCESS,
  GST_TREATMENT_FAIL,
  NEW_GST_TREATMENT_REQUEST,
  NEW_GST_TREATMENT_SUCCESS,
  NEW_GST_TREATMENT_FAIL,
  NEW_GST_TREATMENT_RESET,
  UPDATE_GST_TREATMENT_REQUEST,
  UPDATE_GST_TREATMENT_SUCCESS,
  UPDATE_GST_TREATMENT_FAIL,
  UPDATE_GST_TREATMENT_RESET,
  DELETE_GST_TREATMENT_REQUEST,
  DELETE_GST_TREATMENT_SUCCESS,
  DELETE_GST_TREATMENT_FAIL,
  DELETE_GST_TREATMENT_RESET,
  BANK_ACCOUNT_REQUEST,
  BANK_ACCOUNT_SUCCESS,
  BANK_ACCOUNT_FAIL,
  NEW_BANK_ACCOUNT_REQUEST,
  NEW_BANK_ACCOUNT_SUCCESS,
  NEW_BANK_ACCOUNT_FAIL,
  NEW_BANK_ACCOUNT_RESET,
  UPDATE_BANK_ACCOUNT_REQUEST,
  UPDATE_BANK_ACCOUNT_SUCCESS,
  UPDATE_BANK_ACCOUNT_FAIL,
  UPDATE_BANK_ACCOUNT_RESET,
  DELETE_BANK_ACCOUNT_REQUEST,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_FAIL,
  DELETE_BANK_ACCOUNT_RESET,
  DISPATCH_APP_USER_REQUEST,
  DISPATCH_APP_USER_SUCCESS,
  DISPATCH_APP_USER_FAIL,
  NEW_DISPATCH_APP_USER_REQUEST,
  NEW_DISPATCH_APP_USER_SUCCESS,
  NEW_DISPATCH_APP_USER_FAIL,
  NEW_DISPATCH_APP_USER_RESET,
  UPDATE_DISPATCH_APP_USER_REQUEST,
  UPDATE_DISPATCH_APP_USER_SUCCESS,
  UPDATE_DISPATCH_APP_USER_FAIL,
  UPDATE_DISPATCH_APP_USER_RESET,
  DELETE_DISPATCH_APP_USER_REQUEST,
  DELETE_DISPATCH_APP_USER_SUCCESS,
  DELETE_DISPATCH_APP_USER_FAIL,
  DELETE_DISPATCH_APP_USER_RESET,
  MASTER_MODULE_REQUEST,
  MASTER_MODULE_SUCCESS,
  MASTER_MODULE_FAIL,
  NEW_MASTER_MODULE_REQUEST,
  NEW_MASTER_MODULE_SUCCESS,
  NEW_MASTER_MODULE_FAIL,
  NEW_MASTER_MODULE_RESET,
  UPDATE_MASTER_MODULE_REQUEST,
  UPDATE_MASTER_MODULE_SUCCESS,
  UPDATE_MASTER_MODULE_FAIL,
  UPDATE_MASTER_MODULE_RESET,
  DELETE_MASTER_MODULE_REQUEST,
  DELETE_MASTER_MODULE_SUCCESS,
  DELETE_MASTER_MODULE_FAIL,
  DELETE_MASTER_MODULE_RESET,
  GST_CLASSIFICATION_REQUEST,
  GST_CLASSIFICATION_SUCCESS,
  GST_CLASSIFICATION_FAIL,
  NEW_GST_CLASSIFICATION_REQUEST,
  NEW_GST_CLASSIFICATION_SUCCESS,
  NEW_GST_CLASSIFICATION_FAIL,
  NEW_GST_CLASSIFICATION_RESET,
  UPDATE_GST_CLASSIFICATION_REQUEST,
  UPDATE_GST_CLASSIFICATION_SUCCESS,
  UPDATE_GST_CLASSIFICATION_FAIL,
  UPDATE_GST_CLASSIFICATION_RESET,
  DELETE_GST_CLASSIFICATION_REQUEST,
  DELETE_GST_CLASSIFICATION_SUCCESS,
  DELETE_GST_CLASSIFICATION_FAIL,
  DELETE_GST_CLASSIFICATION_RESET,
  CLEAR_ERRORS,
  DISTANCE_REQUEST,
  DISTANCE_SUCCESS,
  NEW_DISTANCE_REQUEST,
  NEW_DISTANCE_SUCCESS,
  NEW_DISTANCE_FAIL,
  NEW_DISTANCE_RESET,
  DELETE_DISTANCE_REQUEST,
  UPDATE_DISTANCE_REQUEST,
  DELETE_DISTANCE_SUCCESS,
  UPDATE_DISTANCE_SUCCESS,
  DELETE_DISTANCE_RESET,
  DELETE_DISTANCE_FAIL,
  UPDATE_DISTANCE_FAIL,
  UPDATE_DISTANCE_RESET,
} from "../constants/MastersConstants";

//Country Reducers
export const countryReducer = (state = { country: [] }, action) => {
  switch (action.type) {
    case COUNTRY_REQUEST:
      return { loading: true, country: [] };
    case COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        country: action.payload,
      };

    case COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
        country: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const newCountryReducer = (state = { newcountry: {} }, action) => {
  switch (action.type) {
    case NEW_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_COUNTRY_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newcountry: action.payload.response,
      };
    case NEW_COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_COUNTRY_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletCountryReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_COUNTRY_REQUEST:
    case UPDATE_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_COUNTRY_FAIL:
    case UPDATE_COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_COUNTRY_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_COUNTRY_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// State reducers
export const stateReducer = (state = { states: [] }, action) => {
  switch (action.type) {
    case STATE_REQUEST:
      return { loading: true, states: [] };
    case STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        states: action.payload,
      };

    case STATE_FAIL:
      return {
        ...state,
        loading: false,
        states: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const newStateReducer = (state = { newstate: {} }, action) => {
  switch (action.type) {
    case NEW_STATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_STATE_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newstate: action.payload.response,
      };
    case NEW_STATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_STATE_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletStateReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_STATE_REQUEST:
    case UPDATE_STATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_STATE_FAIL:
    case UPDATE_STATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_STATE_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_STATE_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// City reducers
export const CityReducer = (state = { city: [] }, action) => {
  switch (action.type) {
    case CITY_REQUEST:
      return { loading: true, city: [] };
    case CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        city: action.payload,
      };
    case CITY_FAIL:
      return {
        ...state,
        loading: false,
        city: null,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const newCityReducer = (state = { newcity: {} }, action) => {
  switch (action.type) {
    case NEW_CITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_CITY_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newscity: action.payload.response,
      };
    case NEW_CITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_CITY_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletCityReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CITY_REQUEST:
    case UPDATE_CITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_CITY_FAIL:
    case UPDATE_CITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_CITY_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_CITY_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Source of supply Reducers
export const SourceOfSupplyReducer = (
  state = { sourceofsupply: [] },
  action
) => {
  switch (action.type) {
    case SOURCE_OF_SUPPLY_REQUEST:
      return { loading: true, sourceofsupply: [] };
    case SOURCE_OF_SUPPLY_SUCCESS:
      return {
        ...state,
        loading: false,
        sourceofsupply: action.payload,
      };

    case SOURCE_OF_SUPPLY_FAIL:
      return {
        ...state,
        loading: false,
        sourceofsupply: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const newSourceOfSupplyReducer = (
  state = { newsourceofsupply: {} },
  action
) => {
  switch (action.type) {
    case NEW_SOURCE_OF_SUPPLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_SOURCE_OF_SUPPLY_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newsourceofsupply: action.payload.response,
      };
    case NEW_SOURCE_OF_SUPPLY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_SOURCE_OF_SUPPLY_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletSourceOfSupplyReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SOURCE_OF_SUPPLY_REQUEST:
    case UPDATE_SOURCE_OF_SUPPLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SOURCE_OF_SUPPLY_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_SOURCE_OF_SUPPLY_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_SOURCE_OF_SUPPLY_FAIL:
    case UPDATE_SOURCE_OF_SUPPLY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_SOURCE_OF_SUPPLY_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_SOURCE_OF_SUPPLY_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};









// Payment Terms Reducers
export const PaymentTermsReducer = (state = { paymentTerms: [] }, action) => {
  switch (action.type) {
    case PAYMENT_TERMS_REQUEST:
      return { loading: true, paymentTerms: [] };
    case PAYMENT_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentTerms: action.payload,
      };

    case PAYMENT_TERMS_FAIL:
      return {
        ...state,
        loading: false,
        paymentTerms: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const newPaymentTermsReducer = (
  state = { newPaymentTerms: {} },
  action
) => {
  switch (action.type) {
    case NEW_PAYMENT_TERMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_PAYMENT_TERMS_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newPaymentTerms: action.payload.response,
      };
    case NEW_PAYMENT_TERMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_PAYMENT_TERMS_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletPaymentTermsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PAYMENT_TERMS_REQUEST:
    case UPDATE_PAYMENT_TERMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PAYMENT_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_PAYMENT_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PAYMENT_TERMS_FAIL:
    case UPDATE_PAYMENT_TERMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PAYMENT_TERMS_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_PAYMENT_TERMS_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};














 


// Product Terms Reducers
export const ProductTermsReducer = (state = { productTerms: [] }, action) => {
  switch (action.type) {
    case PRODUCT_TERMS_REQUEST:
      return { loading: true, productTerms: [] };
    case PRODUCT_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        productTerms: action.payload,
      };

    case PRODUCT_TERMS_FAIL:
      return {
        ...state,
        loading: false,
        productTerms: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const newProductTermsReducer = (
  state = { newProductTerms: {} },
  action
) => {
  switch (action.type) {
    case NEW_PRODUCT_TERMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_PRODUCT_TERMS_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newProductTerms: action.payload.response,
      };
    case NEW_PRODUCT_TERMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_PRODUCT_TERMS_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletProductTermsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_TERMS_REQUEST:
    case UPDATE_PRODUCT_TERMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRODUCT_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_PRODUCT_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PRODUCT_TERMS_FAIL:
    case UPDATE_PRODUCT_TERMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PRODUCT_TERMS_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_PRODUCT_TERMS_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

















// Payment Terms Reducers
export const PrSectionReducer = (state = { PrSection: [] }, action) => {
  switch (action.type) {
    case PRSECTION_REQUEST:
      return { loading: true, PrSection: [] };
    case PRSECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        PrSection: action.payload,
      };

    case PRSECTION_FAIL:
      return {
        ...state,
        loading: false,
        PrSection: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const newPrSectionReducer = (
  state = { newPrSection: {} },
  action
) => {
  switch (action.type) {
    case NEW_PRSECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_PRSECTION_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newPrSection: action.payload.response,
      };
    case NEW_PRSECTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_PRSECTION_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletPrSectionReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRSECTION_REQUEST:
    case UPDATE_PRSECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRSECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_PRSECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PRSECTION_FAIL:
    case UPDATE_PRSECTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PRSECTION_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_PRSECTION_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};











// gst treatment Reducers
export const GstTreatmentReducer = (state = { gstTreatment: [] }, action) => {
  switch (action.type) {
    case GST_TREATMENT_REQUEST:
      return { loading: true, gstTreatment: [] };
    case GST_TREATMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        gstTreatment: action.payload,
      };

    case GST_TREATMENT_FAIL:
      return {
        ...state,
        loading: false,
        gstTreatment: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const newGstTreatmentReducer = (
  state = { newGstTreatment: {} },
  action
) => {
  switch (action.type) {
    case NEW_GST_TREATMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_GST_TREATMENT_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newGstTreatment: action.payload.response,
      };
    case NEW_GST_TREATMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_GST_TREATMENT_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletGstTreatmentReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_GST_TREATMENT_REQUEST:
    case UPDATE_GST_TREATMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_GST_TREATMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_GST_TREATMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_GST_TREATMENT_FAIL:
    case UPDATE_GST_TREATMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_GST_TREATMENT_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_GST_TREATMENT_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
// bank account Reducers
export const BankAccountReducer = (state = { bankAccount: [] }, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_REQUEST:
      return { loading: true, bankAccount: [] };
    case BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        bankAccount: action.payload,
      };

    case BANK_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        bankAccount: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const newBankAccountReducer = (
  state = { newBankAccount: {} },
  action
) => {
  switch (action.type) {
    case NEW_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_BANK_ACCOUNT_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newBankAccount: action.payload.response,
      };
    case NEW_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_BANK_ACCOUNT_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletBankAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_BANK_ACCOUNT_REQUEST:
    case UPDATE_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_BANK_ACCOUNT_FAIL:
    case UPDATE_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_BANK_ACCOUNT_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_BANK_ACCOUNT_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
// DISPATCH APP USER Reducers
export const DispatchAppUserReducer = (state = { dispatchAppUser: [] }, action) => {
  switch (action.type) {
    case DISPATCH_APP_USER_REQUEST:
      return { loading: true, dispatchAppUser: [] };
    case DISPATCH_APP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        dispatchAppUser: action.payload,
      };

    case DISPATCH_APP_USER_FAIL:
      return {
        ...state,
        loading: false,
        dispatchAppUser: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const newDispatchAppUserReducer = (
  state = { newDispatchAppUser: {} },
  action
) => {
  switch (action.type) {
    case NEW_DISPATCH_APP_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_DISPATCH_APP_USER_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newDispatchAppUser: action.payload.response,
      };
    case NEW_DISPATCH_APP_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_DISPATCH_APP_USER_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletDispatchAppUserReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_DISPATCH_APP_USER_REQUEST:
    case UPDATE_DISPATCH_APP_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_DISPATCH_APP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_DISPATCH_APP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_DISPATCH_APP_USER_FAIL:
    case UPDATE_DISPATCH_APP_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_DISPATCH_APP_USER_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_DISPATCH_APP_USER_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
// MASTER Reducers
export const MasterModuleReducer = (state = { masterModule: [] }, action) => {
  switch (action.type) {
    case MASTER_MODULE_REQUEST:
      return { loading: true, masterModule: [] };
    case MASTER_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        masterModule: action.payload,
      };

    case MASTER_MODULE_FAIL:
      return {
        ...state,
        loading: false,
        dispatchAppUser: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const newMasterModuleReducer = (
  state = { newMasterModule: {} },
  action
) => {
  switch (action.type) {
    case NEW_MASTER_MODULE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_MASTER_MODULE_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newMasterModule: action.payload.response,
      };
    case NEW_MASTER_MODULE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_MASTER_MODULE_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletMasterModuleReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_MASTER_MODULE_REQUEST:
    case UPDATE_MASTER_MODULE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MASTER_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_MASTER_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_MASTER_MODULE_FAIL:
    case UPDATE_MASTER_MODULE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_MASTER_MODULE_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_MASTER_MODULE_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
// CLASSIFICATION Reducers
export const GstClassificationReducer = (state = { gstClassification: [] }, action) => {
  switch (action.type) {
    case GST_CLASSIFICATION_REQUEST:
      return { loading: true, gstClassification: [] };
    case GST_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        gstClassification: action.payload,
      };

    case GST_CLASSIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        dispatchAppUser: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const newGstClassificationReducer = (
  state = { newGstClassification: {} },
  action
) => {
  switch (action.type) {
    case NEW_GST_CLASSIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_GST_CLASSIFICATION_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newGstClassification: action.payload.response,
      };
    case NEW_GST_CLASSIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_GST_CLASSIFICATION_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletGstClassificationReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_GST_CLASSIFICATION_REQUEST:
    case UPDATE_GST_CLASSIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_GST_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_GST_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_GST_CLASSIFICATION_FAIL:
    case UPDATE_GST_CLASSIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_GST_CLASSIFICATION_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_GST_CLASSIFICATION_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};









//Country Reducers
export const DistanceReducer = (state = { distance: [] }, action) => {
  switch (action.type) {
    case DISTANCE_REQUEST:
      return { loading: true, distance: [] };
    case DISTANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        distance: action.payload,
      };
    case COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
        distance: null,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const newDistanceReducer = (state = { newdistance: {} }, action) => {
  switch (action.type) {
    case NEW_DISTANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_DISTANCE_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newdistance: action.payload.response,
      };
    case NEW_DISTANCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_DISTANCE_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletDistanceReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_DISTANCE_REQUEST:
    case UPDATE_DISTANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_DISTANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_DISTANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_DISTANCE_FAIL:
    case UPDATE_DISTANCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_DISTANCE_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_DISTANCE_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
