import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import DrawerErp from "../../Layout/DrawerErp";
import DistanceTable from "./DistanceTable";
import DistanceTableRow from "./DistanceTable/DistanceTableRow";
import {
  getCountrys,
  clearErrors,
  createCountry,
  getDistance,
  createDistance,
} from "../../../Redux/actions/MastersActions";
import { NEW_COUNTRY_RESET, NEW_DISTANCE_RESET } from "../../../Redux/constants/MastersConstants";
import {
  IconButton,
  Divider,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  InputBase,
  Stack,
  Toolbar,
  Box,
  Grid,
  Typography,
  Paper,
  Button,
} from "@mui/material";
import { Close, Search, Add } from "@mui/icons-material";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import "../../Common/style.css";
import jsPDF from "jspdf";
import Excle from "../../Common/Excle";

const SearchBox = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#e7ebf0",
  "&:hover": {
    backgroundColor: "#e7ebf0",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: center;
      padding: 6px;
    }
  
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
      padding:16px
    }
    `
);

const Distance = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [searchInput, setSearchInput] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, success } = useSelector((state) => state.newdistance);
  const { distance } = useSelector((state) => state.distance);

  console.log(distance)
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getDistance());
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
    if (success) {
      alert.success("Distance Added Successfully");
      dispatch({ type: NEW_DISTANCE_RESET });
      dispatch(getDistance());
      setOpen(false);
      setInputData("");
    }
  }, [dispatch, navigate, error, alert, isAuthenticated, success]);


  // Multiple Button Functions
  const [change, setChange] = React.useState(false);
  const generatePDF = () => {
    setChange(true);
    var doc = new jsPDF("p", "pt", "a4");
    doc.html(document.querySelector("#content"), {
      callback: function (pdf) {
        pdf.save("data.pdf");
        setChange(false);
      },
    });
  };
  const ref = React.createRef();
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }, [setLoading]);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const { country } = useSelector((state) => state.country);
  const [inputData, setInputData] = useState({
    km: "",
    slab1: {
      weight: 150,
      rate: "",
    },
    slab2: {
      weight: 250,
      rate: "",
    },
    slab3: {
      weight: 1220,
      rate: "",
    },
  });
  const onChangeInput = (e, ind, last) => {
    if (e.target.name === "km") {
      setInputData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    } else {
      setInputData((prevState) => ({
        ...prevState,
        [ind]: {
          ...prevState[ind],
          [e.target.name]: e.target.value,
        
        },
      }));
    }
  };

  const CreateDistance = (e) => {
    e.preventDefault();
    dispatch(createDistance(inputData));
  };
  console.log(inputData)

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Transport Charges
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "0 24px", mt: "0" }}>
            <Grid item xs={12}>
              <Item>
                <Grid container justifyContent="flex-end">
                  <Button variant="contained" onClick={handleClickOpen}>
                    <Add />
                    Add
                  </Button>
                  <Dialog
                    open={open}
                    maxWidth={"lg"}
                    onClose={handleClose}
                    fullWidth="true"
                  >
                    <DialogTitle>
                      <Toolbar style={{ padding: 0 }}>
                        <Typography
                          sx={{ flex: 1 }}
                          variant="h6"
                          component="div"
                        >
                          Add Distance
                        </Typography>

                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={handleClose}
                          aria-label="close"
                          sx={{ ml: 0 }}
                        >
                          <Close />
                        </IconButton>
                      </Toolbar>
                    </DialogTitle>

                    <Divider />
                    <DialogContent>
                      <DistanceTableRow
                        inputData={inputData}
                        setInputData={setInputData}
                        onChangeInput={onChangeInput}
                      />
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                      <Button onClick={CreateDistance} variant="contained">
                        Add
                      </Button>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                      >
                        Close
                      </Button>
                    </DialogActions>
                    <Divider />
                  </Dialog>
                </Grid>
                <Grid container justifyContent="space-between" sx={{ mt: 8 }}>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="contained"
                      id="copy_btn"
                      sx={{ textTransform: "none" }}
                    >
                      Copy
                    </Button>
                    <Excle
                      headCells={headCells}
                      name="country"
                      data={country}
                      btn="CSV"
                      select="Excle"
                    />
                    <Excle
                      headCells={headCells}
                      name="country"
                      data={country}
                      btn="Export"
                      select="Excle"
                    />
                    <Button variant="contained" onClick={generatePDF}>
                      PDF
                    </Button>
                    <Excle
                      headCells={headCells}
                      data={country}
                      name="country"
                      select="print"
                    />
                  </Stack>
                  <Stack>
                    <SearchBox>
                      <SearchIconWrapper>
                        <Search />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => setSearchInput(e.target.value)}
                        value={searchInput}
                      />
                    </SearchBox>
                  </Stack>
                </Grid>
                <Root sx={{ mt: 2 }}>
                  <DistanceTable
                    searchInput={searchInput}
                    distance={distance}
                  />
                </Root>
              </Item>
            </Grid>
         
        </Grid>
      </Box>
    </Box>
  );
};
export default Distance;

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "country",
    numeric: false,
    disablePadding: true,
    label: "Country",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];
