import { Grid, Typography, Paper, Button } from "@mui/material";
import React, { useEffect } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import SliderTable from "./SliderTable";
import {
  createSlider,
  clearErrors,
  getSlider,
} from "../../../Redux/actions/EcommerceAction";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { NEW_SLIDER_RESET } from "../../../Redux/constants/EcommerceConstants";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#e7ebf0",
  "&:hover": {
    backgroundColor: "#e7ebf0",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: center;
      padding: 6px;
    }
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
      padding:16px
    }`
);

const Slider = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState({
    name: "",
    description: "",
    image: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, success } = useSelector((state) => state.newSlider);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getSlider());
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
    if (success) {
      alert.success("Slider Added Successfully");
      dispatch({ type: NEW_SLIDER_RESET });
      dispatch(getSlider());
    }
  }, [dispatch, navigate, error, alert, isAuthenticated, success]);
  const [file, setFile] = React.useState("");

  const handleCreateSlider = (e) => {
    console.log(name);
    e.preventDefault();
    dispatch(
      createSlider(
        JSON.stringify({
          name: name.name,
          description: name.description,
          image:
            name.image === ""
              ? "https://www.wallpapertip.com/wmimgs/0-2408_4k-wallpaper-for-pc-8-hd-wallpaper-4k.jpg"
              : name.image,
        })
      )
    );
    setOpen(false);
    setName({
      name: "",
      description: "",
      image: "",
    });
  };
  const handleChange = (e) => {
    setName({ ...name, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    const getImage = async () => {
      if (file) {
        let data = new FormData();
        data.append("name", file.name);
        data.append("file", file);
        const image = await fetch(
          `https://erpapi.flaskitsolutions.com/api/v1/upload/user-image`,
          {
            method: "POST",
            body: data,
          }
        );
        const json = await image.json();
        name.image = json;
      }
    };
    getImage();
  }, [file]);

  return (
    <>
      <Grid container sx={{ p: 3 }}>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Slider
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "0 24px", mt: "0" }}>
        <Grid item xs={12}>
          <Item>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" onClick={handleClickOpen}>
                <AddIcon />
                Add
              </Button>
              <Dialog open={open} onClose={handleClose} fullWidth="true">
                <DialogTitle>
                  <Toolbar style={{ padding: 0 }}>
                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                      Add New
                    </Typography>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                      sx={{ ml: 0 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <DialogContentText>Name</DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 2 }}
                    onChange={(e) => handleChange(e)}
                  />
                  <DialogContentText sx={{ mt: 2 }}>
                    Description
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    name="description"
                    label="Description"
                    type="text"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 2 }}
                    onChange={(e) => handleChange(e)}
                  />
                  <DialogContentText sx={{ mt: 2 }}>Image</DialogContentText>
                  <input
                    accept="image/*"
                    type="file"
                    id="fileInput"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </DialogContent>
                <Divider />
                <DialogActions>
                  <Button onClick={handleCreateSlider} variant="contained">
                    Add
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                  >
                    Close
                  </Button>
                </DialogActions>
                <Divider />
              </Dialog>
            </Grid>
            <Grid container justifyContent="space-between" sx={{ mt: 8 }}>
              <Stack>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </Stack>
            </Grid>
            <Root sx={{ mt: 2 }}>
              <SliderTable />
            </Root>
          </Item>
        </Grid>
      </Grid>
    </>
  );
};
export default Slider;
