import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Drawer from "@mui/material/Drawer";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../../Redux/actions/MastersActions";
import { useAlert } from "react-alert";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Divider from "@mui/material/Divider";

import {
  deleteVendor,
  getVendor,
  updateVendor,
} from "../../../Redux/actions/VendorAction";
import Loader from "../../Common/Loader";
import {
  DELETE_VENDOR_RESET,
  UPDATE_VENDOR_RESET,
} from "../../../Redux/constants/VendorConstant";
import { Grid } from "@mui/material";
import VendorInput from "../../Common/VendorInput";
import VendorDropdown from "../../Common/VendorDropdown";
import { getPurchaseOrder } from "../../../Redux/actions/PurchaseOrderAction";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
// import PurchaseOrderDrawer from "./PurchaseOrderDrawer";
import PerformaInvoiceDrawer from "./PerformaInvoiceDrawer";
import {
  UPDATE_PERFORMA_INVOICE_RESET,
  UPDATE_PERFORMA_INVOICE_SUCCESS,
} from "../../../Redux/constants/SalesConstant";
import axios from "axios";
import { getPerformaInvoice } from "../../../Redux/actions/SalesActions";
function createData(sr, country) {
  return {
    sr,
    country,
  };
}

const rows = [
  createData(1, "Cupcake"),
  createData(2, "Donut"),
  createData(3, "Eclair"),
  createData(4, "Frozen yoghurt"),
  createData(5, "Gingerbread"),
  createData(6, "Honeycomb"),
  createData(7, "Ice cream sandwich"),
  createData(8, "Jelly Bean"),
  createData(9, "KitKat"),
  createData(10, "Lollipop"),
  createData(11, "Marshmallow"),
  createData(12, "Nougat"),
  createData(13, "Oreo"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "Invoice No.",
    numeric: false,
    disablePadding: true,
    label: "Invoice No.",
  },
  {
    id: "Customer",
    numeric: false,
    disablePadding: true,
    label: "Customer",
  },
  {
    id: "Total",
    numeric: false,
    disablePadding: true,
    label: "Total",
  },
  {
    id: "Dispatch Status",
    numeric: false,
    disablePadding: true,
    label: "Dispatch Status",
  },
  {
    id: "Invoice",
    numeric: false,
    disablePadding: true,
    label: "Invoice",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// const EnhancedTableToolbar = (props) => {
//   const { numSelected } = props;

//   return (
//     <Toolbar
//       sx={{
//         pl: { sm: 2 },
//         pr: { xs: 1, sm: 1 },
//         ...(numSelected > 0 && {
//           bgcolor: (theme) =>
//             alpha(
//               theme.palette.primary.main,
//               theme.palette.action.activatedOpacity
//             ),
//         }),
//       }}
//     >
//       {numSelected > 0 ? (
//         <Typography
//           sx={{ flex: "1 1 100%" }}
//           color="inherit"
//           variant="subtitle1"
//           component="div"
//         >
//           {numSelected} selected
//         </Typography>
//       ) : (
//         ""
//       )}

//       {numSelected > 0 ? (
//         <Tooltip title="Delete">
//           <IconButton>
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>
//       ) : (
//         <Tooltip title="Filter list">
//           <IconButton>
//             <FilterListIcon />
//           </IconButton>
//         </Tooltip>
//       )}
//     </Toolbar>
//   );
// };

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

export default function PerformaInvoiceTable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("brand");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [POid, setPOid] = React.useState("");
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const [dup, setDup] = React.useState(false);
  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, loading, performaInvoices } = useSelector(
    (state) => state.performaInvoices
  );
  const [state, setState] = React.useState({
    right: false,
  });
  const [toggleData, settoggleData] = React.useState("");
  const {
    error: deleteError,
    isDeleted,
    isUpdated,
  } = useSelector((state) => state.updatePerforma);
  console.log(dup, "dup");
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }
    if (dup) {
      dispatch(getPerformaInvoice());
    }
    if (!state.right) {
      settoggleData("");
    }

    if (isDeleted) {
      alert.success("Performa Invoice Deleted Successfully");
      dispatch({ type: UPDATE_PERFORMA_INVOICE_RESET });
      dispatch(getPerformaInvoice());
    }
    if (isUpdated) {
      alert.success("Performa Invoice Updated Successfully");
      dispatch({ type: UPDATE_PERFORMA_INVOICE_RESET });
      dispatch(getPerformaInvoice());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [
    dispatch,
    navigate,
    error,
    alert,
    isAuthenticated,
    deleteError,
    isDeleted,
    isUpdated,
    dup,
    state.right,
  ]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = performaInvoices.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - performaInvoices.length)
      : 0;

  var perPageData = [];
  var length = Math.ceil(performaInvoices.length / 3);

  for (var i = 1; i <= length; i++) {
    perPageData.push(i * 3);
  }
  const [data, setData] = useState("");

  const toggleDrawer = (anchor, open, item) => (event) => {
    if (item == "duplicate") {
      const getU = async () => {
        const { data } = await axios.get(
          `https://erpapi.flaskitsolutions.com/api/v1/admin/add_sales_order/details/${open}`
        );
        settoggleData(data.salesOrder);
      };
      getU();
    } else {
      if (
        event &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setData(item);
    }
    setState({ ...state, [anchor]: open });
  };
  const list2 = (anchor, item) =>
    toggleData ? (
      <Box sx={{ width: 850 }} role="presentation">
        <PerformaInvoiceDrawer
          item={toggleData}
          POid={POid}
          toggleDrawer={toggleDrawer}
        />
      </Box>
    ) : (
      <Box sx={{ width: 850 }} role="presentation">
        <PerformaInvoiceDrawer
          item={data}
          POid={POid}
          toggleDrawer={toggleDrawer}
        />
      </Box>
    );
  const duplicateButton = async (id) => {
    if (id) {
      axios
        .post(
          `https://erpapi.flaskitsolutions.com/api/v1/admin/create/duplicate/tax_invoice/${id}`
        )
        .then((res) => {
          console.log(res, "==============");
          setDup(res.data.success);
        });
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{ width: "100%", mb: 2, boxShadow: "none", border: "none" }}
          >
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={performaInvoices.length}
                />

                <TableBody>
                  {stableSort(performaInvoices, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => {
                      const isItemSelected = isSelected(item.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          // aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={item.name}
                          // selected={isItemSelected}
                        >
                          <TableCell
                            // component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            // component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {new Date(item.date).toLocaleDateString("en-GB", 2)}
                          </TableCell>
                          <TableCell
                            // component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {item.performa_invoice_number}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.customer}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.total}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            <button
                              style={{
                                background:
                                  item.dispatch_status === "Order Dispatched"
                                    ? "green"
                                    : "pink",
                                color: "#fff",
                                border: "none",
                                width: "100%",
                                padding: "10px 0",
                                fontSize: "16px",
                              }}
                            >
                              {item.dispatch_status === "Order Dispatched"
                                ? "Order Dispatch"
                                : "On Location"}
                            </button>
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            <div>
                              <Button
                                onClick={toggleDrawer("right", true, item)}
                              >
                                <div
                                  style={{ color: "blue" }}
                                  onClick={() => setPOid(item._id)}
                                >
                                  Invoice
                                </div>
                              </Button>
                              <SwipeableDrawer
                                anchor="right"
                                open={state["right"]}
                                onClose={toggleDrawer("right", false)}
                                onOpen={toggleDrawer("right", true)}
                              >
                                {list2("right", item)}
                              </SwipeableDrawer>
                            </div>
                          </TableCell>
                          <TableCell
                            scope="row"
                            padding="none"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Link
                              to={`/sales/edit-performa-invoices/${item._id}`}
                            >
                              <Button>
                                <EditIcon color="primary" />
                              </Button>
                            </Link>
                            {!item.duplicate_invoice ? (
                              <Button
                                disabled={
                                  item.dispatch_status === "Order Dispatched"
                                    ? false
                                    : true
                                }
                                variant="contained"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#fff",
                                  background: "green",
                                  border: "none",
                                  padding: "4px 10px",
                                  cursor: "pointer",
                                  fontSize: "13px",
                                }}
                                onClick={() => duplicateButton(item._id)}
                              >
                                <VisibilityIcon
                                  style={{ marginRight: "5px" }}
                                />
                                Dulpicate Tax Invoice
                              </Button>
                            ) : (
                              <>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "blue",
                                    border: "none",
                                    cursor: "pointer",
                                    fontSize: "13px",
                                  }}
                                  onClick={toggleDrawer(
                                    "right",
                                    item._id,
                                    "duplicate"
                                  )}
                                >
                                  <VisibilityIcon
                                    style={{ marginRight: "5px" }}
                                  />
                                  Dulpicate Tax Invoice
                                </p>
                                <SwipeableDrawer
                                  anchor="right"
                                  open={state["right"]}
                                  onClose={toggleDrawer("right", false)}
                                  onOpen={toggleDrawer("right", true)}
                                >
                                  {list2("right", item)}
                                </SwipeableDrawer>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={perPageData}
              component="div"
              count={performaInvoices.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  );
}

//Update a Brand Dialog
const Dialogcountry = ({
  open,
  setOpen,
  udateId,
  primaryName,
  companyName,
  DisplayName,
  sourceSupply,
  email,
  phone,
  mobile,
  website,
  gsttreatment,
  gstNo,
  panNo,
  paymentTerms,
  setPrimaryName,
  setCompanyName,
  setDisplayName,
  setEmail,
  setPhone,
  setMobile,
  setWebsite,
  setGstNo,
  setGstTreatment,
  setPanNo,
  setPaymentTerms,
  setSourceSupply,
}) => {
  const dispatch = useDispatch();
  const { sourceofsupply } = useSelector((state) => state.sourceofsupply);
  const { gstTreatment } = useSelector((state) => state.GstTreatment);
  //Handle Brand Update Function
  const updateBrandSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateVendor(udateId, {
        primary_name: primaryName,
        company_name: companyName,
        display_name: DisplayName,
        email: email,
        phone: phone,
        mobile: mobile,
        website: website,
        gst_treatment: gsttreatment,
        gst_number: gstNo,
        pan_number: panNo,
        payment_terms: paymentTerms,
      })
    );
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl">
      <DialogTitle>
        <Toolbar style={{ padding: 0 }}>
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            Add New
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ ml: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <VendorInput
              name={primaryName}
              heading="Primary Name"
              setInput={setPrimaryName}
            />
          </Grid>
          <Grid item lg={4}>
            <VendorInput
              name={companyName}
              heading="Company Name"
              setInput={setCompanyName}
            />
          </Grid>
          <Grid item lg={4}>
            <VendorInput
              name={DisplayName}
              heading="Display Name"
              setInput={setDisplayName}
            />
          </Grid>
          <Grid item lg={4}>
            <VendorInput name={email} heading="Email" setInput={setEmail} />
          </Grid>
          <Grid item lg={4}>
            <VendorInput name={phone} heading="Phone" setInput={setPhone} />
          </Grid>
          <Grid item lg={4}>
            <VendorInput name={mobile} heading="Mobile" setInput={setMobile} />
          </Grid>
          <Grid item lg={4}>
            <VendorInput
              name={website}
              heading="Website"
              setInput={setWebsite}
            />
          </Grid>
          <Grid item lg={4}>
            <VendorDropdown
              name={sourceSupply}
              heading="Source of Supply"
              setInput={setSourceSupply}
              data={sourceofsupply}
            />
          </Grid>
          <Grid item lg={4}>
            <VendorDropdown
              name={gsttreatment}
              heading="Gst Treatment"
              setInput={setGstTreatment}
              data={gstTreatment}
            />
          </Grid>
          <Grid item lg={4}>
            <VendorInput name={gstNo} heading="Gst no" setInput={setGstNo} />
          </Grid>
          <Grid item lg={4}>
            <VendorInput name={panNo} heading="Pan no" setInput={setPanNo} />
          </Grid>
          <Grid item lg={4}>
            <VendorInput
              name={paymentTerms}
              heading="Payment Terms"
              setInput={setPaymentTerms}
            />
          </Grid>
          {/* <Grid item lg={4}>
            <VendorInput name={tds} heading="Tds" setInput={setTds} />
          </Grid> */}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={updateBrandSubmitHandler} variant="contained">
          update
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Close
        </Button>
      </DialogActions>
      <Divider />
    </Dialog>
  );
};
