import { Grid, Typography, Paper, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate, useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import DrawerErp from "../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import StockReportTable from "./StockReportTable";
import { clearErrors } from "../../Redux/actions/MastersActions";
import { getCustomers } from "../../Redux/actions/CustomerActions";
import { MenuItem } from "@mui/material";

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: center;
      padding: 6px;
    }
  
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
      padding:16px
    }
    `
);

const data = [
  {
    value: "Data",
  },
  {
    value: "Data",
  },
  {
    value: "Data",
  },
];
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StockReport = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const [selectCustomer, setSelectCustomer] = React.useState("");

  const { isAuthenticated, error } = useSelector((state) => state.admin);
  const { error: adminError, customers } = useSelector(
    (state) => state.customers
  );

  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getCustomers());
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [dispatch, navigate, error, alert, isAuthenticated]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        {adminError ? (
          <Grid container xs={12} justifyContent="center" alignItems="center">
            <Typography variant="h5" sx={{ fontWeight: "bold", color: "red" }}>
              You don't have the required permissions.
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid container sx={{ p: 3 }}>
              <Grid item xs={6}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Stock Report
                </Typography>
              </Grid>
              <Grid container xs={12} display="flex">
                <Select
                  size="small"
                  sx={{ width: "25%" }}
                  value={selectCustomer}
                  // onChange={(e) => setSelectCustomer(e.target.value)}
                >
                  {customers.map((item) => {
                    <MenuItem>{item.name}</MenuItem>;
                  })}
                </Select>
                <Button
                  xs={3}
                  sx={{ ml: 3, backgroundColor: "yellowgreen" }}
                  variant="contained"
                  size="small"
                >
                  Get Report
                </Button>
                <Button
                  xs={3}
                  sx={{ ml: 3, backgroundColor: "orange" }}
                  variant="contained"
                  size="small"
                >
                  Refresh
                </Button>
              </Grid>
            </Grid>
            <Grid container sx={{ padding: "0 24px", mt: "0" }}>
              <Grid item xs={12}>
                <Item>
                  <Root sx={{ mt: 2 }}>
                    <StockReportTable />
                  </Root>
                </Item>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};
export default StockReport;
