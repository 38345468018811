import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../../Redux/actions/MastersActions";
import { useAlert } from "react-alert";
import { Link, useNavigate } from "react-router-dom";
import TaxInvoiceDrawer from "./TaxInvoiceDrawer";
import Loader from "../../Common/Loader";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import EditIcon from "@mui/icons-material/Edit";
import { UPDATE_TAX_INVOICE_RESET } from "../../../Redux/constants/SalesConstant";
import { getSalesInvoice } from "../../../Redux/actions/SalesActions";
function createData(sr, country) {
  return {
    sr,
    country,
  };
}

const rows = [
  createData(1, "Cupcake"),
  createData(2, "Donut"),
  createData(3, "Eclair"),
  createData(4, "Frozen yoghurt"),
  createData(5, "Gingerbread"),
  createData(6, "Honeycomb"),
  createData(7, "Ice cream sandwich"),
  createData(8, "Jelly Bean"),
  createData(9, "KitKat"),
  createData(10, "Lollipop"),
  createData(11, "Marshmallow"),
  createData(12, "Nougat"),
  createData(13, "Oreo"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "Invoice No.",
    numeric: false,
    disablePadding: true,
    label: "Invoice No.",
  },
  {
    id: "Customer",
    numeric: false,
    disablePadding: true,
    label: "Customer",
  },
  ,
  {
    id: "Total",
    numeric: false,
    disablePadding: true,
    label: "Total",
  },
    {
    id: "Tr Charges",
    numeric: false,
    disablePadding: true,
    label: "Tr Charges",
  },
  
  {
    id: "Dispatch Status",
    numeric: false,
    disablePadding: true,
    label: "Dispatch Status",
  },
  {
    id: "Invoice",
    numeric: false,
    disablePadding: true,
    label: "Invoice",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// const EnhancedTableToolbar = (props) => {
//   const { numSelected } = props;

//   return (
//     <Toolbar
//       sx={{
//         pl: { sm: 2 },
//         pr: { xs: 1, sm: 1 },
//         ...(numSelected > 0 && {
//           bgcolor: (theme) =>
//             alpha(
//               theme.palette.primary.main,
//               theme.palette.action.activatedOpacity
//             ),
//         }),
//       }}
//     >
//       {numSelected > 0 ? (
//         <Typography
//           sx={{ flex: "1 1 100%" }}
//           color="inherit"
//           variant="subtitle1"
//           component="div"
//         >
//           {numSelected} selected
//         </Typography>
//       ) : (
//         ""
//       )}

//       {numSelected > 0 ? (
//         <Tooltip title="Delete">
//           <IconButton>
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>
//       ) : (
//         <Tooltip title="Filter list">
//           <IconButton>
//             <FilterListIcon />
//           </IconButton>
//         </Tooltip>
//       )}
//     </Toolbar>
//   );
// };

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

export default function TaxInvoiceTable({ searchInput }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("brand");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [POid, setPOid] = React.useState("");

  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, loading, salesOrder } = useSelector(
    (state) => state.taxInvoice
  );
  const {
    error: deleteError,
    isDeleted,
    isUpdated,
  } = useSelector((state) => state.updateTaxInvoice);
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      alert.success("Tax Invoice Deleted Successfully");
      dispatch({ type: UPDATE_TAX_INVOICE_RESET });
      dispatch(getSalesInvoice());
    }
    if (isUpdated) {
      alert.success("Tax Invoice Updated Successfully");
      dispatch({ type: UPDATE_TAX_INVOICE_RESET });
      dispatch(getSalesInvoice());
    }

    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [
    dispatch,
    navigate,
    error,
    alert,
    isAuthenticated,
    deleteError,
    isDeleted,
    isUpdated,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  let updatedArray = salesOrder.filter(
    (e) =>
      e.customer.toLowerCase().search(searchInput.toLowerCase().trim()) !== -1
  );
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = updatedArray.map((n) => n.customer);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - updatedArray.length) : 0;

  var perPageData = [];
  var length = Math.ceil(updatedArray.length / 3);

  for (var i = 1; i <= length; i++) {
    perPageData.push(i * 3);
  }
  const [data, setData] = React.useState("");
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open, item) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    setData(item);
  };
  const list = (anchor, item) => (
    <Box
      sx={{ width: 850 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
    >
      <TaxInvoiceDrawer item={data} POid={POid} toggleDrawer={toggleDrawer} />
    </Box>
  );
  console.log(updatedArray);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{ width: "100%", mb: 2, boxShadow: "none", border: "none" }}
          >
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={updatedArray.length}
                />

                <TableBody>
                  {stableSort(updatedArray, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => {
                      const isItemSelected = isSelected(item.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={item.name}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              onClick={(event) =>
                                handleClick(event, item.primary_name)
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell> */}
                          <TableCell id={labelId} scope="row" padding="none">
                            {index + 1}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {new Date(item.date).toLocaleDateString("en-GB", 2)}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.invoice_number}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.company_name}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.total.toFixed(2)}
                          </TableCell>
						    <TableCell id={labelId} scope="row" padding="none">
                            {item.forwarding_charges}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            <button
                              style={{
                                background:
                                  item.dispatch_status === "Order Dispatched"
                                    ? "green"
                                    : "pink",
                                color: "#fff",
                                border: "none",
                                width: "100%",
                                padding: "10px 0",
                                fontSize: "16px",
                              }}
                            >
                              {item.dispatch_status === "Order Dispatched"
                                ? "Order Dispatch"
                                : "On Location"}
                            </button>
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            <div>
                              <Button
                                onClick={toggleDrawer("right", true, item)}
                              >
                                <div onClick={() => setPOid(item._id)}>
                                  Invoice
                                </div>
                              </Button>
                              <SwipeableDrawer
                                anchor="right"
                                open={state["right"]}
                                onClose={toggleDrawer("right", false)}
                                onOpen={toggleDrawer("right", true)}
                              >
                                {list("right", item)}
                              </SwipeableDrawer>
                            </div>
                          </TableCell>
                          <TableCell scope="row" padding="none">
                            <Link to={`/sales/edit-tax-invoices/${item._id}`}>
                              {/* {item.dispatch_status === "Order Dispatched" ? (
                                ""
                              ) : ( */}
                              <Button>
                                <EditIcon color="primary" />
                              </Button>
                              {/* )} */}
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={perPageData}
              component="div"
              count={updatedArray.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  );
}
