import {LOCATION_BARCODE_FAIL, LOCATION_BARCODE_REQUEST, LOCATION_BARCODE_SUCCESS} from "../constants/LocatinBarcodeConstant";
import { CLEAR_ERRORS } from "../constants/MastersConstants";
export const LocationBarcodeReducer = (state = { locationBarcode: [] }, action) => {
    switch (action.type) {
      case LOCATION_BARCODE_REQUEST:
        return { loading: true, locationBarcode: [] };
      case LOCATION_BARCODE_SUCCESS:
        return {
          ...state,
          loading: false,
          locationBarcode: action.payload,
        };
  
      case LOCATION_BARCODE_FAIL:
        return {
          ...state,
          loading: false,
          locationBarcode: null,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };