import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useReactToPrint } from "react-to-print";
import { Grid } from "@mui/material";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "lightblue",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 0,
    borderBottom: "1px solid black",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    border: 0,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function GRNDrawerTable({ item, id }) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                Item & Description
              </StyledTableCell>
              <StyledTableCell align="center">Ordered</StyledTableCell>
              <StyledTableCell align="center">QTY Received</StyledTableCell>
              <StyledTableCell align="center">WH Location</StyledTableCell>
              <StyledTableCell align="center">Barcode</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {item.item_details.map((r, ind) => {
				
				var margin_rate=0;
				var bas_rate=0;
				var a_rate=0;
				var b_rate=0;
				var c_rate=0;
				var d_rate=0;
				var d_margin=0;
				
				var mrp = r.mrp;


				
				d_margin = r.margin/5;
				
				margin_rate = (r.rate*r.margin/100)  + r.rate;
				 
				bas_rate = (margin_rate*d_margin/100)  + margin_rate;
				a_rate = (bas_rate*d_margin/100)  + bas_rate;
				b_rate = (a_rate*d_margin/100)  + a_rate;
				c_rate = (b_rate*d_margin/100)  + b_rate;
				d_rate = (c_rate*d_margin/100)  + c_rate;
				
				
				var margin_per=0;
				var bas_per=0;
				var a_per=0;
				var b_per=0;
				var c_per=0;
				var d_per=0;
				var d_per=0;
				
			     margin_per = (margin_rate /  ( mrp /100 ) );
			     bas_per = (bas_rate /  ( mrp /100 ) );
			     a_per = (a_rate /  ( mrp /100 ) );
			     b_per = (b_rate /  ( mrp /100 ) );
			     c_per = (c_rate /  ( mrp /100 ) );
			     d_per = (d_rate /  ( mrp /100 ) );
				 
				 
				 
				
				
              return (
                <StyledTableRow>
                  <StyledTableCell align="center">
                    {r.item} <br />
					
                    <span>MRP:{r.mrp}</span>
					
					  
					
					<span>
					
					 
					<Table aria-label="customized table">
					  <TableHead>
						<TableRow>
						  
						  <StyledTableCell align="center">MRP</StyledTableCell>
						  <StyledTableCell align="center">Purchase</StyledTableCell>
						  <StyledTableCell align="center">Margin ({r.margin}) </StyledTableCell>
						  <StyledTableCell align="center">Bas {"\n"} ({r.margin/5}) </StyledTableCell>
						  <StyledTableCell align="center">A Grp {"\n"} ({r.margin/5})</StyledTableCell>
						  <StyledTableCell align="center">B Grp ({r.margin/5})</StyledTableCell>
						  <StyledTableCell align="center">C Grp ({r.margin/5})</StyledTableCell>
						  <StyledTableCell align="center">D Grp ({r.margin/5})</StyledTableCell>
						</TableRow>
					  </TableHead>
					  
					  
					  <TableRow>
					  <StyledTableCell align="center">{r.mrp.toFixed(2)}</StyledTableCell>
					  <StyledTableCell align="center">{r.rate.toFixed(2)}</StyledTableCell>
					  
					  <StyledTableCell align="center">{margin_rate.toFixed(2)}  {margin_per.toFixed(2)}%  {(100-margin_per).toFixed(2)}% </StyledTableCell>
					  
					  <StyledTableCell align="center">{bas_rate.toFixed(2)} {bas_per.toFixed(2)}%  {(100-bas_per).toFixed(2)}%</StyledTableCell>
					  
					  <StyledTableCell align="center">{a_rate.toFixed(2)} {a_per.toFixed(2)}%  {(100-a_per).toFixed(2)}%</StyledTableCell>
					  
					  <StyledTableCell align="center">{b_rate.toFixed(2)}  {b_per.toFixed(2)}%  {(100-b_per).toFixed(2)}%</StyledTableCell>
					  
					  <StyledTableCell align="center">{c_rate.toFixed(2)} {c_per.toFixed(2)}%  {(100-c_per).toFixed(2)}%</StyledTableCell>
					  
					  
					  <StyledTableCell align="center">{d_rate.toFixed(2)} {d_per.toFixed(2)}%  {(100-d_per).toFixed(2)}% </StyledTableCell>
					   
					  </TableRow>
					  
					  </Table>
					
					</span>
					
					
					
                  </StyledTableCell>
                  <StyledTableCell align="center">{r.ordered}</StyledTableCell>
                  <StyledTableCell align="center">
                    {r.qyt_received}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {r.wh_location}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Link to={`/grnQrCode/${item._id}/${ind}`}>
                      <span style={{ cursor: "pointer", color: "blue" }}>
                        PRINT
                      </span>
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
