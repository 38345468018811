import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import TableBody from "@mui/material/TableBody";
import { MenuItem, Select, TextField } from "@mui/material";
import { add } from "draft-js/lib/DraftEntity";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(181,201,255)",
    color: theme.palette.common.white,
    borderRight: "1px solid #fff",
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "5px",
  },
}));

const StockTableRow = ({
  addChild,
  productSpecification,
  deleteRow,
  onchangeInput,
  defaultData,
  firstDefault,
}) => {
  console.log(addChild, "inner");
  return (
    <DragDropContext
      onDragEnd={(param) => {
        const srcIndex = param.source.index;
        const destIndex = param.destination.index;
        addChild.splice(destIndex, 0, addChild.splice(srcIndex, 1)[0]);
      }}
    >
      <Droppable droppableId="droppable-1">
        {(provided) => (
          <TableBody {...provided.droppableProps} ref={provided.innerRef}>
            {addChild?.map((item, index) => {
              return (
                <Draggable
                  key={item.value}
                  draggableId={"draggable-" + item.value}
                  index={index}
                >
                  {(provided) => (
                    <StyledTableRow
                      key={item.value}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {productSpecification?.length
                        ? productSpecification.map((item, ind) => (
                            <StyledTableCell
                              align="center"
                              style={{ borderRight: "1px solid #fff" }}
                            >
                              <TextField
                                type={"text"}
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                placeholder={item}
                                value={addChild[index][item]}
                                name={item}
                                sx={{
                                  width: "100%",
                                  mr: 1,
                                  background: "#fff",
                                }}
                                onChange={(val) =>
                                  onchangeInput(val, item, index)
                                }
                              />
                            </StyledTableCell>
                          ))
                        : ""}
                      {firstDefault.map((r, ind) => (
                        <StyledTableCell
                          align="center"
                          style={{ borderRight: "1px solid #fff" }}
                        >
                          <TextField
                            type={"number"}
                            // InputProps={{
                            //   inputProps: {
                            //     min: 0,
                            //   },
                            // }}
                            size="small"
                            id="outlined-basic"
                            placeholder={r.value}
                            value={addChild[index][r.value]}
                            variant="outlined"
                            sx={{ width: "100%", mr: 1, background: "#fff" }}
                            onChange={(val) =>
                              onchangeInput(val, r.value, index)
                            }
                          />
                        </StyledTableCell>
                      ))}

                      {productSpecification?.length ? (
                        <>
                          {defaultData.map((item, ind) =>
                            item.value === "Dimenssion LXBXH(cm)" ? (
                              <StyledTableCell
                                align="center"
                                size="small"
                                style={{
                                  display: "flex",
                                  borderRight: "1px solid #fff",
                                }}
                              >
                                {item.data.map((r) => (
                                  <TextField
                                    type={"number"}
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder={r.value}
                                    name={r.value}
                                    value={addChild[index][r.value]}
                                    size="small"
                                    // InputProps={{
                                    //   inputProps: {
                                    //     min: 0,
                                    //   },
                                    // }}
                                    sx={{
                                      width: "100%",
                                      mr: 1,
                                      background: "#fff",
                                    }}
                                    onChange={(val) =>
                                      onchangeInput(val, r.value, index)
                                    }
                                  />
                                ))}
                              </StyledTableCell>
                            ) : item.value === "Weight" ? (
                              <StyledTableCell>
                                <TextField
                                  type={"number"}
                                  id="outlined-basic"
                                  variant="outlined"
                                  size="small"
                                  placeholder="Weight"
                                  value={addChild[index]["Weight"]}
                                  // InputProps={{
                                  //   inputProps: {
                                  //     min: 0,
                                  //   },
                                  // }}
                                  sx={{
                                    width: "60%",
                                    mr: 1,
                                    background: "#fff",
                                  }}
                                  onChange={(val) =>
                                    onchangeInput(val, item.value, index)
                                  }
                                />
                                <Select
                                  size="small"
                                  id="outlined-basic"
                                  variant="outlined"
                                  style={{ background: "#fff" }}
                                  name="weightSelect"
                                  value={addChild[index]["weightSelect"]}
                                  onChange={(val) =>
                                    onchangeInput(val, "weightSelect", index)
                                  }
                                >
                                  <MenuItem value="gram">gram</MenuItem>
                                  <MenuItem value="kg">Kg</MenuItem>
                                </Select>
                              </StyledTableCell>
                            ) : item.value === "Volume" ? (
                              <StyledTableCell align="center">
                                <TextField
                                  type={"number"}
                                  id="outlined-basic"
                                  variant="outlined"
                                  size="small"
                                  disabled
                                  placeholder={"Volume"}
                                  value={addChild[index]["volume"]}
                                  // InputProps={{
                                  //   inputProps: {
                                  //     min: 0,
                                  //   },
                                  // }}
                                  sx={{
                                    width: "100%",
                                    mr: 1,
                                    background: "#fff",
                                  }}
                                  onChange={(val) =>
                                    onchangeInput(val, "Volume", index)
                                  }
                                />
                              </StyledTableCell>
                            ) : (
                              <StyledTableCell align="center">
                                <TextField
                                  type={"number"}
                                  id="outlined-basic"
                                  variant="outlined"
                                  size="small"
                                  placeholder={item.value}
                                  value={addChild[index][item.value]}
                                  // InputProps={{
                                  //   inputProps: {
                                  //     min: 0,
                                  //   },
                                  // }}
                                  sx={{
                                    width: "100%",
                                    mr: 1,
                                    background: "#fff",
                                  }}
                                  onChange={(val) =>
                                    onchangeInput(val, item.value, index)
                                  }
                                />
                              </StyledTableCell>
                            )
                          )}
                          <StyledTableCell
                            align="center"
                            onClick={() => deleteRow(index)}
                          >
                            <RemoveIcon />
                          </StyledTableCell>
                        </>
                      ) : (
                        ""
                      )}
                    </StyledTableRow>
                  )}
                </Draggable>
              );
            })}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default StockTableRow;
