import {
  LOGIN_REQUEST,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  CLEAR_ERRORS,
} from "../constants/AdminLoginConstants";
import axios from "axios";

// Login
// Login
export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    // Adding `withCredentials: true` inside the config
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/login`, 
      { username, password },
      config // Pass config containing `withCredentials`
    );

    // Storing user data in localStorage
    localStorage.setItem('user', data.user);

    dispatch({ type: LOGIN_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload: error.response.data.message });
  }
};


// Logout User
export const logout = () => async (dispatch) => {
  try {
    await axios.get(`https://erpapi.flaskitsolutions.com/api/v1/logout`);
	
	localStorage.removeItem('user')

    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: LOGOUT_FAIL, payload: error.response.data.message });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
