export const GRN_REQUEST = "GRN_REQUEST";
export const GRN_SUCCESS = "GRN_SUCCESS";
export const GRN_FAIL = "GRN_FAIL";

export const GRN_NUMBER_FAIL = "GRN_NUMBER_FAIL";
export const GRN_NUMBER_SUCCESS = "GRN_NUMBER_SUCCESS";
export const GRN_NUMBER_REQUEST = "GRN_NUMBER_REQUEST";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const NEW_GRN_REQUEST = "NEW_GRN_REQUEST";
export const NEW_GRN_SUCCESS = "NEW_GRN_SUCCESS";
export const NEW_GRN_FAIL = "NEW_GRN_FAIL";
export const NEW_GRN_RESET = "NEW_GRN_RESET";

export const UPDATE_GRN_REQUEST = "UPDATE_GRN_REQUEST";
export const UPDATE_GRN_SUCCESS = "UPDATE_GRN_SUCCESS";
export const UPDATE_GRN_FAIL = "UPDATE_GRN_FAIL";
export const UPDATE_GRN_RESET = "UPDATE_GRN_RESET";

export const DELETE_GRN_REQUEST = "DELETE_GRN_REQUEST";
export const DELETE_GRN_SUCCESS = "DELETE_GRN_SUCCESS";
export const DELETE_GRN_FAIL = "DELETE_GRN_FAIL";
export const DELETE_GRN_RESET = "DELETE_GRN_RESET";

export const GRN_PRINT_SUCCESS = "GRN_PRINT_SUCCESS";
export const GRN_PRINT_REQUEST = "GRN_PRINT_REQUEST";
