import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { getEndProductList } from "../../../Redux/actions/InventryActions";
import { useNavigate } from "react-router-dom";
import Loader from "../../Common/Loader";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title",
  },
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    label: "Image",
  },
  {
    id: "trending offer",
    numeric: false,
    disablePadding: true,
    label: "Trending Offer",
  },
  {
    id: "Deal of the day",
    numeric: false,
    disablePadding: true,
    label: "Deal Of The day",
  },
  {
    id: "best offer",
    numeric: false,
    disablePadding: true,
    label: "Best Offer",
  },
  {
    id: "knowkout deal",
    numeric: false,
    disablePadding: true,
    label: "Knowkout Deal",
  },
  {
    id: "Most love",
    numeric: false,
    disablePadding: true,
    label: "Most Love",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Deals
      </Typography>
    </Toolbar>
  );
};

export default function SliderTable(props) {
  console.log(props.Data);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("state");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.admin);
  const { loading, endProductList } = useSelector(
    (state) => state.endProductList
  );
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getEndProductList());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [dispatch, navigate, isAuthenticated]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - endProductList.length)
      : 0;

  var perPageData = [];
  var length = Math.ceil(endProductList.length / 3);

  for (var i = 1; i <= length; i++) {
    perPageData.push(i * 3);
  }
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{ width: "100%", mb: 2, boxShadow: "none", border: "none" }}
          >
            <EnhancedTableToolbar />
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={endProductList.length}
              />

              <TableBody>
                {stableSort(endProductList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow>
                        <TableCell id={labelId} scope="row" padding="none">
                          {index + 1}
                        </TableCell>
                        <TableCell id={labelId} scope="row" padding="none">
                          {item?.data?.product_title}
                        </TableCell>
                        <TableCell id={labelId} scope="row" padding="none">
                          <img
                            src={item.data.images[0].url}  alt=""
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                             
                            }}
                          />
                        </TableCell>
                        <TableCell padding="checkbox">
                          <Checkbox {...label} />
                        </TableCell>
                        <TableCell padding="checkbox" style={{ width: "15%" }}>
                          <Checkbox {...label} />
                        </TableCell>
                        <TableCell padding="checkbox">
                          <Checkbox {...label} />
                        </TableCell>
                        <TableCell padding="checkbox">
                          <Checkbox {...label} />
                        </TableCell>
                        <TableCell padding="checkbox">
                          <Checkbox {...label} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={perPageData}
              component="div"
              count={endProductList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  );
}
