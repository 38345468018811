import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Ecommerce from "./components/Ecommerce/Ecommerce";
import Sales from "./components/Ecommerce/Sales/Sales";
// import Slider from "./components/Ecommerce/Slider/Slider";

// import Enquiry from "./components/Ecommerce/Enquiry";
// import Reviews from "./components/Ecommerce/Reviews";
// import Deals from "./components/Ecommerce/Deals";
// import Customers from "./components/Ecommerce/Customers";
// import PinCode from "./components/Ecommerce/PinCode";
// import Questionnier from "./components/Ecommerce/Questionnier";
// import Contacts from "./components/Ecommerce/Contacts";
// import Slider from "./components/Ecommerce/Slider";
// import Discount from "./components/Ecommerce/Discount";
import DashboardContent from "./components/Ecommerce/DashboardContent";
import Dashboard from "./components/Dashboard/Dashboard";
// import PurchaseOrders from "./components/PurchaseOrders/PurchaseOrders";
// import PurchaseReport from "./components/PurchaseReport/PurchaseReport";
import Login from "./components/Login/Login";

import Country from "./components/Masters/Country/Country";
import PrSection from "./components/Masters/PrSection/PrSection";

import State from "./components/Masters/State/State";
import City from "./components/Masters/City/City";
import Source from "./components/Masters/Source of Supply/Source";

import EnquiryReport from "./components/Ecommerce/Enquiry Report/EnquiryReport";
import Reviews from "./components/Ecommerce/Reviews/Reviews";
import Slider from "./components/Ecommerce/Slider/Slider";
import RelatedProducts from "./components/Ecommerce/RelatedProducts/RelatedProducts";
import Deals from "./components/Ecommerce/Deals/Deals";
import Customers from "./components/Ecommerce/Customers/Customers";
import PinCode from "./components/Ecommerce/PinCode/PinCode";
import Questionnier from "./components/Ecommerce/Questionnier/Questionnier";
import Contacts from "./components/Ecommerce/Contacts/Contacts";
import Discount from "./components/Ecommerce/Discount/Discount";
import CustGroup from "./components/Ecommerce/CustGroup/CustGroup";
import CustSubGroup from "./components/Ecommerce/CustSubGroup/CustSubGroup";

import PaymentTerms from "./components/Masters/Payment Terms/PaymentTerms";
import ProductTerms from "./components/Masters/Product Terms/ProductTerms";
import GstTreatment from "./components/Masters/Gst Treatment/GstTreatment";
import GstClassification from "./components/Masters/Gst Classification/GstClassification";
import BankAccount from "./components/Masters/Bank Accounts/BankAccount";
import DispatchAppUser from "./components/Masters/Dispatch App User/DispatchAppUser";
import Warehouse from "./components/Warehouse/Warehouse";
import MasterModule from "./components/Masters/Master Module/MasterModule";

import Brand from "./components/Inventory/Brands/Brands";
import ProductSpecifications from "./components/Inventory/ProductSpecifications/ProductSpecifications";
import ProductUnit from "./components/Inventory/ProductUnit/ProductUnit";
import ProductType from "./components/Inventory/ProductType/ProductType";
import ProductSegment from "./components/Inventory/productSegment/productSegment";
import ProductCategory from "./components/Inventory/ProductCategory/ProductCategory";
import ProductAccount from "./components/Inventory/ProductAccount/ProductAccount";
import AddProduct from "./components/Inventory/AddProduct/AddProduct";
import ProductGroup from "./components/Inventory/ProductGroup/ProductGroup";
import Product from "./components/Inventory/Product/Product";
import AddCustomers from "./components/Customers/AddCustomer/AddCustomer";
import Vendor from "./components/Vendors/Vendor";
import PurchaseOrder from "./components/PurchaseOrders/PurchaseOrders";
import AddPurchaseOrder from "./components/PurchaseOrders/AddPurchaseOrder";
import Grn from "./components/GRN/GRN";
import StockReport from "./components/StockReport/StockReport";
import AddTaxInvoice from "./components/Sales/AddTaxInvoice/AddTaxInvoice";
import TaxInvoice from "./components/Sales/TaxInvoice/TaxInvoice";
import EditProduct from "./components/Inventory/Product/EditProduct";
import AddPerformaInvoice from "./components/Sales/AddPerformaInvoice/AddPerformaInvoice";
import PerformaInvoice from "./components/Sales/PerformaInvoice/PerformaInvoce";
import EndProductList from "./components/Inventory/EndProductList/EndProductList";
import SaleReport from "./components/SaleReport/SaleReport";
import PurchaseReport from "./components/PurchaseReport/PurchaseReport";
import LocationBarCode from "./components/LocationBarCode/LocationBarCode";
import EditPurchaseOrder from "./components/PurchaseOrders/EditPurchaseOrder";
import EditTaxInvoice from "./components/Sales/AddTaxInvoice/EditTaxInvoice";
import Users from "./components/Users/Users";
import GRNQrCode from "./components/GRN/GRNDrawer/GRNQrCode";
import GRNMultiple from "./components/GRN/GRNDrawer/GRNMultiple";
import EditPerfromaInvoice from "./components/Sales/AddPerformaInvoice/EditPerformaInvoice";
import LocationBarcodeDetails from "./components/LocationBarCode/LocationBarcodeDetails";
import AddCustomerPage from "./components/Customers/AddCustomer/AddCustomerPage";
import VendorPage from "./components/Vendors/VendorPage";
import EditCustomer from "./components/Customers/AddCustomer/EditCustomer";
import EditVendor from "./components/Vendors/EditVendor";
import Distance from "./components/Masters/Distance/Distance";
const routePath = [
  // { Heading: "Warehouses", path: "warehouses" },
  { Heading: "Vendors", path: "vendors" },
  { Heading: "Customers", path: "customers" },
  { Heading: "Inventory", path: "inventory" },
  // { Heading: "Purchase Orders", path: "purchase-orders" },
  { Heading: "Sales", path: "sales" },
  { Heading: "Location Barcode Scan", path: "location-barcode-scan" },
  // { Heading: "Stock Report", path: "stock-report" },
  { Heading: "Sale Report", path: "sale-report" },
  // { Heading: "Purchase Report", path: "purchase-report" },
  { Heading: "Users", path: "users" },
];
const routeSubPath = [
  // { Heading: "Enquiry", path: "enquiry" },
  // { Heading: "Reviews", path: "reviews" },
  // { Heading: "Related Products", path: "related-products" },
  // { Heading: "Deals", path: "deals" },
  // { Heading: "Customers", path: "customers" },
  // { Heading: "PinCode", path: "pincode" },
  // { Heading: "Questionnier", path: "questionnier" },
  // { Heading: "Contacts", path: "contacts" },
  // { Heading: "Slider", path: "slider" },
  // { Heading: "Discount", path: "discount" },
  // { Heading: "Cust Group", path: "cust-group" },
  { Heading: "Cust Sub-Gp", path: "cust-sub-gp" },
];

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path={"/login"} element={<Login />} />
          <Route path="/ecommerce" element={<Ecommerce />}>
            <Route path="dashboard" element={<DashboardContent />} />
            <Route path="sales" element={<Sales />} />
            <Route path="enquiry" element={<EnquiryReport />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="related-products" element={<RelatedProducts />} />
            <Route path="deals" element={<Deals />} />
            <Route path="customers" element={<Customers />} />

            <Route path="pincode" element={<PinCode />} />
            <Route path="questionnier" element={<Questionnier />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="discount" element={<Discount />} />
            <Route path="cust-group" element={<CustGroup />} />

            <Route path="cust-sub-gp" element={<CustSubGroup />} />
            <Route path="slider" element={<Slider />} />
            {/* <Route path="slider" element={<Slider />} /> */}
            {/* <Route path="reviews" element={<Reviews />} /> */}
            {/* <Route path="reviews" element={<Reviews />} />
            <Route path="deals" element={<Deals />} />
            <Route path="Customers" element={<Customers />} />
            <Route path="pincode" element={<PinCode />} />
            <Route path="questionnier" element={<Questionnier />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="slider" element={<Slider />} />
            <Route path="enquiry" element={<Enquiry />} />
            <Route path="discount" element={<Discount />} /> */}

            {/* {routeSubPath.map((path) => {
              return (
                <Route
                  path={`${path.path}`}
                  element={<h1>{path.Heading}</h1>}
                />
              );
            })} */}
          </Route>
          {/* {routePath.map((path) => {
            return (
              <Route path={`/${path.path}`} element={<h1>{path.Heading}</h1>} />
            );
          })} */}
           <Route path="/masters/distance" element={<Distance/>} />
          <Route path="/vendor/vendors" element={<Vendor />} />
          <Route path="/vendor/vendors-page" element={<VendorPage />} />
          <Route path={"/sale-report-outer"} element={<SaleReport />} />
          <Route path="/purchase-report" element={<PurchaseReport />} />
          <Route path="/vendor/edit_vendor/:id" element={<EditVendor />} />
          <Route
            path={"/location-barcode-scan-outer"}
            element={<LocationBarCode />}
          />
          <Route path="/grnMultiple" element={<GRNMultiple />} />
          <Route path="/grnQrCode/:id/:ind" element={<GRNQrCode />} />
          <Route path="/purchase-orders" element={<PurchaseOrder />} />
          <Route path="/purchase-orders/:id" element={<EditPurchaseOrder />} />
          <Route path="/add-purchase-orders" element={<AddPurchaseOrder />} />
          <Route path="/sales/add-text-invoices" element={<AddTaxInvoice />} />
          <Route path="/sales/tax-invoices" element={<TaxInvoice />} />
          <Route
            path="/sales/edit-tax-invoices/:id"
            element={<EditTaxInvoice />}
          />
          <Route
            path="/locationBarcode/:id"
            element={<LocationBarcodeDetails />}
          />
          <Route
            path="/sales/edit-performa-invoices/:id"
            element={<EditPerfromaInvoice />}
          />
          <Route
            path="/sales/add-performa-invoices"
            element={<AddPerformaInvoice />}
          />
          <Route
            path="/sales/performa-invoices"
            element={<PerformaInvoice />}
          />
          <Route path={"/warehouses"} element={<Warehouse />} />
          <Route path={"/dashboard"} element={<Dashboard />} />
          {/* <Route path={"/purchase-orders"} element={<PurchaseOrders />} /> */}
          {/* <Route path={"/purchase-report"} element={<PurchaseReport />} /> */}
          <Route path={"/masters/country_masters"} element={<Country />} />
          <Route path={"/masters/pr_section"} element={<PrSection />} />
          <Route path={"/inventory/products"} element={<Product />} />
          <Route path="/inventory/editProduct/:id" element={<EditProduct />} />
          <Route path="/inventory/product_unit" element={<ProductUnit />} />
          <Route path="/inventory/product_type" element={<ProductType />} />
          <Route
            path="/inventory/product_segment"
            element={<ProductSegment />}
          />
          <Route path="/inventory/product_group" element={<ProductGroup />} />
          <Route path="/inventory/brands" element={<Brand />} />
          <Route path="/inventory/product_unit" element={<ProductUnit />} />

          <Route path={"/masters/state_masters"} element={<State />} />
          <Route path={"/masters/city_masters"} element={<City />} />
          <Route path={"/masters/source_of_supply"} element={<Source />} />
          <Route path={"/masters/payment_terms"} element={<PaymentTerms />} />
          <Route path={"/masters/product_terms"} element={<ProductTerms />} />
          <Route path={"/masters/gst_treatment"} element={<GstTreatment />} />

          <Route
            path={"/masters/gst_classification"}
            element={<GstClassification />}
          />
          <Route path={"/masters/bank_account"} element={<BankAccount />} />
          <Route
            path={"/masters/dispatch_app_user"}
            element={<DispatchAppUser />}
          />
          <Route path={"/masters/module_master"} element={<MasterModule />} />

          {/* Inventory Routes */}
          <Route
            path={"/inventory/product_specifications"}
            element={<ProductSpecifications />}
          />
          <Route
            path={"/inventory/product_account"}
            element={<ProductAccount />}
          />
          <Route path={"/inventory/add_product"} element={<AddProduct />} />

          <Route
            path="/inventory/product_category"
            element={<ProductCategory />}
          />
          <Route
            path="/inventory/end_product_list"
            element={<EndProductList />}
          />

          {/* Customer Routes */}

          <Route path="/customers/add_customers" element={<AddCustomers />} />
          <Route
            path="/customers/add-customer-page"
            element={<AddCustomerPage />}
          />
          <Route
            path="/customers/edit_customer/:id"
            element={<EditCustomer />}
          />

          {/* GRN */}
          <Route path="/grn" element={<Grn />} />

          {/* Stock Report */}
          <Route path="/stock-report" element={<StockReport />} />

          {/* Users */}
          <Route path="/users" element={<Users />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
