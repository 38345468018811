import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../../Redux/actions/MastersActions";
import {
  getProductSpecifications,
  updateProductSpecifications,
  deleteProductSpecifications,
} from "../../../Redux/actions/InventryActions";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { DELETE_PRODUCT_SPECIFICATIONS_RESET } from "../../../Redux/constants/InventryConstant";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { UPDATE_PRODUCT_SPECIFICATIONS_RESET } from "../../../Redux/constants/InventryConstant";
import Loader from "../../Common/Loader";
import DeleteDialog from "../../Common/DeleteDialog";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Product Specifications
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ProductSpecificationsTable({searchInput, componentRef, change}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [open, setOpen] = React.useState(false);
  const [udatedata, setUpdatedata] = React.useState("");
  const [udateId, setUpdateId] = React.useState("");

  const handleClickOpen = (cou, id) => {
    setOpen(true);
    setUpdatedata(cou);
    setUpdateId(id);
  };
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, loading, productSpecification } = useSelector(
    (state) => state.productSpecification
  );
  const {
    error: deleteError,
    isDeleted,
    isUpdated,
  } = useSelector((state) => state.updateProductSpecification);


     // Delete Dialog
 const [deleteOpen, setDelete] = React.useState(false);
 const [id,setId] = React.useState("")
 const handleClickOpenDelete = (id) => {
   setDelete(true);
   setId(id)
 };
 const deleteHandler = (id) => {
  dispatch(deleteProductSpecifications(id));
};

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      alert.success("Product Specifications Deleted Successfully");
      dispatch({ type: DELETE_PRODUCT_SPECIFICATIONS_RESET });
      dispatch(getProductSpecifications());
    }
    if (isUpdated) {
      alert.success("Product Specifications Updated Successfully");
      dispatch({ type: UPDATE_PRODUCT_SPECIFICATIONS_RESET });
      dispatch(getProductSpecifications());
    }

    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [
    dispatch,
    navigate,
    error,
    alert,
    isAuthenticated,
    deleteError,
    isDeleted,
    isUpdated,
  ]);
  let updatedArray = productSpecification.filter(
    (e) => e.name.toLowerCase().search(searchInput.toLowerCase().trim()) !== -1
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = updatedArray.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - updatedArray.length)
      : 0;

  var perPageData = [];
  var length = Math.ceil(updatedArray.length / 3);

  for (var i = 1; i <= length; i++) {
    perPageData.push(i * 3);
  }
  return (
    <>
      {change || loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{ width: "100%", mb: 2, boxShadow: "none", border: "none" }}
          >
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
            <div
                ref={componentRef}
                id="content"
                className={change ? "content" : ""}
              >
              <Table
               id="table-to-xls"
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={updatedArray.length}
                />

                <TableBody>
                  {stableSort(
                    updatedArray,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((productSpecification, index) => {
                      const isItemSelected = isSelected(
                        productSpecification.name
                      );
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          // aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={productSpecification.name}
                          // selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              onClick={(event) =>
                                handleClick(event, productSpecification.name)
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            // component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            // component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {productSpecification.name}
                          </TableCell>
                          <TableCell
                            // component="th"
                            // id={labelId}
                            scope="row"
                            padding="none"
                          >
                            <Button>
                              <EditIcon
                                color="primary"
                                onClick={() =>
                                  handleClickOpen(
                                    productSpecification.name,
                                    productSpecification._id
                                  )
                                }
                              />
                            </Button>
                            <DialogProductSpecifications
                              open={open}
                              setOpen={setOpen}
                              udateId={udateId}
                              udatedata={udatedata}
                              setUpdatedata={setUpdatedata}
                            />
                             <Button>
                                <DeleteIcon
                                   onClick={() => handleClickOpenDelete(productSpecification._id)}
                                  color="error"
                                />
                              </Button>
                              <DeleteDialog
                                setDelete={setDelete}
                                deleteOpen={deleteOpen}
                                id={id}
                                deleteHandler={deleteHandler}
                              />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              </div>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={perPageData}
              component="div"
              count={updatedArray.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  );
}

//Update a Product Specification Dialog
const DialogProductSpecifications = ({
  open,
  setOpen,
  udateId,
  udatedata,
  setUpdatedata,
}) => {
  const dispatch = useDispatch();

  //Handle Product Specification Update Function
  const updateProductSpecificationsSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(updateProductSpecifications(udateId, { name: udatedata }));
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth="true">
      <DialogTitle>
        <Toolbar style={{ padding: 0 }}>
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            Add New
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ ml: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>

      <Divider />
      <DialogContent>
        <DialogContentText>Name</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          sx={{ mt: 2 }}
          value={udatedata}
          onChange={(e) => setUpdatedata(e.target.value)}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={updateProductSpecificationsSubmitHandler}
          variant="contained"
        >
          Update
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Close
        </Button>
      </DialogActions>
      <Divider />
    </Dialog>
  );
};
