import { DialogContentText, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Autocomplete } from "@mui/material";
const options = ["Option 1", "Option 2"];

const BasicAutoComplete = ({
  heading,
  setInput,
  val,
  data,
  size,
  textSize,
  name
}) => {
  // const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState(val);
  useEffect(()=>{
    setInputValue(val);
  },[])
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <DialogContentText>{heading}</DialogContentText>
      <Autocomplete
        value={inputValue}
        onChange={(event, newValue) => {
          setInputValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          setInput(newInputValue);
        }}
        id="controllable-states-demo"
        options={data}
        sx={{ width: size ? `${size}`: "100%" }} 
        renderInput={(params) => (
          <TextField {...params} label={heading} size={textSize?`${textSize}`:"medium"} sx={{ mt: 2 }} />
        )}
      />

    </div>
  );
};

export default BasicAutoComplete;
