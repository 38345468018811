import * as React from "react";
import { useReactToPrint } from "react-to-print";
import { Grid } from "@mui/material";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
const GRNMultiple = () => {
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const { grns } = useSelector((state) => state.grns);
  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 2000);
    });
  }, [setLoading]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,

    removeAfterPrint: true,
  });
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);
  return (
    <>
      <span style={{ cursor: "pointer", color: "blue" }} onClick={handlePrint}>
        PRINT
      </span>
      <div ref={componentRef}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexGrow: "1",
          }}
        >
          {grns?.map((e) => {
            return e.item_details.map((r) => {
              return r.grn_product_codes?.map((e) => {
                return (
                  <div
                    style={{
                      border: "1px solid black",
                      width: "182px",
                      height: "148px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "10px",
                      fontWeight: "600",
                      padding: "5px",
                      marginRight: "5px",
                      marginBottom: "15px",
                    }}
                  >
                    <QRCode value={e} size="60" />
                    <span style={{ marginTop: "5px" }}>{e}</span>
                    <span
                      style={{
                        textAlign: "center",
                        fontSize: "9px",
                        wordBreak: "break-word",
                        whiteSpace: "initial",
                      }}
                    >
                      {r.item}
                    </span>
                    <span>MRP : {r.mrp}</span>
                  </div>
                );
              });
            });
          })}
        </div>
      </div>
    </>
  );
};

export default GRNMultiple;
