import { Grid, Typography, Paper, Button } from "@mui/material";
import React, { useEffect } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Link, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../../../components/Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import {
  clearErrors,
  getGstTreatment,
  getPaymentTerms,
  getSourceOfSupply,
} from "../../../Redux/actions/MastersActions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { createVendor, getVendor } from "../../../Redux/actions/VendorAction";
import VendorInput from "../../Common/VendorInput";
import { NEW_VENDOR_RESET } from "../../../Redux/constants/VendorConstant";
import VendorDropdown from "../../Common/VendorDropdown";
import {
  createPurchseOrder,
  getPurchaseOrder,
} from "../../../Redux/actions/PurchaseOrderAction";
import { NEW_PURCHASE_ORDER_RESET } from "../../../Redux/constants/PurchaseOrderConstant";
import PerformaInvoiceTable from "./PerformaInvoiceTable";
import { getPerformaInvoice } from "../../../Redux/actions/SalesActions";
import { NEW_PERFORMA_INVOICE_RESET } from "../../../Redux/constants/SalesConstant";
import Excle from "../../Common/Excle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#e7ebf0",
  "&:hover": {
    backgroundColor: "#e7ebf0",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: center;
      padding: 6px;
    }
  
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
      padding:16px
    }
    `
);

const PerformaInvoice = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, success } = useSelector((state) => state.newPerformaInvoice);
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getPerformaInvoice());
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
    if (success) {
      alert.success("Performa Invoice Added Successfully");
      dispatch({ type: NEW_PERFORMA_INVOICE_RESET });
      dispatch(getPerformaInvoice());
    }
  }, [dispatch, navigate, error, alert, isAuthenticated, success]);

  const { performaInvoices } = useSelector(
    (state) => state.performaInvoices
  );
 return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: "5px" }}
            >
              Offline Sales - Performa Invoice
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link
              to={"/sales/add-performa-invoices"}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Button
                variant="contained"
                style={{
                  background: "blue",
                  color: "#fff",
                  fontSize: "12px",
                  marginRight: "4px",
                }}
              >
                <AddIcon /> Add Order
              </Button>
            </Link>

            <Button variant="contained" style={{ background: "green" }}>
              <ContentCopyIcon />
              <Excle
                headCells={headCells}
                data={performaInvoices}
                name="tax"
                btn="Export"
                color="transparent"
                select="Excle"
              />
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "0 24px", mt: "0" }}>
          <Grid item xs={12}>
            <Item>
              <Grid container justifyContent="flex-end" sx={{ mt: 8 }}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </Grid>
              <Root sx={{ mt: 2 }}>
                <PerformaInvoiceTable />
              </Root>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default PerformaInvoice;

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "Invoice No.",
    numeric: false,
    disablePadding: true,
    label: "Performa Invoice No.",
  },
  {
    id: "Customer",
    numeric: false,
    disablePadding: true,
    label: "Customer",
  },
  ,
  {
    id: "Total",
    numeric: false,
    disablePadding: true,
    label: "Total",
  },
  {
    id: "Dispatch Status",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "Invoice",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];
