import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TableBody from "@mui/material/TableBody";
import { Button, Grid, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(181,201,255)",
    color: theme.palette.common.white,
    borderRight: "1px solid #fff",
    height: "50px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "30px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const defaultData = [
  {
    value: "Name",
  },
  {
    value: "Category",
  },
  {
    value: "Stock On Hand",
  },
];
export default function AddPurchaseOrderItem({
  addChild,
  data,
  searchInput,
  setIsModal,
  setAddChild,
  index,
  sales,
}) {
  const func = (data, data2, productCode, hsn, mrp,margin, last, stock , Weight , weightSelect , volume) => {
    console.log(index,"data")

    let up = addChild.map((r, ind) =>
      index === ind
        ? {
            ...r,
            ["item"]: data,
            ["_id"]: data2,
            ["hsn_code"]: hsn,
            ["mrp"]: mrp,
		    ["margin"]: margin,
            ["product_code"]: productCode,
            ["gst"]: last,
            ["stock"]: stock,
            ["Weight"]: Weight,
            ["weightSelect"]: weightSelect,
            ["volume"]: volume,
            ["tax"]: last,
          }
        : r
    );
    let updated = up.map((r, ind) =>
      index === ind && sales === "sales"
        ? {
            ...r,
            ["rate"]: mrp,
          }
        : r
    );
    console.log(updated,"------------")
    setAddChild(updated);
    setIsModal(false);
  };
  let updated = data.filter(
    (e) =>
      e.name
        .toLowerCase()
        .search(addChild[index]?.item?.toLowerCase().trim()) !== -1
  );
  return (
    <>
      <div
        style={{
          width: "85%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CloseIcon onClick={() => setIsModal(false)} />
        <Link
          to={"/inventory/add_product"}
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <span
            style={{
              color: "blue",
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
            }}
          >
            <AddIcon />
            Add New
          </span>
        </Link>
      </div>
      <TableContainer component={Paper} style={{ width: "85%" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {defaultData.map((item) => (
                <StyledTableCell
                  sx={{
                    width: item.value === "Name" ? "50%" : "15%",
                  }}
                >
                  {item.value}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {updated.map((item) => {
              return (
                <TableRow hover role="checkbox">
                  <TableCell
                    scope="row"
                    onClick={() =>
                      func(
                        item.name,
                        item._id,
                        item.stock_and_prices[0].product_code,
                        item.data.hsn_code,
                        item.stock_and_prices[0].MRP,
                        item.stock_and_prices[0].Margin,
                        item.data.account_information.gst,
                        item.stock_and_prices[0].Stocks,
                        item.stock_and_prices[0].Weight,
                         item.stock_and_prices[0].weightSelect,
                        item.stock_and_prices[0].volume,
						
                      )
                    }
                  >
                    {item.name} 
                  </TableCell>
                  <TableCell scope="row">
                    {item.data.product_category}
                  </TableCell>
                  <TableCell
                    scope="row"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {item.stock_and_prices[0].Stocks}
                    <Link to={`/inventory/editProduct/${item?.common_id}`}>
                      <Button>
                        <EditIcon color="primary" />
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
