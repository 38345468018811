import {
  COUNTRY_REQUEST,
  COUNTRY_SUCCESS,
  COUNTRY_FAIL,
  NEW_COUNTRY_REQUEST,
  NEW_COUNTRY_SUCCESS,
  NEW_COUNTRY_FAIL,
  UPDATE_COUNTRY_REQUEST,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAIL,
  DELETE_COUNTRY_REQUEST,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAIL,
  
  
    PRSECTION_REQUEST,
  PRSECTION_SUCCESS,
  PRSECTION_FAIL,
  NEW_PRSECTION_REQUEST,
  NEW_PRSECTION_SUCCESS,
  NEW_PRSECTION_FAIL,
  UPDATE_PRSECTION_REQUEST,
  UPDATE_PRSECTION_SUCCESS,
  UPDATE_PRSECTION_FAIL,
  DELETE_PRSECTION_REQUEST,
  DELETE_PRSECTION_SUCCESS,
  DELETE_PRSECTION_FAIL,
  NEW_PRSECTION_RESET,
  
  STATE_REQUEST,
  STATE_SUCCESS,
  STATE_FAIL,
  NEW_STATE_REQUEST,
  NEW_STATE_SUCCESS,
  NEW_STATE_FAIL,
  UPDATE_STATE_REQUEST,
  UPDATE_STATE_SUCCESS,
  UPDATE_STATE_FAIL,
  DELETE_STATE_REQUEST,
  DELETE_STATE_SUCCESS,
  DELETE_STATE_FAIL,
  CITY_REQUEST,
  CITY_SUCCESS,
  CITY_FAIL,
  NEW_CITY_REQUEST,
  NEW_CITY_SUCCESS,
  NEW_CITY_FAIL,
  UPDATE_CITY_REQUEST,
  UPDATE_CITY_SUCCESS,
  UPDATE_CITY_FAIL,
  DELETE_CITY_REQUEST,
  DELETE_CITY_SUCCESS,
  DELETE_CITY_FAIL,
  SOURCE_OF_SUPPLY_REQUEST,
  SOURCE_OF_SUPPLY_SUCCESS,
  SOURCE_OF_SUPPLY_FAIL,
  NEW_SOURCE_OF_SUPPLY_REQUEST,
  NEW_SOURCE_OF_SUPPLY_SUCCESS,
  NEW_SOURCE_OF_SUPPLY_FAIL,
  UPDATE_SOURCE_OF_SUPPLY_REQUEST,
  UPDATE_SOURCE_OF_SUPPLY_SUCCESS,
  UPDATE_SOURCE_OF_SUPPLY_FAIL,
  DELETE_SOURCE_OF_SUPPLY_REQUEST,
  DELETE_SOURCE_OF_SUPPLY_SUCCESS,
  DELETE_SOURCE_OF_SUPPLY_FAIL,
  
  
  PAYMENT_TERMS_REQUEST,
  PAYMENT_TERMS_SUCCESS,
  PAYMENT_TERMS_FAIL,
  NEW_PAYMENT_TERMS_REQUEST,
  NEW_PAYMENT_TERMS_SUCCESS,
  NEW_PAYMENT_TERMS_FAIL,
  UPDATE_PAYMENT_TERMS_REQUEST,
  UPDATE_PAYMENT_TERMS_SUCCESS,
  UPDATE_PAYMENT_TERMS_FAIL,
  DELETE_PAYMENT_TERMS_REQUEST,
  DELETE_PAYMENT_TERMS_SUCCESS,
  DELETE_PAYMENT_TERMS_FAIL,
  
  
  PRODUCT_TERMS_REQUEST,
  PRODUCT_TERMS_SUCCESS,
  PRODUCT_TERMS_FAIL,
  NEW_PRODUCT_TERMS_REQUEST,
  NEW_PRODUCT_TERMS_SUCCESS,
  NEW_PRODUCT_TERMS_FAIL,
  UPDATE_PRODUCT_TERMS_REQUEST,
  UPDATE_PRODUCT_TERMS_SUCCESS,
  UPDATE_PRODUCT_TERMS_FAIL,
  DELETE_PRODUCT_TERMS_REQUEST,
  DELETE_PRODUCT_TERMS_SUCCESS,
  DELETE_PRODUCT_TERMS_FAIL,
  
  
  
  
  GST_TREATMENT_REQUEST,
  GST_TREATMENT_SUCCESS,
  GST_TREATMENT_FAIL,
  NEW_GST_TREATMENT_REQUEST,
  NEW_GST_TREATMENT_SUCCESS,
  NEW_GST_TREATMENT_FAIL,
  UPDATE_GST_TREATMENT_REQUEST,
  UPDATE_GST_TREATMENT_SUCCESS,
  UPDATE_GST_TREATMENT_FAIL,
  DELETE_GST_TREATMENT_REQUEST,
  DELETE_GST_TREATMENT_SUCCESS,
  DELETE_GST_TREATMENT_FAIL,
  BANK_ACCOUNT_REQUEST,
  BANK_ACCOUNT_SUCCESS,
  BANK_ACCOUNT_FAIL,
  NEW_BANK_ACCOUNT_REQUEST,
  NEW_BANK_ACCOUNT_SUCCESS,
  NEW_BANK_ACCOUNT_FAIL,
  UPDATE_BANK_ACCOUNT_REQUEST,
  UPDATE_BANK_ACCOUNT_SUCCESS,
  UPDATE_BANK_ACCOUNT_FAIL,
  DELETE_BANK_ACCOUNT_REQUEST,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_FAIL,
  DISPATCH_APP_USER_REQUEST,
  DISPATCH_APP_USER_SUCCESS,
  DISPATCH_APP_USER_FAIL,
  NEW_DISPATCH_APP_USER_REQUEST,
  NEW_DISPATCH_APP_USER_SUCCESS,
  NEW_DISPATCH_APP_USER_FAIL,
  UPDATE_DISPATCH_APP_USER_REQUEST,
  UPDATE_DISPATCH_APP_USER_SUCCESS,
  UPDATE_DISPATCH_APP_USER_FAIL,
  DELETE_DISPATCH_APP_USER_REQUEST,
  DELETE_DISPATCH_APP_USER_SUCCESS,
  DELETE_DISPATCH_APP_USER_FAIL,
  // DELETE_BANK_ACCOUNT_FAIL,
  MASTER_MODULE_REQUEST,
  MASTER_MODULE_SUCCESS,
  MASTER_MODULE_FAIL,
  NEW_MASTER_MODULE_REQUEST,
  NEW_MASTER_MODULE_SUCCESS,
  NEW_MASTER_MODULE_FAIL,
  UPDATE_MASTER_MODULE_REQUEST,
  UPDATE_MASTER_MODULE_SUCCESS,
  UPDATE_MASTER_MODULE_FAIL,
  DELETE_MASTER_MODULE_REQUEST,
  DELETE_MASTER_MODULE_SUCCESS,
  DELETE_MASTER_MODULE_FAIL,
  GST_CLASSIFICATION_REQUEST,
  GST_CLASSIFICATION_SUCCESS,
  GST_CLASSIFICATION_FAIL,
  NEW_GST_CLASSIFICATION_REQUEST,
  NEW_GST_CLASSIFICATION_SUCCESS,
  NEW_GST_CLASSIFICATION_FAIL,
  UPDATE_GST_CLASSIFICATION_REQUEST,
  UPDATE_GST_CLASSIFICATION_SUCCESS,
  UPDATE_GST_CLASSIFICATION_FAIL,
  DELETE_GST_CLASSIFICATION_REQUEST,
  DELETE_GST_CLASSIFICATION_SUCCESS,
  DELETE_GST_CLASSIFICATION_FAIL,
  CLEAR_ERRORS,
  DISTANCE_REQUEST,
  DISTANCE_SUCCESS,
  DISTANCE_FAIL,
  NEW_DISTANCE_REQUEST,
  NEW_DISTANCE_SUCCESS,
  NEW_DISTANCE_FAIL,
  UPDATE_DISTANCE_REQUEST,
  UPDATE_DISTANCE_SUCCESS,
  UPDATE_DISTANCE_FAIL,
  DELETE_DISTANCE_REQUEST,
  DELETE_DISTANCE_SUCCESS,
  DELETE_DISTANCE_FAIL,
} from "../constants/MastersConstants";
import axios from "axios";

// GET all Country's
export const getCountrys = () => async (dispatch) => {
  try {
    dispatch({ type: COUNTRY_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/countrys`,
      config
    );
    dispatch({ type: COUNTRY_SUCCESS, payload: data.countrys });
  } catch (error) {
    dispatch({ type: COUNTRY_FAIL, payload: error.response.data.message });
  }
};

// Create a Country
export const createCountry = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_COUNTRY_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/country`,
      Data,
      config
    );

    dispatch({
      type: NEW_COUNTRY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_COUNTRY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update country
export const updateCountry = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_COUNTRY_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    console.log(id, Data);

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/country/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_COUNTRY_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_COUNTRY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Country
export const deleteCountry = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_COUNTRY_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/country/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_COUNTRY_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_COUNTRY_FAIL,
      payload: error.response.data.message,
    });
  }
};










// GET all PrSection's
export const getPrSections = () => async (dispatch) => {
  try {
    dispatch({ type: PRSECTION_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/pr_sections`,
      config
    );
    dispatch({ type: PRSECTION_SUCCESS, payload: data.countrys });
  } catch (error) {
    dispatch({ type: PRSECTION_FAIL, payload: error.response.data.message });
  }
};

// Create a PrSection
export const createPrSection = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PRSECTION_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/pr_section`,
      Data,
      config
    );

    dispatch({
      type: NEW_PRSECTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRSECTION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update PrSection
export const updatePrSection = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRSECTION_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    console.log(id, Data);

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/pr_section/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_PRSECTION_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRSECTION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete PrSection
export const deletePrSection = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRSECTION_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/pr_section/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_PRSECTION_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRSECTION_FAIL,
      payload: error.response.data.message,
    });
  }
};














// GET all state's
export const getStates = () => async (dispatch) => {
  try {
    dispatch({ type: STATE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/states`,
      config
    );
    dispatch({ type: STATE_SUCCESS, payload: data.states });
  } catch (error) {
    dispatch({ type: STATE_FAIL, payload: error.response.data.message });
  }
};

// Create a State
export const createState = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_STATE_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/state`,
      Data,
      config
    );
    dispatch({
      type: NEW_STATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_STATE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update State
export const updateState = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_STATE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/state/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_STATE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_STATE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete State
export const deleteState = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_STATE_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/state/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_STATE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_STATE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET all City's
export const getCitys = () => async (dispatch) => {
  try {
    dispatch({ type: CITY_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/citys`,
      config
    );
    dispatch({ type: CITY_SUCCESS, payload: data.citys });
  } catch (error) {
    dispatch({ type: CITY_FAIL, payload: error.response.data.message });
  }
};

// Create a City
export const createCity = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_CITY_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/city`,
      Data,
      config
    );
    dispatch({
      type: NEW_CITY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_CITY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update City
export const updateCity = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CITY_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/city/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_CITY_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CITY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete City
export const deleteCity = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CITY_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/city/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_CITY_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CITY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET all source of supply
export const getSourceOfSupply = () => async (dispatch) => {
  try {
    dispatch({ type: SOURCE_OF_SUPPLY_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/source_of_supply`,
      config
    );
    dispatch({ type: SOURCE_OF_SUPPLY_SUCCESS, payload: data.sources });
  } catch (error) {
    dispatch({
      type: SOURCE_OF_SUPPLY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a source of supply
export const createSourceOfSupply = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_SOURCE_OF_SUPPLY_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/source_of_supply`,
      Data,
      config
    );
    dispatch({
      type: NEW_SOURCE_OF_SUPPLY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_SOURCE_OF_SUPPLY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update source of supply
export const updateSourceOfSupply = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SOURCE_OF_SUPPLY_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/source_of_supply/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_SOURCE_OF_SUPPLY_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_SOURCE_OF_SUPPLY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete a source of supply
export const deleteSourceOfSupply = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SOURCE_OF_SUPPLY_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/source_of_supply/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_SOURCE_OF_SUPPLY_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_SOURCE_OF_SUPPLY_FAIL,
      payload: error.response.data.message,
    });
  }
};










// GET all Payment Terms
export const getPaymentTerms = () => async (dispatch) => {
  try {
    dispatch({ type: PAYMENT_TERMS_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/payment_terms`,
      config
    );
    dispatch({ type: PAYMENT_TERMS_SUCCESS, payload: data.payment_terms });
  } catch (error) {
    dispatch({
      type: PAYMENT_TERMS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Payment Terms
export const createPaymentTerms = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PAYMENT_TERMS_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/payment_terms`,
      Data,
      config
    );
    dispatch({
      type: NEW_PAYMENT_TERMS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PAYMENT_TERMS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update a Payment Terms
export const updatePaymentTerms = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PAYMENT_TERMS_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/payment_terms/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_PAYMENT_TERMS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PAYMENT_TERMS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete a Payment Terms
export const deletePaymentTerms = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PAYMENT_TERMS_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/payment_terms/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_PAYMENT_TERMS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PAYMENT_TERMS_FAIL,
      payload: error.response.data.message,
    });
  }
};















// GET all product Terms
export const getProductTerms = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TERMS_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/product_terms`,
      config
    );
    dispatch({ type: PRODUCT_TERMS_SUCCESS, payload: data.product_terms });
  } catch (error) {
    dispatch({
      type: PRODUCT_TERMS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Payment Terms
export const createProductTerms = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PRODUCT_TERMS_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/product_terms`,
      Data,
      config
    );
    dispatch({
      type: NEW_PRODUCT_TERMS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRODUCT_TERMS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update a Payment Terms
export const updateProductTerms = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCT_TERMS_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/product_terms/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_PRODUCT_TERMS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_TERMS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete a Payment Terms
export const deleteProductTerms = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCT_TERMS_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/product_terms/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_PRODUCT_TERMS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_TERMS_FAIL,
      payload: error.response.data.message,
    });
  }
};


























// GET all gst treatment
export const getGstTreatment = () => async (dispatch) => {
  try {
    dispatch({ type: GST_TREATMENT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/gst_treatment`,
      config
    );
    dispatch({ type: GST_TREATMENT_SUCCESS, payload: data.gst_treatment });
  } catch (error) {
    dispatch({
      type: GST_TREATMENT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a gst treatment
export const createGstTreatment = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_GST_TREATMENT_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/gst_treatment`,
      Data,
      config
    );
    dispatch({
      type: NEW_GST_TREATMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_GST_TREATMENT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update a gst treatment
export const updateGstTreatment = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_GST_TREATMENT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/gst_treatment/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_GST_TREATMENT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_GST_TREATMENT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete a gst treatment
export const deleteGstTreatment = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_GST_TREATMENT_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/gst_treatment/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_GST_TREATMENT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_GST_TREATMENT_FAIL,
      payload: error.response.data.message,
    });
  }
};
// GET all bank account
export const getBankAccount = () => async (dispatch) => {
  try {
    dispatch({ type: BANK_ACCOUNT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/bank_account`,
      config
    );
    dispatch({ type: BANK_ACCOUNT_SUCCESS, payload: data.bankAccount });
  } catch (error) {
    dispatch({
      type: BANK_ACCOUNT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a bank account
export const createBankAccount = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_BANK_ACCOUNT_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/bank_account`,
      Data,
      config
    );
    dispatch({
      type: NEW_BANK_ACCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_BANK_ACCOUNT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update a bank account
export const updateBankAccount = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_BANK_ACCOUNT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/bank_account/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_BANK_ACCOUNT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_BANK_ACCOUNT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete a bank account
export const deleteBankAccount = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_BANK_ACCOUNT_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/bank_account/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_BANK_ACCOUNT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_BANK_ACCOUNT_FAIL,
      payload: error.response.data.message,
    });
  }
};
// GET all DISPATCH
export const getDispatchAppUser = () => async (dispatch) => {
  try {
    dispatch({ type: DISPATCH_APP_USER_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/dispatch_app_user`,
      config
    );
    dispatch({
      type: DISPATCH_APP_USER_SUCCESS,
      payload: data.dispatchAppUser,
    });
  } catch (error) {
    dispatch({
      type: DISPATCH_APP_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a DISPATCH
export const createDispatchAppUser = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_DISPATCH_APP_USER_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/dispatch_app_user`,
      Data,
      config
    );
    dispatch({
      type: NEW_DISPATCH_APP_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_DISPATCH_APP_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update a DISPATCH
export const updateDispatchAppUser = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_DISPATCH_APP_USER_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/dispatch_app_user/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_DISPATCH_APP_USER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_DISPATCH_APP_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete a DISPATCH
export const deleteDispatchAppUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_DISPATCH_APP_USER_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/dispatch_app_user/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_DISPATCH_APP_USER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_DISPATCH_APP_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};
// GET all MASTER
export const getMasterModule = () => async (dispatch) => {
  try {
    dispatch({ type: MASTER_MODULE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/module_master`,
      config
    );
    dispatch({ type: MASTER_MODULE_SUCCESS, payload: data.module_master });
  } catch (error) {
    dispatch({
      type: MASTER_MODULE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a MASTER
export const createMasterModule = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_MASTER_MODULE_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/module_master`,
      Data,
      config
    );
    dispatch({
      type: NEW_MASTER_MODULE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_MASTER_MODULE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update a MASTER
export const updateMasterModule = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_MASTER_MODULE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/module_master/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_MASTER_MODULE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_MASTER_MODULE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete a MASTER
export const deleteMasterModule = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_MASTER_MODULE_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/module_master/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_MASTER_MODULE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_MASTER_MODULE_FAIL,
      payload: error.response.data.message,
    });
  }
};
// GET all CLASSIFICATION
export const getGstClassification = () => async (dispatch) => {
  try {
    dispatch({ type: GST_CLASSIFICATION_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/gst_classification`,
      config
    );
    dispatch({
      type: GST_CLASSIFICATION_SUCCESS,
      payload: data.gstClassification,
    });
  } catch (error) {
    dispatch({
      type: GST_CLASSIFICATION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a CLASSIFICATION
export const createGstClassification = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_GST_CLASSIFICATION_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/gst_classification`,
      Data,
      config
    );
    dispatch({
      type: NEW_GST_CLASSIFICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_GST_CLASSIFICATION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update a CLASSIFICATION
export const updateGstClassification = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_GST_CLASSIFICATION_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/gst_classification/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_GST_CLASSIFICATION_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_GST_CLASSIFICATION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete a CLASSIFICATION
export const deleteGstClassification = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_GST_CLASSIFICATION_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/master/gst_classification/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_GST_CLASSIFICATION_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_GST_CLASSIFICATION_FAIL,
      payload: error.response.data.message,
    });
  }
};
// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// GET all Distance
export const getDistance = () => async (dispatch) => {
  try {
    dispatch({ type: DISTANCE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/express/delivery/charges`,
      config
    );
    dispatch({
      type: DISTANCE_SUCCESS,
      payload: data.response,
    });
  } catch (error) {
    dispatch({
      type: DISTANCE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Distance
export const createDistance = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_DISTANCE_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/express/delivery/charges`,
      Data,
      config
    );
    dispatch({
      type: NEW_DISTANCE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_DISTANCE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update a Distance
export const updateDistance = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_DISTANCE_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/express/delivery/charges/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_DISTANCE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_DISTANCE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete a Distance
export const deleteDistance = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_DISTANCE_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/express/delivery/charges/${id}`,
      config
    );
    dispatch({
      type: DELETE_DISTANCE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_DISTANCE_FAIL,
      payload: error.response.data.message,
    });
  }
};
