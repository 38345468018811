import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import converter from "number-to-words";
import { Grid } from "@mui/material";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#fff",
    color: "black",
    border: "1px solid black",
    padding: 2,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 2,
    border: 0,
    borderRight: "1px solid black",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: "1px solid black",
  },
}));

export default function PurchaseOrderPdfTable({
  item,
  qut,
  cgst,
  sum,
  sumLast,
  disc,
  tr_charges,
}) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          style={{ border: "1px solid black" }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell width="5%" align="center">
                Sl no.
              </StyledTableCell>
              <StyledTableCell width="50%" align="center">
                Description of Goods
              </StyledTableCell>
              <StyledTableCell width="10%" align="center">
                HSN/SAC
              </StyledTableCell>
              <StyledTableCell width="10%" align="center">
                Quantity
              </StyledTableCell>
              <StyledTableCell width="10%" align="center">
                Rate
              </StyledTableCell>
              <StyledTableCell width="10%" align="center">
                Dis
              </StyledTableCell>
              <StyledTableCell width="5%" align="center">
                Per
              </StyledTableCell>
              {/* <StyledTableCell width="5%" align="center">
                Taxable Amt
              </StyledTableCell> */}
              <StyledTableCell width="10%" align="center">
                Amount
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {item?.item_details?.map((row, index) => {
              let ra = (row?.rate * 100) / (100 + row?.tax);
              let ra2 = (row?.amount * 100) / (100 + row?.tax);
     
              return (
                <StyledTableRow key={row.name}>
                  <StyledTableCell width="5%" align="center">
                    {index}
                  </StyledTableCell>
                  <StyledTableCell width="50%" align="center">
                    {row?.item}
                  </StyledTableCell>
                  <StyledTableCell width="10%" align="center">
                    {row?.hsn_code}
                  </StyledTableCell>
                  <StyledTableCell width="10%" align="center">
                    {row?.quantity?.toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell width="10%" align="center">
                    {ra?.toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell width="5%" align="center">
                    {row?.discount} {row?.discount_selected}
                  </StyledTableCell>
                  <StyledTableCell width="5%" align="center">
                    Box
                  </StyledTableCell>
                  {/* <StyledTableCell width="5%" align="center">
                    {row?.amount}
                  </StyledTableCell> */}
                  <StyledTableCell width="20%" align="right">
                    {ra2?.toFixed(2)}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
            <StyledTableRow>
              <StyledTableCell width="3%" align="center"></StyledTableCell>
              <StyledTableCell
                width="40%"
                align="right"
                style={{ fontWeight: "700" }}
              >
               Transport Charges Sale
              </StyledTableCell>
              <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              {/* <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell> */}
              <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="5%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="5%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="5%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="20%"
                align="right"
                style={{ fontWeight: "700" }}
              >
			  {tr_charges}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell width="3%" align="center"></StyledTableCell>
              <StyledTableCell
                width="40%"
                align="right"
                style={{ fontWeight: "700" }}
              >
                CGST
              </StyledTableCell>
              <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              {/* <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell> */}
              <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="5%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="5%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="20%"
                align="right"
                style={{ fontWeight: "700" }}
              >
                {(item?.total_tax / 2).toFixed(2)}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell width="3%" align="center"></StyledTableCell>
              <StyledTableCell
                width="40%"
                align="right"
                style={{ fontWeight: "700" }}
              >
                SGST
              </StyledTableCell>
              <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              {/* <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell> */}
              <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="5%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="5%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="20%"
                align="right"
                style={{ fontWeight: "700" }}
              >
               {(item?.total_tax / 2).toFixed(2)}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell width="3%" align="center"></StyledTableCell>
              <StyledTableCell
                width="40%"
                align="center"
                style={{ fontWeight: "700" }}
              >
                Total
              </StyledTableCell>
              <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              >
                {qut?.toFixed(2)}
              </StyledTableCell>
              {/* <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell> */}
              <StyledTableCell
                width="10%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="5%"
                align="center"
                style={{ fontWeight: "700" }}
              > {disc?.toFixed(2)} Rs</StyledTableCell>
              <StyledTableCell
                width="5%"
                align="center"
                style={{ fontWeight: "700" }}
              ></StyledTableCell>
              <StyledTableCell
                width="20%"
                align="right"
                style={{ fontWeight: "700" }}
              >
                {item?.total?.toFixed(2)}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid lg={12} border="1px solid black" padding="0 4px" container>
        <p style={{ fontSize: "13px" }}>
          {" "}
          Amount Chargeable(in words) :<br />{" "}
          <span style={{ fontWeight: "700", textTransform: "uppercase" }}>
            {sumLast ? converter?.toWords(Number(sumLast)) : ""}
          </span>
        </p>
      </Grid>
    </>
  );
}
