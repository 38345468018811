import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Button } from "@mui/material";
const DeleteDialog = ({ deleteOpen, setDelete, id, deleteHandler }) => {
  const handleClose = (id) => {
    deleteHandler(id);
    setDelete(false);
  };
  const handleCloseSecond = () => {
    setDelete(false);
  };
  return (
    <Dialog
      open={deleteOpen}
      onClose={handleCloseSecond}
      aria-labelledby="responsive-dialog-title"
      fullWidth="true"
    >
      <DialogContent>
        <DialogContentText color={"black"} fontWeight={500} fontSize={22}>
          Are you sure you want to delete
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(id)}
          variant="contained"
          color="error"
          autoFocus
        >
          Delete
        </Button>
        <Button onClick={handleCloseSecond} variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
