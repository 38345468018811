import {
  WAREHOUSE_REQUEST,
  WAREHOUSE_SUCCESS,
  WAREHOUSE_FAIL,
  NEW_WAREHOUSE_REQUEST,
  NEW_WAREHOUSE_SUCCESS,
  NEW_WAREHOUSE_FAIL,
  NEW_WAREHOUSE_RESET,
  UPDATE_WAREHOUSE_REQUEST,
  UPDATE_WAREHOUSE_SUCCESS,
  UPDATE_WAREHOUSE_FAIL,
  UPDATE_WAREHOUSE_RESET,   
  DELETE_WAREHOUSE_REQUEST,
  DELETE_WAREHOUSE_SUCCESS,
  DELETE_WAREHOUSE_FAIL,
  DELETE_WAREHOUSE_RESET,
  CLEAR_ERRORS,
} from "../constants/WarehouseConstants";

// warehouse Reducers
export const WarehouseReducer = (state = { Warehouse: [] }, action) => {
  switch (action.type) {
    case WAREHOUSE_REQUEST:
      return { loading: true, Warehouse: [] };
    case WAREHOUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        Warehouse: action.payload,
      };

    case WAREHOUSE_FAIL:
      return {
        ...state,
        loading: false,
        Warehouse: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Create warehouse
export const newWarehouseReducer = (state = { newWarehouse: {} }, action) => {
  switch (action.type) {
    case NEW_WAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_WAREHOUSE_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newWarehouse: action.payload.response,
      };
    case NEW_WAREHOUSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_WAREHOUSE_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Update Delete warehouse
export const updateDeleteWarehouseReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_WAREHOUSE_REQUEST:
    case UPDATE_WAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_WAREHOUSE_FAIL:
    case UPDATE_WAREHOUSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_WAREHOUSE_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_WAREHOUSE_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
