import {
  DASHBOARD_LIST_REQUEST,
  DASHBOARD_LIST_SUCCESS,
  DASHBOARD_LIST_FAIL,
  CLEAR_ERRORS,
} from "../constants/DashboardListConstants";
import axios from "axios";

// GET dashboardList
export const getDashboardList = () => async (dispatch) => {
  try {
    dispatch({ type: DASHBOARD_LIST_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    let link = `https://erpapi.flaskitsolutions.com/api/v1/admin/dashboard/last_five_sales/purchase`;
    const { data } = await axios.get(link,config);
    dispatch({ type: DASHBOARD_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DASHBOARD_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
