import { CLEAR_ERRORS } from "../constants/AdminLoginConstants";
import {
  DELETE_VENDOR_BILLING_FAIL,
  DELETE_VENDOR_BILLING_REQUEST,
  DELETE_VENDOR_BILLING_RESET,
  DELETE_VENDOR_BILLING_SUCCESS,
  DELETE_VENDOR_CONTACT_PERSON_FAIL,
  DELETE_VENDOR_CONTACT_PERSON_REQUEST,
  DELETE_VENDOR_CONTACT_PERSON_RESET,
  DELETE_VENDOR_CONTACT_PERSON_SUCCESS,
  DELETE_VENDOR_FAIL,
  DELETE_VENDOR_REQUEST,
  DELETE_VENDOR_RESET,
  DELETE_VENDOR_SHIPPING_FAIL,
  DELETE_VENDOR_SHIPPING_REQUEST,
  DELETE_VENDOR_SHIPPING_RESET,
  DELETE_VENDOR_SHIPPING_SUCCESS,
  DELETE_VENDOR_SUCCESS,
  NEW_VENDOR_BILLING_FAIL,
  NEW_VENDOR_BILLING_REQUEST,
  NEW_VENDOR_BILLING_RESET,
  NEW_VENDOR_BILLING_SUCCESS,
  NEW_VENDOR_CONTACT_PERSON_FAIL,
  NEW_VENDOR_CONTACT_PERSON_REQUEST,
  NEW_VENDOR_CONTACT_PERSON_RESET,
  NEW_VENDOR_CONTACT_PERSON_SUCCESS,
  NEW_VENDOR_FAIL,
  NEW_VENDOR_REQUEST,
  NEW_VENDOR_RESET,
  NEW_VENDOR_SHIPPING_FAIL,
  NEW_VENDOR_SHIPPING_REQUEST,
  NEW_VENDOR_SHIPPING_RESET,
  NEW_VENDOR_SHIPPING_SUCCESS,
  NEW_VENDOR_SUCCESS,
  UPDATE_VENDOR_BILLING_FAIL,
  UPDATE_VENDOR_BILLING_REQUEST,
  UPDATE_VENDOR_BILLING_RESET,
  UPDATE_VENDOR_BILLING_SUCCESS,
  UPDATE_VENDOR_CONTACT_PERSON_FAIL,
  UPDATE_VENDOR_CONTACT_PERSON_REQUEST,
  UPDATE_VENDOR_CONTACT_PERSON_RESET,
  UPDATE_VENDOR_CONTACT_PERSON_SUCCESS,
  UPDATE_VENDOR_FAIL,
  UPDATE_VENDOR_REQUEST,
  UPDATE_VENDOR_RESET,
  UPDATE_VENDOR_SHIPPING_FAIL,
  UPDATE_VENDOR_SHIPPING_REQUEST,
  UPDATE_VENDOR_SHIPPING_RESET,
  UPDATE_VENDOR_SHIPPING_SUCCESS,
  UPDATE_VENDOR_SUCCESS,
  VENDOR_BILLING_FAIL,
  VENDOR_BILLING_REQUEST,
  VENDOR_BILLING_SUCCESS,
  VENDOR_CONTACT_PERSON_FAIL,
  VENDOR_CONTACT_PERSON_REQUEST,
  VENDOR_CONTACT_PERSON_SUCCESS,
  VENDOR_FAIL,
  VENDOR_REQUEST,
  VENDOR_SHIPPING_FAIL,
  VENDOR_SHIPPING_REQUEST,
  VENDOR_SHIPPING_SUCCESS,
  VENDOR_SUCCESS,
} from "../constants/VendorConstant";
// Brand Reducers
export const vendorReducer = (state = { vendors: [] }, action) => {
  switch (action.type) {
    case VENDOR_REQUEST:
      return { loading: true, vendors: [] };
    case VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        vendors: action.payload,
      };

    case VENDOR_FAIL:
      return {
        ...state,
        loading: false,
        vendors: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Create Brand
export const newVendorReducer = (state = { newVendors: {} }, action) => {
  switch (action.type) {
    case NEW_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_VENDOR_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newVendors: action.payload.response,
      };
    case NEW_VENDOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_VENDOR_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Update Delete Brand
export const updateDeleteVendorReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_VENDOR_REQUEST:
    case UPDATE_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_VENDOR_FAIL:
    case UPDATE_VENDOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_VENDOR_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_VENDOR_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};


// VendorContact Reducers
export const vendorContactPersonReducer = (state = { vendorContact: [] }, action) => {
  switch (action.type) {
    case VENDOR_CONTACT_PERSON_REQUEST:
      return { loading: true, vendorContact: [] };
    case VENDOR_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorContact: action.payload,
      };

    case VENDOR_CONTACT_PERSON_FAIL:
      return {
        ...state,
        loading: false,
        vendorContact: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Update Delete vendor contact person
export const updateDeleteVendorContactPersonReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_VENDOR_CONTACT_PERSON_REQUEST:
    case UPDATE_VENDOR_CONTACT_PERSON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_VENDOR_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_VENDOR_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_VENDOR_CONTACT_PERSON_FAIL:
    case UPDATE_VENDOR_CONTACT_PERSON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_VENDOR_CONTACT_PERSON_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_VENDOR_CONTACT_PERSON_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create Vendor Contact
export const newVendorContactPersonReducer = (state = { newVendorContact: {} }, action) => {
  switch (action.type) {
    case NEW_VENDOR_CONTACT_PERSON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_VENDOR_CONTACT_PERSON_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newVendorContact: action.payload.response,
      };
    case NEW_VENDOR_CONTACT_PERSON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_VENDOR_CONTACT_PERSON_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};



// VendorShipping Reducers
export const vendorShippingReducer = (state = { vendorShipping: [] }, action) => {
  switch (action.type) {
    case VENDOR_SHIPPING_REQUEST:
      return { loading: true, vendorShipping: [] };
    case VENDOR_SHIPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorShipping: action.payload,
      };

    case VENDOR_SHIPPING_FAIL:
      return {
        ...state,
        loading: false,
        vendorShipping: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Update Delete vendor contact person
export const updateDeleteVendorShippingReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_VENDOR_SHIPPING_REQUEST:
    case UPDATE_VENDOR_SHIPPING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_VENDOR_SHIPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_VENDOR_SHIPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_VENDOR_SHIPPING_FAIL:
    case UPDATE_VENDOR_SHIPPING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_VENDOR_SHIPPING_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_VENDOR_SHIPPING_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create Vendor Contact
export const newVendorShippingReducer = (state = { newVendorShipping: {} }, action) => {
  switch (action.type) {
    case NEW_VENDOR_SHIPPING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_VENDOR_SHIPPING_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newVendorShipping: action.payload.response,
      };
    case NEW_VENDOR_SHIPPING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_VENDOR_SHIPPING_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};


// VendorBilling Reducers
export const vendorBillingReducer = (state = { vendorBilling: [] }, action) => {
  switch (action.type) {
    case VENDOR_BILLING_REQUEST:
      return { loading: true, vendorBilling: [] };
    case VENDOR_BILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorBilling: action.payload,
      };

    case VENDOR_BILLING_FAIL:
      return {
        ...state,
        loading: false,
        vendorBilling: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Update Delete vendor Billing
export const updateDeleteVendorBillingReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_VENDOR_BILLING_REQUEST:
    case UPDATE_VENDOR_BILLING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_VENDOR_BILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_VENDOR_BILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_VENDOR_BILLING_FAIL:
    case UPDATE_VENDOR_BILLING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_VENDOR_BILLING_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_VENDOR_BILLING_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create Vendor Contact
export const newVendorBillingReducer = (state = { newVendorBilling: {} }, action) => {
  switch (action.type) {
    case NEW_VENDOR_BILLING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_VENDOR_BILLING_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newVendorBilling: action.payload.response,
      };
    case NEW_VENDOR_BILLING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_VENDOR_BILLING_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
