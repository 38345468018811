// Add Customer

export const ADDCUSTOMERS_REQUEST = "ADDCUSTOMERS_REQUEST";
export const ADDCUSTOMERS_SUCCESS = "ADDCUSTOMERS_SUCCESS";
export const ADDCUSTOMERS_FAIL = "ADDCUSTOMERS_FAIL";

export const NEW_ADDCUSTOMERS_REQUEST = "NEW_ADDCUSTOMERS_REQUEST";
export const NEW_ADDCUSTOMERS_SUCCESS = "NEW_ADDCUSTOMERS_SUCCESS";
export const NEW_ADDCUSTOMERS_FAIL = "NEW_ADDCUSTOMERS_FAIL";
export const NEW_ADDCUSTOMERS_RESET = "NEW_ADDCUSTOMERS_RESET";

export const UPDATE_ADDCUSTOMERS_REQUEST = "UPDATE_ADDCUSTOMERS_REQUEST";
export const UPDATE_ADDCUSTOMERS_SUCCESS = "UPDATE_ADDCUSTOMERS_SUCCESS";
export const UPDATE_ADDCUSTOMERS_FAIL = "UPDATE_ADDCUSTOMERS_FAIL";
export const UPDATE_ADDCUSTOMERS_RESET = "UPDATE_ADDCUSTOMERS_RESET";

export const DELETE_ADDCUSTOMERS_REQUEST = "DELETE_ADDCUSTOMERS_REQUEST";
export const DELETE_ADDCUSTOMERS_SUCCESS = "DELETE_ADDCUSTOMERS_SUCCESS";
export const DELETE_ADDCUSTOMERS_FAIL = "DELETE_ADDCUSTOMERS_FAIL";
export const DELETE_ADDCUSTOMERS_RESET = "DELETE_ADDCUSTOMERS_RESET";


// Customer Contact Persons

export const CUSTOMERCONTACTPERSON_REQUEST = "CUSTOMERCONTACTPERSON_REQUEST";
export const CUSTOMERCONTACTPERSON_SUCCESS = "CUSTOMERCONTACTPERSON_SUCCESS";
export const CUSTOMERCONTACTPERSON_FAIL = "CUSTOMERCONTACTPERSON_FAIL";

export const NEW_CUSTOMERCONTACTPERSON_REQUEST = "NEW_CUSTOMERCONTACTPERSON_REQUEST";
export const NEW_CUSTOMERCONTACTPERSON_SUCCESS = "NEW_CUSTOMERCONTACTPERSON_SUCCESS";
export const NEW_CUSTOMERCONTACTPERSON_FAIL = "NEW_CUSTOMERCONTACTPERSON_FAIL";
export const NEW_CUSTOMERCONTACTPERSON_RESET = "NEW_CUSTOMERCONTACTPERSON_RESET";

export const UPDATE_CUSTOMERCONTACTPERSON_REQUEST = "UPDATE_CUSTOMERCONTACTPERSON_REQUEST";
export const UPDATE_CUSTOMERCONTACTPERSON_SUCCESS = "UPDATE_CUSTOMERCONTACTPERSON_SUCCESS";
export const UPDATE_CUSTOMERCONTACTPERSON_FAIL = "UPDATE_CUSTOMERCONTACTPERSON_FAIL";
export const UPDATE_CUSTOMERCONTACTPERSON_RESET = "UPDATE_CUSTOMERCONTACTPERSON_RESET";

export const DELETE_CUSTOMERCONTACTPERSON_REQUEST = "DELETE_CUSTOMERCONTACTPERSON_REQUEST";
export const DELETE_CUSTOMERCONTACTPERSON_SUCCESS = "DELETE_CUSTOMERCONTACTPERSON_SUCCESS";
export const DELETE_CUSTOMERCONTACTPERSON_FAIL = "DELETE_CUSTOMERCONTACTPERSON_FAIL";
export const DELETE_CUSTOMERCONTACTPERSON_RESET = "DELETE_CUSTOMERCONTACTPERSON_RESET";


// CUSTOMER SHIPPING ADDRESS

export const CUSTOMERSHIPPINGADDRESS_REQUEST = "CUSTOMERSHIPPINGADDRESS_REQUEST";
export const CUSTOMERSHIPPINGADDRESS_SUCCESS = "CUSTOMERSHIPPINGADDRESS_SUCCESS";
export const CUSTOMERSHIPPINGADDRESS_FAIL = "CUSTOMERSHIPPINGADDRESS_FAIL";

export const NEW_CUSTOMERSHIPPINGADDRESS_REQUEST = "NEW_CUSTOMERSHIPPINGADDRESS_REQUEST";
export const NEW_CUSTOMERSHIPPINGADDRESS_SUCCESS = "NEW_CUSTOMERSHIPPINGADDRESS_SUCCESS";
export const NEW_CUSTOMERSHIPPINGADDRESS_FAIL = "NEW_CUSTOMERSHIPPINGADDRESS_FAIL";
export const NEW_CUSTOMERSHIPPINGADDRESS_RESET = "NEW_CUSTOMERSHIPPINGADDRESS_RESET";

export const UPDATE_CUSTOMERSHIPPINGADDRESS_REQUEST = "UPDATE_CUSTOMERSHIPPINGADDRESS_REQUEST";
export const UPDATE_CUSTOMERSHIPPINGADDRESS_SUCCESS = "UPDATE_CUSTOMERSHIPPINGADDRESS_SUCCESS";
export const UPDATE_CUSTOMERSHIPPINGADDRESS_FAIL = "UPDATE_CUSTOMERSHIPPINGADDRESS_FAIL";
export const UPDATE_CUSTOMERSHIPPINGADDRESS_RESET = "UPDATE_CUSTOMERSHIPPINGADDRESS_RESET";

export const DELETE_CUSTOMERSHIPPINGADDRESS_REQUEST = "DELETE_CUSTOMERSHIPPINGADDRESS_REQUEST";
export const DELETE_CUSTOMERSHIPPINGADDRESS_SUCCESS = "DELETE_CUSTOMERSHIPPINGADDRESS_SUCCESS";
export const DELETE_CUSTOMERSHIPPINGADDRESS_FAIL = "DELETE_CUSTOMERSHIPPINGADDRESS_FAIL";
export const DELETE_CUSTOMERSHIPPINGADDRESS_RESET = "DELETE_CUSTOMERSHIPPINGADDRESS_RESET";



// CUSTOMER BILLING ADDRESS


export const CUSTOMERBILLINGADDRESS_REQUEST = "CUSTOMERBILLINGADDRESS_REQUEST";
export const CUSTOMERBILLINGADDRESS_SUCCESS = "CUSTOMERBILLINGADDRESS_SUCCESS";
export const CUSTOMERBILLINGADDRESS_FAIL = "CUSTOMERBILLINGADDRESS_FAIL";

export const NEW_CUSTOMERBILLINGADDRESS_REQUEST = "NEW_CUSTOMERBILLINGADDRESS_REQUEST";
export const NEW_CUSTOMERBILLINGADDRESS_SUCCESS = "NEW_CUSTOMERBILLINGADDRESS_SUCCESS";
export const NEW_CUSTOMERBILLINGADDRESS_FAIL = "NEW_CUSTOMERBILLINGADDRESS_FAIL";
export const NEW_CUSTOMERBILLINGADDRESS_RESET = "NEW_CUSTOMERBILLINGADDRESS_RESET";

export const UPDATE_CUSTOMERBILLINGADDRESS_REQUEST = "UPDATE_CUSTOMERBILLINGADDRESS_REQUEST";
export const UPDATE_CUSTOMERBILLINGADDRESS_SUCCESS = "UPDATE_CUSTOMERBILLINGADDRESS_SUCCESS";
export const UPDATE_CUSTOMERBILLINGADDRESS_FAIL = "UPDATE_CUSTOMERBILLINGADDRESS_FAIL";
export const UPDATE_CUSTOMERBILLINGADDRESS_RESET = "UPDATE_CUSTOMERBILLINGADDRESS_RESET";

export const DELETE_CUSTOMERBILLINGADDRESS_REQUEST = "DELETE_CUSTOMERBILLINGADDRESS_REQUEST";
export const DELETE_CUSTOMERBILLINGADDRESS_SUCCESS = "DELETE_CUSTOMERBILLINGADDRESS_SUCCESS";
export const DELETE_CUSTOMERBILLINGADDRESS_FAIL = "DELETE_CUSTOMERBILLINGADDRESS_FAIL";
export const DELETE_CUSTOMERBILLINGADDRESS_RESET = "DELETE_CUSTOMERBILLINGADDRESS_RESET";