import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Grid from "@mui/material/Grid";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Select, FormControl, MenuItem } from "@mui/material";
import {
  clearErrors,
  getCustGroup,
} from "../../../Redux/actions/EcommerceAction";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustSubGroup,
  deleteCustSubGroup,
  updateCustSubGroup,
} from "../../../Redux/actions/EcommerceAction";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import {
  DELETE_CUST_SUB_GROUP_RESET,
  UPDATE_CUST_SUB_GROUP_RESET,
} from "../../../Redux/constants/EcommerceConstants";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../Common/Loader";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "group name",
    numeric: false,
    disablePadding: true,
    label: "Group Name",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        CustSubGroup
      </Typography>
    </Toolbar>
  );
};

export default function CountryTable(props) {
  console.log(props.Data);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [open, setOpen] = React.useState(false);
  const [updatedata, setUpdatedata] = React.useState({
    name: "",
    group_name: "",
  });
  const [udateId, setUpdateId] = React.useState("");

  const handleClickOpen = (state, id) => {
    setOpen(true);
    setUpdatedata(state);
    setUpdateId(id);
  };
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, loading, custSubGroup } = useSelector(
    (state) => state.custSubGroup
  );

  const {
    error: deleteError,
    isDeleted,
    isUpdated,
  } = useSelector((state) => state.updateCustSubGroup);

  const deleteCustSubGroupHandler = (id) => {
    dispatch(deleteCustSubGroup(id));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }
    if (isDeleted) {
      alert.success("CustSubGroup Deleted Successfully");
      dispatch({ type: DELETE_CUST_SUB_GROUP_RESET });
      dispatch(getCustSubGroup());
    }
    if (isUpdated) {
      alert.success("CustSubGroup Updated Successfully");
      dispatch({ type: UPDATE_CUST_SUB_GROUP_RESET });
      dispatch(getCustSubGroup());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [
    dispatch,
    navigate,
    error,
    alert,
    isAuthenticated,
    deleteError,
    isDeleted,
    isUpdated,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - custSubGroup.length) : 0;

  var perPageData = [];
  var length = Math.ceil(custSubGroup.length / 3);

  for (var i = 1; i <= length; i++) {
    perPageData.push(i * 3);
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{ width: "100%", mb: 2, boxShadow: "none", border: "none" }}
          >
            <EnhancedTableToolbar />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={custSubGroup.length}
                />

                <TableBody>
                  {stableSort(custSubGroup, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow>
                          <TableCell id={labelId} scope="row" padding="none">
                            {index + 1}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.name}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.group_name}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleClickOpen(
                                  {
                                    name: item.name,
                                    group_name: item.group_name,
                                  },
                                  item._id
                                )
                              }
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell scope="row" padding="none">
                            <Dialogcountry
                              open={open}
                              setOpen={setOpen}
                              udateId={udateId}
                              updatedata={updatedata}
                              setUpdatedata={setUpdatedata}
                            />
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() =>
                                deleteCustSubGroupHandler(item._id)
                              }
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={perPageData}
              component="div"
              count={custSubGroup.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  );
}

//Update a Country Dialog
const Dialogcountry = ({
  open,
  setOpen,
  udateId,
  updatedata,
  setUpdatedata,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.admin);
  const { custGroup } = useSelector((state) => state.custGroup);
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getCustGroup());
    }
  }, [dispatch, navigate, isAuthenticated]);

  const updateCustSubGroupSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateCustSubGroup(udateId, {
        name: updatedata.name,
        group_name: updatedata.group_name,
      })
    );
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
    setUpdatedata({ ...updatedata, [e.target.name]: e.target.value });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth="true">
      <DialogTitle>
        <Toolbar style={{ padding: 0 }}>
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            Edit
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ ml: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>Name</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label="Name"
          type="text"
          fullWidth
          displayEmpty
          size="small"
          value={updatedata.name}
          onChange={(e) => handleChange(e)}
        />
        <DialogContentText>Group Name</DialogContentText>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="group_name"
            name="group_name"
            size="small"
            defaultValue={updatedata.group_name}
            onChange={(e) => handleChange(e)}
          >
            {custGroup.map((item) => (
              <MenuItem key={item._id} value={item.name} id="group_name">
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <Divider />
      <Grid
        container
        sx={{ mt: 1, mb: 1, p: 1 }}
        justifyContent="space-between"
      >
        <Button variant="contained" onClick={updateCustSubGroupSubmitHandler}>
          submit
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </Grid>
    </Dialog>
  );
};
