import {
  STOCK_REPORT_REQUEST,
  STOCK_REPORT_SUCCESS,
  STOCK_REPORT_FAIL,
  STOCK_REPORT_SUMMARY_REQUEST,
  STOCK_REPORT_SUMMARY_SUCCESS,
  STOCK_REPORT_SUMMARY_FAIL,
} from "../constants/StockReportConstants";
import axios from "axios";

// Get Stock Report

export const getStockReport = () => async (dispatch) => {
  try {
    dispatch({ type: STOCK_REPORT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/stock/report`,
      config
    );
    dispatch({ type: STOCK_REPORT_SUCCESS, payload: data.response });
  } catch (error) {
    dispatch({ type: STOCK_REPORT_FAIL, payload: error.response.data.message });
  }
};

// Get Stock Report Summary

export const getStockReportSummary = (code) => async (dispatch) => {
  try {
    dispatch({ type: STOCK_REPORT_SUMMARY_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/stock/report/summary?product_code_unique=${code}`,
      config
    );
    dispatch({ type: STOCK_REPORT_SUMMARY_SUCCESS, payload: data.response });
  } catch (error) {
    dispatch({
      type: STOCK_REPORT_SUMMARY_FAIL,
      payload: error.response.data.message,
    });
  }
};
