import { CLEAR_ERRORS } from "../constants/MastersConstants";
import {
    
 
 
  DASHBOARD_FAIL,
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  
} from "../constants/DashboardConstants";

export const dashboardReducer = (state = { dashboard: {} }, action) => {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return { loading: true, dashboard: {} };
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard: action.payload,
      };

    case DASHBOARD_FAIL:
      return {
        ...state,
        loading: false,
        dashboard: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};



  
  