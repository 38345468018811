import {
    USER_REQUEST,
    USER_SUCCESS,
    USER_FAIL,
    NEW_USER_REQUEST,
    NEW_USER_SUCCESS,
    NEW_USER_FAIL,
    NEW_USER_RESET,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    UPDATE_USER_RESET,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    DELETE_USER_RESET,
    CLEAR_ERRORS,
    } from "../constants/UserConstants";
    
    // USER reducers
  export const userReducer = (state = { user: [] }, action) => {
      switch (action.type) {
        case USER_REQUEST:
          return { loading: true, user: [] };
        case USER_SUCCESS:
          return {
            ...state,
            loading: false,
            user: action.payload,
          };
        case USER_FAIL:
          return {
            ...state,
            loading: false,
            slider: null,
            error: action.payload,
          };
        case CLEAR_ERRORS:
          return {
            ...state,
            error: null,
          };
        default:
          return state;
      }
    };
    
    export const newUserReducer = (state = { newuser: {} }, action) => {
      switch (action.type) {
        case NEW_USER_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case NEW_USER_SUCCESS:
          return {
            loading: false,
            success: action.payload.success,
            newuser: action.payload.response,
          };
        case NEW_USER_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
        case NEW_USER_RESET:
          return {
            ...state,
            success: false,
          };
        case CLEAR_ERRORS:
          return {
            ...state,
            error: null,
          };
        default:
          return state;
      }
    };
    
    export const updateDeletUserReducer = (state = {}, action) => {
      switch (action.type) {
        case DELETE_USER_REQUEST:
        case UPDATE_USER_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case DELETE_USER_SUCCESS:
          return {
            ...state,
            loading: false,
            isDeleted: action.payload,
          };
    
        case UPDATE_USER_SUCCESS:
          return {
            ...state,
            loading: false,
            isUpdated: action.payload,
          };
        case DELETE_USER_FAIL:
        case UPDATE_USER_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
        case DELETE_USER_RESET:
          return {
            ...state,
            isDeleted: false,
          };
        case UPDATE_USER_RESET:
          return {
            ...state,
            isUpdated: false,
          };
        case CLEAR_ERRORS:
          return {
            ...state,
            error: null,
          };
        default:
          return state;
      }
    };
    