import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Autocomplete,
  DialogContentText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import { Add, Cancel } from "@mui/icons-material";
import PurchaseOrderInput from "../../Common/PurchaseOrderInput";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { getPaymentTerms } from "../../../Redux/actions/MastersActions";
import { getVendor } from "../../../Redux/actions/VendorAction";
import { getWarehouse } from "../../../Redux/actions/WarehouseActions";
import { getCustomers } from "../../../Redux/actions/CustomerActions";
import AddPurchaseOrderTable from "../../PurchaseOrders/AddPurchaseOrderTable";
import { getEndProductList } from "../../../Redux/actions/InventryActions";
import AddPurchaseOrderItem from "../../PurchaseOrders/AddPurchaseOrderItem";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { TextFieldComponent } from "../../Common/FormikComponent/TextFieldComponent";
import AutoCompleteSelect from "../../Common/FormikComponent/AutoCompleteSelect";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { createPerformaInvoice } from "../../../Redux/actions/SalesActions";
import {
  NEW_PERFORMA_INVOICE_RESET,
  NEW_PERFORMA_INVOICE_SUCCESS,
} from "../../../Redux/constants/SalesConstant";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const AddPerformaInvoice = () => {
  const [value, setValue] = React.useState(new Date());
  const [delivreyDate, setDeliveryDate] = React.useState(new Date());
  const [PO, setPO] = React.useState("");
  const [delivery, setDelivery] = React.useState("");
  const [isModal, setIsModal] = useState(false);
  const [post, setPost] = useState([]);
  const [subTotal, setSubTotal] = useState("");
  const [item, setItem] = useState("");
  const [cust, setCust] = useState("");
  const [billing, setBilling] = React.useState("");
  const alert = useAlert();
  const handleChange1 = (newValue) => {
    setValue(newValue);
  };
  const handleChange2 = (newValue) => {
    setDeliveryDate(newValue);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roal = (first, id) => {
    setIsModal(first);
    setItem(id);
  };
  const [details, setDetails] = useState({
    adjustment: "",
    total: "",
    forwarding_charges: "",
    company_name: "",
  });

  // Table Data
  const [addChild, setAddChild] = React.useState([
    { value: 1, discount_selected: "Rs" },
  ]);
  const addChildInputField = (event) => {
    const id = addChild.length + 1;
    setAddChild((oldValue) => [
      ...oldValue,
      { value: id, discount_selected: "Rs" },
    ]);
    event.preventDefault();
  };
  const deleteRow = (id) => {
    let updatedArray = addChild.filter((r, ind) => ind !== id);
    setAddChild(updatedArray);
    let sum = updatedArray.reduce((a, v) => (a = a + v?.amount), 0);
    setSubTotal(sum);
    let addSum = 0;
    let TotalAddSum = 0;
    updatedArray.map((item, ind) => {
      return (addSum = addSum + (item?.amount * 100) / (100 + item?.tax));
    });
    updatedArray.map((item, ind) => {
      let my =
        ((Number(details.forwarding_charges) / addSum) * (item?.amount * 100)) /
          (100 + item?.tax) +
        (item?.amount * 100) / (100 + item?.tax);
      return (TotalAddSum += my + (my * item?.tax) / 100);
    });
    setDetails((old) => ({
      ...old,
      forwarding_charges: Number(details.forwarding_charges),
      total: TotalAddSum,
    }));
  };
  const onchangeInput = (val, index, outerIndex) => {
    let updated = addChild.map((r, ind) =>
      ind === outerIndex
        ? {
            ...r,
            [index]: val.target.value,
            ["amount"]:
              !(val.target.value === "Rs") &&
              (val.target.value === "%" || r.discount_selected === "%")
                ? index === "quantity"
                  ? (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (r.discount === undefined ? 0 : r.discount / 100)
                  : index === "rate"
                  ? (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.quantity === undefined ? 0 : r.quantity)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.quantity === undefined ? 0 : r.quantity)) *
                      (r.discount === undefined ? 0 : r.discount / 100)
                  : index === "discount"
                  ? (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (val.target.value / 100)
                  : (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (r.discount === undefined ? 0 : r.discount / 100)
                : val.target.value === "Rs"
                ? (r.rate === undefined ? 0 : r.rate) *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  (r.discount === undefined ? 0 : r.discount)
                : index === "quantity"
                ? val.target.value * (r.rate === undefined ? 0 : r.rate) -
                  (r.discount === undefined ? 0 : r.discount)
                : index === "discount"
                ? (r.rate === undefined ? 0 : r.rate) *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  val.target.value
                : val.target.value *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  (r.discount === undefined ? 0 : r.discount),
            ["ordered"]: r.quantity,
          }
        : r
    );
    setAddChild(updated);

    let addSum = 0;
    let TotalAddSum = 0;
    updated.map((item, ind) => {
      return (addSum = addSum + (item?.amount * 100) / (100 + item?.tax));
    });
    updated.map((item, ind) => {
      let my =
        (((val.target.name === "forwarding"
          ? val.target.value
          : details.forwarding_charges) /
          addSum) *
          (item?.amount * 100)) /
          (100 + item?.tax) +
        (item?.amount * 100) / (100 + item?.tax);
      return (TotalAddSum += my + (my * item?.tax) / 100);
    });
    setDetails((old) => ({
      ...old,
      forwarding_charges:
        val.target.name === "forwarding"
          ? val.target.value
            ? Number(val.target.value)
            : ""
          : Number(old.forwarding_charges),
      total: TotalAddSum,
    }));
    let sum = updated.reduce((a, v) => (a = a + v.amount), 0);
    setSubTotal(sum);
    if (details.forwarding_charges === "") {
      setDetails((old) => ({
        ...old,
        total: sum,
      }));
    }
  };
  const [getBank, setBankAcoount] = React.useState("");
  const [getCust, setgeCustomer] = React.useState("");
  const [obj, setObj] = React.useState({
    email: "",
    mobile: "",
    gst_number: "",
    company_name: "",
  });

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { success } = useSelector((state) => state.newPerformaInvoice);
  const { error: adminError2, paymentTerms } = useSelector(
    (state) => state.paymentTerms
  );
  const { error: adminError3, endProductList } = useSelector(
    (state) => state.endProductList
  );
  const { error: adminError } = useSelector((state) => state.performaInvoices);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getPaymentTerms());
      dispatch(getVendor());
      dispatch(getWarehouse());
      dispatch(getCustomers());
      dispatch(getEndProductList());
      axios
        .get("https://erpapi.flaskitsolutions.com/api/v1/admin/performa_invoice_number")
        .then((res) => {
          setPost(res.data.data);
        });
      axios
        .get(
          "https://erpapi.flaskitsolutions.com/api/v1/admin/customer/shipping_billing/address"
        )
        .then((res) => {
          setgeCustomer(res.data.data);
        });
      axios
        .get("https://erpapi.flaskitsolutions.com/api/v1/admin/bank_account/details")
        .then((res) => {
          setBankAcoount(res.data.BankAccountDetails);
        });
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
    if (success) {
      alert.success("Performa Invoic Added Successfully");
      dispatch({ type: NEW_PERFORMA_INVOICE_RESET });
      navigate("/sales/performa-invoices");
    }
    if (details.forwarding_charges === "") {
      setDetails((old) => ({
        ...old,
        total: subTotal,
      }));
    }
  }, [
    dispatch,
    isAuthenticated,
    addChild.length,
    success,
    details.forwarding_charges,
  ]);
  const [bank, setBank] = React.useState("");
  const [contactPerson, setContactPerson] = React.useState("");
  const custFunc = (v) => {
    setCust(v.name);
    let update = getCust?.find((r) => r.name === v.name);
    setBilling(update.customer_billing_address);
    setDelivery(update.customer_shipping_address);
    setObj({
      email: update.email,
      mobile: update.mobile,
      gst_number: update.gst_number,
      company_name: update.company_name,
    });
    setContactPerson(update.contact_persons);
  };
  const [personName, setPersonName] = React.useState([]);
  const handleChangeMulti = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };
  const validationSchema = Yup.object({
    notes: Yup.string(),
    terms_and_condition: Yup.string(),
    ref: Yup.string(),
    payment_terms: Yup.string(),
    shipment_preference: Yup.string(),
    delivery_note: Yup.string(),
    buyers_order_no: Yup.string(),
    dispatch_doc_no: Yup.string(),
    destination: Yup.string(),
    forwarding_charges: Yup.string(),
  });
  const initialValues = {
    payment_terms: "",
    shipment_preference: "",
    ref: "",
    delivery_note: "",
    terms_and_condition: termText,
    notes: "",
    buyers_order_no: "",
    dispatch_doc_no: "",
    destination: "",
    forwarding_charges: "",
  };
  const onSubmit = (values) => {
    dispatch(
      createPerformaInvoice({
        ...values,
        sub_total: subTotal,
        item_details: addChild,
        performa_invoice_number: post,
        date: value,
        delivery_note_date: delivreyDate,
        customer: cust,
        delivery_to: delivery,
        billing_to: billing,
        email: obj.email,
        mobile: obj.mobile,
        gst_number: obj.gst_number,
        contact_persons: personName,
        total: details.total,
        forwarding_charges: details.forwarding_charges,
        company_name: obj.company_name,
        bank_account_details: bank,
      })
    );
  };
  const returnFunc = () => {
    navigate("/sales/performa-invoices");
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Add Sale Order
            </Typography>
          </Grid>
        </Grid>
        {adminError || adminError2 || adminError3 ? (
          <Grid container xs={12} justifyContent="center" alignItems="center">
            <Typography variant="h5" sx={{ fontWeight: "bold", color: "red" }}>
              You don't have the required permissions.
            </Typography>
          </Grid>
        ) : (
          <Grid container sx={{ padding: "0 24px", mt: "0" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ errors, handleChange, values, touched, setFieldValue }) => (
                <Form>
                  <Grid item xs={12}>
                    <Item>
                      {/* <AccountInfo /> */}
                      <Grid container lg={12} justifyContent="space-between">
                        <Grid
                          container
                          lg={4.9}
                          style={{
                            border: "1px solid lightgray",
                            borderRadius: "10px",
                            paddingLeft: "8px",
                            paddingTop: "8px",
                          }}
                        >
                          <Grid container lg={12} mb={2} spacing={2}>
                            <Grid lg={12} md={12} item>
                              <div
                                className="form-control"
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <DialogContentText mb={2}>
                                  Select Customer
                                </DialogContentText>
                                <Autocomplete
                                  size="small"
                                  options={getCust}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(e, v) => custFunc(v)}
                                  sx={{ width: "100%" }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      width="100%"
                                      onChange={({ target }) =>
                                        setCust(target.value)
                                      }
                                      value={cust}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <PurchaseOrderInput
                                heading="Billing To:"
                                name={billing}
                                setInput={setBilling}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <PurchaseOrderInput
                                heading="Delivery To:"
                                name={delivery}
                                setInput={setDelivery}
                              />
                            </Grid>

                            <Grid item lg={6}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "90%",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ mb: 1 }}
                                  >
                                    Contact Person
                                  </Typography>
                                </div>
                                <Select
                                  labelId="demo-multiple-name-label"
                                  id="demo-multiple-name"
                                  multiple
                                  sx={{ width: "100%" }}
                                  size="small"
                                  value={personName}
                                  onChange={handleChangeMulti}
                                >
                                  {contactPerson
                                    ? contactPerson.map((name) => (
                                        <MenuItem key={name} value={name}>
                                          {name}
                                        </MenuItem>
                                      ))
                                    : ""}
                                </Select>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* Second Row */}

                        <Grid
                          container
                          lg={7}
                          style={{
                            border: "1px solid lightgray",
                            borderRadius: "10px",
                            paddingLeft: "8px",
                            paddingTop: "8px",
                          }}
                        >
                          <Grid container lg={12} sx={{ mb: 2 }} spacing={1}>
                            <Grid item xs={4}>
                              <PurchaseOrderInput
                                heading="Invoice#"
                                name={post}
                                post={post}
                                setInput={setPO}
                                placeholder="Invoice#"
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <TextFieldComponent
                                heading="Refrence#"
                                name="ref"
                                type="text"
                                error={errors.ref && touched.ref}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              <DialogContentText
                                style={{ marginBottom: "5px" }}
                              >
                                Date
                              </DialogContentText>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DesktopDatePicker
                                  inputFormat="dd/MM/yyyy"
                                  value={value}
                                  onChange={handleChange1}
                                  renderInput={(params) => (
                                    <TextField {...params} size="small" />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>

                          <Grid container lg={12} sx={{ mb: 2 }} spacing={1}>
                            <Grid item xs={4}>
                              <TextFieldComponent
                                heading="Buyer's Order No"
                                name="buyers_order_no"
                                type="text"
                                error={
                                  errors.buyers_order_no &&
                                  touched.buyers_order_no
                                }
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <AutoCompleteSelect
                                name="payment_terms"
                                data={paymentTerms}
                                error={
                                  errors.payment_terms && touched.payment_terms
                                }
                                values={values}
                                handleChange={handleChange}
                                heading="Payment Terms"
                                setFieldValue={setFieldValue}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <TextFieldComponent
                                heading="Disptach Doc No."
                                name="dispatch_doc_no"
                                type="text"
                                error={
                                  errors.dispatch_doc_no &&
                                  touched.dispatch_doc_no
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid container lg={12} sx={{ mb: 2 }} spacing={1}>
                            <Grid item xs={3}>
                              <TextFieldComponent
                                heading="Destination"
                                name="destination"
                                type="text"
                                error={
                                  errors.destination && touched.destination
                                }
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextFieldComponent
                                heading="Shipping Preference"
                                name="shipment_preference"
                                type="text"
                                error={
                                  errors.shipment_preference &&
                                  touched.shipment_preference
                                }
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <TextFieldComponent
                                heading="Delivery Note"
                                name="delivery_note"
                                type="text"
                                error={
                                  errors.delivery_note && touched.delivery_note
                                }
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <DialogContentText
                                style={{
                                  marginBottom: "5px",
                                  fontSize: "14px",
                                }}
                              >
                                Delivery Note Date
                              </DialogContentText>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DesktopDatePicker
                                  inputFormat="dd/MM/yyyy"
                                  value={delivreyDate}
                                  onChange={handleChange2}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      style={{ width: "80%" }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ mt: 3 }}>
                        <AddPurchaseOrderTable
                          addChild={addChild}
                          onchangeInput={onchangeInput}
                          deleteRow={deleteRow}
                          addChildInputField={addChildInputField}
                          setAddChild={setAddChild}
                          setIsModal={roal}
                          sales="sales"
                          defaultData={defaultData}
                        />
                      </Grid>
                      <Grid container sx={{ mt: 3 }}>
                        {isModal === false ? (
                          ""
                        ) : (
                          <AddPurchaseOrderItem
                            addChild={addChild}
                            setAddChild={setAddChild}
                            data={endProductList}
                            setIsModal={setIsModal}
                            index={item}
                            sales="sales"
                          />
                        )}
                      </Grid>

                      <Grid
                        container
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                        sx={{ mt: 3 }}
                      >
                        <Grid xs={4}>
                          <Grid
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography fontWeight="700" marginRight="10px">
                              Sub Total :
                            </Typography>
                            <TextField
                              type={"number"}
                              InputProps={{
                                readOnly: true,
                              }}
                              id="outlined-read-only-input"
                              size="small"
                              placeholder="0.0"
                              value={subTotal}
                              variant="outlined"
                              sx={{ width: "70%", background: "#fff" }}
                              onChange={(e) => setSubTotal(e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                        sx={{ mt: 3 }}
                      >
                        <Grid xs={4}>
                          <Grid
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography fontWeight="700" marginRight="10px">
                              forwarding Charges :
                            </Typography>
                            <TextField
                              type={"number"}
                              size="small"
                              placeholder="forwarding"
                              variant="outlined"
                              name="forwarding"
                              value={details.forwarding_charges}
                              sx={{ width: "70%", background: "#fff" }}
                              onChange={(e) => onchangeInput(e)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                        sx={{ mt: 3 }}
                      >
                        <Grid xs={4}>
                          <Grid
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography fontWeight="700" marginRight="10px">
                              Total :
                            </Typography>
                            <TextField
                              type={"number"}
                              size="small"
                              InputProps={{
                                readOnly: true,
                              }}
                              id="outlined-read-only-input"
                              placeholder="0.0"
                              variant="outlined"
                              name="total"
                              value={details.total}
                              sx={{ width: "70%", background: "#fff" }}
                              onChange={(e) => onchangeInput(e)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid lg={7} md={12} item>
                        <div
                          className="form-control"
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <DialogContentText mb={2}>
                            Select Bank Account
                          </DialogContentText>
                          <Autocomplete
                            size="small"
                            value={bank}
                            options={getBank}
                            getOptionLabel={(option) => option}
                            onChange={(e, v) => setBank(v)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                width="100%"
                                onChange={({ target }) => setBank(target.value)}
                                variant="outlined"
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid container sx={{ mt: 3 }}>
                        <Grid container lg={12}>
                          <TextFieldComponent
                            heading="Terms and Condition"
                            name="terms_and_condition"
                            type="text"
                            error={
                              errors.terms_and_condition &&
                              touched.terms_and_condition
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container sx={{ mt: 3 }}>
                        <Grid container lg={12}>
                          <TextFieldComponent
                            heading="Notes"
                            name="notes"
                            type="text"
                            error={errors.notes && touched.notes}
                          />
                        </Grid>
                      </Grid>

                      <Grid container justifyContent="flex-end" sx={{ mt: 3 }}>
                        <Button
                          variant="contained"
                          sx={{ mr: 2 }}
                          color="secondary"
                          type="submit"
                        >
                          <Add sx={{ mr: 1 }} />
                          Add
                        </Button>
                        <Button
                          variant="contained"
                          onClick={returnFunc}
                          color="error"
                        >
                          <Cancel sx={{ mr: 1 }} />
                          Cancel
                        </Button>
                      </Grid>
                    </Item>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default AddPerformaInvoice;
const termText =
  "I/We Herby certify that my/our registration under Maharashtra Goods and Service tax Act 2017 is in force on the date on which the sale of the goods specified in this tax invoice is made by me/us and that the transaction of sale covered bt this tax invoice has been effected by me/us and it shall be accounted for in turnover of sales while filling 0 retrn and the due tax if any,payable on the sale has been paid or shall be paid.";

const defaultData = [
  {
    value: "item",
  },
  {
    value: "add_Desc",
  },
  {
    value: "quantity",
  },
  {
    value: "rate",
  },
  {
    value: "discount",
  },
  {
    value: "tax",
  },
  {
    value: "amount",
  },
];
