


export const VENDOR_BILLING_REQUEST = "VENDOR_BILLING_REQUEST";
export const VENDOR_BILLING_SUCCESS = "VENDOR_BILLING_SUCCESS";
export const VENDOR_BILLING_FAIL = "VENDOR_BILLING_FAIL";

export const UPDATE_VENDOR_BILLING_REQUEST = "UPDATE_VENDOR_BILLING_REQUEST";
export const UPDATE_VENDOR_BILLING_SUCCESS = "UPDATE_VENDOR_BILLING_SUCCESS";
export const UPDATE_VENDOR_BILLING_FAIL = "UPDATE_VENDOR_BILLING_FAIL";
export const UPDATE_VENDOR_BILLING_RESET = "UPDATE_VENDOR_BILLING_RESET";

export const DELETE_VENDOR_BILLING_REQUEST = "DELETE_VENDOR_BILLING_REQUEST";
export const DELETE_VENDOR_BILLING_SUCCESS = "DELETE_VENDOR_BILLING_SUCCESS";
export const DELETE_VENDOR_BILLING_FAIL = "DELETE_VENDOR_BILLING_FAIL";
export const DELETE_VENDOR_BILLING_RESET = "DELETE_VENDOR_BILLING_RESET";

export const NEW_VENDOR_BILLING_REQUEST = "NEW_VENDOR_BILLING_REQUEST";
export const NEW_VENDOR_BILLING_SUCCESS = "NEW_VENDOR_BILLING_SUCCESS";
export const NEW_VENDOR_BILLING_FAIL = "NEW_VENDOR_BILLING_FAIL";
export const NEW_VENDOR_BILLING_RESET = "NEW_VENDOR_BILLING_RESET";


export const VENDOR_REQUEST = "VENDOR_REQUEST";
export const VENDOR_SUCCESS = "VENDOR_SUCCESS";
export const VENDOR_FAIL = "VENDOR_FAIL";

export const UPDATE_VENDOR_REQUEST = "UPDATE_VENDOR_REQUEST";
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";
export const UPDATE_VENDOR_FAIL = "UPDATE_VENDOR_FAIL";
export const UPDATE_VENDOR_RESET = "UPDATE_VENDOR_RESET";

export const DELETE_VENDOR_REQUEST = "DELETE_VENDOR_REQUEST";
export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS";
export const DELETE_VENDOR_FAIL = "DELETE_VENDOR_FAIL";
export const DELETE_VENDOR_RESET = "DELETE_VENDOR_RESET";

export const NEW_VENDOR_REQUEST = "NEW_VENDOR_REQUEST";
export const NEW_VENDOR_SUCCESS = "NEW_VENDOR_SUCCESS";
export const NEW_VENDOR_FAIL = "NEW_VENDOR_FAIL";
export const NEW_VENDOR_RESET = "NEW_VENDOR_RESET";

export const VENDOR_SHIPPING_REQUEST = "VENDOR_SHIPPING_REQUEST";
export const VENDOR_SHIPPING_SUCCESS = "VENDOR_SHIPPING_SUCCESS";
export const VENDOR_SHIPPING_FAIL = "VENDOR_SHIPPING_FAIL";

export const UPDATE_VENDOR_SHIPPING_REQUEST = "UPDATE_VENDOR_SHIPPING_REQUEST";
export const UPDATE_VENDOR_SHIPPING_SUCCESS = "UPDATE_VENDOR_SHIPPING_SUCCESS";
export const UPDATE_VENDOR_SHIPPING_FAIL = "UPDATE_VENDOR_SHIPPING_FAIL";
export const UPDATE_VENDOR_SHIPPING_RESET = "UPDATE_VENDOR_SHIPPING_RESET";

export const DELETE_VENDOR_SHIPPING_REQUEST = "DELETE_VENDOR_SHIPPING_REQUEST";
export const DELETE_VENDOR_SHIPPING_SUCCESS = "DELETE_VENDOR_SHIPPING_SUCCESS";
export const DELETE_VENDOR_SHIPPING_FAIL = "DELETE_VENDOR_SHIPPING_FAIL";
export const DELETE_VENDOR_SHIPPING_RESET = "DELETE_VENDOR_SHIPPING_RESET";

export const NEW_VENDOR_SHIPPING_REQUEST = "NEW_VENDOR_SHIPPING_REQUEST";
export const NEW_VENDOR_SHIPPING_SUCCESS = "NEW_VENDOR_SHIPPING_SUCCESS";
export const NEW_VENDOR_SHIPPING_FAIL = "NEW_VENDOR_SHIPPING_FAIL";
export const NEW_VENDOR_SHIPPING_RESET = "NEW_VENDOR_SHIPPING_RESET";

export const VENDOR_CONTACT_PERSON_REQUEST = "VENDOR_CONTACT_PERSON_REQUEST";
export const VENDOR_CONTACT_PERSON_SUCCESS = "VENDOR_CONTACT_PERSON_SUCCESS";
export const VENDOR_CONTACT_PERSON_FAIL = "VENDOR_CONTACT_PERSON_FAIL";

export const UPDATE_VENDOR_CONTACT_PERSON_REQUEST = "UPDATE_VENDOR_CONTACT_PERSON_REQUEST";
export const UPDATE_VENDOR_CONTACT_PERSON_SUCCESS = "UPDATE_VENDOR_CONTACT_PERSON_SUCCESS";
export const UPDATE_VENDOR_CONTACT_PERSON_FAIL = "UPDATE_VENDOR_CONTACT_PERSON_FAIL";
export const UPDATE_VENDOR_CONTACT_PERSON_RESET = "UPDATE_VENDOR_CONTACT_PERSON_RESET";

export const DELETE_VENDOR_CONTACT_PERSON_REQUEST = "DELETE_VENDOR_CONTACT_PERSON_REQUEST";
export const DELETE_VENDOR_CONTACT_PERSON_SUCCESS = "DELETE_VENDOR_CONTACT_PERSON_SUCCESS";
export const DELETE_VENDOR_CONTACT_PERSON_FAIL = "DELETE_VENDOR_CONTACT_PERSON_FAIL";
export const DELETE_VENDOR_CONTACT_PERSON_RESET = "DELETE_VENDOR_CONTACT_PERSON_RESET";

export const NEW_VENDOR_CONTACT_PERSON_REQUEST = "NEW_VENDOR_CONTACT_PERSON_REQUEST";
export const NEW_VENDOR_CONTACT_PERSON_SUCCESS = "NEW_VENDOR_CONTACT_PERSON_SUCCESS";
export const NEW_VENDOR_CONTACT_PERSON_FAIL = "NEW_VENDOR_CONTACT_PERSON_FAIL";
export const NEW_VENDOR_CONTACT_PERSON_RESET = "NEW_VENDOR_CONTACT_PERSON_RESET";
