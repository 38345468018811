import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  DialogContentText,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate, useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import { Add, Cancel } from "@mui/icons-material";
import PurchaseOrderInput from "../../Common/PurchaseOrderInput";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import PurchaseOrderDropdown from "../../Common/PurchaseOrderDropdown";
import { getPaymentTerms } from "../../../Redux/actions/MastersActions";
import { getVendor } from "../../../Redux/actions/VendorAction";
import { getWarehouse } from "../../../Redux/actions/WarehouseActions";
import AddPurchaseOrderTable from "../../PurchaseOrders/AddPurchaseOrderTable";
import { getEndProductList } from "../../../Redux/actions/InventryActions";
import AddPurchaseOrderItem from "../../PurchaseOrders/AddPurchaseOrderItem";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  getSalesInvoice,
  updateSalesInvoice,
} from "../../../Redux/actions/SalesActions";
import Loader from "../../Common/Loader";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { TextFieldComponent } from "../../Common/FormikComponent/TextFieldComponent";
import AutoCompleteSelect from "../../Common/FormikComponent/AutoCompleteSelect";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const EditTaxInvoice = () => {
  let { id } = useParams();
  const [demo, setDemo] = useState("");
  const [value, setValue] = React.useState(new Date());
  const [delivreyDate, setDeliveryDate] = React.useState(new Date());
  const [PO, setPO] = React.useState("");
  const [refrence, setRefrence] = React.useState("");
  const [shippingPrefrence, setShippingPrefrence] = React.useState("");
  const [paymentterms, setPaymentterms] = React.useState("");
  const [delivery, setDelivery] = React.useState("");
  const [deliveryNote, setDeliveryNote] = React.useState("");
  const [billing, setBilling] = React.useState("");
  
  
   
   const [pincode, setPincode] = React.useState("");
  const [distance, setDistance] = React.useState("");
  const [transportData, setTransportData] = useState([]);

  const [total_transport_charge, setTotalTrCharge] = React.useState(0);
  const [total_weight, setTotalWeight] = React.useState(0);
  
  
  
  
  
  
  const [buyerOrder, setBuyerOrder] = React.useState("");
  const [dispatchNo, setDispatchNo] = React.useState("");
  const [destination, setDestination] = React.useState("");
  const [terms, setTerms] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [isModal, setIsModal] = useState(false);
  const [post, setPost] = useState([]);
  const [subTotal, setSubTotal] = useState("");
  const [dis, setDis] = useState("");
  const [total, setTotal] = useState("");
  const [item, setItem] = useState("");
  const [cust, setCust] = useState("");
  const [adjustment, setAdjustment] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roal = (first, id) => {
    setIsModal(first);
    setItem(id);
  };
  const handleChange1 = (newValue) => {
    setValue(newValue);
  };
  const handleChange2 = (newValue) => {
    setDeliveryDate(newValue);
  };

  const [details, setDetails] = useState({
    adjustment: "",
    total: "",
    forwarding_charges: "",
    total_tax: 0,
    rounding: "",
    company_name: "",
  });
  // Table Data
  const [addChild, setAddChild] = React.useState([
    { value: 1, discount_selected: "Rs" },
  ]);
  const addChildInputField = (event) => {
    const id = addChild.length + 1;
    setAddChild((oldValue) => [
      ...oldValue,
      { value: id, discount_selected: "Rs" },
    ]);
    event.preventDefault();
  };
  const deleteRow = (id) => {
    let updatedArray = addChild.filter((r, ind) => ind !== id);
    setAddChild(updatedArray);
    let sum = updatedArray.reduce((a, v) => (a = a + v.amount), 0);
    setSubTotal(sum);
    let addSum = 0;
    let TotalAddSum = 0;
    updatedArray.map((item, ind) => {
      return (addSum = addSum + (item?.amount * 100) / (100 + item?.tax));
    });
    updatedArray.map((item, ind) => {
      let my =
        ((Number(details.forwarding_charges) / addSum) * (item?.amount * 100)) /
          (100 + item?.tax) +
        (item?.amount * 100) / (100 + item?.tax);
      return (TotalAddSum += my + (my * item?.tax) / 100);
    });
    setDetails((old) => ({
      ...old,
      forwarding_charges: Number(details.forwarding_charges),
      total: TotalAddSum,
    }));
  };
  
  
  
    
    const setDistanceCal = (val) => {
		 
		 
	 setDistance(val);
		
	 let updated = addChild;	
	 /*******************************************/
	 // calculate total weight
	 
	 let TotalWt = 0;
	 let TotalTax = 0;
	 updated.map((item, ind) => {
      return ( TotalWt = TotalWt + ( parseInt(item?.quantity) * parseInt( item?.Weight == '' ||  item?.Weight == null ? 0 : item?.Weight  ) ) );
		});
		  
	  setTotalWeight(TotalWt);
 
	    
	    let total_t_charges = 0;
		 
		
	   let current_km_arr = transportData.filter(item => item?.km === parseInt(val));
	     let near_val = parseInt(val);
		while(current_km_arr.length == 0 && near_val <2000)
		{ 
	        near_val++;
			current_km_arr = transportData.filter(item => item?.km ===  near_val);
		}
	   
	   
	    //alert.success("Test"+parseInt(distance)+" Array :"+JSON.stringify(current_km_arr));
			 
			
			if(TotalWt >= 0 && TotalWt <= 150 )
			{
				total_t_charges = parseInt(current_km_arr[0]?.slab1?.total);
			}
			
			if(TotalWt >= 151 && TotalWt <= 250 )
			{
				total_t_charges = parseInt(current_km_arr[0]?.slab2?.total);
			}
			
			if(TotalWt >= 251 )
			{
				total_t_charges = parseInt(current_km_arr[0]?.slab3?.total);
			}

  
 
		   setTotalTrCharge(total_t_charges);
	
	
	
	/*********************************************/
	 
	
	//  New tr charge
	
		 
	  updated =  updated.map((r, ind) =>  
       true
        ? {
            ...r,
            ["tr_charge"]:  total_t_charges / updated.length ,
            ["tax_amount"]:  (( ( r.amount * 100) / (100 + r.tax )   )   * r.tax / 100) +  (( ( (total_t_charges / updated.length) * 100) / (100 + r.tax )   )   * r.tax / 100) ,
			
		  } : r
		  
		  );
	 
	  
	  setAddChild(updated);


	    updated.map((item, ind) => {
      return (TotalTax = TotalTax + item?.tax_amount);
    });
	 
	/**********************************************/
	
	
	
	
	
		var final_total =  parseInt(details.total) + parseInt(total_t_charges);
	
	 setDetails((old) => ({
        ...old,
        total: final_total,
      }));
	
	
	
	
	
	/**********************************************/
	 
		
	};
  
  
  
  
  
  const onchangeInput = (val, index, outerIndex) => {
	  
	  
	    if(index == 'add_Desc' || index == 'item')
	  {
		  return false;
	  }
	  
	  
    let updated = addChild.map((r, ind) =>
      ind === outerIndex
        ? {
            ...r,
            [index]: val.target.value,
            ["amount"]:
              !(val.target.value === "Rs") &&
              (val.target.value === "%" || r.discount_select === "%")
                ? index === "quantity"
                  ? (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (r.discount === undefined ? 0 : r.discount / 100)
                  : index === "rate"
                  ? (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.quantity === undefined ? 0 : r.quantity)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.quantity === undefined ? 0 : r.quantity)) *
                      (r.discount === undefined ? 0 : r.discount / 100)
                  : index === "discount"
                  ? (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (val.target.value / 100)
                  : (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (r.discount === undefined ? 0 : r.discount / 100)
                : val.target.value === "Rs"
                ? (r.rate === undefined ? 0 : r.rate) *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  (r.discount === undefined ? 0 : r.discount)
                : index === "quantity"
                ? val.target.value * (r.rate === undefined ? 0 : r.rate) -
                  (r.discount === undefined ? 0 : r.discount)
                : index === "discount"
                ? (r.rate === undefined ? 0 : r.rate) *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  val.target.value
                : val.target.value *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  (r.discount === undefined ? 0 : r.discount),
            ["ordered"]: r.quantity,
			
			
				["tax_amount"]:  !(val.target.value === "Rs") &&
              (val.target.value === "%" || r.discount_selected === "%")
                ? index === "quantity"
                  ? (  ( (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (r.discount === undefined ? 0 : r.discount / 100) )  * 100 /  (100 + r.tax ) )    * r.tax / 100
                  : index === "rate"
                  ? ( ( (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.quantity === undefined ? 0 : r.quantity)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.quantity === undefined ? 0 : r.quantity)) *
                      (r.discount === undefined ? 0 : r.discount / 100) ) * 100 /  (100 + r.tax ) )    * r.tax / 100
                  : index === "discount"
                  ?(r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (val.target.value / 100)
                  : (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (r.discount === undefined ? 0 : r.discount / 100)
                : val.target.value === "Rs"
                ? (r.rate === undefined ? 0 : r.rate) *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  (r.discount === undefined ? 0 : r.discount)
                : index === "quantity"
                ?  ( ( val.target.value * (r.rate === undefined ? 0 : r.rate) -
                  (r.discount === undefined ? 0 : r.discount)  ) * 100 /  (100 + r.tax ) )    * r.tax / 100
                : index === "discount"
                ?  ( ( (r.rate === undefined ? 0 : r.rate) *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  val.target.value )   * 100 /  (100 + r.tax ) )    * r.tax / 100
                :  ( ( val.target.value *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  (r.discount === undefined ? 0 : r.discount)  )  * 100 /  (100 + r.tax ) )    * r.tax / 100 ,
			
			
			
          }
        : r
    );
    setAddChild(updated);
    let sum = updated.reduce((a, v) => (a = a + v.amount), 0);
    setSubTotal(sum);

    let addSum = 0;
    let TotalAddSum = 0;
    let TotalTax = 0;
    updated.map((item, ind) => {
      return (addSum = addSum + (item?.amount * 100) / (100 + item?.tax));
    });
	
	
	
	  
	
	 /*******************************************/
	 // calculate total weight
	 
	 let TotalWt = 0;
	 
	  updated.map((item, ind) => {
      return ( TotalWt = TotalWt + ( parseInt(item?.quantity) * parseInt( item?.Weight == '' ||  item?.Weight == null ? 0 : item?.Weight  ) ) );
		});
		
		  
	  setTotalWeight(TotalWt);
 
	   //getTransportCost();  get trasport charge
	    let total_t_charges = 0;
		
		/*
	   transportData.map((item, ind) => {
      return ( total_t_charges =   ( TotalWt  * parseFloat(item?.slab1?.rate) ) );
		});
		*/
		
	   let current_km_arr = transportData.filter(item => item?.km === parseInt(distance));
	    let near_val = parseInt(distance);
		while(current_km_arr.length == 0 && near_val <2000)
		{ 
	        near_val++;
			current_km_arr = transportData.filter(item => item?.km ===  near_val);
		}
	   
	   
	   
	    //alert.success("Test"+parseInt(distance)+" Array :"+JSON.stringify(current_km_arr));
			 
			
			if(TotalWt >= 0 && TotalWt <= 150 )
			{
				total_t_charges = parseInt(current_km_arr[0]?.slab1?.total);
			}
			
			if(TotalWt >= 151 && TotalWt <= 250 )
			{
				total_t_charges = parseInt(current_km_arr[0]?.slab2?.total);
			}
			
			if(TotalWt >= 251 )
			{
				total_t_charges = parseInt(current_km_arr[0]?.slab3?.total);
			}

  
 
		   setTotalTrCharge(total_t_charges);
	
	
	/**********************************************/
	
	
	
		   
	/**********************************************/
	 
	
	//  New tr charge
	
		 
	  updated =  updated.map((r, ind) =>  
       true
        ? {
            ...r,
            ["tr_charge"]:  total_t_charges / updated.length ,
            ["tax_amount"]:  (( ( r.amount * 100) / (100 + r.tax )   )   * r.tax / 100) +  (( ( (total_t_charges / updated.length) * 100) / (100 + r.tax )   )   * r.tax / 100) ,
			
		  } : r
		  
		  );
	 
	  
	  setAddChild(updated);

	    
	  updated.map((item, ind) => {
      return (TotalTax = TotalTax + item?.tax_amount);
    });
	 
	  
	/**********************************************/
	
	 
		   
		   
		   
	
	
	
	
	
    updated.map((item, ind) => {
      let my =
        (((val.target.name === "forwarding"
          ? val.target.value
          : details.forwarding_charges) /
          addSum) *
          (item?.amount * 100)) /
          (100 + item?.tax) +
        (item?.amount * 100) / (100 + item?.tax);
      return (TotalAddSum += my + (my * item?.tax) / 100);
    });
    setDetails((old) => ({
      ...old,
      forwarding_charges:
        val.target.name === "forwarding"
          ? val.target.value
            ? Number(val.target.value)
            : ""
          : Number(details.forwarding_charges),
		  
		   total_tax:TotalTax,
		     rounding:
			val.target.name === "rounding"
          ? val.target.value
            ? Number(val.target.value)
            : ""
          : Number(old.rounding),
		  
		  
		  
      total: TotalAddSum,
    }));
    if (details.forwarding_charges === "") {
      setDetails((old) => ({
        ...old,
        total: sum,
      }));
    }
	
	
	
	
	
		
	var final_total =  TotalAddSum + parseInt(total_t_charges);
	
	 setDetails((old) => ({
        ...old,
        total: final_total,
      }));
	
	
	 
	
	  if (details.rounding != "") {
      setDetails((old) => ({
        ...old,
        total: final_total - details.rounding,
      }));
    }
	 
	 
	 if(val.target.name == "rounding" )
	 {
		 setDetails((old) => ({
        ...old,
        total: final_total - val.target.value,
      })); 
		 
	 }
	
	
	
	 
	
	
	
  };

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { paymentTerms } = useSelector((state) => state.paymentTerms);
  const { customers } = useSelector((state) => state.customers);
  const alert = useAlert();
  const { endProductList } = useSelector((state) => state.endProductList);

  const [obj, setObj] = React.useState({
    email: "",
    mobile: "",
    gst_number: "",
    contact_persons: [],
  });

  const [getCust, setgeCustomer] = React.useState("");
  const [personName, setPersonName] = React.useState([]);
  const handleChangeMulti = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };
  const [bank, setBank] = React.useState("");
  const [getBank, setBankAcoount] = React.useState("");
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getPaymentTerms());
      dispatch(getVendor());
      dispatch(getWarehouse());
      dispatch(getSalesInvoice());
      axios
        .get("https://erpapi.flaskitsolutions.com/api/v1/admin/bank_account/details")
        .then((res) => {
          setBankAcoount(res.data.BankAccountDetails);
        });
		
		 
		axios
        .get("https://erpapi.flaskitsolutions.com/api/v1/admin/get_transport_charge")
        .then((res) => {
          setTransportData(res?.data?.Details);
        });
		
		
      axios
        .get(
          "https://erpapi.flaskitsolutions.com/api/v1/admin/customer/shipping_billing/address"
        )
        .then((res) => {
          setgeCustomer(res.data.data);
        });
      dispatch(getEndProductList());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
    if (id) {
      const get = async () => {
        const { data } = await axios.get(
          `https://erpapi.flaskitsolutions.com/api/v1/admin/add_sales_order/details/${id}`
        );
        setDemo(data.salesOrder);
        setBank(data.salesOrder.bank_account_details);
        setRefrence(data.salesOrder.ref);
        setShippingPrefrence(data.salesOrder.shipment_preference);
        setPaymentterms(data.salesOrder.payment_terms);
        setSubTotal(data.salesOrder.sub_total);
        setPost(data.salesOrder.invoice_number);
        setDis(data.salesOrder.discount);
        setTotalTrCharge(data.salesOrder.forwarding_charges);
        setDistance(data.salesOrder.distance);
        setPincode(data.salesOrder.pincode);
        setTotal(data.salesOrder.total);
        setAdjustment(data.salesOrder.adjustment);
        setTerms(data.salesOrder.terms_and_condition);
        setNotes(data.salesOrder.notes);
        setValue(data.salesOrder.createdAt);
        setDeliveryDate(data.salesOrder.delivery_note_date);
        setAddChild(data.salesOrder.item_details);
        setDeliveryNote(data.salesOrder.delivery_note);
        setDispatchNo(data.salesOrder.dispatch_doc_no);
        setBuyerOrder(data.salesOrder.buyers_order_no);
        setDestination(data.salesOrder.destination);
        setContactPerson(data.salesOrder.contact_persons);
        setDetails((old) => ({
          ...old,
          total: data.salesOrder.total,
          forwarding_charges: data.salesOrder.forwarding_charges,
          total_tax: data.salesOrder.total_tax,
          rounding: data.salesOrder.rounding,
        }));
        setCust(data.salesOrder.customer);
        setDelivery(data.salesOrder.delivery_to);
        setBilling(data.salesOrder.billing_to);
        setPersonName(data.salesOrder.contact_persons);
      };
      get();
    }
    if (details.forwarding_charges === "") {
      setDetails((old) => ({
        ...old,
        total: subTotal,
      }));
    }
  }, [dispatch, isAuthenticated, id, details.forwarding_charges]);
  const [contactPerson, setContactPerson] = React.useState("");
  const custFunc = (v) => {
    setCust(v.target.value);
    let update = getCust?.find((r) => r.name === v.target.value);
    setBilling(update.customer_billing_address);
    setDelivery(update.customer_shipping_address);
    console.log(update, "update");
    setObj({
      email: update.email,
      mobile: update.mobile,
      gst_number: update.gst_number,
      company_name: update.company_name,
    });
    setContactPerson(update.contact_persons);
  };
  const validationSchema = Yup.object({
    notes: Yup.string(),
    terms_and_condition: Yup.string(),
    ref: Yup.string(),
    payment_terms: Yup.string(),
    shipment_preference: Yup.string(),
    delivery_note: Yup.string(),
    buyers_order_no: Yup.string(),
    dispatch_doc_no: Yup.string(),
    destination: Yup.string(),
  });
  const initialValues = {
    payment_terms: demo.payment_terms ? demo.payment_terms : "",
    shipment_preference: demo.shipment_preference
      ? demo.shipment_preference
      : "",
    ref: demo.ref ? demo.ref : "",
    delivery_note: demo.delivery_note ? demo.delivery_note : "",
    terms_and_condition: demo.terms_and_condition
      ? demo.terms_and_condition
      : "",
    notes: demo.notes ? demo.notes : "",
    buyers_order_no: demo.buyers_order_no ? demo.buyers_order_no : "",
    dispatch_doc_no: demo.dispatch_doc_no ? demo.dispatch_doc_no : "",
    destination: demo.destination,
  };
  const onSubmit = (values) => {
    dispatch(
      updateSalesInvoice(id, {
        ...values,
        sub_total: subTotal,
        item_details: addChild,
        invoice_number: post,
        date: value,
        delivery_note_date: delivreyDate,
        customer: cust,
        company_name: obj.company_name,
        delivery_to: delivery,
        billing_to: billing,
		pincode: pincode,
        distance: distance,
        email: obj.email,
        mobile: obj.mobile,
        gst_number: obj.gst_number,
        contact_persons: personName,
        adjustment: details.adjustment,
        rounding: details.rounding,
        total_tax: details.total_tax,
        total: details.total,
        forwarding_charges: total_transport_charge,
        bank_account_details: bank,
      })
    );
    navigate("/sales/tax-invoices");
  };
  const returnHandle = () => {
    navigate("/sales/tax-invoices");
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <DrawerErp />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Grid container sx={{ p: 3 }}>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Add Sale Order
              </Typography>
            </Grid>
          </Grid>

          {!demo ? (
            <Loader />
          ) : (
            <Grid container sx={{ padding: "0 24px", mt: "0" }}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ errors, handleChange, values, touched, setFieldValue }) => (
                  <Form>
                    <Grid item xs={12}>
                      <Item>
                        {/* <AccountInfo /> */}
                        <Grid container lg={12} justifyContent="space-between">
                          <Grid
                            container
                            lg={4.9}
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: "10px",
                              paddingLeft: "8px",
                              paddingTop: "8px",
                            }}
                          >
                            <Grid container lg={12} spacing={2} mb={2}>
                              <Grid xs={12} item>
                                <PurchaseOrderDropdown
                                  size="100%"
                                  heading="Select Customer"
                                  data={getCust}
                                  setInput={custFunc}
                                  cu="cust"
                                  name={cust}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <PurchaseOrderInput
                                  heading="Billing To:"
                                  name={billing}
                                  setInput={setBilling}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <PurchaseOrderInput
                                  heading="Delivery To:"
                                  name={delivery}
                                  setInput={setDelivery}
                                />
                              </Grid>
                              <Grid item lg={6}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "90%",
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      sx={{ mb: 1 }}
                                    >
                                      Contact Person
                                    </Typography>
                                  </div>
                                  <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple
                                    sx={{ width: "100%" }}
                                    size="small"
                                    value={personName}
                                    onChange={handleChangeMulti}
                                  >
                                    {contactPerson
                                      ? contactPerson.map((name) => (
                                          <MenuItem key={name} value={name}>
                                            {name}
                                          </MenuItem>
                                        ))
                                      : ""}
                                  </Select>
                                </div>
                              </Grid>
							  
							  
							  	
							<Grid item xs={3}>
                              <PurchaseOrderInput
                                heading="Pincode" 
                                name={pincode}
                                setInput={setPincode}
                              />
                            </Grid>
							
							<Grid item xs={3}>
                              <PurchaseOrderInput
                                heading="Distance(km)" 
                                name={distance}
                                setInput={setDistanceCal}
                              />
                            </Grid>
							
							  
							  
							  
							  
							  
							  
							  
                            </Grid>
                          </Grid>

                          {/* Second Row */}

                          <Grid
                            container
                            lg={7}
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: "10px",
                              paddingLeft: "8px",
                              paddingTop: "8px",
                            }}
                          >
                            <Grid container lg={12} sx={{ mb: 2 }} spacing={1}>
                              <Grid item xs={4}>
                                <PurchaseOrderInput
                                  heading="Invoice#"
                                  name={post}
                                  post={post}
                                  setInput={setPO}
                                  placeholder="Invoice#"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextFieldComponent
                                  heading="Refrence#"
                                  name="ref"
                                  sales={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  type="text"
                                  error={errors.ref && touched.ref}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  flexDirection: "column",
                                }}
                              >
                                <DialogContentText
                                  style={{ marginBottom: "5px" }}
                                >
                                  Date
                                </DialogContentText>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DesktopDatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={value}
                                    disabled={
                                      demo.dispatch_status ===
                                      "Order Dispatched"
                                        ? true
                                        : false
                                    }
                                    onChange={handleChange1}
                                    renderInput={(params) => (
                                      <TextField {...params} size="small" />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>

                            <Grid container lg={12} sx={{ mb: 2 }} spacing={1}>
                              <Grid item xs={4}>
                                <TextFieldComponent
                                  heading="Buyer's Order No"
                                  name="buyers_order_no"
                                  type="text"
                                  sales={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  error={
                                    errors.buyers_order_no &&
                                    touched.buyers_order_no
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <AutoCompleteSelect
                                  name="payment_terms"
                                  data={paymentTerms}
                                  error={
                                    errors.payment_terms &&
                                    touched.payment_terms
                                  }
                                  values={values.payment_terms}
                                  handleChange={handleChange}
                                  sales={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  heading="Payment Terms"
                                  setFieldValue={setFieldValue}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextFieldComponent
                                  heading="Disptach Doc No."
                                  name="dispatch_doc_no"
                                  type="text"
                                  sales={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  error={
                                    errors.dispatch_doc_no &&
                                    touched.dispatch_doc_no
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid container lg={12} sx={{ mb: 2 }} spacing={1}>
                              <Grid item xs={3}>
                                <TextFieldComponent
                                  heading="Destination"
                                  name="destination"
                                  type="text"
                                  sales={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  error={
                                    errors.destination && touched.destination
                                  }
                                />
                              </Grid>

                              <Grid item xs={3}>
                                <TextFieldComponent
                                  heading="Shipping Preference"
                                  name="shipment_preference"
                                  type="text"
                                  sales={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  error={
                                    errors.shipment_preference &&
                                    touched.shipment_preference
                                  }
                                />
                              </Grid>

                              <Grid item xs={3}>
                                <TextFieldComponent
                                  heading="Delivery Note"
                                  name="delivery_note"
                                  type="text"
                                  sales={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  disabled={
                                    demo.dispatch_status === "Order Dispatched"
                                      ? true
                                      : false
                                  }
                                  error={
                                    errors.delivery_note &&
                                    touched.delivery_note
                                  }
                                />
                              </Grid>

                              <Grid item xs={3}>
                                <DialogContentText
                                  style={{
                                    marginBottom: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Delivery Note Date
                                </DialogContentText>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DesktopDatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={delivreyDate}
                                    disabled={
                                      demo.dispatch_status ===
                                      "Order Dispatched"
                                        ? true
                                        : false
                                    }
                                    onChange={handleChange2}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        style={{ width: "80%" }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 3 }}>
                          <AddPurchaseOrderTable
                            addChild={addChild}
                            onchangeInput={onchangeInput}
                            deleteRow={deleteRow}
                            addChildInputField={addChildInputField}
                            setAddChild={setAddChild}
                            setIsModal={roal}
                            defaultData={defaultData}
                            sales="sales"
                            di={
                              demo.dispatch_status === "Order Dispatched"
                                ? true
                                : false
                            }
                            data={demo}
                          />
                        </Grid>
                        <Grid container sx={{ mt: 3 }}>
                          {isModal === false ? (
                            ""
                          ) : (
                            <AddPurchaseOrderItem
                              addChild={addChild}
                              setAddChild={setAddChild}
                              data={endProductList}
                              setIsModal={setIsModal}
                              index={item}
                              sales="sales"
                            />
                          )}
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                          sx={{ mt: 3 }}
                        >
                          <Grid xs={4}>
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography fontWeight="700" marginRight="10px">
                                Sub Total :
                              </Typography>
                              <TextField
                                type={"number"}
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="outlined-read-only-input"
                                size="small"
                                placeholder="0.0"
                                value={subTotal}
                                variant="outlined"
                                sx={{ width: "70%", background: "#fff" }}
                                onChange={(e) => setSubTotal(e.target.value)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
 
					   
                      <Grid
                        container
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                        sx={{ mt: 3 }}
                      >
                        <Grid xs={4}>
                          <Grid
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography fontWeight="700" marginRight="10px">
                              Total Chargable Weight:
                            </Typography>
                            <TextField
                              type={"number"}
                              size="small"
                              placeholder="total_weight"
                              variant="outlined"
                              name="total_weight"
                              value={total_weight}
							  
							   InputProps={{
                                readOnly: true,
                              }}
							  
                              sx={{ width: "70%", background: "#fff" }}
                             />
                          </Grid>
                        </Grid>
                      </Grid>




                        <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                          sx={{ mt: 3 }}
                        >
                          <Grid xs={4}>
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography fontWeight="700" marginRight="10px">
                                Transport Charges :
                              </Typography>
                              <TextField
                                type={"number"}
                                size="small"
                                placeholder="forwarding"
                                variant="outlined"
                                name="forwarding"
                                value={total_transport_charge}
                                sx={{ width: "70%", background: "#fff" }}
                                onChange={(e) => onchangeInput(e)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
						
						
						
						
							
						 <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                          sx={{ mt: 3 }}
                        >
                          <Grid xs={4}>
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography fontWeight="700" marginRight="10px">
                               Total Tax :
                              </Typography>
                              <TextField
                                type={"number"}
                                size="small"
                                placeholder="total_tax"
                                variant="outlined"
								 InputProps={{
                                  readOnly: true,
                                }}
                                name="total_tax"
                                value={details.total_tax?.toFixed(2)}
                                sx={{ width: "70%", background: "#fff" }}
                                onChange={(e) => onchangeInput(e)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
						
						
						 
						
						    <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                          sx={{ mt: 3 }}
                        >
                          <Grid xs={4}>
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography fontWeight="700" marginRight="10px">
                               Rounding:
                              </Typography>
                              <TextField
                                type={"number"}
                                size="small"
                                placeholder="rounding"
                                variant="outlined"
                                name="rounding"
                                value={details.rounding}
                                sx={{ width: "70%", background: "#fff" }}
                                onChange={(e) => onchangeInput(e)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
						
						
						
						
						
						
						
						
                        <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                          sx={{ mt: 3 }}
                        >
                          <Grid xs={4}>
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography fontWeight="700" marginRight="10px">
                                Total :
                              </Typography>
                              <TextField
                                type={"number"}
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="outlined-read-only-input"
                                size="small"
                                placeholder="0.0"
                                variant="outlined"
                                name="total"
                                value={details.total}
                                sx={{ width: "70%", background: "#fff" }}
                                onChange={(e) => onchangeInput(e)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid lg={7} md={12} item>
                          <div
                            className="form-control"
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <DialogContentText mb={2}>
                              Select Bank Account
                            </DialogContentText>
                            <Autocomplete
                              size="small"
                              value={bank}
                              options={getBank}
                              getOptionLabel={(option) => option}
                              // defaultValue={bank}
                              onChange={(e, v) => setBank(v)}
                              sx={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  width="100%"
                                  onChange={({ target }) =>
                                    setBank(target.value)
                                  }
                                  variant="outlined"
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid container sx={{ mt: 3 }}>
                          <Grid container lg={12}>
                            <TextFieldComponent
                              heading="Terms and Condition"
                              name="terms_and_condition"
                              type="text"
                              sales={
                                demo.dispatch_status === "Order Dispatched"
                                  ? true
                                  : false
                              }
                              error={
                                errors.terms_and_condition &&
                                touched.terms_and_condition
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 3 }}>
                          <Grid container lg={12}>
                            <TextFieldComponent
                              heading="Notes"
                              name="notes"
                              type="text"
                              sales={
                                demo.dispatch_status === "Order Dispatched"
                                  ? true
                                  : false
                              }
                              error={errors.notes && touched.notes}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          justifyContent="flex-end"
                          sx={{ mt: 3 }}
                        >
                          <Button
                            variant="contained"
                            sx={{ mr: 2 }}
                            color="secondary"
                            type="submit"
                          >
                            <Add sx={{ mr: 1 }} />
                            Update
                          </Button>
                          <Button
                            variant="contained"
                            onClick={returnHandle}
                            color="error"
                          >
                            <Cancel sx={{ mr: 1 }} />
                            Cancel
                          </Button>
                        </Grid>
                      </Item>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default EditTaxInvoice;

const defaultData = [
  {
    value: "item",
  },
  {
    value: "add_Desc",
  },
  {
    value: "quantity",
  },
  {
    value: "rate",
  },
  {
    value: "discount",
  },
   {
    value: "tr_charge",
  },
  {
    value: "tax",
  },
   {
    value: "tax_amount",
  },
  {
    value: "amount",
  },
];
