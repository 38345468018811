import {
  SLIDER_REQUEST,
  SLIDER_SUCCESS,
  SLIDER_FAIL,
  NEW_SLIDER_REQUEST,
  NEW_SLIDER_SUCCESS,
  NEW_SLIDER_FAIL,
  NEW_SLIDER_RESET,
  UPDATE_SLIDER_REQUEST,
  UPDATE_SLIDER_SUCCESS,
  UPDATE_SLIDER_FAIL,
  UPDATE_SLIDER_RESET,
  DELETE_SLIDER_REQUEST,
  DELETE_SLIDER_SUCCESS,
  DELETE_SLIDER_FAIL,
  DELETE_SLIDER_RESET,
  CUST_GROUP_REQUEST,
  CUST_GROUP_SUCCESS,
  CUST_GROUP_FAIL,
  NEW_CUST_GROUP_REQUEST,
  NEW_CUST_GROUP_SUCCESS,
  NEW_CUST_GROUP_FAIL,
  NEW_CUST_GROUP_RESET,
  UPDATE_CUST_GROUP_REQUEST,
  UPDATE_CUST_GROUP_SUCCESS,
  UPDATE_CUST_GROUP_FAIL,
  UPDATE_CUST_GROUP_RESET,
  DELETE_CUST_GROUP_REQUEST,
  DELETE_CUST_GROUP_SUCCESS,
  DELETE_CUST_GROUP_FAIL,
  DELETE_CUST_GROUP_RESET,
  CUST_SUB_GROUP_REQUEST,
  CUST_SUB_GROUP_SUCCESS,
  CUST_SUB_GROUP_FAIL,
  NEW_CUST_SUB_GROUP_REQUEST,
  NEW_CUST_SUB_GROUP_SUCCESS,
  NEW_CUST_SUB_GROUP_FAIL,
  NEW_CUST_SUB_GROUP_RESET,
  UPDATE_CUST_SUB_GROUP_REQUEST,
  UPDATE_CUST_SUB_GROUP_SUCCESS,
  UPDATE_CUST_SUB_GROUP_FAIL,
  UPDATE_CUST_SUB_GROUP_RESET,
  DELETE_CUST_SUB_GROUP_REQUEST,
  DELETE_CUST_SUB_GROUP_SUCCESS,
  DELETE_CUST_SUB_GROUP_FAIL,
  DELETE_CUST_SUB_GROUP_RESET,
  CLEAR_ERRORS,
  } from "../constants/EcommerceConstants";
  
  // slider reducers
export const sliderReducer = (state = { slider: [] }, action) => {
    switch (action.type) {
      case SLIDER_REQUEST:
        return { loading: true, slider: [] };
      case SLIDER_SUCCESS:
        return {
          ...state,
          loading: false,
          slider: action.payload,
        };
      case SLIDER_FAIL:
        return {
          ...state,
          loading: false,
          slider: null,
          error: action.payload,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  export const newSliderReducer = (state = { newslider: {} }, action) => {
    switch (action.type) {
      case NEW_SLIDER_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_SLIDER_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
          newsslider: action.payload.response,
        };
      case NEW_SLIDER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_SLIDER_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  export const updateDeletSliderReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_SLIDER_REQUEST:
      case UPDATE_SLIDER_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case DELETE_SLIDER_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: action.payload,
        };
  
      case UPDATE_SLIDER_SUCCESS:
        return {
          ...state,
          loading: false,
          isUpdated: action.payload,
        };
      case DELETE_SLIDER_FAIL:
      case UPDATE_SLIDER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case DELETE_SLIDER_RESET:
        return {
          ...state,
          isDeleted: false,
        };
      case UPDATE_SLIDER_RESET:
        return {
          ...state,
          isUpdated: false,
        };
        
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  // custGroup reducers
export const custGroupReducer = (state = { custGroup: [] }, action) => {
  switch (action.type) {
    case CUST_GROUP_REQUEST:
      return { loading: true, custGroup: [] };
    case CUST_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        custGroup: action.payload,
      };
    case CUST_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        custGroup: null,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const newCustGroupReducer = (state = { newcustGroup: {} }, action) => {
  switch (action.type) {
    case NEW_CUST_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_CUST_GROUP_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newscustGroup: action.payload.response,
      };
    case NEW_CUST_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_CUST_GROUP_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletCustGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CUST_GROUP_REQUEST:
    case UPDATE_CUST_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CUST_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_CUST_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_CUST_GROUP_FAIL:
    case UPDATE_CUST_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_CUST_GROUP_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_CUST_GROUP_RESET:
      return {
        ...state,
        isUpdated: false,
      };
      
      
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
// custSUBGroup reducers
export const custSubGroupReducer = (state = { custSubGroup: [] }, action) => {
  switch (action.type) {
    case CUST_SUB_GROUP_REQUEST:
      return { loading: true, custSubGroup: [] };
    case CUST_SUB_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        custSubGroup: action.payload,
      };
    case CUST_SUB_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        custGroup: null,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const newCustSubGroupReducer = (state = { newcustSubGroup: {} }, action) => {
  switch (action.type) {
    case NEW_CUST_SUB_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_CUST_SUB_GROUP_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newscustSubGroup: action.payload.response,
      };
    case NEW_CUST_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_CUST_SUB_GROUP_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateDeletCustSubGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CUST_SUB_GROUP_REQUEST:
    case UPDATE_CUST_SUB_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CUST_SUB_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_CUST_SUB_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_CUST_SUB_GROUP_FAIL:
    case UPDATE_CUST_SUB_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_CUST_SUB_GROUP_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_CUST_SUB_GROUP_RESET:
      return {
        ...state,
        isUpdated: false,
      };
      
      
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};