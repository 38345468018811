import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import TextError from "./TextError";
import { Button, DialogContentText, Divider } from "@mui/material";
import { ErrorMessage } from "formik";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  createBand,
  createProductAccount,
  createProductCategory,
  createProductGroup,
  createProductSegment,
  createProductSpecifications,
  createProductType,
  createProductUnit,
} from "../../../Redux/actions/InventryActions";
import { useDispatch, useSelector } from "react-redux";
import BasicAutoComplete from "../AutoComplete";
const AutoCompleteSelect = ({
  name,
  heading,
  data,
  values,
  handleChange,
  setFieldValue,
  icon,
  sales,
}) => {
  let city = data.find((r) => r.city === values);
  let state = data.find((r) => r.state === values);
  let country = data.find((r) => r.country === values);
  let val = data.find((r) => r.name === values);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
        }}
      >
        <DialogContentText>{heading}</DialogContentText>
        {icon ? (
          <>
            <AddIcon
              style={{ color: "blue", cursor: "pointer" }}
              onClick={handleClickOpen}
            />
            {heading === "Brand" ? (
              <DialogBrands open={open} setOpen={setOpen} />
            ) : heading === "Product Unit" ? (
              <DialogProductUnit open={open} setOpen={setOpen} />
            ) : heading === "Product Type" ? (
              <DialogProductType open={open} setOpen={setOpen} />
            ) : heading === "Product Group" ? (
              <DialogProductGroup open={open} setOpen={setOpen} />
            ) : heading === "Product Category" ? (
              <DialogProductCategory open={open} setOpen={setOpen} />
            ) : heading === "Product Account" ? (
              <DialogProductAccount open={open} setOpen={setOpen} />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
      <Autocomplete
        name={name}
        size="small"
        options={data}
        disabled={sales ? true : false}
        defaultValue={
          name === "city"
            ? city
            : name === "country"
            ? country
            : name === "state"
            ? state
            : val
        }
        getOptionLabel={(option) =>
          name === "city"
            ? option.city
            : name === "state"
            ? option.state
            : name === "country"
            ? option.country
            : option.name
        }
        style={{ width: "90%" }}
        onChange={(event, value) => {
          setFieldValue(
            name,
            name === "city"
              ? value.city
              : name === "state"
              ? value.state
              : name === "country"
              ? value.country
              : value.name
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleChange}
            margin="dense"
            style={{
              width: "100%",
            }}
            value={values}
          />
        )}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};
export default AutoCompleteSelect;

const DialogProductCategory = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { productGroup } = useSelector((state) => state.productGroup);
  const [name, setName] = React.useState({
    name: "",
    item_group: "",
    image: "",
  });
  const [productGroupName, setProductGroupName] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const [file, setFile] = React.useState("");
  const handleCreateProductCategory = (e) => {
    e.preventDefault();
    dispatch(
      createProductCategory(
        JSON.stringify({
          name: name.name,
          item_group: productGroupName,
          image:
            name.image === ""
              ? "https://www.wallpapertip.com/wmimgs/0-2408_4k-wallpaper-for-pc-8-hd-wallpaper-4k.jpg"
              : name.image,
        })
      )
    );
    setOpen(false);
    setName("");
    setProductGroupName("");
  };

  React.useEffect(() => {
    const getImage = async () => {
      if (file) {
        let data = new FormData();
        data.append("name", file.name);
        data.append("file", file);
        const image = await fetch(
          `https://erpapi.flaskitsolutions.com/api/v1/upload/user-image`,
          {
            method: "POST",
            body: data,
          }
        );
        const json = await image.json();
        name.image = json;
      }
    };
    getImage();
  }, [file]);

  const handleChange = (e) => {
    setName({ ...name, [e.target.name]: e.target.value });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText>Product Category</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          sx={{ mt: 2 }}
          onChange={(e) => handleChange(e)}
        />
        <DialogContentText>Product Group</DialogContentText>
        <BasicAutoComplete
          data={productGroup.map((data) => data.name)}
          name="product group"
          setInput={setProductGroupName}
          val={productGroupName}
        />
        <input
          accept="image/*"
          type="file"
          id="fileInput"
          onChange={(e) => setFile(e.target.files[0])}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={handleCreateProductCategory}>
          Add
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogBrands = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [name, setName] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleCreateBrand = (e) => {
    e.preventDefault();
    dispatch(createBand({ name: name }));
    setOpen(false);
    setName("");
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText>Brand Name</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="brand"
          label="Brand Name"
          type="text"
          size="small"
          fullWidth
          variant="outlined"
          sx={{ mt: 2 }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={handleCreateBrand}>
          Add
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogProductUnit = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [name, setName] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleCreateBrand = (e) => {
    e.preventDefault();
    dispatch(createProductUnit({ name: name }));
    setOpen(false);
    setName("");
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText>Product Unit</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Product Unit"
          type="text"
          size="small"
          fullWidth
          variant="outlined"
          sx={{ mt: 2 }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={handleCreateBrand}>
          Add
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogProductType = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [name, setName] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleCreateBrand = (e) => {
    e.preventDefault();
    dispatch(createProductType({ name: name }));
    setOpen(false);
    setName("");
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText>Product Type</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Product Type"
          type="text"
          size="small"
          fullWidth
          variant="outlined"
          sx={{ mt: 2 }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={handleCreateBrand}>
          Add
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogProductGroup = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [name, setName] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const initialPost = {
    image: "",
  };
  const [file, setFile] = React.useState("");
  const [post, setPost] = React.useState(initialPost);
  React.useEffect(() => {
    const getImage = async () => {
      if (file) {
        let data = new FormData();
        data.append("name", file.name);
        data.append("file", file);
        const image = await fetch(
          `https://erpapi.flaskitsolutions.com/api/v1/upload/user-image`,
          {
            method: "POST",
            body: data,
          }
        );
        const json = await image.json();
        post.image = json;
      }
    };
    getImage();
  }, [file]);

  const handleCreateProductSegment = (e) => {
    e.preventDefault();
    dispatch(
      createProductGroup(
        JSON.stringify({
          name,
          image:
            post.image === ""
              ? "https://www.wallpapertip.com/wmimgs/0-2408_4k-wallpaper-for-pc-8-hd-wallpaper-4k.jpg"
              : post.image,
        })
      )
    );
    setOpen(false);
    setName("");
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText>Product Group</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Product Group"
          type="text"
          size="small"
          fullWidth
          variant="outlined"
          sx={{ mt: 2 }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          accept="image/*"
          type="file"
          id="fileInput"
          onChange={(e) => setFile(e.target.files[0])}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={handleCreateProductSegment}>
          Add
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogProductAccount = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [name, setName] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleCreateBrand = (e) => {
    e.preventDefault();
    dispatch(createProductAccount({ name: name }));
    setOpen(false);
    setName("");
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText>Product Account</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Product Account"
          type="text"
          size="small"
          fullWidth
          variant="outlined"
          sx={{ mt: 2 }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={handleCreateBrand}>
          Add
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
