import React, { useEffect } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import DrawerErp from "../Layout/DrawerErp";
import UsersTable from "./UsersTable";
import {
  getUser,
  clearErrors,
  createUser,
} from "../../Redux/actions/UserActions";
import { NEW_USER_RESET } from "../../Redux/constants/UserConstants";
import {
  IconButton,
  Divider,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  InputBase,
  Stack,
  Toolbar,
  Box,
  Grid,
  Typography,
  Paper,
  Button,
} from "@mui/material";
import { Close, Search, Add } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import UserTable from "./UserTable";
import Excle from "../Common/Excle";

const SearchBox = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#e7ebf0",
  "&:hover": {
    backgroundColor: "#e7ebf0",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: center;
      padding: 6px;
    }
  
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
      padding:16px
    }
    `
);
const data = {
  username: "",
  password: "",
  master: {
    view: false,
    create: false,
    edit: false,
    delete: false,
  },
  warehouse: {
    view: false,
    create: false,
    edit: false,
    delete: false,
  },
  vendor: {
    view: false,
    create: false,
    edit: false,
    delete: false,
  },
  customer: {
    view: false,
    create: false,
    edit: false,
    delete: false,
  },
  inventory: {
    view: false,
    create: false,
    edit: false,
    delete: false,
  },
  purchase: {
    view: false,
    create: false,
    edit: false,
    delete: false,
  },
  sales: {
    view: false,
    create: false,
    edit: false,
    delete: false,
  },
  report: {
    view: false,
    create: false,
    edit: false,
    delete: false,
  },
  ecommerce: {
    view: false,
    create: false,
    edit: false,
    delete: false,
  },
  admin: {
    view: false,
    create: false,
    edit: false,
    delete: false,
  },
};
const Users = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState(data);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName(data);
  };

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { loading, error, success } = useSelector((state) => state.newUser);
  const { error: adminError } = useSelector((state) => state.user);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getUser());
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
    if (success) {
      alert.success("User Added Successfully");
      dispatch({ type: NEW_USER_RESET });
      dispatch(getUser());
      setName(data);
    }
  }, [dispatch, navigate, error, alert, isAuthenticated, success]);

  const handleCreateUser = (e) => {
    e.preventDefault();
    dispatch(createUser(name));
    setOpen(false);
  };

  const setUpdatedata = (e, ind, last) => {
    console.log(ind, last);
    if (e.target.name === "username" || e.target.name === "password") {
      setName((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    } else {
      setName((prevState) => ({
        ...prevState,
        [ind]: {
          ...prevState[ind],
          [e.target.name]: name[ind][last] === true ? false : true,
        },
      }));
    }
  };
  const { user } = useSelector((state) => state.user);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              ERP Users
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "0 24px", mt: "0" }}>
          {adminError ? (
            <Grid container xs={12} justifyContent="center" alignItems="center">
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "red" }}
              >
                {adminError}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Item>
                <Grid container justifyContent="flex-end">
                  <Button variant="contained" onClick={handleClickOpen}>
                    <Add />
                    Add
                  </Button>
                  <Dialog open={open} onClose={handleClose} fullWidth="true">
                    <DialogTitle>
                      <Toolbar style={{ padding: 0 }}>
                        <Typography
                          sx={{ flex: 1 }}
                          variant="h6"
                          component="div"
                        >
                          Add New
                        </Typography>

                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={handleClose}
                          aria-label="close"
                          sx={{ ml: 0 }}
                        >
                          <Close />
                        </IconButton>
                      </Toolbar>
                    </DialogTitle>

                    <Divider />
                    <DialogContent>
                      <DialogContentText>User Name</DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        label="User Name"
                        name="username"
                        type="text"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2 }}
                        // value={}
                        onChange={(e) => setUpdatedata(e)}
                      />
                      <DialogContentText>Password</DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        label="Password"
                        name="password"
                        type="text"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2, mb: 2 }}
                        // value={udatedata}
                        onChange={(e) => setUpdatedata(e)}
                      />
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Module</TableCell>
                              <TableCell align="right">Add</TableCell>
                              <TableCell align="right">Delete</TableCell>
                              <TableCell align="right">Edit</TableCell>
                              <TableCell align="right">Show</TableCell>
                            </TableRow>
                          </TableHead>
                          <UserTable
                            data={name}
                            setUpdatedata={setUpdatedata}
                          />
                        </Table>
                      </TableContainer>
                    </DialogContent>

                    <Divider />
                    <DialogActions>
                      <Button onClick={handleCreateUser} variant="contained">
                        Add
                      </Button>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                      >
                        Close
                      </Button>
                    </DialogActions>
                    <Divider />
                  </Dialog>
                </Grid>
                <Grid container justifyContent="space-between" sx={{ mt: 8 }}>
                  <Stack direction="row" spacing={1}>
                    <Button variant="contained" sx={{ textTransform: "none" }}>
                      Copy
                    </Button>
                    <Excle
                      headCells={headCells}
                      data={user}
                      name="user"
                      btn="CSV"
                      select="Excle"
                    />
                    <Excle
                      headCells={headCells}
                      data={user}
                      name="user"
                      btn="Export"
                      select="Excle"
                    />
                    <Button variant="contained" sx={{ textTransform: "none" }}>
                      PDF
                    </Button>
                    <Excle
                      headCells={headCells}
                      data={user}
                      name="user"
                      select="print"
                    />
                  </Stack>
                  <Stack>
                    <SearchBox>
                      <SearchIconWrapper>
                        <Search />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </SearchBox>
                  </Stack>
                </Grid>
                <Root sx={{ mt: 2 }}>
                  <UsersTable />
                </Root>
              </Item>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default Users;

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "user name",
    numeric: false,
    disablePadding: true,
    label: "User Name",
  },
  {
    id: "password",
    numeric: false,
    disablePadding: true,
    label: "Password",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];