import React from "react";
import { ErrorMessage, useField, Field } from "formik";
import TextError from "./TextError";
import { DialogContentText, TextField } from "@mui/material";

export const TextFieldComponent = ({
  heading,
  name,
  type,
  error,
  value,
  handleChange,
  sales,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <DialogContentText mb={1}>{heading}</DialogContentText>
      <Field
        type={type}
        name={name}
        disabled={sales ? true : false}
        as={name === "terms_and_condition" ? "textarea" : ""}
        placeholder={heading}
        style={{
          textTransform:name === "email" ? "lowercase" : "uppercase",
          width: name === "terms_and_condition" || name === "notes" ? "100%" : "95%",
          height:name === "terms_and_condition" ? "60px" : "40px",
          border: error ? "1.5px solid red" : "1px solid gray",
          borderRadius: "5px",
          padding: "0 10px",
          outline: "none",
        }}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};
