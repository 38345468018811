import {
  BRAND_FAIL,
  BRAND_REQUEST,
  BRAND_SUCCESS,
  DELETE_BRAND_FAIL,
  DELETE_BRAND_REQUEST,
  DELETE_BRAND_RESET,
  DELETE_BRAND_SUCCESS,
  NEW_BRAND_FAIL,
  NEW_BRAND_REQUEST,
  NEW_BRAND_RESET,
  NEW_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_RESET,
  UPDATE_BRAND_SUCCESS,
  DELETE_PRODUCTUNIT_FAIL,
  DELETE_PRODUCTUNIT_REQUEST,
  DELETE_PRODUCTUNIT_SUCCESS,
  NEW_PRODUCTUNIT_FAIL,
  NEW_PRODUCTUNIT_REQUEST,
  NEW_PRODUCTUNIT_RESET,
  NEW_PRODUCTUNIT_SUCCESS,
  PRODUCTUNIT_FAIL,
  PRODUCTUNIT_REQUEST,
  PRODUCTUNIT_SUCCESS,
  UPDATE_PRODUCTUNIT_FAIL,
  UPDATE_PRODUCTUNIT_REQUEST,
  UPDATE_PRODUCTUNIT_RESET,
  UPDATE_PRODUCTUNIT_SUCCESS,
  DELETE_PRODUCTUNIT_RESET,
  PRODUCT_SPECIFICATIONS_REQUEST,
  PRODUCT_SPECIFICATIONS_SUCCESS,
  PRODUCT_SPECIFICATIONS_FAIL,
  UPDATE_PRODUCT_SPECIFICATIONS_REQUEST,
  UPDATE_PRODUCT_SPECIFICATIONS_SUCCESS,
  UPDATE_PRODUCT_SPECIFICATIONS_FAIL,
  UPDATE_PRODUCT_SPECIFICATIONS_RESET,
  DELETE_PRODUCT_SPECIFICATIONS_REQUEST,
  DELETE_PRODUCT_SPECIFICATIONS_SUCCESS,
  DELETE_PRODUCT_SPECIFICATIONS_FAIL,
  DELETE_PRODUCT_SPECIFICATIONS_RESET,
  NEW_PRODUCT_SPECIFICATIONS_REQUEST,
  NEW_PRODUCT_SPECIFICATIONS_SUCCESS,
  NEW_PRODUCT_SPECIFICATIONS_FAIL,
  NEW_PRODUCT_SPECIFICATIONS_RESET,
  PRODUCTTYPE_REQUEST,
  PRODUCTTYPE_SUCCESS,
  PRODUCTTYPE_FAIL,
  NEW_PRODUCTTYPE_REQUEST,
  NEW_PRODUCTTYPE_SUCCESS,
  NEW_PRODUCTTYPE_FAIL,
  NEW_PRODUCTTYPE_RESET,
  DELETE_PRODUCTTYPE_REQUEST,
  UPDATE_PRODUCTTYPE_REQUEST,
  DELETE_PRODUCTTYPE_SUCCESS,
  UPDATE_PRODUCTTYPE_SUCCESS,
  DELETE_PRODUCTTYPE_FAIL,
  UPDATE_PRODUCTTYPE_FAIL,
  DELETE_PRODUCTTYPE_RESET,
  UPDATE_PRODUCTTYPE_RESET,
  PRODUCTSEGMENT_REQUEST,
  PRODUCTSEGMENT_SUCCESS,
  PRODUCTSEGMENT_FAIL,
  NEW_PRODUCTSEGMENT_REQUEST,
  NEW_PRODUCTSEGMENT_SUCCESS,
  NEW_PRODUCTSEGMENT_FAIL,
  NEW_PRODUCTSEGMENT_RESET,
  DELETE_PRODUCTSEGMENT_REQUEST,
  UPDATE_PRODUCTSEGMENT_REQUEST,
  DELETE_PRODUCTSEGMENT_SUCCESS,
  UPDATE_PRODUCTSEGMENT_SUCCESS,
  DELETE_PRODUCTSEGMENT_FAIL,
  UPDATE_PRODUCTSEGMENT_FAIL,
  DELETE_PRODUCTSEGMENT_RESET,
  UPDATE_PRODUCTSEGMENT_RESET,
  PRODUCTACCOUNT_REQUEST,
  PRODUCTACCOUNT_SUCCESS,
  PRODUCTACCOUNT_FAIL,
  NEW_PRODUCTACCOUNT_REQUEST,
  NEW_PRODUCTACCOUNT_SUCCESS,
  NEW_PRODUCTACCOUNT_FAIL,
  NEW_PRODUCTACCOUNT_RESET,
  UPDATE_PRODUCTACCOUNT_REQUEST,
  UPDATE_PRODUCTACCOUNT_SUCCESS,
  UPDATE_PRODUCTACCOUNT_FAIL,
  UPDATE_PRODUCTACCOUNT_RESET,
  DELETE_PRODUCTACCOUNT_REQUEST,
  DELETE_PRODUCTACCOUNT_SUCCESS,
  DELETE_PRODUCTACCOUNT_FAIL,
  DELETE_PRODUCTACCOUNT_RESET,
  PRODUCT_CATEGORY_FAIL,
  PRODUCT_CATEGORY_REQUEST,
  PRODUCT_CATEGORY_SUCCESS,
  DELETE_PRODUCT_CATEGORY_FAIL,
  DELETE_PRODUCT_CATEGORY_REQUEST,
  DELETE_PRODUCT_CATEGORY_RESET,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  NEW_PRODUCT_CATEGORY_FAIL,
  NEW_PRODUCT_CATEGORY_REQUEST,
  NEW_PRODUCT_CATEGORY_RESET,
  NEW_PRODUCT_CATEGORY_SUCCESS,
  UPDATE_PRODUCT_CATEGORY_FAIL,
  UPDATE_PRODUCT_CATEGORY_REQUEST,
  UPDATE_PRODUCT_CATEGORY_RESET,
  UPDATE_PRODUCT_CATEGORY_SUCCESS,
  PRODUCTGROUP_REQUEST,
  PRODUCTGROUP_SUCCESS,
  PRODUCTGROUP_FAIL,
  NEW_PRODUCTGROUP_REQUEST,
  NEW_PRODUCTGROUP_SUCCESS,
  NEW_PRODUCTGROUP_FAIL,
  NEW_PRODUCTGROUP_RESET,
  DELETE_PRODUCTGROUP_REQUEST,
  UPDATE_PRODUCTGROUP_REQUEST,
  DELETE_PRODUCTGROUP_SUCCESS,
  UPDATE_PRODUCTGROUP_SUCCESS,
  DELETE_PRODUCTGROUP_FAIL,
  UPDATE_PRODUCTGROUP_FAIL,
  DELETE_PRODUCTGROUP_RESET,
  UPDATE_PRODUCTGROUP_RESET,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  NEW_ADD_PRODUCT_REQUEST,
  NEW_ADD_PRODUCT_SUCCESS,
  NEW_ADD_PRODUCT_FAIL,
  NEW_ADD_PRODUCT_RESET,
  DELETE_ADD_PRODUCT_REQUEST,
  UPDATE_ADD_PRODUCT_REQUEST,
  DELETE_ADD_PRODUCT_SUCCESS,
  UPDATE_ADD_PRODUCT_SUCCESS,
  DELETE_ADD_PRODUCT_FAIL,
  UPDATE_ADD_PRODUCT_FAIL,
  DELETE_ADD_PRODUCT_RESET,
  UPDATE_ADD_PRODUCT_RESET,
  EDIT_PRODUCT_LIST,
  DELETE_END_PRODUCT_REQUEST,
  UPDATE_END_PRODUCT_REQUEST,
  DELETE_END_PRODUCT_SUCCESS,
  UPDATE_END_PRODUCT_SUCCESS,
  DELETE_END_PRODUCT_FAIL,
  UPDATE_END_PRODUCT_FAIL,
  DELETE_END_PRODUCT_RESET,
  UPDATE_END_PRODUCT_RESET,
  END_PRODUCT_REQUEST,
  END_PRODUCT_SUCCESS,
  END_PRODUCT_FAIL,
} from "../constants/InventryConstant";
import { CLEAR_ERRORS } from "../constants/MastersConstants";
// Brand Reducers
export const brandReducer = (state = { brands: [] }, action) => {
  switch (action.type) {
    case BRAND_REQUEST:
      return { loading: true, brands: [] };
    case BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        brands: action.payload,
      };

    case BRAND_FAIL:
      return {
        ...state,
        loading: false,
        brands: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Create Brand
export const newBrandReducer = (state = { newBrands: {} }, action) => {
  switch (action.type) {
    case NEW_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_BRAND_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newBrands: action.payload.response,
      };
    case NEW_BRAND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_BRAND_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Update Delete Brand
export const updateDeleteBranchReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_BRAND_REQUEST:
    case UPDATE_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_BRAND_FAIL:
    case UPDATE_BRAND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_BRAND_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_BRAND_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get productUnit
export const productUnitReducer = (state = { productUnit: [] }, action) => {
  switch (action.type) {
    case PRODUCTUNIT_REQUEST:
      return { loading: true, productUnit: [] };
    case PRODUCTUNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        productUnit: action.payload,
      };

    case PRODUCTUNIT_FAIL:
      return {
        ...state,
        loading: false,
        productUnit: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Create product unit
export const newProductUnitReducer = (
  state = { newproductUnit: {} },
  action
) => {
  switch (action.type) {
    case NEW_PRODUCTUNIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_PRODUCTUNIT_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newproductUnit: action.payload.response,
      };
    case NEW_PRODUCTUNIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_PRODUCTUNIT_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Update Delete product unit
export const updateDeleteProductUnitReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCTUNIT_REQUEST:
    case UPDATE_PRODUCTUNIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRODUCTUNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_PRODUCTUNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PRODUCTUNIT_FAIL:
    case UPDATE_PRODUCTUNIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PRODUCTUNIT_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_PRODUCTUNIT_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Product Type
export const productTypeReducer = (state = { productType: [] }, action) => {
  switch (action.type) {
    case PRODUCTTYPE_REQUEST:
      return { loading: true, productType: [] };
    case PRODUCTTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        productType: action.payload,
      };

    case PRODUCTTYPE_FAIL:
      return {
        ...state,
        loading: false,
        productType: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Create product type
export const newProductTypeReducer = (
  state = { newproductType: {} },
  action
) => {
  switch (action.type) {
    case NEW_PRODUCTTYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_PRODUCTTYPE_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newproductType: action.payload.response,
      };
    case NEW_PRODUCTTYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_PRODUCTTYPE_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Update Delete product type
export const updateDeleteProductTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCTTYPE_REQUEST:
    case UPDATE_PRODUCTTYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRODUCTTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_PRODUCTTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PRODUCTTYPE_FAIL:
    case UPDATE_PRODUCTTYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PRODUCTTYPE_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_PRODUCTTYPE_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get ProductSegment
export const productSegmentReducer = (
  state = { productSegmente: [] },
  action
) => {
  switch (action.type) {
    case PRODUCTSEGMENT_REQUEST:
      return { loading: true, productSegmente: [] };
    case PRODUCTSEGMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        productSegmente: action.payload,
      };

    case PRODUCTSEGMENT_FAIL:
      return {
        ...state,
        loading: false,
        productSegmente: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Create ProductSegment
export const newProductSegmentReducer = (
  state = { newproductSegment: {} },
  action
) => {
  switch (action.type) {
    case NEW_PRODUCTSEGMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_PRODUCTSEGMENT_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newproductSegment: action.payload.response,
      };
    case NEW_PRODUCTSEGMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_PRODUCTSEGMENT_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Update Delete ProductSegment
export const updateDeleteProductSegmentReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCTSEGMENT_REQUEST:
    case UPDATE_PRODUCTSEGMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRODUCTSEGMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_PRODUCTSEGMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PRODUCTSEGMENT_FAIL:
    case UPDATE_PRODUCTSEGMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PRODUCTSEGMENT_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_PRODUCTSEGMENT_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get ProductGroup
export const productGroupReducer = (state = { productGroup: [] }, action) => {
  switch (action.type) {
    case PRODUCTGROUP_REQUEST:
      return { loading: true, productGroup: [] };
    case PRODUCTGROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        productGroup: action.payload,
      };

    case PRODUCTGROUP_FAIL:
      return {
        ...state,
        loading: false,
        productGroup: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Create ProductGroup
export const newProductGroupReducer = (
  state = { newproductGroup: {} },
  action
) => {
  switch (action.type) {
    case NEW_PRODUCTGROUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_PRODUCTGROUP_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newproductGroup: action.payload.response,
      };
    case NEW_PRODUCTGROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_PRODUCTGROUP_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Update Delete ProductGroup
export const updateDeleteProductGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCTGROUP_REQUEST:
    case UPDATE_PRODUCTGROUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRODUCTGROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_PRODUCTGROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PRODUCTGROUP_FAIL:
    case UPDATE_PRODUCTGROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PRODUCTGROUP_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_PRODUCTGROUP_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Product Specification Reducers
export const productSpecificationReducer = (
  state = { productSpecification: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_SPECIFICATIONS_REQUEST:
      return { loading: true, productSpecification: [] };
    case PRODUCT_SPECIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        productSpecification: action.payload,
      };

    case PRODUCT_SPECIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        productSpecification: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Create Product Specification
export const newProductSpecificationReducer = (
  state = { newProductSpecification: {} },
  action
) => {
  switch (action.type) {
    case NEW_PRODUCT_SPECIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_PRODUCT_SPECIFICATIONS_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newProductSpecification: action.payload.response,
      };
    case NEW_PRODUCT_SPECIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_PRODUCT_SPECIFICATIONS_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Update Delete Product Specification
export const updateDeleteProductSpecificationReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_SPECIFICATIONS_REQUEST:
    case UPDATE_PRODUCT_SPECIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRODUCT_SPECIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_PRODUCT_SPECIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PRODUCT_SPECIFICATIONS_FAIL:
    case UPDATE_PRODUCT_SPECIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PRODUCT_SPECIFICATIONS_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_PRODUCT_SPECIFICATIONS_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Product Account Reducers
export const productAccountReducer = (
  state = { productAccount: [] },
  action
) => {
  switch (action.type) {
    case PRODUCTACCOUNT_REQUEST:
      return { loading: true, productAccount: [] };
    case PRODUCTACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        productAccount: action.payload,
      };

    case PRODUCTACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        productAccount: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Create Product Account
export const newProductAccountReducer = (
  state = { newProductAccount: {} },
  action
) => {
  switch (action.type) {
    case NEW_PRODUCTACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_PRODUCTACCOUNT_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newProductAccount: action.payload.response,
      };
    case NEW_PRODUCTACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_PRODUCTACCOUNT_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Update Delete Product Account
export const updateDeleteProductAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCTACCOUNT_REQUEST:
    case UPDATE_PRODUCTACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRODUCTACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_PRODUCTACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PRODUCTACCOUNT_FAIL:
    case UPDATE_PRODUCTACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PRODUCTACCOUNT_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_PRODUCTACCOUNT_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
// PRODUCTCATEGORY Reducers
export const productCategoryReducer = (
  state = { productCategory: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_CATEGORY_REQUEST:
      return { loading: true, productCategory: [] };
    case PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        productCategory: action.payload,
      };

    case PRODUCT_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        productCategory: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Create PRODUCTCATEGORY
export const newProductCategoryReducer = (
  state = { newProductCategory: {} },
  action
) => {
  switch (action.type) {
    case NEW_PRODUCT_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_PRODUCT_CATEGORY_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newBrands: action.payload.response,
      };
    case NEW_PRODUCT_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_PRODUCT_CATEGORY_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Update Delete PRODUCT Category
export const updateDeleteProductCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_CATEGORY_REQUEST:
    case UPDATE_PRODUCT_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PRODUCT_CATEGORY_FAIL:
    case UPDATE_PRODUCT_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PRODUCT_CATEGORY_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_PRODUCT_CATEGORY_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// PRODUCT LIst Reducers
export const productListReducer = (state = { product: [] }, action) => {
  switch (action.type) {
    case ADD_PRODUCT_REQUEST:
      return { loading: true, product: [] };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload,
      };

    case ADD_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        product: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Create PRODUCTCATEGORY
export const newProductListReducer = (
  state = { newProductList: {} },
  action
) => {
  switch (action.type) {
    case NEW_ADD_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_ADD_PRODUCT_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newProductList: action.payload.response,
      };
    case NEW_ADD_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_ADD_PRODUCT_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Update Delete PRODUCT Category
export const updateDeleteProductListReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ADD_PRODUCT_REQUEST:
    case UPDATE_ADD_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_ADD_PRODUCT_FAIL:
    case UPDATE_ADD_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_ADD_PRODUCT_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_ADD_PRODUCT_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// PRODUCT LIst Reducers
export const EndproductListReducer = (
  state = { endProductList: [] },
  action
) => {
  switch (action.type) {
    case END_PRODUCT_REQUEST:
      return { loading: true, endProductList: [] };
    case END_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        endProductList: action.payload,
      };

    case END_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        endProductList: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// Update Delete PRODUCT Category
export const updateDeleteEndProductListReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_END_PRODUCT_REQUEST:
    case UPDATE_END_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_END_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_END_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_END_PRODUCT_FAIL:
    case UPDATE_END_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_END_PRODUCT_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_END_PRODUCT_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
