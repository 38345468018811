import { Grid, Typography, Paper, Button } from "@mui/material";
import React, { useEffect } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import EnquiryReportTable from "./EnquiryReportTable";
import { clearErrors, getSlider } from "../../../Redux/actions/EcommerceAction";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import "../../Common/style.css";
import jsPDF from "jspdf";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#e7ebf0",
  "&:hover": {
    backgroundColor: "#e7ebf0",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: center;
      padding: 6px;
    }
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
      padding:16px
    } `
);

const EnquiryReport = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const { error, isAuthenticated } = useSelector((state) => state.admin);
  const [searchInput, setSearchInput] = React.useState("");
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getSlider());
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [dispatch, navigate, error, alert, isAuthenticated]);

  // Multiple Button Functions
  const [change, setChange] = React.useState(false);
  const generatePDF = () => {
    setChange(true);
    var doc = new jsPDF("p", "pt", "a4");
    doc.html(document.querySelector("#content"), {
      callback: function (pdf) {
        // var pageCount = doc.internal.getNumberOfPages();
        // pdf.deletePage(pageCount)
        pdf.save("data.pdf");
        setChange(false);
      },
    });
  };
  const ref = React.createRef();
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }, [setLoading]);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "data",
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);

  return (
    <>
      <Grid container sx={{ p: 3 }}>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            EnquiryReport
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: "0 24px", mt: "0" }}>
        <Grid item xs={12}>
          <Item>
            <Grid container justifyContent="space-between" sx={{ mt: 8 }}>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="contained"
                  id="copy_btn"
                  sx={{ textTransform: "none" }}
                >
                  Copy
                </Button>
                <Button variant="contained" sx={{ textTransform: "none" }}>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    table="table-to-xls"
                    filename="data"
                    sheet="tablexls"
                    className="download-table-xls-button"
                    buttonText="CSV"
                  />
                </Button>
                <Button variant="contained" sx={{ textTransform: "none" }}>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    table="table-to-xls"
                    filename="data"
                    sheet="tablexls"
                    className="download-table-xls-button"
                    buttonText="Excel"
                  />
                </Button>
                <Button variant="contained" onClick={generatePDF}>
                  PDF
                </Button>
                <Button
                  variant="contained"
                  onClick={handlePrint}
                  sx={{ textTransform: "none" }}
                >
                  Print
                </Button>
              </Stack>
              <Stack>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => setSearchInput(e.target.value)}
                    value={searchInput}
                  />
                </Search>
              </Stack>
            </Grid>
            <Root sx={{ mt: 2 }}>
              <EnquiryReportTable
                searchInput={searchInput}
                change={change}
                componentRef={componentRef}
              />
            </Root>
          </Item>
        </Grid>
      </Grid>
    </>
  );
};
export default EnquiryReport;
