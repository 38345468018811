import { Grid, Typography, Paper, Button } from "@mui/material";
import React, { useEffect } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Link, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { getEndProductList } from "../../../Redux/actions/InventryActions";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EndProductListTable from "./EndProductListTable";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Excle from "../../Common/Excle";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#e7ebf0",
  "&:hover": {
    backgroundColor: "#e7ebf0",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: center;
      padding: 6px;
    }
  
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
      padding:16px
    }
    `
);

const EndProductList = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = React.useState("");
  const { endProductList } = useSelector(
    (state) => state.endProductList
  );
  const { isAuthenticated } = useSelector((state) => state.admin);
  // const { loading, error, success } = useSelector((state) => state.newProduct);
  const { error: adminError } = useSelector((state) => state.endProductList);
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getEndProductList());
    }
    // if (error) {
    //   alert.error(error);
    //   dispatch(clearErrors());
    // }
    // if (success) {
    //   alert.success("Product Added Successfully");
    //   dispatch({ type: NEW_ADD_PRODUCT_RESET });
    //   dispatch(getEnd());
    // }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [dispatch, navigate, alert, isAuthenticated]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        {adminError ? (
          <Grid container xs={12} justifyContent="center" alignItems="center">
            <Typography variant="h5" sx={{ fontWeight: "bold", color: "red" }}>
              You don't have the required permissions.
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid container sx={{ p: 3 }}>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", marginBottom: "8px" }}
                >
                  End Product List
                </Typography>
              </Grid>
              <Grid container>
                <Link
                  to={"/inventory/add_product"}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <Button variant="contained" style={{ marginRight: "10px" }}>
                    <AddIcon />
                    Add Product
                  </Button>
                </Link>

                <Button variant="contained" style={{ background: "green" }}>
                  <ContentCopyIcon />
                  <Excle
                    headCells={headCells}
                    data={endProductList}
                    name="product"
                    btn="Export"
                    color="transparent"
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid container sx={{ padding: "0 24px", mt: "0" }}>
              <Grid item xs={12}>
                <Item>
                  <Grid container justifyContent="flex-end" sx={{ mt: 8 }}>
                    <Stack>
                      <Search>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          onChange={(e) => setSearchInput(e.target.value)}
                          value={searchInput}
                        />
                      </Search>
                    </Stack>
                  </Grid>
                  <Root sx={{ mt: 2 }}>
                    <EndProductListTable searchInput={searchInput} />
                  </Root>
                </Item>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};
export default EndProductList;

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "Product Code",
    numeric: false,
    disablePadding: true,
    label: "Product Code",
  },
  {
    id: "Name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "Category",
    numeric: false,
    disablePadding: true,
    label: "Category",
  },
  {
    id: "Warehouse Location",
    numeric: false,
    disablePadding: true,
    label: "Warehouse Location",
  },
  {
    id: "MRP",
    numeric: false,
    disablePadding: true,
    label: "MRP",
  },
  {
    id: "Prices",
    numeric: false,
    disablePadding: true,
    label: "Prices",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];