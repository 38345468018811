import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { mainListItems } from "../Dashboard/listItems";
import { Menu, MenuItem, Link , Button} from "@mui/material";
import { PowerSettingsNew, ChevronRight } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { logout } from "../../Redux/actions/adminActions";
import { useNavigate } from "react-router-dom";

const drawerWidth = 270;
const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(0)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(0)} + 1px)`,
	},
});

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

function Dashboard() {
	const theme = useTheme();
	const alert = useAlert();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { error, loading, isAuthenticated } = useSelector(
		(state) => state.admin,
	);
	const [open, setOpen] = useState(true);
	const [click, setClick] = useState(false);
	const toggleDrawer = () => {
		setOpen(!open);
	};

	const handleClick = (event) => {
		setClick(event.currentTarget);
	};

	const handleClose = () => {
		setClick(false);
	};

	const handleLogout = () => {
		dispatch(logout());
		alert.success("Logout Successfully");
		navigate("/login");
	};

	useEffect(() => {
		if (error) {
			alert.error(error);
		}
		if (!localStorage.getItem('user')) {
			navigate("/login");
		}
	}, [dispatch, navigate, error, alert, isAuthenticated]);

	return (
		<>
			<AppBar open={open} style={{ zIndex: "1", position: "fixed" }}>
				<Toolbar
					sx={{
						pr: "24px",
					}}
				>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={toggleDrawer}
						sx={{
							marginRight: "20px",
							...(open && { display: "none" }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<Typography
						component="h1"
						variant="h6"
						color="inherit"
						noWrap
						sx={{ flexGrow: 1 }}
					>
					 <Button style={{ color:'yellow' }} onClick={() => navigate(-1)} >go back</Button> 	Dashboard
					</Typography>
					<Link onClick={handleClick}>
						<IconButton color="inherit" style={{ background: "#ff9e00" }}>
							<LightbulbIcon sx={{ color: "white" }} />
						</IconButton>
					</Link>
					<Menu
						anchorEl={click}
						open={Boolean(click)}
						onClose={handleClose}
						sx={{ mt: 2, mr: 4 }}
					>
						<MenuItem
							onClick={() => {
								handleClose();
								handleLogout();
							}}
						>
							<PowerSettingsNew fontSize="small" color="primary" />
							<Typography sx={{ fontSize: "14px", ml: "20px" }}>
								Logout
							</Typography>
						</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={open}>
				<DrawerHeader>
					<IconButton onClick={toggleDrawer}>
						{theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeftIcon />}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<List>{mainListItems}</List>
			</Drawer>
		</>
	);
}

export default Dashboard;
