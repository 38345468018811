import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteDialog from "../../Common/DeleteDialog";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, TablePagination } from "@mui/material";
import TableExpand from "../../Common/TableExpand";
function Row(props) {
  const {
    row,
    handleExpandClick,
    collapse,
    handleClickOpenDelete,
    setDelete,
    id,
    deleteHandler,
    index,
    countCollapse,
    deleteOpen,
    expandData,
    text,
    update,
  } = props;
  console.log(text, "text");
  return (
    <React.Fragment>
      <TableRow>
        <TableCell scope="row" padding="none">
          {index + 1}
        </TableCell>

        <TableCell scope="row" padding="none">
          {row?.company_name}
        </TableCell>
        <TableCell scope="row" padding="none">
          {row.name}
        </TableCell>

        <TableCell scope="row" padding="none">
          {row.email}
        </TableCell>

        <TableCell scope="row" padding="none">
          {row.mobile}
        </TableCell>
		
		 <TableCell scope="row" padding="none">
          {row.group}
        </TableCell>

		<TableCell scope="row" padding="none">
          {row.sub_group}
        </TableCell>

        <TableCell scope="row" padding="none">
          {row.gst_treatment}
        </TableCell>

        <TableCell scope="row" padding="none">
          {row.gst_number}
        </TableCell>

        <TableCell padding="none" scope="row">
          <IconButton onClick={() => handleExpandClick(index, row, 1)}>
            <KeyboardArrowUpIcon
              style={{
                transform:
                  index === collapse && countCollapse === 1
                    ? ""
                    : "rotate(180deg)",
              }}
            />
          </IconButton>
        </TableCell>

        <TableCell padding="none" scope="row">
          <IconButton onClick={() => handleExpandClick(index, row, 2)}>
            <KeyboardArrowUpIcon
              style={{
                transform:
                  index === collapse && countCollapse === 2
                    ? ""
                    : "rotate(180deg)",
              }}
            />
          </IconButton>
        </TableCell>

        <TableCell padding="none" scope="row">
          <IconButton onClick={() => handleExpandClick(index, row, 3)}>
            <KeyboardArrowUpIcon
              style={{
                transform:
                  index === collapse && countCollapse === 3
                    ? ""
                    : "rotate(180deg)",
              }}
            />
          </IconButton>
        </TableCell>

        <TableCell scope="row" padding="none">
          <Link to={`${update}/${row._id}`} style={{ textDecoration: "none" }}>
            <Button>
              <EditIcon color="primary" />
            </Button>
          </Link>
          <Button>
            <DeleteIcon
              onClick={() => handleClickOpenDelete(row._id)}
              color="error"
            />
          </Button>
          <DeleteDialog
            setDelete={setDelete}
            deleteOpen={deleteOpen}
            id={id}
            deleteHandler={deleteHandler}
          />
        </TableCell>
      </TableRow>
      {index === collapse ? (
        <TableRow>
          <TableCell style={{ padding: "10px" }} colSpan={11}>
            <TableExpand expandData={expandData} text={text} />
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
}

export default function CollapsibleTable({
  customers,
  headCells,
  handleExpandClick,
  collapse,
  handleClickOpenDelete,
  setDelete,
  id,
  deleteHandler,
  countCollapse,
  deleteOpen,
  expandData,
  text,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPageOptions,
  update,
}) {
  return (
    <Box>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {headCells.map((r, index) => {
                  return <TableCell align="right">{r.label}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {customers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <Row
                      row={row}
                      index={index}
                      handleExpandClick={handleExpandClick}
                      collapse={collapse}
                      handleClickOpenDelete={handleClickOpenDelete}
                      setDelete={setDelete}
                      id={id}
                      deleteHandler={deleteHandler}
                      countCollapse={countCollapse}
                      deleteOpen={deleteOpen}
                      expandData={expandData}
                      text={text}
                      update={update}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={customers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </Paper>
    </Box>
  );
}
