import {
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAIL,
  CLEAR_ERRORS,
} from "../constants/DashboardConstants";
import axios from "axios";

// GET dashboard
export const getDashboard = () => async (dispatch) => {
  try {
    dispatch({ type: DASHBOARD_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    let link = `https://erpapi.flaskitsolutions.com/api/v1/admin/dashboard/sale_purchase`;
    const { data } = await axios.get(link,config);
    dispatch({ type: DASHBOARD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DASHBOARD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
