import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../../Redux/actions/EcommerceAction";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import Loader from "../../Common/Loader";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "order id",
    numeric: false,
    disablePadding: true,
    label: "Order id",
  },
  {
    id: "total amount",
    numeric: false,
    disablePadding: true,
    label: "Total Amount",
  },
  {
    id: "customer name",
    numeric: false,
    disablePadding: true,
    label: "Customer Name",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: true,
    label: "Phone",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: "Address",
  },
  {
    id: "pincode",
    numeric: false,
    disablePadding: true,
    label: "Pincode",
  },
  {
    id: "pickup type",
    numeric: false,
    disablePadding: true,
    label: "Pickup Type",
  },
  {
    id: "payment method",
    numeric: false,
    disablePadding: true,
    label: "Payment Method",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "date time",
    numeric: false,
    disablePadding: true,
    label: "Date Time",
  },
  {
    id: "updating",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={headCell.numeric ? "right" : "left"}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Sales
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function SliderTable({ searchInput, componentRef, change }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("state");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [open, setOpen] = React.useState(false);
  const [updatedata, setUpdatedata] = React.useState("");
  const [udateId, setUpdateId] = React.useState("");

  const handleClickOpen = (state, id) => {
    setOpen(true);
    setUpdatedata(state);
    // console.log(updatedata);
    setUpdateId(id);
  };
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, loading, slider } = useSelector((state) => state.slider);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [dispatch, navigate, error, alert, isAuthenticated]);

  let updatedArray = slider.filter(
    (e) => e.name.toLowerCase().search(searchInput.toLowerCase().trim()) !== -1
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - slider.length) : 0;

  var perPageData = [];
  var length = Math.ceil(slider.length / 3);

  for (var i = 1; i <= length; i++) {
    perPageData.push(i * 3);
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{ width: "100%", mb: 2, boxShadow: "none", border: "none" }}
          >
            <EnhancedTableToolbar />
            <TableContainer>
              <div
                ref={componentRef}
                id="content"
                className={change ? "content" : ""}
              >
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  id="table-to-xls"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={updatedArray.length}
                  />
                  <TableBody>
                    {stableSort(updatedArray, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((slider, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow>
                            <TableCell
                              id={labelId}
                              className={change ? "pop" : ""}
                              scope="row"
                              padding="none"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              id={labelId}
                              className={change ? "pop" : ""}
                              scope="row"
                              padding="none"
                            >
                              {slider.name}
                            </TableCell>
                            <TableCell
                              id={labelId}
                              className={change ? "pop" : ""}
                              scope="row"
                              padding="none"
                            >
                              {slider.name}
                            </TableCell>
                            <TableCell
                              id={labelId}
                              className={change ? "pop" : ""}
                              scope="row"
                              padding="none"
                            >
                              {slider.name}
                            </TableCell>
                            <TableCell
                              id={labelId}
                              className={change ? "pop" : ""}
                              scope="row"
                              padding="none"
                            >
                              {slider.name}
                            </TableCell>
                            <TableCell
                              id={labelId}
                              className={change ? "pop" : ""}
                              scope="row"
                              padding="none"
                            >
                              {slider.name}
                            </TableCell>
                            <TableCell
                              id={labelId}
                              className={change ? "pop" : ""}
                              scope="row"
                              padding="none"
                            >
                              {slider.name}
                            </TableCell>
                            <TableCell
                              id={labelId}
                              className={change ? "pop" : ""}
                              scope="row"
                              padding="none"
                            >
                              {slider.name}
                            </TableCell>
                            <TableCell
                              id={labelId}
                              className={change ? "pop" : ""}
                              scope="row"
                              padding="none"
                            >
                              {slider.name}
                            </TableCell>
                            <TableCell
                              style={{ backgroundColor: "yellow" }}
                              id={labelId}
                              className={change ? "pop" : ""}
                              scope="row"
                              padding="none"
                            >
                              {slider.name}
                            </TableCell>
                            <TableCell
                              className={change ? "pop" : ""}
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              hello
                            </TableCell>
                            <TableCell
                              scope="row"
                              className={change ? "non2" : ""}
                              padding="none"
                            >
                              <DialogSales
                                open={open}
                                setOpen={setOpen}
                                udateId={udateId}
                                updatedata={updatedata}
                                setUpdatedata={setUpdatedata}
                              />
                              <Button
                                variant="contained"
                                onClick={handleClickOpen}
                              >
                                View
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={perPageData}
              component="div"
              count={updatedArray.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  );
}

const DialogRoot = styled("div")`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #ddd;
  }
`;

//Sales Dialog
const DialogSales = ({ open, setOpen, udateId, updatedata, setUpdatedata }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("lg");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle container xs={12} display="flex">
        <Grid container>
          <Typography variant="h6">Customer Product Details</Typography>
        </Grid>
        <Grid justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Grid container justifyContent="center" sx={{ mb: 1 }}>
        <Button variant="outlined">Print Table</Button>
      </Grid>
      <Divider />
      <Grid container xs={12} justifyContent="space-around" sx={{ mt: 3 }}>
        <Grid xs={4}>
          <Typography>Name:</Typography>
        </Grid>
        <Grid xs={4}>
          <Typography>Mobile:</Typography>
        </Grid>
      </Grid>
      <Grid container xs={12} justifyContent="space-around" sx={{ mt: 1 }}>
        <Grid container xs={4} alignItems="center">
          <Typography>Address:</Typography>
        </Grid>
        <Grid container xs={4} alignItems="center">
          <Typography>Status:</Typography>
          <Select size="small" sx={{ width: "50%" }}>
            <MenuItem>asdfghj</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid>
        <DialogRoot sx={{ ml: 2, mr: 2, mt: 1, mb: 1 }}>
          <Table>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Product</th>
                <th>Size</th>
                <th>Quantity</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
              </tr>
            </tbody>
          </Table>
        </DialogRoot>
      </Grid>
      <Grid>
        <DialogRoot sx={{ ml: 2, mr: 2, mt: 1, mb: 1 }}>
          <Table>
            <tbody>
              <tr>
                <TableCell variant="head" sx={{ width: "20%" }}>
                  Amount
                </TableCell>
                <TableCell></TableCell>
              </tr>
              <tr>
                <TableCell variant="head" sx={{ width: "20%" }}>
                  Discount
                </TableCell>
                <TableCell></TableCell>
              </tr>
              <tr>
                <TableCell variant="head" sx={{ width: "20%" }}>
                  Subtotal
                </TableCell>
                <TableCell></TableCell>
              </tr>
              <tr>
                <TableCell variant="head" sx={{ width: "20%" }}>
                  Delivery Charge
                </TableCell>
                <TableCell></TableCell>
              </tr>
              <tr>
                <TableCell
                  variant="head"
                  sx={{ width: "20%", fontWeight: "bold" }}
                >
                  Total Amount
                </TableCell>
                <TableCell></TableCell>
              </tr>
            </tbody>
          </Table>
        </DialogRoot>
      </Grid>
    </Dialog>
  );
};
