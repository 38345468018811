import {
    ADDCUSTOMERS_REQUEST,
    ADDCUSTOMERS_SUCCESS,
    ADDCUSTOMERS_FAIL,
    NEW_ADDCUSTOMERS_REQUEST,
    NEW_ADDCUSTOMERS_SUCCESS,
    NEW_ADDCUSTOMERS_FAIL,
    NEW_ADDCUSTOMERS_RESET,
    UPDATE_ADDCUSTOMERS_REQUEST,
    UPDATE_ADDCUSTOMERS_SUCCESS,
    UPDATE_ADDCUSTOMERS_FAIL,
    UPDATE_ADDCUSTOMERS_RESET,
    DELETE_ADDCUSTOMERS_REQUEST,
    DELETE_ADDCUSTOMERS_SUCCESS,
    DELETE_ADDCUSTOMERS_FAIL,
    DELETE_ADDCUSTOMERS_RESET,
    CUSTOMERCONTACTPERSON_REQUEST,
    CUSTOMERCONTACTPERSON_SUCCESS,
    CUSTOMERCONTACTPERSON_FAIL,
    NEW_CUSTOMERCONTACTPERSON_REQUEST,
    NEW_CUSTOMERCONTACTPERSON_SUCCESS,
    NEW_CUSTOMERCONTACTPERSON_FAIL,
    NEW_CUSTOMERCONTACTPERSON_RESET,
    UPDATE_CUSTOMERCONTACTPERSON_REQUEST,
    UPDATE_CUSTOMERCONTACTPERSON_SUCCESS,
    UPDATE_CUSTOMERCONTACTPERSON_FAIL,
    UPDATE_CUSTOMERCONTACTPERSON_RESET,
    DELETE_CUSTOMERCONTACTPERSON_REQUEST,
    DELETE_CUSTOMERCONTACTPERSON_SUCCESS,
    DELETE_CUSTOMERCONTACTPERSON_FAIL,
    DELETE_CUSTOMERCONTACTPERSON_RESET,
    CUSTOMERSHIPPINGADDRESS_REQUEST,
    CUSTOMERSHIPPINGADDRESS_SUCCESS,
    CUSTOMERSHIPPINGADDRESS_FAIL,
    NEW_CUSTOMERSHIPPINGADDRESS_REQUEST,
    NEW_CUSTOMERSHIPPINGADDRESS_SUCCESS,
    NEW_CUSTOMERSHIPPINGADDRESS_FAIL,
    NEW_CUSTOMERSHIPPINGADDRESS_RESET,
    UPDATE_CUSTOMERSHIPPINGADDRESS_REQUEST,
    UPDATE_CUSTOMERSHIPPINGADDRESS_SUCCESS,
    UPDATE_CUSTOMERSHIPPINGADDRESS_FAIL,
    UPDATE_CUSTOMERSHIPPINGADDRESS_RESET,
    DELETE_CUSTOMERSHIPPINGADDRESS_REQUEST,
    DELETE_CUSTOMERSHIPPINGADDRESS_SUCCESS,
    DELETE_CUSTOMERSHIPPINGADDRESS_FAIL,
    DELETE_CUSTOMERSHIPPINGADDRESS_RESET,
    CUSTOMERBILLINGADDRESS_REQUEST,
    CUSTOMERBILLINGADDRESS_SUCCESS,
    CUSTOMERBILLINGADDRESS_FAIL,
    NEW_CUSTOMERBILLINGADDRESS_REQUEST,
    NEW_CUSTOMERBILLINGADDRESS_SUCCESS,
    NEW_CUSTOMERBILLINGADDRESS_FAIL,
    NEW_CUSTOMERBILLINGADDRESS_RESET,
    UPDATE_CUSTOMERBILLINGADDRESS_REQUEST,
    UPDATE_CUSTOMERBILLINGADDRESS_SUCCESS,
    UPDATE_CUSTOMERBILLINGADDRESS_FAIL,
    UPDATE_CUSTOMERBILLINGADDRESS_RESET,
    DELETE_CUSTOMERBILLINGADDRESS_REQUEST,
    DELETE_CUSTOMERBILLINGADDRESS_SUCCESS,
    DELETE_CUSTOMERBILLINGADDRESS_FAIL,
    DELETE_CUSTOMERBILLINGADDRESS_RESET,
} from "../constants/CustomerConstants";
import { CLEAR_ERRORS } from "../constants/MastersConstants";



// Add Customer Reducers

// Get Customers
export const customerReducer = (state = { customers: [] }, action) => {
    switch (action.type) {
      case ADDCUSTOMERS_REQUEST:
        return { loading: true, customers: [] };
      case ADDCUSTOMERS_SUCCESS:
        return {
          ...state,
          loading: false,
          customers: action.payload,
        };
  
      case ADDCUSTOMERS_FAIL:
        return {
          ...state,
          loading: false,
          customers: null,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  // Create Customers
  export const newCustomerReducer = (state = { newCustomers: {} }, action) => {
    switch (action.type) {
      case NEW_ADDCUSTOMERS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_ADDCUSTOMERS_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
          newCustomers: action.payload.response,
        };
      case NEW_ADDCUSTOMERS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_ADDCUSTOMERS_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  // Update Delete Customers
  export const updateDeleteCustomerReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_ADDCUSTOMERS_REQUEST:
      case UPDATE_ADDCUSTOMERS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case DELETE_ADDCUSTOMERS_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: action.payload,
        };
  
      case UPDATE_ADDCUSTOMERS_SUCCESS:
        return {
          ...state,
          loading: false,
          isUpdated: action.payload,
        };
      case DELETE_ADDCUSTOMERS_FAIL:
      case UPDATE_ADDCUSTOMERS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case DELETE_ADDCUSTOMERS_RESET:
        return {
          ...state,
          isDeleted: false,
        };
      case UPDATE_ADDCUSTOMERS_RESET:
        return {
          ...state,
          isUpdated: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };





  // Customer Contact Person Reducers

// Get Customers contact Person
export const customerContactPersonReducer = (state = { contactPerson: [] }, action) => {
    switch (action.type) {
      case CUSTOMERCONTACTPERSON_REQUEST:
        return { loading: true, contactPerson: [] };
      case CUSTOMERCONTACTPERSON_SUCCESS:
        return {
          ...state,
          loading: false,
          contactPerson: action.payload,
        };
  
      case CUSTOMERCONTACTPERSON_FAIL:
        return {
          ...state,
          loading: false,
          contactPerson: null,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  // Create Customer Contact Person
  export const newCustomerContactPersonReducer = (state = { newContactPerson: {} }, action) => {
    switch (action.type) {
      case NEW_CUSTOMERCONTACTPERSON_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_CUSTOMERCONTACTPERSON_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
          newContactPerson: action.payload.response,
        };
      case NEW_CUSTOMERCONTACTPERSON_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_CUSTOMERCONTACTPERSON_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  // Update Delete Customer Contact Person
  export const updateDeleteCustomerContactPersonReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_CUSTOMERCONTACTPERSON_REQUEST:
      case UPDATE_CUSTOMERCONTACTPERSON_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case DELETE_CUSTOMERCONTACTPERSON_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: action.payload,
        };
  
      case UPDATE_CUSTOMERCONTACTPERSON_SUCCESS:
        return {
          ...state,
          loading: false,
          isUpdated: action.payload,
        };
      case DELETE_CUSTOMERCONTACTPERSON_FAIL:
      case UPDATE_CUSTOMERCONTACTPERSON_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case DELETE_CUSTOMERCONTACTPERSON_RESET:
        return {
          ...state,
          isDeleted: false,
        };
      case UPDATE_CUSTOMERCONTACTPERSON_RESET:
        return {
          ...state,
          isUpdated: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };




// Customer Shipping Address Reducers

// Get Customers Shipping Address
export const customerShippingAddressReducer = (state = { customerShippingAddress: [] }, action) => {
    switch (action.type) {
      case CUSTOMERSHIPPINGADDRESS_REQUEST:
        return { loading: true, customerShippingAddress: [] };
      case CUSTOMERSHIPPINGADDRESS_SUCCESS:
        return {
          ...state,
          loading: false,
          customerShippingAddress: action.payload,
        };
  
      case CUSTOMERSHIPPINGADDRESS_FAIL:
        return {
          ...state,
          loading: false,
          customerShippingAddress: null,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  // Create Customer Shipping Address
  export const newCustomerShippingAddressReducer = (state = { newCustomerShippingAddress: {} }, action) => {
    switch (action.type) {
      case NEW_CUSTOMERSHIPPINGADDRESS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_CUSTOMERSHIPPINGADDRESS_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
          newCustomerShippingAddress: action.payload.response,
        };
      case NEW_CUSTOMERSHIPPINGADDRESS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_CUSTOMERSHIPPINGADDRESS_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  // Update Delete Customer Shipping Address
  export const updateDeleteCustomerShippingAddressReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_CUSTOMERSHIPPINGADDRESS_REQUEST:
      case UPDATE_CUSTOMERSHIPPINGADDRESS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case DELETE_CUSTOMERSHIPPINGADDRESS_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: action.payload,
        };
  
      case UPDATE_CUSTOMERSHIPPINGADDRESS_SUCCESS:
        return {
          ...state,
          loading: false,
          isUpdated: action.payload,
        };
      case DELETE_CUSTOMERSHIPPINGADDRESS_FAIL:
      case UPDATE_CUSTOMERSHIPPINGADDRESS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case DELETE_CUSTOMERSHIPPINGADDRESS_RESET:
        return {
          ...state,
          isDeleted: false,
        };
      case UPDATE_CUSTOMERSHIPPINGADDRESS_RESET:
        return {
          ...state,
          isUpdated: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };





// Customer Billing Address Reducers

// Get Customers Billing Address
export const customerBillingAddressReducer = (state = { customerBillingAddress: [] }, action) => {
    switch (action.type) {
      case CUSTOMERBILLINGADDRESS_REQUEST:
        return { loading: true, customerBillingAddress: [] };
      case CUSTOMERBILLINGADDRESS_SUCCESS:
        return {
          ...state,
          loading: false,
          customerBillingAddress: action.payload,
        };
  
      case CUSTOMERBILLINGADDRESS_FAIL:
        return {
          ...state,
          loading: false,
          customerBillingAddress: null,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  // Create Customer Billing Address
  export const newCustomerBillingAddressReducer = (state = { newCustomerBillingAddress: {} }, action) => {
    switch (action.type) {
      case NEW_CUSTOMERBILLINGADDRESS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_CUSTOMERBILLINGADDRESS_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
          newCustomerBillingAddress: action.payload.response,
        };
      case NEW_CUSTOMERBILLINGADDRESS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_CUSTOMERBILLINGADDRESS_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  // Update Delete Customer Billing Address
  export const updateDeleteCustomerBillingAddressReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_CUSTOMERBILLINGADDRESS_REQUEST:
      case UPDATE_CUSTOMERBILLINGADDRESS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case DELETE_CUSTOMERBILLINGADDRESS_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: action.payload,
        };
  
      case UPDATE_CUSTOMERBILLINGADDRESS_SUCCESS:
        return {
          ...state,
          loading: false,
          isUpdated: action.payload,
        };
      case DELETE_CUSTOMERBILLINGADDRESS_FAIL:
      case UPDATE_CUSTOMERBILLINGADDRESS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case DELETE_CUSTOMERBILLINGADDRESS_RESET:
        return {
          ...state,
          isDeleted: false,
        };
      case UPDATE_CUSTOMERBILLINGADDRESS_RESET:
        return {
          ...state,
          isUpdated: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };