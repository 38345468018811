export const PURCHASE_ORDER_REQUEST = "PURCHASE_ORDER_REQUEST";
export const PURCHASE_ORDER_SUCCESS = "PURCHASE_ORDER_SUCCESS";
export const PURCHASE_ORDER_FAIL = "PURCHASE_ORDER_FAIL";

export const UPDATE_PURCHASE_ORDER_REQUEST = "UPDATE_PURCHASE_ORDER_REQUEST";
export const UPDATE_PURCHASE_ORDER_SUCCESS = "UPDATE_PURCHASE_ORDER_SUCCESS";
export const UPDATE_PURCHASE_ORDER_FAIL = "UPDATE_PURCHASE_ORDER_FAIL";
export const UPDATE_PURCHASE_ORDER_RESET = "UPDATE_PURCHASE_ORDER_RESET";

export const NEW_PURCHASE_ORDER_REQUEST = "NEW_PURCHASE_ORDER_REQUEST";
export const NEW_PURCHASE_ORDER_SUCCESS = "NEW_PURCHASE_ORDER_SUCCESS";
export const NEW_PURCHASE_ORDER_FAIL = "NEW_PURCHASE_ORDER_FAIL";
export const NEW_PURCHASE_ORDER_RESET = "NEW_PURCHASE_ORDER_RESET";


export const PURCHASE_ORDER_SINGLE_REQUEST = "PURCHASE_ORDER_SINGLE_REQUEST";
export const PURCHASE_ORDER_SINGLE_SUCCESS = "PURCHASE_ORDER_SINGLE_SUCCESS";
export const PURCHASE_ORDER_SINGLE_FAIL = "PURCHASE_ORDER_SINGLE_FAIL";