export const COUNTRY_REQUEST = "COUNTRY_REQUEST";
export const COUNTRY_SUCCESS = "COUNTRY_SUCCESS";
export const COUNTRY_FAIL = "COUNTRY_FAIL";

export const NEW_COUNTRY_REQUEST = "NEW_COUNTRY_REQUEST";
export const NEW_COUNTRY_SUCCESS = "NEW_COUNTRY_SUCCESS";
export const NEW_COUNTRY_FAIL = "NEW_COUNTRY_FAIL";
export const NEW_COUNTRY_RESET = "NEW_COUNTRY_RESET";

export const UPDATE_COUNTRY_REQUEST = "UPDATE_COUNTRY_REQUEST";
export const UPDATE_COUNTRY_SUCCESS = "UPDATE_COUNTRY_SUCCESS";
export const UPDATE_COUNTRY_FAIL = "UPDATE_COUNTRY_FAIL";
export const UPDATE_COUNTRY_RESET = "UPDATE_COUNTRY_RESET";

export const DELETE_COUNTRY_REQUEST = "DELETE_COUNTRY_REQUEST";
export const DELETE_COUNTRY_SUCCESS = "DELETE_COUNTRY_SUCCESS";
export const DELETE_COUNTRY_FAIL = "DELETE_COUNTRY_FAIL";
export const DELETE_COUNTRY_RESET = "DELETE_COUNTRY_RESET";









export const PRSECTION_REQUEST = "PRSECTION_REQUEST";
export const PRSECTION_SUCCESS = "PRSECTION_SUCCESS";
export const PRSECTION_FAIL = "PRSECTION_FAIL";

export const NEW_PRSECTION_REQUEST = "NEW_PRSECTION_REQUEST";
export const NEW_PRSECTION_SUCCESS = "NEW_PRSECTION_SUCCESS";
export const NEW_PRSECTION_FAIL = "NEW_PRSECTION_FAIL";
export const NEW_PRSECTION_RESET = "NEW_PRSECTION_RESET";

export const UPDATE_PRSECTION_REQUEST = "UPDATE_PRSECTION_REQUEST";
export const UPDATE_PRSECTION_SUCCESS = "UPDATE_PRSECTION_SUCCESS";
export const UPDATE_PRSECTION_FAIL = "UPDATE_PRSECTION_FAIL";
export const UPDATE_PRSECTION_RESET = "UPDATE_PRSECTION_RESET";

export const DELETE_PRSECTION_REQUEST = "DELETE_PRSECTION_REQUEST";
export const DELETE_PRSECTION_SUCCESS = "DELETE_PRSECTION_SUCCESS";
export const DELETE_PRSECTION_FAIL = "DELETE_PRSECTION_FAIL";
export const DELETE_PRSECTION_RESET = "DELETE_PRSECTION_RESET";




//State Constants

export const STATE_REQUEST = "STATE_REQUEST";
export const STATE_SUCCESS = "STATE_SUCCESS";
export const STATE_FAIL = "STATE_FAIL";

export const NEW_STATE_REQUEST = "NEW_STATE_REQUEST";
export const NEW_STATE_SUCCESS = "NEW_STATE_SUCCESS";
export const NEW_STATE_FAIL = "NEW_STATE_FAIL";
export const NEW_STATE_RESET = "NEW_STATE_RESET";

export const UPDATE_STATE_REQUEST = "UPDATE_STATE_REQUEST";
export const UPDATE_STATE_SUCCESS = "UPDATE_STATE_SUCCESS";
export const UPDATE_STATE_FAIL = "UPDATE_STATE_FAIL";
export const UPDATE_STATE_RESET = "UPDATE_STATE_RESET";

export const DELETE_STATE_REQUEST = "DELETE_STATE_REQUEST";
export const DELETE_STATE_SUCCESS = "DELETE_STATE_SUCCESS";
export const DELETE_STATE_FAIL = "DELETE_STATE_FAIL";
export const DELETE_STATE_RESET = "DELETE_STATE_RESET";

//City Constants

export const CITY_REQUEST = "CITY_REQUEST";
export const CITY_SUCCESS = "CITY_SUCCESS";
export const CITY_FAIL = "CITY_FAIL";

export const NEW_CITY_REQUEST = "NEW_CITY_REQUEST";
export const NEW_CITY_SUCCESS = "NEW_CITY_SUCCESS";
export const NEW_CITY_FAIL = "NEW_CITY_FAIL";
export const NEW_CITY_RESET = "NEW_CITY_RESET";

export const UPDATE_CITY_REQUEST = "UPDATE_CITY_REQUEST";
export const UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS";
export const UPDATE_CITY_FAIL = "UPDATE_CITY_FAIL";
export const UPDATE_CITY_RESET = "UPDATE_CITY_RESET";

export const DELETE_CITY_REQUEST = "DELETE_CITY_REQUEST";
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";
export const DELETE_CITY_FAIL = "DELETE_CITY_FAIL";
export const DELETE_CITY_RESET = "DELETE_CITY_RESET";

//Source of Supply constants
export const SOURCE_OF_SUPPLY_REQUEST = "SOURCE_OF_SUPPLY_REQUEST";
export const SOURCE_OF_SUPPLY_SUCCESS = "SOURCE_OF_SUPPLY_SUCCESS";
export const SOURCE_OF_SUPPLY_FAIL = "SOURCE_OF_SUPPLY_FAIL";

export const NEW_SOURCE_OF_SUPPLY_REQUEST = "NEW_SOURCE_OF_SUPPLY_REQUEST";
export const NEW_SOURCE_OF_SUPPLY_SUCCESS = "NEW_SOURCE_OF_SUPPLY_SUCCESS";
export const NEW_SOURCE_OF_SUPPLY_FAIL = "NEW_SOURCE_OF_SUPPLY_FAIL";
export const NEW_SOURCE_OF_SUPPLY_RESET = "NEW_SOURCE_OF_SUPPLY_RESET";

export const UPDATE_SOURCE_OF_SUPPLY_REQUEST =
  "UPDATE_SOURCE_OF_SUPPLY_REQUEST";
export const UPDATE_SOURCE_OF_SUPPLY_SUCCESS =
  "UPDATE_SOURCE_OF_SUPPLY_SUCCESS";
export const UPDATE_SOURCE_OF_SUPPLY_FAIL = "UPDATE_SOURCE_OF_SUPPLY_FAIL";
export const UPDATE_SOURCE_OF_SUPPLY_RESET = "UPDATE_SOURCE_OF_SUPPLY_RESET";

export const DELETE_SOURCE_OF_SUPPLY_REQUEST =
  "DELETE_SOURCE_OF_SUPPLY_REQUEST";
export const DELETE_SOURCE_OF_SUPPLY_SUCCESS =
  "DELETE_SOURCE_OF_SUPPLY_SUCCESS";
export const DELETE_SOURCE_OF_SUPPLY_FAIL = "DELETE_SOURCE_OF_SUPPLY_FAIL";
export const DELETE_SOURCE_OF_SUPPLY_RESET = "DELETE_SOURCE_OF_SUPPLY_RESET";

//Payment Terms constants
export const PAYMENT_TERMS_REQUEST = "PAYMENT_TERMS_REQUEST";
export const PAYMENT_TERMS_SUCCESS = "PAYMENT_TERMS_SUCCESS";
export const PAYMENT_TERMS_FAIL = "PAYMENT_TERMS_FAIL";

export const NEW_PAYMENT_TERMS_REQUEST = "NEW_PAYMENT_TERMS_REQUEST";
export const NEW_PAYMENT_TERMS_SUCCESS = "NEW_PAYMENT_TERMS_SUCCESS";
export const NEW_PAYMENT_TERMS_FAIL = "NEW_PAYMENT_TERMS_FAIL";
export const NEW_PAYMENT_TERMS_RESET = "NEW_PAYMENT_TERMS_RESET";

export const UPDATE_PAYMENT_TERMS_REQUEST = "UPDATE_PAYMENT_TERMS_REQUEST";
export const UPDATE_PAYMENT_TERMS_SUCCESS = "UPDATE_PAYMENT_TERMS_SUCCESS";
export const UPDATE_PAYMENT_TERMS_FAIL = "UPDATE_PAYMENT_TERMS_FAIL";
export const UPDATE_PAYMENT_TERMS_RESET = "UPDATE_PAYMENT_TERMS_RESET";

export const DELETE_PAYMENT_TERMS_REQUEST = "DELETE_PAYMENT_TERMS_REQUEST";
export const DELETE_PAYMENT_TERMS_SUCCESS = "DELETE_PAYMENT_TERMS_SUCCESS";
export const DELETE_PAYMENT_TERMS_FAIL = "DELETE_PAYMENT_TERMS_FAIL";
export const DELETE_PAYMENT_TERMS_RESET = "DELETE_PAYMENT_TERMS_RESET";





//PRODUCT Terms constants
export const PRODUCT_TERMS_REQUEST = "PRODUCT_TERMS_REQUEST";
export const PRODUCT_TERMS_SUCCESS = "PRODUCT_TERMS_SUCCESS";
export const PRODUCT_TERMS_FAIL = "PRODUCT_TERMS_FAIL";

export const NEW_PRODUCT_TERMS_REQUEST = "NEW_PRODUCT_TERMS_REQUEST";
export const NEW_PRODUCT_TERMS_SUCCESS = "NEW_PRODUCT_TERMS_SUCCESS";
export const NEW_PRODUCT_TERMS_FAIL = "NEW_PRODUCT_TERMS_FAIL";
export const NEW_PRODUCT_TERMS_RESET = "NEW_PRODUCT_TERMS_RESET";

export const UPDATE_PRODUCT_TERMS_REQUEST = "UPDATE_PRODUCT_TERMS_REQUEST";
export const UPDATE_PRODUCT_TERMS_SUCCESS = "UPDATE_PRODUCT_TERMS_SUCCESS";
export const UPDATE_PRODUCT_TERMS_FAIL = "UPDATE_PRODUCT_TERMS_FAIL";
export const UPDATE_PRODUCT_TERMS_RESET = "UPDATE_PRODUCT_TERMS_RESET";

export const DELETE_PRODUCT_TERMS_REQUEST = "DELETE_PRODUCT_TERMS_REQUEST";
export const DELETE_PRODUCT_TERMS_SUCCESS = "DELETE_PRODUCT_TERMS_SUCCESS";
export const DELETE_PRODUCT_TERMS_FAIL = "DELETE_PRODUCT_TERMS_FAIL";
export const DELETE_PRODUCT_TERMS_RESET = "DELETE_PRODUCT_TERMS_RESET";











//Gst Treatment constants
export const GST_TREATMENT_REQUEST = "GST_TREATMENT_REQUEST";
export const GST_TREATMENT_SUCCESS = "GST_TREATMENT_SUCCESS";
export const GST_TREATMENT_FAIL = "GST_TREATMENT_FAIL";

export const NEW_GST_TREATMENT_REQUEST = "NEW_GST_TREATMENT_REQUEST";
export const NEW_GST_TREATMENT_SUCCESS = "NEW_GST_TREATMENT_SUCCESS";
export const NEW_GST_TREATMENT_FAIL = "NEW_GST_TREATMENT_FAIL";
export const NEW_GST_TREATMENT_RESET = "NEW_GST_TREATMENT_RESET";

export const UPDATE_GST_TREATMENT_REQUEST = "UPDATE_GST_TREATMENT_REQUEST";
export const UPDATE_GST_TREATMENT_SUCCESS = "UPDATE_GST_TREATMENT_SUCCESS";
export const UPDATE_GST_TREATMENT_FAIL = "UPDATE_GST_TREATMENT_FAIL";
export const UPDATE_GST_TREATMENT_RESET = "UPDATE_GST_TREATMENT_RESET";

export const DELETE_GST_TREATMENT_REQUEST = "DELETE_GST_TREATMENT_REQUEST";
export const DELETE_GST_TREATMENT_SUCCESS = "DELETE_GST_TREATMENT_SUCCESS";
export const DELETE_GST_TREATMENT_FAIL = "DELETE_GST_TREATMENT_FAIL";
export const DELETE_GST_TREATMENT_RESET = "DELETE_GST_TREATMENT_RESET";
//BANK ACCOUNT constants
export const BANK_ACCOUNT_REQUEST = "BANK_ACCOUNT_REQUEST";
export const BANK_ACCOUNT_SUCCESS = "BANK_ACCOUNT_SUCCESS";
export const BANK_ACCOUNT_FAIL = "BANK_ACCOUNT_FAIL";

export const NEW_BANK_ACCOUNT_REQUEST = "NEW_BANK_ACCOUNT_REQUEST";
export const NEW_BANK_ACCOUNT_SUCCESS = "NEW_BANK_ACCOUNT_SUCCESS";
export const NEW_BANK_ACCOUNT_FAIL = "NEW_BANK_ACCOUNT_FAIL";
export const NEW_BANK_ACCOUNT_RESET = "NEW_BANK_ACCOUNT_RESET";

export const UPDATE_BANK_ACCOUNT_REQUEST = "UPDATE_BANK_ACCOUNT_REQUEST";
export const UPDATE_BANK_ACCOUNT_SUCCESS = "UPDATE_BANK_ACCOUNT_SUCCESS";
export const UPDATE_BANK_ACCOUNT_FAIL = "UPDATE_BANK_ACCOUNT_FAIL";
export const UPDATE_BANK_ACCOUNT_RESET = "UPDATE_BANK_ACCOUNT_RESET";

export const DELETE_BANK_ACCOUNT_REQUEST = "DELETE_BANK_ACCOUNT_REQUEST";
export const DELETE_BANK_ACCOUNT_SUCCESS = "DELETE_BANK_ACCOUNT_SUCCESS";
export const DELETE_BANK_ACCOUNT_FAIL = "DELETE_BANK_ACCOUNT_FAIL";
export const DELETE_BANK_ACCOUNT_RESET = "DELETE_BANK_ACCOUNT_RESET";
//dispatch app user constants
export const DISPATCH_APP_USER_REQUEST = "DISPATCH_APP_USER_REQUEST";
export const DISPATCH_APP_USER_SUCCESS = "DISPATCH_APP_USER_SUCCESS";
export const DISPATCH_APP_USER_FAIL = "DISPATCH_APP_USER_FAIL";

export const NEW_DISPATCH_APP_USER_REQUEST = "NEW_DISPATCH_APP_USER_REQUEST";
export const NEW_DISPATCH_APP_USER_SUCCESS = "NEW_DISPATCH_APP_USER_SUCCESS";
export const NEW_DISPATCH_APP_USER_FAIL = "NEW_DISPATCH_APP_USER_FAIL";
export const NEW_DISPATCH_APP_USER_RESET = "NEW_DISPATCH_APP_USER_RESET";

export const UPDATE_DISPATCH_APP_USER_REQUEST = "UPDATE_DISPATCH_APP_USER_REQUEST";
export const UPDATE_DISPATCH_APP_USER_SUCCESS = "UPDATE_DISPATCH_APP_USER_SUCCESS";
export const UPDATE_DISPATCH_APP_USER_FAIL = "UPDATE_DISPATCH_APP_USER_FAIL";
export const UPDATE_DISPATCH_APP_USER_RESET = "UPDATE_DISPATCH_APP_USER_RESET";

export const DELETE_DISPATCH_APP_USER_REQUEST = "DELETE_DISPATCH_APP_USER_REQUEST";
export const DELETE_DISPATCH_APP_USER_SUCCESS = "DELETE_DISPATCH_APP_USER_SUCCESS";
export const DELETE_DISPATCH_APP_USER_FAIL = "DELETE_DISPATCH_APP_USER_FAIL";
export const DELETE_DISPATCH_APP_USER_RESET = "DELETE_DISPATCH_APP_USER_RESET";
//MASTER MODULE constants
export const MASTER_MODULE_REQUEST = "MASTER_MODULE_REQUEST";
export const MASTER_MODULE_SUCCESS = "MASTER_MODULE_SUCCESS";
export const MASTER_MODULE_FAIL = "MASTER_MODULE_FAIL";

export const NEW_MASTER_MODULE_REQUEST = "NEW_MASTER_MODULE_REQUEST";
export const NEW_MASTER_MODULE_SUCCESS = "NEW_MASTER_MODULE_SUCCESS";
export const NEW_MASTER_MODULE_FAIL = "NEW_MASTER_MODULE_FAIL";
export const NEW_MASTER_MODULE_RESET = "NEW_MASTER_MODULE_RESET";

export const UPDATE_MASTER_MODULE_REQUEST = "UPDATE_MASTER_MODULE_REQUEST";
export const UPDATE_MASTER_MODULE_SUCCESS = "UPDATE_MASTER_MODULE_SUCCESS";
export const UPDATE_MASTER_MODULE_FAIL = "UPDATE_MASTER_MODULE_FAIL";
export const UPDATE_MASTER_MODULE_RESET = "UPDATE_MASTER_MODULE_RESET";

export const DELETE_MASTER_MODULE_REQUEST = "DELETE_MASTER_MODULE_REQUEST";
export const DELETE_MASTER_MODULE_SUCCESS = "DELETE_MASTER_MODULE_SUCCESS";
export const DELETE_MASTER_MODULE_FAIL = "DELETE_MASTER_MODULE_FAIL";
export const DELETE_MASTER_MODULE_RESET = "DELETE_MASTER_MODULE_RESET";
//classification constants
export const GST_CLASSIFICATION_REQUEST = "GST_CLASSIFICATION_REQUEST";
export const GST_CLASSIFICATION_SUCCESS = "GST_CLASSIFICATION_SUCCESS";
export const GST_CLASSIFICATION_FAIL = "GST_CLASSIFICATION_FAIL";

export const NEW_GST_CLASSIFICATION_REQUEST = "NEW_GST_CLASSIFICATION_REQUEST";
export const NEW_GST_CLASSIFICATION_SUCCESS = "NEW_GST_CLASSIFICATION_SUCCESS";
export const NEW_GST_CLASSIFICATION_FAIL = "NEW_GST_CLASSIFICATION_FAIL";
export const NEW_GST_CLASSIFICATION_RESET = "NEW_GST_CLASSIFICATION_RESET";

export const UPDATE_GST_CLASSIFICATION_REQUEST = "UPDATE_GST_CLASSIFICATION_REQUEST";
export const UPDATE_GST_CLASSIFICATION_SUCCESS = "UPDATE_GST_CLASSIFICATION_SUCCESS";
export const UPDATE_GST_CLASSIFICATION_FAIL = "UPDATE_GST_CLASSIFICATION_FAIL";
export const UPDATE_GST_CLASSIFICATION_RESET = "UPDATE_GST_CLASSIFICATION_RESET";

export const DELETE_GST_CLASSIFICATION_REQUEST = "DELETE_GST_CLASSIFICATION_REQUEST";
export const DELETE_GST_CLASSIFICATION_SUCCESS = "DELETE_GST_CLASSIFICATION_SUCCESS";
export const DELETE_GST_CLASSIFICATION_FAIL = "DELETE_GST_CLASSIFICATION_FAIL";
export const DELETE_GST_CLASSIFICATION_RESET = "DELETE_GST_CLASSIFICATION_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";


//Distance Constants

export const DISTANCE_REQUEST = "DISTANCE_REQUEST";
export const DISTANCE_SUCCESS = "DISTANCE_SUCCESS";
export const DISTANCE_FAIL = "DISTANCE_FAIL";

export const NEW_DISTANCE_REQUEST = "NEW_DISTANCE_REQUEST";
export const NEW_DISTANCE_SUCCESS = "NEW_DISTANCE_SUCCESS";
export const NEW_DISTANCE_FAIL = "NEW_DISTANCE_FAIL";
export const NEW_DISTANCE_RESET = "NEW_DISTANCE_RESET";

export const UPDATE_DISTANCE_REQUEST = "UPDATE_DISTANCE_REQUEST";
export const UPDATE_DISTANCE_SUCCESS = "UPDATE_DISTANCE_SUCCESS";
export const UPDATE_DISTANCE_FAIL = "UPDATE_DISTANCE_FAIL";
export const UPDATE_DISTANCE_RESET = "UPDATE_DISTANCE_RESET";

export const DELETE_DISTANCE_REQUEST = "DELETE_DISTANCE_REQUEST";
export const DELETE_DISTANCE_SUCCESS = "DELETE_DISTANCE_SUCCESS";
export const DELETE_DISTANCE_FAIL = "DELETE_DISTANCE_FAIL";
export const DELETE_DISTANCE_RESET = "DELETE_DISTANCE_RESET";