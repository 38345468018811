import * as React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Loading() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "82.5vh" }}
    >
      <Stack sx={{ color: "grey.500" }} spacing={4} direction="row">
        <CircularProgress size={isMobile ? 50 : 200} color="secondary" />
        <CircularProgress size={isMobile ? 50 : 200} color="success" />
        <CircularProgress size={isMobile ? 50 : 200} color="inherit" />
      </Stack>
    </Grid>
  );
}
