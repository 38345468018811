import { Grid, Typography, Paper } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import React, { useEffect } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import { getDashboard } from "../../Redux/actions/DashboardAction";
import { getDashboardList } from "../../Redux/actions/DashboardListAction";
import Loader from "../Common/Loader";

import { Link } from "react-router-dom";



const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: center;
      padding: 6px;
    }
  
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
      padding:16px
    }
    `
);

const SubDashboard = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const { error, isAuthenticated } = useSelector((state) => state.admin);
  const { dashboard } = useSelector((state) => state.dashboard);
  const { dashboardList, loading } = useSelector(
    (state) => state.dashboardList
  );
  const lastFivePr = dashboardList?.LastFivePurchase;
  const lastFiveSales = dashboardList?.LastFiveSales;
  useEffect(() => {
   if (localStorage.getItem('user')) {
      dispatch(getDashboard());
      dispatch(getDashboardList());
    }

   if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [dispatch, navigate, error, alert, isAuthenticated]);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6">Dashboard</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ padding: "0 24px" }}>
          <Grid item lg={3} sm={12} sx={{ width: "100%" }}>
		  
		  <Link
			to={"/purchase-orders"}   style={{ textDecoration: 'none' }} >
            <Item sx={{ backgroundColor: "orange", color: "white" }}>
              <Typography
                variant="h6"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                Todays Purchase
                <ArrowDownwardIcon />
              </Typography>
              <Typography variant="h5" sx={{ mt: 2 }}>
                {dashboard?.TotalTodayPurchase?.toFixed(2)}
              </Typography>
            </Item>
			</Link>
			
          </Grid>
          <Grid item lg={3} sm={12} sx={{ width: "100%" }}>
		  <Link
			to={"/purchase-orders"}   style={{ textDecoration: 'none' }} >
            <Item sx={{ backgroundColor: "red", color: "white" }}>
              <Typography
                variant="h6"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                Total Purchase <ArrowDownwardIcon />
              </Typography>
              <Typography variant="h5" sx={{ mt: 2 }}>
                {dashboard?.TotalPurchase?.toFixed(2)}
              </Typography>
            </Item>
			</Link>
          </Grid>
          <Grid item lg={3} sm={12} sx={{ width: "100%" }}>
		  <Link
			to={"/sales/tax-invoices"}   style={{ textDecoration: 'none' }} >
            <Item sx={{ backgroundColor: "#5e8bcd", color: "white" }}>
              <Typography
                variant="h6"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                Todays Sale <ArrowUpwardIcon />
              </Typography>
              <Typography variant="h5" sx={{ mt: 2 }}>
                {dashboard?.TotalTodaysales?.toFixed(2)}
              </Typography>
            </Item>
			</Link>
          </Grid>
          <Grid item lg={3} sm={12} sx={{ width: "100%" }}>
		  <Link
			to={"/sales/tax-invoices"}   style={{ textDecoration: 'none' }} >
            <Item sx={{ backgroundColor: "#298946", color: "white" }}>
              <Typography
                variant="h6"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                Total Sale <ArrowUpwardIcon />
              </Typography>
              <Typography variant="h5" sx={{ mt: 2 }}>
                {dashboard?.TotalSales?.toFixed(2)}
              </Typography>
            </Item>
			</Link>
          </Grid>
        </Grid>
        {loading ? (
          <Loader />
        ) : (
          <Grid container spacing={3} sx={{ padding: "0 24px", mt: "0" }}>
            <Grid item lg={6} sm={12}>
              <Item>
                <Typography variant="h6">Last 5 Purchase</Typography>
                <Root sx={{ mt: 2 }}>
                  <table aria-label="custom pagination table">
                    <thead>
                      <tr>
                        <th>Po Date</th>
                        <th>Po No</th>
                        <th>Vendor</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lastFivePr?.map((row) => {
                        return (
                          <tr>
                            <td>
                              {new Date(row?.date).toLocaleDateString(
                                "en-GB",
                                2
                              )}
                            </td>
                            <td style={{ width: 100 }} align="right">
                              {row?.po}
                            </td>
                            <td style={{ width: 250 }} align="right">
                              {row?.vendor}
                            </td>
                            <td style={{ width: 120 }} align="right">
                              {row?.total.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Root>
              </Item>
            </Grid>
            <Grid item lg={6} sm={12}>
              <Item>
                <Typography variant="h6">Last 5 Sale</Typography>
                <Root sx={{ mt: 2 }}>
                  <table aria-label="custom pagination table">
                    <thead>
                      <tr>
                        <th>Invoice Date</th>
                        <th>Invoice No</th>
                        <th>Customer</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lastFiveSales?.map((row) => (
                        <tr key={row?.invoice_number}>
                          <td>
                            {new Date(row?.date).toLocaleDateString("en-GB", 2)}
                          </td>
                          <td style={{ width: 120 }} align="right">
                            {row?.invoice_number}
                          </td>
                          <td style={{ width: 200 }} align="right">
                            {row?.customer}
                          </td>
                          <td style={{ width: 120 }} align="right">
                            {row?.total.toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Root>
              </Item>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={3} sx={{ padding: "0 24px", mt: 2 }}>
          <Grid
            item
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
		  
		  <Link
			to={"/customers/add_customers"}   style={{ textDecoration: 'none' }} >
			
            <Item sx={{ backgroundColor: "#4fc3f7", color: "white" }}>
              <Typography variant="h6">Total customer</Typography>
              <Typography variant="h5" sx={{ mt: 2 }}>
                {dashboard?.TotalCustomer}
              </Typography>
            </Item>
			
			</Link>
			
			
			<Link
			to={"/vendor/vendors"}   style={{ textDecoration: 'none' }} >
			
            <Item sx={{ backgroundColor: "#4fc3f7", color: "white" }}>
              <Typography variant="h6">Total Vendor</Typography>
              <Typography variant="h5" sx={{ mt: 2 }}>
                {dashboard?.TotalVendor}
              </Typography>
            </Item>
			</Link>
			
			<Link
			to={"/inventory/end_product_list"}   style={{ textDecoration: 'none' }} >
            <Item sx={{ backgroundColor: "#4fc3f7", color: "white" }}>
              <Typography variant="h6">Total Products</Typography>
              <Typography variant="h5" sx={{ mt: 2 }}>
                {dashboard?.TotalProducts}
              </Typography>
            </Item>
			</Link>
			
			
			<Link
			to={"/sales/tax-invoices"}   style={{ textDecoration: 'none' }} >
            <Item sx={{ backgroundColor: "#4fc3f7", color: "white" }}>
              <Typography variant="h6">Total Invoices</Typography>
              <Typography variant="h5" sx={{ mt: 2 }}>
                {dashboard?.TotalInvoices}
              </Typography>
            </Item>
			</Link>
			
			
			<Link
			to={"/purchase-orders"}   style={{ textDecoration: 'none' }} >
            <Item sx={{ backgroundColor: "#4fc3f7", color: "white" }}>
              <Typography variant="h6">Total PO</Typography>
              <Typography variant="h5" sx={{ mt: 2 }}>
                {dashboard?.TotalPO}
              </Typography>
            </Item>
			</Link>
			
			<Link
			to={"/users"}   style={{ textDecoration: 'none' }} >
            <Item sx={{ backgroundColor: "#4fc3f7", color: "white" }}>
              <Typography variant="h6">Total Users</Typography>
              <Typography variant="h5" sx={{ mt: 2 }}>
                {dashboard?.TotalUsers}
              </Typography>
            </Item>
			</Link>
			
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default SubDashboard;
