import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TableBody from "@mui/material/TableBody";
import { TextField, MenuItem, Select } from "@mui/material";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(181,201,255)",
    color: theme.palette.common.white,
    borderRight: "1px solid #fff",
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "2px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function AddPurchaseOrderTable({
  addChild,
  deleteRow,
  totalqn,
  defaultData,
  addChildInputField,
  setIsModal,
  onchangeInput,
  demo,
  di,
  sales,
}) {
  return (
    <TableContainer component={Paper} style={{ width: "100%" }}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {defaultData.map((item) => (
              <StyledTableCell
                sx={{
                  width:
                    item.value === "Item"
                      ? "35%"
                      : item.value === "Add.Desc"
                      ? "14%"
                      : "10%",
                }}
                align="center"
              >
                {item?.value}
              </StyledTableCell>
            ))}
			
			  
            <StyledTableCell align="center" sx={{ width: "1%" }}>
              {di ? "" : <AddIcon onClick={(e) => addChildInputField(e)} />}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addChild?.map((item, index) => {
            return (
              <StyledTableRow key={item?.value}>
                {defaultData.map((r, ind) => (
                  <StyledTableCell
                    align="center"
                    style={{ borderRight: "1px solid #fff" }}
                  >
                    {r?.value === "add_Desc" ? (
                      <TextField
                        type="text"
                        size="small"
                        disabled={item?.enable || di ? true : false}
                        id={
                          demo?.quantity === 0
                            ? "filled-disabled"
                            : "outlined-basic"
                        }
                        placeholder="Additional Description"
                        variant="outlined"
                        value={addChild[index]["add_Desc"]}
                        sx={{ width: "100%", background: "#fff" }}
                        onChange={(val) => onchangeInput(val, r?.value, index)}
                      />
                    ) : r?.value === "amount" ? (
                      <TextField
                        type="text"
                        size="small"
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-read-only-input"
                        placeholder={r?.value}
                        value={addChild[index]["amount"]}
                        variant="outlined"
                        sx={{ width: "100%", background: "#fff" }}
                        onChange={(val) => onchangeInput(val, r?.value, index)}
                      />
                    ) : r?.value === "item" ? (
                      <TextField
                        type="text"
                        size="small"
                        disabled={item?.enable || di ? true : false}
                        id={
                          demo?.quantity === 0
                            ? "filled-disabled"
                            : "outlined-basic"
                        }
                        placeholder="Type or Click to Select Item"
                        variant="outlined"
                        value={addChild[index]["item"]}
                        sx={{ width: "100%", background: "#fff" }}
                        onClick={() => setIsModal(true, index)}
                        onChange={(val) => onchangeInput(val, r?.value, index)}
                      />
                    ) : r?.value === "quantity" ? (
                      <TextField
                        InputProps={{
                          inputProps: {
                            max:
                              sales === "sales" ? addChild[index]["stock"] : "",
                          },
                        }}
                        // helperText={addChild[index]["quantity"] > addChild[index]["stock"]  ? 'Empty field!' : ''}
                        type={"number"}
                        size="small"
                        disabled={item?.enable || di ? true : false}
                        id={
                          demo?.quantity === 0
                            ? "filled-disabled"
                            : "outlined-basic"
                        }
                        placeholder={r?.value}
                        // value={
                        //   sales === "sales"
                        //     ? addChild[index]["quantity"]
                        //     : addChild[index]["ordered"]
                        // }
                        value={addChild[index]["quantity"]}
                        variant="outlined"
                        sx={{ width: "100%", background: "#fff" }}
                        onChange={(val) => onchangeInput(val, r?.value, index)}
                      />
                    ) : r?.value === "rate" ? (
                      <TextField
                        type={"number"}
                        size="small"
                        disabled={item?.enable || di ? true : false}
                        id={
                          demo?.quantity === 0
                            ? "filled-disabled"
                            : "outlined-basic"
                        }
                        placeholder="rate"
                        value={addChild[index]["rate"]}
                        variant="outlined"
                        sx={{ width: "100%", background: "#fff" }}
                        onChange={(val) => onchangeInput(val, r?.value, index)}
                      />
                    ) : r?.value === "tr_charge" ? (
                      <TextField
                        type={"number"}
                        size="small"
                        disabled={true}
                        id={
                          demo?.quantity === 0
                            ? "filled-disabled"
                            : "outlined-basic"
                        }
                        placeholder="tr_charge"
                        value={addChild[index]["tr_charge"]}
                        variant="outlined"
                        sx={{ width: "100%", background: "#fff" }}
                        onChange={(val) => onchangeInput(val, r?.value, index)}
                      />
                    ) : r?.value === "tax" ? (
                      <TextField
                        type={"number"}
                        size="small"
                       // disabled={item?.enable || di || sales === "sales" ? true : false}
                        disabled={true}
                        id={
                          demo?.quantity === 0
                            ? "filled-disabled"
                            : "outlined-basic"
                        }
                        placeholder="tax"
                        value={addChild[index]["tax"]}
                        variant="outlined"
                        sx={{ width: "100%", background: "#fff" }}
                        onChange={(val) => onchangeInput(val, r?.value, index)}
                      />
                    ) : r?.value === "tax_amount" ? (
                      <TextField
                        type={"number"}
                        size="small"
                       // disabled={item?.enable || di || sales === "sales" ? true : false}
                        disabled={true}
                        id={
                          demo?.quantity === 0
                            ? "filled-disabled"
                            : "outlined-basic"
                        }
                        placeholder="tax"
                        value={addChild[index]["tax_amount"]?.toFixed(2)}
                        variant="outlined"
                        sx={{ width: "100%", background: "#fff" }}
                        onChange={(val) => onchangeInput(val, r?.value, index)}
                      />
                    ) : r?.value === "discount" ? (
                      // <TextField
                      //   type={"number"}
                      //   size="small"
                      //   disabled={item.enable || di ? true : false}
                      //   id={
                      //     demo?.quantity === 0
                      //       ? "filled-disabled"
                      //       : "outlined-basic"
                      //   }
                      //   placeholder="discount"
                      //   value={addChild[index]["discount"]}
                      //   variant="outlined"
                      //   sx={{ width: "100%", background: "#fff" }}
                      //   onChange={(val) => onchangeInput(val, r.value, index)}
                      // />
                      <StyledTableCell>
                        <TextField
                          type={"number"}
                          size="small"
                          disabled={item?.enable || di ? true : false}
                          id={
                            demo?.quantity === 0
                              ? "filled-disabled"
                              : "outlined-basic"
                          }
                          value={addChild[index]["discount"]}
                          placeholder="discount"
                          sx={{ width: "60%", background: "#fff" }}
                          onChange={(val) =>
                            onchangeInput(val, r?.value, index)
                          }
                        />
                        <Select
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          style={{ background: "#fff", width: "40%" }}
                          name="weightSelect"
                          value={addChild[index]["discount_selected"]}
                          onChange={(val) =>
                            onchangeInput(val, "discount_selected", index)
                          }
                        >
                          <MenuItem value="Rs">Rs</MenuItem>
                          <MenuItem value="%">%</MenuItem>
                        </Select>
                      </StyledTableCell>
                    ) : (
                      <TextField
                        type={"number"}
                        disabled={item?.enable || di ? true : false}
                        id={
                          demo?.quantity === 0
                            ? "filled-disabled"
                            : "outlined-basic"
                        }
                        size="small"
                        value={addChild[index][r.value]}
                        placeholder={r?.value}
                        variant="outlined"
                        sx={{ width: "100%", background: "#fff" }}
                        onChange={(val) => onchangeInput(val, r?.value, index)}
                      />
					  
                    )}
					 
                  </StyledTableCell>
				  
                ))}
				 
					   
                {item?.enable || di ? (
                  ""
                ) : (
                  <StyledTableCell
                    align="center"
                    sx={{ width: "1%" }}
                    disabled
                    onClick={() => deleteRow(index)}
                  >
                    <RemoveIcon />
                  </StyledTableCell>
                )}
				
				
				   
					  
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
