import {
  SLIDER_REQUEST,
  SLIDER_SUCCESS,
  SLIDER_FAIL,
  NEW_SLIDER_REQUEST,
  NEW_SLIDER_SUCCESS,
  NEW_SLIDER_FAIL,
  UPDATE_SLIDER_REQUEST,
  UPDATE_SLIDER_SUCCESS,
  UPDATE_SLIDER_FAIL,
  DELETE_SLIDER_REQUEST,
  DELETE_SLIDER_SUCCESS,
  DELETE_SLIDER_FAIL,
  CUST_GROUP_REQUEST,
  CUST_GROUP_SUCCESS,
  CUST_GROUP_FAIL,
  NEW_CUST_GROUP_REQUEST,
  NEW_CUST_GROUP_SUCCESS,
  NEW_CUST_GROUP_FAIL,
  UPDATE_CUST_GROUP_REQUEST,
  UPDATE_CUST_GROUP_SUCCESS,
  UPDATE_CUST_GROUP_FAIL,
  DELETE_CUST_GROUP_REQUEST,
  DELETE_CUST_GROUP_SUCCESS,
  DELETE_CUST_GROUP_FAIL,
  CUST_SUB_GROUP_REQUEST,
  CUST_SUB_GROUP_SUCCESS,
  CUST_SUB_GROUP_FAIL,
  NEW_CUST_SUB_GROUP_REQUEST,
  NEW_CUST_SUB_GROUP_SUCCESS,
  NEW_CUST_SUB_GROUP_FAIL,
  UPDATE_CUST_SUB_GROUP_REQUEST,
  UPDATE_CUST_SUB_GROUP_SUCCESS,
  UPDATE_CUST_SUB_GROUP_FAIL,
  DELETE_CUST_SUB_GROUP_REQUEST,
  DELETE_CUST_SUB_GROUP_SUCCESS,
  DELETE_CUST_SUB_GROUP_FAIL,
  CLEAR_ERRORS,
} from "../constants/EcommerceConstants";
import axios from "axios";

// GET all Slider
export const getSlider = () => async (dispatch) => {
  try {
    dispatch({ type: SLIDER_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/slider`,
      config
    );
    dispatch({ type: SLIDER_SUCCESS, payload: data.slider });
  } catch (error) {
    dispatch({ type: SLIDER_FAIL, payload: error.response.data.message });
  }
};

// Create a Slider
export const createSlider = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_SLIDER_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/slider`,
      Data,
      config
    );
    dispatch({
      type: NEW_SLIDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_SLIDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Slider
export const updateSlider = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SLIDER_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    console.log(id, Data);
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/slider/update/${id}`,
      Data,
      config
    );
    console.log(data, "hello");
    dispatch({
      type: UPDATE_SLIDER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_SLIDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Slider
export const deleteSlider = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SLIDER_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/slider/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_SLIDER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_SLIDER_FAIL,
      payload: error.response.data.message,
    });
  }
};
// GET all custgroup
export const getCustGroup = () => async (dispatch) => {
  try {
    dispatch({ type: CUST_GROUP_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/ecommerce_customer_group`,
      config
    );
    dispatch({ type: CUST_GROUP_SUCCESS, payload: data.customerGroup });
  } catch (error) {
    dispatch({ type: CUST_GROUP_FAIL, payload: error.response.data.message });
  }
};

// Create a CUST_GROUP
export const createCustGroup = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_CUST_GROUP_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/ecommerce_customer_group`,
      Data,
      config
    );
    dispatch({
      type: NEW_CUST_GROUP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_CUST_GROUP_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update CUST_GROUP
export const updateCustGroup = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CUST_GROUP_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    console.log(id, Data);
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/ecommerce_customer_group/update/${id}`,
      Data,
      config
    );
    // console.log(data, "hello");
    dispatch({
      type: UPDATE_CUST_GROUP_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CUST_GROUP_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete CUST_GROUP
export const deleteCustGroup = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CUST_GROUP_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/ecommerce_customer_group/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_CUST_GROUP_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CUST_GROUP_FAIL,
      payload: error.response.data.message,
    });
  }
};
// GET all CUST-SUB-GROUP
export const getCustSubGroup = () => async (dispatch) => {
  try {
    dispatch({ type: CUST_SUB_GROUP_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/ecommerce_customer_sub_group`,
      config
    );
    dispatch({ type: CUST_SUB_GROUP_SUCCESS, payload: data.customerSubGroup });
  } catch (error) {
    dispatch({
      type: CUST_SUB_GROUP_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a CUST_GROUP
export const createCustSubGroup = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_CUST_SUB_GROUP_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/ecommerce_customer_sub_group`,
      Data,
      config
    );
    dispatch({
      type: NEW_CUST_SUB_GROUP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_CUST_SUB_GROUP_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update CUST_SUB_GROUP
export const updateCustSubGroup = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CUST_SUB_GROUP_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    console.log(id, Data);
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/ecommerce_customer_sub_group/update/${id}`,
      Data,
      config
    );
    // console.log(data, "hello");
    dispatch({
      type: UPDATE_CUST_SUB_GROUP_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CUST_SUB_GROUP_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete CUST_SUB_GROUP
export const deleteCustSubGroup = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CUST_SUB_GROUP_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/ecommerce_customer_sub_group/delete/${id}`,
      config
    );
    dispatch({
      type: DELETE_CUST_SUB_GROUP_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CUST_SUB_GROUP_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
