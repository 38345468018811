import {
  GRN_FAIL,
  GRN_REQUEST,
  GRN_SUCCESS,
  GRN_NUMBER_REQUEST,
  GRN_NUMBER_SUCCESS,
  GRN_NUMBER_FAIL,
  CLEAR_ERRORS,
  NEW_GRN_REQUEST,
  NEW_GRN_SUCCESS,
  NEW_GRN_FAIL,
  GRN_PRINT_SUCCESS,
  GRN_PRINT_REQUEST,
} from "../constants/GrnConstants";
import axios from "axios";

// Get receive grns
export const getGrnNumber = () => async (dispatch) => {
  try {
    dispatch({ type: GRN_NUMBER_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/grn_number`,
      config
    );
    dispatch({ type: GRN_NUMBER_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GRN_NUMBER_FAIL, payload: error.response.data.message });
  }
};

// Get receive grns
export const getReceiveGrn = (id) => async (dispatch) => {
  try {
    dispatch({ type: GRN_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/grn/get_a_single_purchase_order/${id}`,
      config
    );
    dispatch({ type: GRN_SUCCESS, payload: data.response });
  } catch (error) {
    dispatch({ type: GRN_FAIL, payload: error.response.data.message });
  }
};

// Create a GRN
export const createGrn = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_GRN_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/grn`,
      Data,
      config
    );
    dispatch({
      type: NEW_GRN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_GRN_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getGrnPrint = (id) => async (dispatch) => {
  try {
    console.log(id, "=========");
    dispatch({ type: GRN_PRINT_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(`${id}`, config);

    dispatch({ type: GRN_PRINT_SUCCESS, payload: data.grns });
  } catch (error) {}
};
