import React from "react";
import TableBody from "@mui/material/TableBody";
import UserTableInner from "./UserTableInner";
const UserTable = ({  data,setUpdatedata ,editPage}) => {
  return (
    <TableBody>
      <UserTableInner
       heading="ecommerce"
       create="create"
       remove="delete"
       edit="edit"
       view="view"
       editPage
        data={data}
        setUpdatedata={setUpdatedata}
      />
       <UserTableInner
        heading="admin"
        create="create"
        remove="delete"
        edit="edit"
        view="view"
        editPage
        data={data}
        setUpdatedata={setUpdatedata}
      />
       <UserTableInner
        heading="master"
        create="create"
        remove="delete"
        edit="edit"
        view="view"
        editPage
        data={data}
        setUpdatedata={setUpdatedata}
      />
       <UserTableInner
        heading="warehouse"
        create="create"
        remove="delete"
        edit="edit"
        view="view"
        data={data}
        editPage
        setUpdatedata={setUpdatedata}
      />
       <UserTableInner
        heading="vendor"
        create="create"
        remove="delete"
        edit="edit"
        view="view"
        editPage
        data={data}
        setUpdatedata={setUpdatedata}
       /> 
        <UserTableInner
        heading="customer"
        create="create"
        remove="delete"
        edit="edit"
        view="view"
        data={data}
        setUpdatedata={setUpdatedata}
        editPage
      /> 
       <UserTableInner
        heading="inventory"
        create="create"
        remove="delete"
        edit="edit"
        view="view"
        data={data}
        setUpdatedata={setUpdatedata}
        editPage
      />

       <UserTableInner
        heading="purchase"
        create="create"
        remove="delete"
        edit="edit"
        view="view"
        data={data}
        setUpdatedata={setUpdatedata}
        editPage
      />
       <UserTableInner
        heading="sales"
        create="create"
        remove="delete"
        edit="edit"
        view="view"
        data={data}
        setUpdatedata={setUpdatedata}
        editPage
      />
       <UserTableInner
        heading="report"
        create="create"
        remove="delete"
        edit="edit"
        view="view"
        data={data}
        setUpdatedata={setUpdatedata}
        editPage
      />

    </TableBody>
  );
};

export default UserTable;
