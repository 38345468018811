import { CLEAR_ERRORS } from "../constants/MastersConstants";
import {
  DASHBOARD_LIST_FAIL,
  DASHBOARD_LIST_REQUEST,
  DASHBOARD_LIST_SUCCESS,
} from "../constants/DashboardListConstants";

export const dashboardListReducer = (state = { dashboardList: [], }, action) => {
  switch (action.type) {
    case DASHBOARD_LIST_REQUEST:
      return { loading: true, dashboardList: [] };
    case DASHBOARD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardList: action.payload,
      };

    case DASHBOARD_LIST_FAIL:
      return {
        ...state,
        loading: false,
        dashboardList: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
