import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import { Grid } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Demo from "./demo";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import BasicAutoComplete from "../../Common/AutoComplete";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../../Redux/actions/MastersActions";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";
import {
  getCustomers,
  deleteCustomers,
  updateCustomers,
  getCustomerBillingAddress,
  getCustomerShippingAddress,
} from "../../../Redux/actions/CustomerActions";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { DELETE_ADDCUSTOMERS_RESET } from "../../../Redux/constants/CustomerConstants";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { UPDATE_ADDCUSTOMERS_RESET } from "../../../Redux/constants/CustomerConstants";
import Loader from "../../Common/Loader";
import * as Yup from "yup";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FieldArray,
  FastField,
  useFormik,
} from "formik";
import { TextFieldComponent } from "../../Common/FormikComponent/TextFieldComponent";
import AutoCompleteSelect from "../../Common/FormikComponent/AutoCompleteSelect";
import DeleteDialog from "../../Common/DeleteDialog";
import axios from "axios";
import TableExpand from "../../Common/TableExpand";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "company_name",
    numeric: false,
    disablePadding: true,
    label: "Company Name",
  },
  {
    id: "Name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "Email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "Mobile",
    numeric: false,
    disablePadding: true,
    label: "Mobile",
  },
   {
    id: "group",
    numeric: false,
    disablePadding: true,
    label: "Group",
  },
   {
    id: "sub_group",
    numeric: false,
    disablePadding: true,
    label: "Sub Group",
  },
  {
    id: "GST Treatment",
    numeric: false,
    disablePadding: true,
    label: "GST Treatment",
  },
  {
    id: "Gst no",
    numeric: false,
    disablePadding: true,
    label: "Gst no",
  },
  {
    id: "expand",
    numeric: false,
    disablePadding: true,
    label: "Billing Address",
  },
  {
    id: "expand",
    numeric: false,
    disablePadding: true,
    label: "Shipping Address",
  },
  {
    id: "expand",
    numeric: false,
    disablePadding: true,
    label: "Designation",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function AddCustomerTable({
  searchInput,
  componentRef,
  change,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("state");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [open, setOpen] = React.useState(false);
  const [updatedata, setUpdatedata] = React.useState("");
  const [docImg1, setDocImg1] = React.useState("");
  const [docImg2, setDocImg2] = React.useState("");
  const [docImg3, setDocImg3] = React.useState("");
  const [docImg4, setDocImg4] = React.useState("");

  const [updateDataId, setUpdateDataId] = React.useState("");
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, customers, loading } = useSelector((state) => state.customers);
  const {
    error: deleteError,
    isDeleted,
    isUpdated,
  } = useSelector((state) => state.updateCustomers);

  // Delete Dialog
  const [deleteOpen, setDelete] = React.useState(false);
  const [id, setId] = React.useState("");
  const handleClickOpenDelete = (id) => {
    setDelete(true);
    setId(id);
  };
  const deleteHandler = (id) => {
    dispatch(deleteCustomers(id));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }
    if (updateDataId) {
      const get = async () => {
        const { data } = await axios.get(
          `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/single_customer/details/${updateDataId}`
        );
        setUpdatedata(data.response);
      };
      get();
    }
    if (isDeleted) {
      alert.success("Customer Deleted Successfully");
      dispatch({ type: DELETE_ADDCUSTOMERS_RESET });
      dispatch(getCustomers());
    }
    if (isUpdated) {
      alert.success("Customers Updated Successfully");
      dispatch({ type: UPDATE_ADDCUSTOMERS_RESET });
      dispatch(getCustomers());
    }

    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [
    dispatch,
    navigate,
    error,
    alert,
    isAuthenticated,
    deleteError,
    isDeleted,
    isUpdated,
    updateDataId,
  ]);
  useEffect(() => {
    const getImage = async () => {
      let data = new FormData();

      if (docImg1) {
        data.append("name", docImg1.name);
        data.append("file", docImg1);
        const image = await fetch(
          `https://erpapi.flaskitsolutions.com/api/v1/upload/user-image`,
          {
            method: "POST",
            body: data,
          }
        );
        const json = await image.json();
        setDocImg1(json);
      }
      if (docImg2) {
        data.append("name", docImg2.name);
        data.append("file", docImg2);
        const image = await fetch(
          `https://erpapi.flaskitsolutions.com/api/v1/upload/user-image`,
          {
            method: "POST",
            body: data,
          }
        );
        const json = await image.json();
        setDocImg2(json);
      }
      if (docImg3) {
        data.append("name", docImg3.name);
        data.append("file", docImg3);
        const image = await fetch(
          `https://erpapi.flaskitsolutions.com/api/v1/upload/user-image`,
          {
            method: "POST",
            body: data,
          }
        );
        const json = await image.json();
        setDocImg3(json);
      }
      if (docImg4) {
        data.append("name", docImg4.name);
        data.append("file", docImg4);
        const image = await fetch(
          `https://erpapi.flaskitsolutions.com/api/v1/upload/user-image`,
          {
            method: "POST",
            body: data,
          }
        );
        const json = await image.json();
        setDocImg4(json);
      }
    };
    getImage();
  }, [docImg1, docImg2, docImg3, docImg4]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  let updatedArray = customers.filter(
    (e) => e.name.toLowerCase().search(searchInput.toLowerCase().trim()) !== -1
  );
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = customers.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - updatedArray.length) : 0;

  var perPageData = [];
  var length = Math.ceil(updatedArray.length / 3);

  for (var i = 1; i <= length; i++) {
    perPageData.push(i * 3);
  }
  // Expand Table
  const [collapse, setCollapse] = React.useState("");
  const [expandData, setExpandData] = React.useState("");
  const [expanded, setExpanded] = React.useState(false);
  const [texto, setText] = React.useState("");
  const [countCollapse, setCountCollapse] = React.useState("");
  const handleExpandClick = (value, Data, count) => {
    setExpanded(!expanded);
    setCountCollapse(count);
    let updated;
    let text;
    if (count === 1) {
      updated = Data.customer_billing_address;
      text = "comman";
    } else if (count === 2) {
      updated = Data.customer_shipping_address;
      text = "comman";
    } else if (count === 3) {
      updated = Data.Designation;
      text = "designation";
    } else {
      return "";
    }
    setExpandData(updated);
    setText(text);
    if (collapse === value && count === countCollapse) {
      setCollapse("");
    } else {
      setCollapse(value);
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Demo
          customers={updatedArray}
          headCells={headCells}
          handleExpandClick={handleExpandClick}
          collapse={collapse}
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={perPageData}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          handleClickOpenDelete={handleClickOpenDelete}
          setDelete={setDelete}
          id={id}
          update="/customers/edit_customer"
          deleteHandler={deleteHandler}
          countCollapse={countCollapse}
          deleteOpen={deleteOpen}
          expandData={expandData}
          text={texto}
          EnhancedTableToolbar={EnhancedTableToolbar}
          numSelected={selected.length}
        />
      )}
    </>
  );
}

// Billing Addres
const headCellsBilling = [
  {
    id: "sr",
    numeric: false,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "Street 1",
    numeric: false,
    disablePadding: true,
    label: "Street 1",
  },
  {
    id: "Street 2",
    numeric: false,
    disablePadding: true,
    label: "Street 2",
  },
  {
    id: "City",
    numeric: false,
    disablePadding: true,
    label: "City",
  },
  {
    id: "State",
    numeric: false,
    disablePadding: true,
    label: "State",
  },
  {
    id: "Country",
    numeric: false,
    disablePadding: true,
    label: "Country",
  },
  {
    id: "zip_code",
    numeric: false,
    disablePadding: true,
    label: "Zip code",
  },
  {
    id: "Phone",
    numeric: false,
    disablePadding: true,
    label: "Phone",
  },
  {
    id: "Email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
];
function EnhancedTableHeadBilling() {
  return (
    <TableHead>
      <TableRow>
        {headCellsBilling.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            style={{ fontWeight: "700" }}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const DialogBillingAddress = ({
  openButtonBilling,
  setOpenButtonBilling,
  updatedata,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const handleClose = () => {
    setOpenButtonBilling(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log(updatedata);
  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage -
            updatedata?.customer_billing_address?.length
        )
      : 0;

  var perPageData = [];
  var length = Math.ceil(updatedata?.customer_billing_address?.length / 3);

  for (var i = 1; i <= length; i++) {
    perPageData.push(i * 3);
  }
  return (
    <Dialog open={openButtonBilling} onClose={handleClose} maxWidth="xl">
      <Divider />
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", boxShadow: "none", border: "none" }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 1200 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHeadBilling
                  rowCount={updatedata?.customer_billing_address?.length}
                />
                <TableBody>
                  {updatedata?.customer_billing_address
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={item.name}
                        >
                          <TableCell id={labelId} scope="row" padding="none">
                            {index + 1}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.street_1}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.street_2}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.city}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.state}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.country}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.zip_code}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.phone}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.email}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={perPageData}
              component="div"
              count={updatedata?.customer_billing_address?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

// Shipping Address
const headCellShipping = [
  {
    id: "sr",
    numeric: false,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "Street 1",
    numeric: false,
    disablePadding: true,
    label: "Street 1",
  },
  {
    id: "Street 2",
    numeric: false,
    disablePadding: true,
    label: "Street 2",
  },
  {
    id: "City",
    numeric: false,
    disablePadding: true,
    label: "City",
  },
  {
    id: "State",
    numeric: false,
    disablePadding: true,
    label: "State",
  },
  {
    id: "Country",
    numeric: false,
    disablePadding: true,
    label: "Country",
  },
  {
    id: "Zip Code",
    numeric: false,
    disablePadding: true,
    label: "Zip Code",
  },
  {
    id: "Phone",
    numeric: false,
    disablePadding: true,
    label: "Phone",
  },
  {
    id: "Email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
];

function EnhancedTableHeadShipping() {
  return (
    <TableHead>
      <TableRow>
        {headCellShipping.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            style={{ fontWeight: "700" }}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const DialogShippingAddress = ({
  openButtonShipping,
  setOpenButtonShipping,
  updatedata,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const handleClose = () => {
    setOpenButtonShipping(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage -
            updatedata?.customer_shipping_address?.length
        )
      : 0;

  var perPageData = [];
  var length = Math.ceil(updatedata?.customer_shipping_address?.length / 3);

  for (var i = 1; i <= length; i++) {
    perPageData.push(i * 3);
  }
  return (
    <Dialog open={openButtonShipping} onClose={handleClose} maxWidth="xl">
      <Divider />
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", boxShadow: "none", border: "none" }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 1200 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHeadShipping
                  rowCount={updatedata?.customer_shipping_address?.length}
                />
                <TableBody>
                  {updatedata?.customer_shipping_address
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={item.name}
                        >
                          <TableCell id={labelId} scope="row" padding="none">
                            {index + 1}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.street_1}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.street_2}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.city}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.state}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.country}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.zip_code}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.phone}
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            {item.email}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={perPageData}
              component="div"
              count={updatedata?.customer_shipping_address?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
