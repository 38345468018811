import React, { useEffect } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import DrawerErp from "../../Layout/DrawerErp";
import {
  getCountrys,
  clearErrors,
  createCountry,
} from "../../../Redux/actions/MastersActions";
import { NEW_COUNTRY_RESET } from "../../../Redux/constants/MastersConstants";
import {
  IconButton,
  Divider,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  InputBase,
  Stack,
  Toolbar,
  Box,
  Grid,
  Typography,
  Paper,
  Button,
} from "@mui/material";
import { Close, Search, Add } from "@mui/icons-material";
import {
  createProductUnit,
  getProductUnit,
} from "../../../Redux/actions/InventryActions";
import { NEW_PRODUCTUNIT_RESET } from "../../../Redux/constants/InventryConstant";
import ProductUnitTable from "./ProductUnitTable";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import "../../Common/style.css";
import jsPDF from "jspdf";
import Excle from "../../Common/Excle";

const SearchBox = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#e7ebf0",
  "&:hover": {
    backgroundColor: "#e7ebf0",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: center;
      padding: 6px;
    }
  
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
      padding:16px
    }
    `
);

const ProductUnit = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [searchInput, setSearchInput] = React.useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, success } = useSelector((state) => state.newproductUnit);
  const { error: adminError } = useSelector((state) => state.productUnit);
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getProductUnit());
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
    if (success) {
      dispatch({ type: NEW_PRODUCTUNIT_RESET });
      dispatch(getProductUnit());
      setOpen(false);
      alert.success("Product Unit Added Successfully");
      setName("");
    }
  }, [dispatch, navigate, error, alert, isAuthenticated, success]);

  const handleCreateCountry = (e) => {
    e.preventDefault();
    dispatch(createProductUnit({ name: name }));
  };

  // Multiple Button Functions
  const [change, setChange] = React.useState(false);
  const generatePDF = () => {
    setChange(true);
    var doc = new jsPDF("p", "pt", "a4");
    doc.html(document.querySelector("#content"), {
      callback: function (pdf) {
        // var pageCount = doc.internal.getNumberOfPages();
        // pdf.deletePage(pageCount)
        pdf.save("data.pdf");
        setChange(false);
      },
    });
  };
  const ref = React.createRef();
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }, [setLoading]);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "data",
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);
  const { productUnit } = useSelector((state) => state.productUnit);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Product Unit
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "0 24px", mt: "0" }}>
          {adminError ? (
            <Grid container xs={12} justifyContent="center" alignItems="center">
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "red" }}
              >
                You don't have the required permissions.
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Item>
                <Grid container justifyContent="flex-end">
                  <Button variant="contained" onClick={handleClickOpen}>
                    <Add />
                    Add
                  </Button>
                  <Dialog open={open} onClose={handleClose} fullWidth="true">
                    <DialogTitle>
                      <Toolbar style={{ padding: 0 }}>
                        <Typography
                          sx={{ flex: 1 }}
                          variant="h6"
                          component="div"
                        >
                          Add New
                        </Typography>

                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={handleClose}
                          aria-label="close"
                          sx={{ ml: 0 }}
                        >
                          <Close />
                        </IconButton>
                      </Toolbar>
                    </DialogTitle>

                    <Divider />
                    <DialogContent>
                      <DialogContentText>Prouct Unit Name</DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="productUnit"
                        label="ProductUnit Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2 }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                      <Button onClick={handleCreateCountry} variant="contained">
                        Add
                      </Button>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                      >
                        Close
                      </Button>
                    </DialogActions>
                    <Divider />
                  </Dialog>
                </Grid>
                <Grid container justifyContent="space-between" sx={{ mt: 8 }}>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="contained"
                      id="copy_btn"
                      sx={{ textTransform: "none" }}
                    >
                      Copy
                    </Button>
                    <Excle
                      headCells={headCells}
                      data={productUnit}
                      name="brand"
                      btn="CSV"
                      select="Excle"
                    />
                    <Excle
                      headCells={headCells}
                      data={productUnit}
                      name="brand"
                      btn="Export"
                      select="Excle"
                    />
                    <Button variant="contained" onClick={generatePDF}>
                      PDF
                    </Button>
                    <Excle
                      headCells={headCells}
                      data={productUnit}
                      name="brand"
                      select="print"
                    />
                  </Stack>
                  <Stack>
                    <SearchBox>
                      <SearchIconWrapper>
                        <Search />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => setSearchInput(e.target.value)}
                        value={searchInput}
                      />
                    </SearchBox>
                  </Stack>
                </Grid>
                <Root sx={{ mt: 2 }}>
                  <ProductUnitTable
                    searchInput={searchInput}
                    change={change}
                    componentRef={componentRef}
                  />
                </Root>
              </Item>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default ProductUnit;

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "productUnit",
    numeric: false,
    disablePadding: true,
    label: "productUnit",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];
