import {
  DialogContentText,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import React from "react";

const POradioButtonSelect = ({
  heading,
  radio,
  heading2,
  heading3,
  name,
  setInput,
  data,
  size,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <DialogContentText>
        {radio === "Warehouse"
          ? heading
          : radio === "Customer"
          ? heading2
          : radio === "other"
          ? heading3
          : ""}
      </DialogContentText>
      {radio === "other" ? (
        <TextField
          autoFocus
          margin="dense"
          type="text"
          fullWidth
          size="small"
          variant="outlined"
          value={name}
          onChange={setInput}
        />
      ) : (
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          size="small"
          value={name}
          // defaultValue={{ label: "Select Dept", value: name }}
          sx={{mb:2, width: "100%" }}
          onChange={setInput}
        >
          {data.map((r) =>
            radio === "Warehouse" ? (
              <MenuItem value={r.warehouse_name}>{r.warehouse_name}</MenuItem>
            ) : (
              <MenuItem value={r.name}>{r.name}</MenuItem>
            )
          )}
        </Select>
      )}
    </div>
  );
};

export default POradioButtonSelect;
