import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  DialogContentText,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Link, useNavigate, useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import { Add, Cancel } from "@mui/icons-material";
import RadioButton from "../Common/RadioButton";
import PurchaseOrderInput from "../Common/PurchaseOrderInput";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { getPaymentTerms } from "../../Redux/actions/MastersActions";
import { getVendor } from "../../Redux/actions/VendorAction";
import POradioButtonSelect from "../Common/POradioButtonSelect";
import { getWarehouse } from "../../Redux/actions/WarehouseActions";
import { getCustomers } from "../../Redux/actions/CustomerActions";
import AddPurchaseOrderTable from "./AddPurchaseOrderTable";
import AddPurchaseOrderItem from "./AddPurchaseOrderItem";
import { getEndProductList } from "../../Redux/actions/InventryActions";
import axios from "axios";
import Loader from "../Common/Loader";
import { updatePurchaseOrder } from "../../Redux/actions/PurchaseOrderAction";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Formik, Form } from "formik";
import { TextFieldComponent } from "../Common/FormikComponent/TextFieldComponent";
import AutoCompleteSelect from "../Common/FormikComponent/AutoCompleteSelect";
import { UPDATE_PURCHASE_ORDER_SUCCESS } from "../../Redux/constants/PurchaseOrderConstant";
import PurchaseOrderDropdown from "../Common/PurchaseOrderDropdown";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const EditPurchaseOrder = () => {
  let { id } = useParams();
  const [demo, setDemo] = useState("");
  const [value, setValue] = React.useState(new Date());
  const [delivreyDate, setDeliveryDate] = React.useState(new Date());
  const [radio, setRadio] = React.useState("Warehouse");
  const [PO, setPO] = React.useState("");
  const [POradio, setPORadio] = React.useState("");
  const [searchInput, setSearchInput] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [subTotal, setSubTotal] = useState("");
  const [total, setTotal] = useState("");
  const [item, setItem] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const handleChange2 = (newValue) => {
    setDeliveryDate(newValue);
  };
  const handleChangeRadio = (event) => {
    setRadio(event.target.value);
  };
  const handleChangePOradioButtonSelect = (event) => {
    setPORadio(event.target.value);
  };
  const [details, setDetails] = useState({
    total: "",
    forwarding_charges: "",
    rounding: "",
    total_tax: 0,
  });
  const roal = (first, id) => {
    setIsModal(first);
    setItem(id);
  };
  // Table Data
  const [addChild, setAddChild] = React.useState([
    { value: 1, discount_selected: "Rs" },
  ]);
  const addChildInputField = (event) => {
    const id = addChild.length + 1;
    setAddChild((oldValue) => [
      ...oldValue,
      { value: id, discount_selected: "Rs" },
    ]);
    event.preventDefault();
  };
  const deleteRow = (id) => {
    let updatedArray = addChild.filter((r, ind) => ind !== id);
    setAddChild(updatedArray);
    let sum = updatedArray.reduce((a, v) => (a = a + v.amount), 0);
    setSubTotal(sum);
    let addSum = 0;
    let TotalAddSum = 0;
    updatedArray.map((item) => {
      return (addSum = addSum + (item?.amount * 100) / (100 + item?.tax));
    });
    updatedArray.map((item) => {
      let my =
        ((Number(details.forwarding_charges) / addSum) * (item?.amount * 100)) /
          (100 + item?.tax) +
        (item?.amount * 100) / (100 + item?.tax);
      return (TotalAddSum += my + (my * item?.tax) / 100);
    });
    setDetails((old) => ({
      ...old,
      forwarding_charges: Number(details.forwarding_charges),
      total: TotalAddSum,
    }));
  };
  const onchangeInput = (val, index, outerIndex) => {
	  
	    if(index == 'add_Desc' || index == 'item')
	  {
		  return false;
	  }
	  
	  
    let updated = addChild.map((r, ind) =>
      ind === outerIndex
        ? {
            ...r,
            [index]: val.target.value,
            ["amount"]:
              !(val.target.value === "Rs") &&
              (val.target.value === "%" || r.discount_selected === "%")
                ? index === "quantity"
                  ? (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (r.discount === undefined ? 0 : r.discount / 100)
                  : index === "rate"
                  ? (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.quantity === undefined ? 0 : r.quantity)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.quantity === undefined ? 0 : r.quantity)) *
                      (r.discount === undefined ? 0 : r.discount / 100)
                  : index === "discount"
                  ? (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (val.target.value / 100)
                  : (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (r.discount === undefined ? 0 : r.discount / 100)
                : val.target.value === "Rs"
                ? (r.rate === undefined ? 0 : r.rate) *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  (r.discount === undefined ? 0 : r.discount)
                : index === "quantity"
                ? val.target.value * (r.rate === undefined ? 0 : r.rate) -
                  (r.discount === undefined ? 0 : r.discount)
                : index === "discount"
                ? (r.rate === undefined ? 0 : r.rate) *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  val.target.value
                : val.target.value *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  (r.discount === undefined ? 0 : r.discount),
            ["ordered"]: r.quantity,
			
					["tax_amount"]:  !(val.target.value === "Rs") &&
              (val.target.value === "%" || r.discount_selected === "%")
                ? index === "quantity"
                  ? (  ( (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (r.discount === undefined ? 0 : r.discount / 100) )  * 100 /  (100 + r.tax ) )    * r.tax / 100
                  : index === "rate"
                  ? ( ( (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.quantity === undefined ? 0 : r.quantity)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : val.target.value *
                        (r.quantity === undefined ? 0 : r.quantity)) *
                      (r.discount === undefined ? 0 : r.discount / 100) ) * 100 /  (100 + r.tax ) )    * r.tax / 100
                  : index === "discount"
                  ?(r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (val.target.value / 100)
                  : (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) -
                    (r.amount === NaN || r.amount === undefined
                      ? 0
                      : (r.quantity === undefined ? 0 : r.quantity) *
                        (r.rate === undefined ? 0 : r.rate)) *
                      (r.discount === undefined ? 0 : r.discount / 100)
                : val.target.value === "Rs"
                ? (r.rate === undefined ? 0 : r.rate) *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  (r.discount === undefined ? 0 : r.discount)
                : index === "quantity"
                ?  ( ( val.target.value * (r.rate === undefined ? 0 : r.rate) -
                  (r.discount === undefined ? 0 : r.discount)  ) * 100 /  (100 + r.tax ) )    * r.tax / 100
                : index === "discount"
                ?  ( ( (r.rate === undefined ? 0 : r.rate) *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  val.target.value )   * 100 /  (100 + r.tax ) )    * r.tax / 100
                :  ( ( val.target.value *
                    (r.quantity === undefined ? 0 : r.quantity) -
                  (r.discount === undefined ? 0 : r.discount)  )  * 100 /  (100 + r.tax ) )    * r.tax / 100 ,
			
			
			
			
          }
        : r
    );
    setAddChild(updated);
    let sum = updated.reduce((a, v) => (a = a + v.amount), 0);
    setSubTotal(sum);

    let addSum = 0;
    let TotalAddSum = 0;
	    let TotalTax = 0;


    updated.map((item, ind) => {
      return (addSum = addSum + (item?.amount * 100) / (100 + item?.tax));
    });
	
	
	 updated.map((item, ind) => {
      return (TotalTax = TotalTax + item?.tax_amount);
    });
	
	
	
	
    updated.map((item, ind) => {
      let my =
        (((val.target.name === "forwarding"
          ? val.target.value
          : details.forwarding_charges) /
          addSum) *
          (item?.amount * 100)) /
          (100 + item?.tax) +
        (item?.amount * 100) / (100 + item?.tax);
      return (TotalAddSum += my + (my * item?.tax) / 100);
    });
    setDetails((old) => ({
      ...old,
      forwarding_charges:
        val.target.name === "forwarding"
          ? val.target.value
            ? Number(val.target.value)
            : ""
          : Number(details.forwarding_charges),
		  
		    total_tax:TotalTax,
		     rounding:
			val.target.name === "rounding"
          ? val.target.value
            ? Number(val.target.value)
            : ""
          : Number(old.rounding),
		  
		  
		  
      total: TotalAddSum,
    }));
    if (details.forwarding_charges === "") {
      setDetails((old) => ({
        ...old,
        total: sum,
      }));
    }
	
	
	
	  if (details.rounding != "") {
      setDetails((old) => ({
        ...old,
        total: sum - details.rounding,
      }));
    }
	 
	 
	 if(val.target.name == "rounding" )
	 {
		 setDetails((old) => ({
        ...old,
        total: sum - val.target.value,
      })); 
		 
	 }
	
	
	
	
	
  };

  // Selector
  const { isAuthenticated } = useSelector((state) => state.admin);
  const { paymentTerms } = useSelector((state) => state.paymentTerms);
  const { Warehouse } = useSelector((state) => state.Warehouse);
  const { customers } = useSelector((state) => state.customers);
  const { endProductList } = useSelector((state) => state.endProductList);
  const [contactPerson, setContactPerson] = React.useState("");
  const [cust, setCust] = useState("");
  const [obj, setObj] = React.useState({
    email: "",
    mobile: "",
    gst_number: "",
    company_name: "",
    billing: "",
    shipping: "",
  });
  const [getCust, setgeCustomer] = React.useState("");
  const [personName, setPersonName] = React.useState([]);
  const handleChangeMulti = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };
  const custFunc = (v) => {
    setCust(v.target.value);
    let update = getCust?.find((r) => r.name === v.target.value);
    setObj({
      email: update.email,
      mobile: update.mobile,
      gst_number: update.gst_number,
      name: update.company_name,
      billing: update.vendor_billing_address,
      shipping: update.vendor_shipping_address,
    });
    setContactPerson(update.contact_persons);
  };
  const [bank, setBank] = React.useState("");
  const [getBank, setBankAcoount] = React.useState("");
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getPaymentTerms());
      dispatch(getVendor());
      dispatch(getWarehouse());
      dispatch(getCustomers());
      dispatch(getEndProductList());
      axios
        .get("https://erpapi.flaskitsolutions.com/api/v1/admin/bank_account/details")
        .then((res) => {
          setBankAcoount(res.data.BankAccountDetails);
        });
      axios
        .get(
          "https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/shipping_billing/address"
        )
        .then((res) => {
          setgeCustomer(res.data.data);
        });
    }
    if (id) {
      const get = async () => {
        const { data } = await axios.get(
          `https://erpapi.flaskitsolutions.com/api/v1/admin/add_purchase_order/details/${id}`
        );

        setDemo(data.purchaseOrder);
        setSubTotal(data.purchaseOrder.sub_total);
        setTotal(data.purchaseOrder.total);
        setBank(data.purchaseOrder.bank_account_details);
        setPO(data.purchaseOrder.po);
        setValue(data.purchaseOrder.createdAt);
        setDeliveryDate(data.purchaseOrder.expected_delivery_date);
        setPersonName(data.purchaseOrder.contact_persons);
        setContactPerson(data.purchaseOrder.contact_persons);
        setCust(data.purchaseOrder.vendor);
        setDetails((old) => ({
          ...old,
          total: data.purchaseOrder.total,
          forwarding_charges: data.purchaseOrder.forwarding_charges,
          rounding: data.purchaseOrder.rounding,
          total_tax: data.purchaseOrder.total_tax,
        }));
        setObj((old) => ({
          ...old,
          email: data.purchaseOrder.email,
          mobile: data.purchaseOrder.mobile,
          name: data.purchaseOrder.company_name,
          gst_number: data.purchaseOrder.gst_number,
          billing: data.purchaseOrder.vendor_billing_address,
          shipping: data.purchaseOrder.vendor_shipping_address,
        }));

        let updated = data.purchaseOrder.item_details.map((r) => {
          return r.ordered === 0
            ? { ...r, enable: true }
            : { ...r, enable: false };
        });
        setAddChild(updated);
        setPORadio(data.purchaseOrder.deliver_to);
      };
      get();
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [dispatch, isAuthenticated]);
  const validationSchema = Yup.object({
    notes: Yup.string(),
    terms_and_condition: Yup.string(),
    ref: Yup.string(),
    payment_terms: Yup.string(),
    shipment_preference: Yup.string(),
  });
  const initialValues = {
    payment_terms: demo.payment_terms ? demo.payment_terms : "",
    shipment_preference: demo.shipment_preference
      ? demo.shipment_preference
      : "",
    ref: demo.ref ? demo.ref : "",
    terms_and_condition: demo.terms_and_condition
      ? demo.terms_and_condition
      : "",
    notes: demo.notes ? demo.notes : "",
  };
  const onSubmit = (values) => {
    dispatch(
      updatePurchaseOrder(id, {
        ...values,
        vendor: cust,
        date: value,
        expected_delivery_date: delivreyDate,
        sub_total: subTotal,
        deliver_to: POradio,
        item_details: addChild,
        total: details.total,
        forwarding_charges: details.forwarding_charges,
        total_tax: details.total_tax,
        rounding: details.rounding,
        company_name: obj.company_name,
        email: obj.email,
        mobile: obj.mobile,
        gst_number: obj.gst_number,
        contact_persons: personName,
        vendor_billing_address: obj.billing,
        vendor_shipping_address: obj.shipping,
        bank_account_details: bank,
      })
    );
    alert.success("Purchase Order Updated Successfully");
    dispatch({ type: UPDATE_PURCHASE_ORDER_SUCCESS });
    navigate("/purchase-orders");
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <DrawerErp />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Grid container sx={{ p: 3 }}>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Update Purchase Invoice
              </Typography>
            </Grid>
          </Grid>
          {!demo ? (
            <Loader />
          ) : (
            <Grid container sx={{ padding: "0 24px", mt: "0" }}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ errors, handleChange, values, touched, setFieldValue }) => (
                  <Form>
                    <Grid item xs={12}>
                      <Item>
                        <Grid container lg={12} justifyContent="space-between">
                          <Grid
                            container
                            lg={5.9}
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: "10px",
                              paddingLeft: "8px",
                              paddingTop: "8px",
                            }}
                          >
                            <Grid container lg={12} spacing={2}>
                              <Grid xs={12} item>
                                <PurchaseOrderDropdown
                                  size="100%"
                                  heading="Select Vendor"
                                  data={getCust}
                                  setInput={custFunc}
                                  cu="cust"
                                  name={cust}
                                />
                              </Grid>
                              <Grid item lg={8} md={8}>
                                <RadioButton
                                  name={radio}
                                  change={handleChangeRadio}
                                />
                              </Grid>
                              <Grid item lg={4} md={4}>
                                <POradioButtonSelect
                                  data={
                                    radio === "Warehouse"
                                      ? Warehouse
                                      : radio === "Customer"
                                      ? customers
                                      : ""
                                  }
                                  radio={radio}
                                  heading="Select Warehouse"
                                  heading2="Select Customer"
                                  heading3="Address"
                                  setInput={handleChangePOradioButtonSelect}
                                  name={POradio}
                                />
                              </Grid>
                            </Grid>

                            <Grid item lg={6}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "90%",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ mb: 1 }}
                                  >
                                    Contact Person
                                  </Typography>
                                </div>
                                <Select
                                  labelId="demo-multiple-name-label"
                                  id="demo-multiple-name"
                                  multiple
                                  sx={{ width: "100%" }}
                                  size="small"
                                  value={personName}
                                  onChange={handleChangeMulti}
                                >
                                  {contactPerson
                                    ? contactPerson.map((name) => (
                                        <MenuItem key={name} value={name}>
                                          {name}
                                        </MenuItem>
                                      ))
                                    : ""}
                                </Select>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            lg={6}
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: "10px",
                              paddingLeft: "8px",
                              paddingTop: "8px",
                            }}
                          >
                            <Grid container lg={12} spacing={1}>
                              <Grid item xs={4}>
                                <PurchaseOrderInput
                                  heading="PO#"
                                  name={PO}
                                  setInput={setPO}
                                  placeholder="PO#"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextFieldComponent
                                  heading="Refrence#"
                                  name="ref"
                                  type="text"
                                  error={errors.ref && touched.ref}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  flexDirection: "column",
                                }}
                              >
                                <DialogContentText
                                  style={{ marginBottom: "5px" }}
                                >
                                  Date
                                </DialogContentText>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DesktopDatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(params) => (
                                      <TextField {...params} size="small" />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>

                            <Grid container lg={12} spacing={1}>
                              <Grid item xs={4}>
                                <DialogContentText
                                  style={{ marginBottom: "5px" }}
                                >
                                  Expected Delivery Date
                                </DialogContentText>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DesktopDatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={delivreyDate}
                                    onChange={handleChange2}
                                    renderInput={(params) => (
                                      <TextField {...params} size="small" />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={4}>
                                <TextFieldComponent
                                  heading="Shipping Preference"
                                  name="shipment_preference"
                                  type="text"
                                  error={
                                    errors.shipment_preference &&
                                    touched.shipment_preference
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <AutoCompleteSelect
                                  name="payment_terms"
                                  data={paymentTerms}
                                  error={
                                    errors.payment_terms &&
                                    touched.payment_terms
                                  }
                                  values={values.payment_terms}
                                  handleChange={handleChange}
                                  heading="Payment Terms"
                                  setFieldValue={setFieldValue}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 3 }}>
                          <div
                            style={{
                              padding:
                                demo.item_details[0].ordered === 0 ? "2px" : "",
                              display:
                                demo.item_details[0].ordered === 0
                                  ? "flex"
                                  : "",
                              flexDirection:
                                demo.item_details[0].ordered === 0
                                  ? "column"
                                  : "",
                              alignItems:
                                demo.item_details[0].ordered === 0
                                  ? "flex-start"
                                  : "",
                            }}
                          >
                            {demo.item_details[0].ordered === 0 ? (
                              <p
                                style={{ fontSize: "20px", fontWeight: "700" }}
                              >
                                Item Details -{" "}
                                <span style={{ color: "red" }}>
                                  Not Editable (Because GRN created for this)
                                </span>
                              </p>
                            ) : (
                              ""
                            )}
                            <AddPurchaseOrderTable
                              addChild={addChild}
                              onchangeInput={onchangeInput}
                              deleteRow={deleteRow}
                              addChildInputField={addChildInputField}
                              setAddChild={setAddChild}
                              data={demo}
                              setIsModal={roal}
                              demo={demo?.item_details[0]}
                              defaultData={defaultData}
                            />
                          </div>
                        </Grid>
                        <Grid container sx={{ mt: 3 }}>
                          {isModal === false ? (
                            ""
                          ) : (
                            <AddPurchaseOrderItem
                              addChild={addChild}
                              setAddChild={setAddChild}
                              data={endProductList}
                              setIsModal={setIsModal}
                              searchInput={searchInput}
                              index={item}
                            />
                          )}
                        </Grid>

                        <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                          sx={{ mt: 3 }}
                        >
                          <Grid xs={4}>
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography fontWeight="700" marginRight="10px">
                                Sub Total :
                              </Typography>
                              <TextField
                                type={"number"}
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="outlined-read-only-input"
                                size="small"
                                placeholder="0.0"
                                value={subTotal}
                                variant="outlined"
                                sx={{ width: "70%", background: "#fff" }}
                                onChange={(e) => setSubTotal(e.target.value)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                          sx={{ mt: 3 }}
                        >
                          <Grid xs={4}>
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography fontWeight="700" marginRight="10px">
                                forwarding Charges :
                              </Typography>
                              <TextField
                                type={"number"}
                                size="small"
                                placeholder="forwarding"
                                variant="outlined"
                                name="forwarding"
                                value={details.forwarding_charges}
                                sx={{ width: "70%", background: "#fff" }}
                                onChange={(e) => onchangeInput(e)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
						
						
						
						
						
						
						
						
						
						
						 <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                          sx={{ mt: 3 }}
                        >
                          <Grid xs={4}>
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography fontWeight="700" marginRight="10px">
                               Total Tax :
                              </Typography>
                              <TextField
                                type={"number"}
                                size="small"
                                placeholder="total_tax"
                                variant="outlined"
								 InputProps={{
                                  readOnly: true,
                                }}
                                name="total_tax"
                                 value={details.total_tax?.toFixed(2)}
                                sx={{ width: "70%", background: "#fff" }}
                                onChange={(e) => onchangeInput(e)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
						
						
						 
						
						    <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                          sx={{ mt: 3 }}
                        >
                          <Grid xs={4}>
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography fontWeight="700" marginRight="10px">
                               Rounding:
                              </Typography>
                              <TextField
                                type={"number"}
                                size="small"
                                placeholder="rounding"
                                variant="outlined"
                                name="rounding"
                                value={details.rounding}
                                sx={{ width: "70%", background: "#fff" }}
                                onChange={(e) => onchangeInput(e)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
						
						
						 
						

                        <Grid
                          container
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                          }}
                          sx={{ mt: 3 }}
                        >
                          <Grid xs={4}>
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography fontWeight="700" marginRight="10px">
                                Total :
                              </Typography>
                              <TextField
                                type={"number"}
                                InputProps={{
                                  readOnly: true,
                                }}
                                id="outlined-read-only-input"
                                size="small"
                                placeholder="0.0"
                                variant="outlined"
                                name="total"
                                value={details.total}
                                sx={{ width: "70%", background: "#fff" }}
                                onChange={(e) => onchangeInput(e)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid lg={7} md={12} item>
                          <div
                            className="form-control"
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <DialogContentText mb={2}>
                              Select Bank Account
                            </DialogContentText>
                            <Autocomplete
                              size="small"
                              value={bank}
                              options={getBank}
                              getOptionLabel={(option) => option}
                              onChange={(e, v) => setBank(v)}
                              sx={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  width="100%"
                                  onChange={({ target }) =>
                                    setBank(target.value)
                                  }
                                  variant="outlined"
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid container sx={{ mt: 3 }}>
                          <Grid container lg={12}>
                            <TextFieldComponent
                              heading="Terms and Condition"
                              name="terms_and_condition"
                              type="text"
                              error={
                                errors.terms_and_condition &&
                                touched.terms_and_condition
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 3 }}>
                          <Grid container lg={12}>
                            <TextFieldComponent
                              heading="Notes"
                              name="notes"
                              type="text"
                              error={errors.notes && touched.notes}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          justifyContent="flex-end"
                          sx={{ mt: 3 }}
                        >
                          <Button
                            variant="contained"
                            sx={{ mr: 2 }}
                            color="secondary"
                            type="submit"
                          >
                            <Add sx={{ mr: 1 }} />
                            Update
                          </Button>
                          <Link
                            to="/purchase-orders"
                            style={{ textDecoration: "none" }}
                          >
                            <Button variant="contained" color="error">
                              <Cancel sx={{ mr: 1 }} />
                              Cancel
                            </Button>
                          </Link>
                        </Grid>
                      </Item>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default EditPurchaseOrder;

const defaultData = [
  {
    value: "item",
  },
  {
    value: "add_Desc",
  },
  {
    value: "quantity",
  },
  {
    value: "rate",
  },
  {
    value: "discount",
  },
  {
    value: "tax",
  },
   {
    value: "tax_amount",
  },
  {
    value: "amount",
  },
];
