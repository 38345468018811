import React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
const PurchaseOrderTableSecond = ({ item, sum2, taxSum }) => {
  const cgstFun = (amountVal, taxVal) => {
    let TaxableTotal = 0;
    let TotalGst = 0;
    item?.item_details.map((item) => {
      TaxableTotal += (item.amount * 100) / (100 + item.tax);
    });
    let myTotal =
      ((Number(item?.forwarding_charges) / TaxableTotal) * (amountVal * 100)) /
        (100 + taxVal) +
      (amountVal * 100) / (100 + taxVal);

    TotalGst = (myTotal * taxVal) / 100;
  
    return TotalGst / 2;
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" width="30%" fontSize={12}>
              Taxable Value
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ padding: "0", margin: "0", fontSize: "12px" }}
              width="25%"
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <sapn style={{ borderBottom: "1px solid black" }}>
                  {" "}
                  Central Tax
                </sapn>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                >
                  <span>Rate</span>
                  <sapn>Amount</sapn>
                </div>
              </div>
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ padding: "0", margin: "0", fontSize: "12px" }}
              width="25%"
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <sapn style={{ borderBottom: "1px solid black" }}>
                  {" "}
                  State Tax
                </sapn>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                >
                  <span>Rate</span>
                  <sapn>Amount</sapn>
                </div>
              </div>
            </StyledTableCell>
            <StyledTableCell width="20%" align="center" fontSize={12}>
              Total Tax Amount
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {item?.item_details?.map((row) => {
            cgstFun();
            let sum =
              ((row?.amount / 100) * row?.tax) / 2 +
              ((row?.amount / 100) * row?.tax) / 2;
            return (
              <StyledTableRow
                key={row.name}
                sx={{
                  "&:last-child td, &:last-child th": { borderBottom: 0 },
                }}
              >
                <StyledTableCell component="th" align="right" scope="row">
                  {(row?.amount + ( item?.forwarding_charges / item?.item_details.length) ).toFixed(2)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{row?.tax / 2}%</span>
                    <span>
                      {/* {(((row?.amount / 100) * row?.tax) / 2)?.toFixed(2)} */}
                      {/*cgstFun((row?.amount + ( item?.forwarding_charges / item?.item_details.length) ), row?.tax)?.toFixed(2)*/}
					  
					  { (((((row?.amount + ( item?.forwarding_charges / item?.item_details.length) )* 100) / (100 + row?.tax) ) * row?.tax / 100 ) / 2).toFixed(2) }
					  
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{row?.tax / 2}%</span>
                    <span>
                      {/* {(((row?.amount / 100) * row?.tax) / 2)?.toFixed(2)} */}
                      { /*cgstFun((row?.amount + ( item?.forwarding_charges / item?.item_details.length) ), row?.tax)?.toFixed(2) */}
					  
					  { (((((row?.amount + ( item?.forwarding_charges / item?.item_details.length) )* 100) / (100 + row?.tax) ) * row?.tax / 100 ) / 2).toFixed(2) }
					  
					  
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {/* {sum?.toFixed(2)} */}
                  {cgstFun(row?.amount, row?.tax)?.toFixed(2) * 2}
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
          <StyledTableRow
            sx={{
              "&:last-child td, &:last-child th": { borderBottom: 0 },
              fontWeight: "800",
            }}
          >
            <StyledTableCell
              component="th"
              align="right"
              style={{ fontWeight: "700" }}
              scope="row"
            >
              Total :{" "}
              {item?.total}
            </StyledTableCell>
            <StyledTableCell style={{ fontWeight: "700" }} align="center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span></span>
                <span>
                  {" "}
                  {/* {item?.item_details
                    ?.reduce((a, v) => (a = a + (v.amount / 100) * 9), 0)
                    ?.toFixed(2)} */}
                  {(item?.total_tax  / 2)?.toFixed(2)}
                </span>
              </div>
            </StyledTableCell>
            <StyledTableCell style={{ fontWeight: "700" }} align="center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span></span>
                <span>
                  {" "}
                  {/* {item?.item_details
                    ?.reduce((a, v) => (a = a + (v.amount / 100) * 9), 0)
                    ?.toFixed(2)} */}
                 {(item?.total_tax  / 2)?.toFixed(2)}
                </span>
              </div>
            </StyledTableCell>
            <StyledTableCell style={{ fontWeight: "700" }} align="center">
              {/* {sum2?.toFixed(2)} */}
               {(item?.total_tax)?.toFixed(2)}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PurchaseOrderTableSecond;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: "1px solid black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    border: "1px solid black",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    border: "1px solid black",
  },
  "&:nth-of-type(even)": {
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // fontWeight: "600",
  },
}));
