import { CLEAR_ERRORS } from "../constants/MastersConstants";
import {
  SALE_REPORT_FAIL,
  SALE_REPORT_REQUEST,
  SALE_REPORT_SUCCESS,
} from "../constants/SaleReportConstant";

export const saleReportReducer = (state = { saleReport: [] }, action) => {
  switch (action.type) {
    case SALE_REPORT_REQUEST:
      return { loading: true, saleReport: [] };
    case SALE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        saleReport: action.payload,
      };

    case SALE_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        saleReport: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
