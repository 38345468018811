import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled } from "@mui/system";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../Redux/actions/MastersActions";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import {
  DELETE_BRAND_RESET,
  UPDATE_BRAND_RESET,
} from "../../Redux/constants/InventryConstant";
import {
  getBrands,
  deleteBrands,
  updateBrands,
} from "../../Redux/actions/InventryActions";
import { createGrn } from "../../Redux/actions/GrnActions";
import { getPurchaseOrder } from "../../Redux/actions/PurchaseOrderAction";
import { getGrnNumber } from "../../Redux/actions/GrnActions";
import Loader from "../Common/Loader";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "items",
    numeric: true,
    disablePadding: true,
    label: "Items & Description",
  },
  {
    id: "ordered",
    numeric: false,
    disablePadding: true,
    label: "Ordered",
  },
  {
    id: "received",
    numeric: false,
    disablePadding: true,
    label: "Received",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "Quantity to Receive",
  },
  {
    id: "warehouse",
    numeric: false,
    disablePadding: true,
    label: "Warehouse Location",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "2px" : "normal"}
            // margin={headCell.numeric ? "2px" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function AwaitingGRNTable(props) {
  const { grnReceivedData, setGrnReceivedData, handleChangeNote } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("brand");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { error, purchaseOrders } = useSelector(
    (state) => state.purchaseOrders
  );
  const { loading } = useSelector((state) => state.newGrn);
  const purchaseOrdersData = purchaseOrders.map((item) => item);
  const { grnNumber } = useSelector((state) => state.grnNumber);
  let checkReceived = 0;

  // const handleCall = () => {
  //   console.log("heeeeeeeeeee");
  //   let qytReceivedData = [];
  //   for (let i = 0; i < grnReceivedData.item_details.length; i++) {
  //     let Mydata = {
  //       ...grnReceivedData.item_details[i],
  //       ["qyt_received"]: purchaseOrdersData[0]?.item_details[i].quantity,
  //       ["wh_location"]: "",
  //     };
  //     qytReceivedData.push(Mydata);
  //   }
  //   console.log(qytReceivedData, "??????????????");
  //   let ordered = qytReceivedData.reduce((a, v) => (a = a + v.ordered), 0);
  //   let recived = qytReceivedData.reduce((a, v) => (a = a + v.qyt_received), 0);
  //   setGrnReceivedData({
  //     ...grnReceivedData,
  //     item_details: qytReceivedData,
  //     total_ordered: ordered,
  //     total_received: recived,
  //     po: purchaseOrdersData[0].po,
  //   });
  // };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (loading) {
      dispatch(getPurchaseOrder(purchaseOrdersData[0].po));
      dispatch(getGrnNumber());
    }
    if (purchaseOrders || grnNumber) {
      // setGrnReceivedData({
      //   ...grnReceivedData,
      //   common_id: purchaseOrdersData[0]?._id,
      //   vendor: purchaseOrdersData[0]?.vendor,
      //   warehouse: purchaseOrdersData[0]?.deliver_to,
      //   grn: grnNumber,
      //   received_date: DateValue,
      //   item_details: purchaseOrdersData[0]?.item_details,
      // });
      let qytReceivedData = [];
      let qytReceivedData1 = purchaseOrdersData[0]?.item_details;
      for (let i = 0; i < purchaseOrdersData[0]?.item_details.length; i++) {
        let Mydata = {
          ...qytReceivedData1[i],
          ["qyt_received"]: purchaseOrdersData[0]?.item_details[i].ordered,
          ["wh_location"]: "",
        };
        qytReceivedData.push(Mydata);
      }
      let ordered = qytReceivedData.reduce((a, v) => (a = a + v.quantity), 0);
      let recived = qytReceivedData.reduce(
        (a, v) => (a = a + v.qyt_received),
        0
      );
      setGrnReceivedData({
        ...grnReceivedData,
        common_id: purchaseOrdersData[0]?._id,
        vendor: purchaseOrdersData[0]?.vendor,
        warehouse: purchaseOrdersData[0]?.deliver_to,
        grn: grnNumber,
        item_details: qytReceivedData,
        total_ordered: ordered,
        total_received: recived,
        po: purchaseOrdersData[0].po,
      });
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [
    dispatch,
    navigate,
    error,
    alert,
    isAuthenticated,
    purchaseOrders,
    loading,
    grnNumber,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = purchaseOrders.map((n) => n.vendor);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - purchaseOrders.length)
      : 0;

  var perPageData = [];
  var length = Math.ceil(purchaseOrders.length / 3);

  for (var i = 1; i <= length; i++) {
    perPageData.push(i * 3);
  }
  const handleChange = (e, ind) => {
    let updated;
    if (e.target.name === "qyt_received") {
      updated = grnReceivedData.item_details.map((r, inde) =>
        inde === ind
          ? {
              ...r,
              ["qyt_received"]: Number(e.target.value),
              ["recived"]: r.quantity - r.ordered,
            }
          : r
      );
    } else {
      updated = grnReceivedData.item_details.map((r, inde) =>
        inde === ind
          ? {
              ...r,
              ["wh_location"]: e.target.value,
            }
          : r
      );
    }
    let ordered = updated.reduce((a, v) => (a = a + v.quantity), 0);
    let recived = updated.reduce((a, v) => (a = a + v.qyt_received), 0);
    setGrnReceivedData({
      ...grnReceivedData,
      item_details: updated,
      total_ordered: ordered,
      total_received: recived,
      po: purchaseOrdersData[0].po,
    });
  };
  const handleReceiveGRN = () => {
    console.log(
      grnReceivedData.item_details.every((item) =>
        console.log(item?.qyt_received <= checkReceived)
      ),
      "eeeee",
      checkReceived,
      grnReceivedData.item_details
    );
    {
      grnReceivedData.item_details.every(
        (item, ind) =>
          item?.qyt_received <= item?.ordered && item?.qyt_received >= 0
      )
        ? dispatch(createGrn(grnReceivedData))
        : alert.error("Quantity to receive Grn are wrong");
    }
    // dispatch(createGrn(grnReceivedData));
  };

  return (
    <>
      {error ? (
        <Grid container xs={12} justifyContent="center" alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: "bold", color: "red" }}>
            You don't have the required permissions.
          </Typography>
        </Grid>
      ) : (
        <>
          {purchaseOrdersData[0]?.item_details?.every(
            (item, ind) => item.ordered === 0
          ) ? (
            ""
          ) : (
            <Box sx={{ width: "100%" }}>
              <Paper
                sx={{
                  width: "100%",
                  mb: 2,
                  boxShadow: "none",
                  border: "none",
                  p: 2,
                }}
              >
                <EnhancedTableToolbar numSelected={selected?.length} />
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected?.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={purchaseOrders?.length}
                    />

                    <TableBody>
                      {stableSort(purchaseOrders, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, index) => {
                          const isItemSelected = isSelected(item.vendor);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return item.item_details.map((r, ind) => {
                            checkReceived = r?.ordered;
                            console.log(checkReceived, "check");
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={item?._id}
                                selected={isItemSelected}
                              >
                                <TableCell
                                  // component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {r?.item}
                                </TableCell>
                                <TableCell
                                  // component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {r?.quantity}
                                </TableCell>
                                <TableCell
                                  // component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {r?.quantity - r?.ordered}
                                </TableCell>
                                <TableCell
                                  // component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  <TextField
                                    size="small"
                                    type="number"
                                    id="qyt_received"
                                    name="qyt_received"
                                    InputProps={
                                      r?.ordered === 0
                                        ? { readOnly: true }
                                        : {
                                            inputProps: {
                                              min: 0,
                                              max: r?.ordered,
                                            },
                                          }
                                    }
                                    defaultValue={r?.ordered}
                                    onChange={(e) => handleChange(e, ind)}
                                  />
                                </TableCell>
                                <TableCell
                                  // component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  <TextField
                                    size="small"
                                    type="text"
                                    id="wh_location"
                                    name="wh_location"
                                    InputProps={
                                      r?.ordered === 0 ? { readOnly: true } : ""
                                    }
                                    defaultValue=""
                                    onChange={(e) => handleChange(e, ind)}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          });
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={perPageData}
                  component="div"
                  count={purchaseOrders?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Grid lg={12}>
                  <Grid
                    container
                    sx={{ mt: 2, ml: 2 }}
                    justifyContent="flex-start"
                  >
                    <Typography>Notes (For Internal Use Only)</Typography>
                  </Grid>
                  <TextField
                    name="note"
                    id="note"
                    aria-label="empty textarea"
                    multiline
                    minRows={3}
                    style={{ width: "97%" }}
                    value={grnReceivedData?.notes}
                    onChange={(e) => handleChangeNote(e)}
                  />
                </Grid>
                <Grid container sx={{ mt: 2, ml: 2, mb: 2 }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleReceiveGRN}
                  >
                    Receive
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ ml: 2, backgroundColor: "black" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Paper>
            </Box>
          )}
        </>
      )}
    </>
  );
}
