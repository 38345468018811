import { Button, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import { Outlet, Link } from "react-router-dom";
import React from "react";
import { useLocation } from "react-router";

const CustomButton = styled(Button)(({ theme }) => ({
  color: "#1976d2",
  textTransform: "none",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: blue[900],
    color: theme.palette.getContrastText(blue[500]),
  },
}));

function EcommerceButton() {
  let location = useLocation();
  React.useEffect(() => {
    console.log(location.pathname);
  }, [location]);
  return (
    <>
      <Grid container>
        <Grid item sx={{ "& button": { m: "4px" }, mt: 3 }} spacing={2}>
          <Link
            to="/ecommerce/dashboard"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CustomButton
              variant="outlined"
              style={
                location.pathname === "/ecommerce/dashboard"
                  ? { color: "white", backgroundColor: "#0d47a1" }
                  : {}
              }
            >
              Dashboard
            </CustomButton>
          </Link>
          <Link
            to="/ecommerce/sales"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CustomButton
              variant="outlined"
              style={
                location.pathname === "/ecommerce/sales"
                  ? { color: "white", backgroundColor: "#0d47a1" }
                  : {}
              }
            >
              Sales
            </CustomButton>
          </Link>
          <Link
            to="/ecommerce/enquiry"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CustomButton
              variant="outlined"
              style={
                location.pathname === "/ecommerce/enquiry"
                  ? { color: "white", backgroundColor: "#0d47a1" }
                  : {}
              }
            >
              Enquiry
            </CustomButton>
          </Link>
          <Link
            to="/ecommerce/reviews"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CustomButton
              variant="outlined"
              style={
                location.pathname === "/ecommerce/reviews"
                  ? { color: "white", backgroundColor: "#0d47a1" }
                  : {}
              }
            >
              Reviews
            </CustomButton>
          </Link>
          <Link
            to="/ecommerce/related-products"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CustomButton
              variant="outlined"
              style={
                location.pathname === "/ecommerce/related-products"
                  ? { color: "white", backgroundColor: "#0d47a1" }
                  : {}
              }
            >
              Related Products
            </CustomButton>
          </Link>
          <Link
            to="/ecommerce/deals"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CustomButton
              variant="outlined"
              style={
                location.pathname === "/ecommerce/deals"
                  ? { color: "white", backgroundColor: "#0d47a1" }
                  : {}
              }
            >
              Deals
            </CustomButton>
          </Link>
          <Link
            to="/ecommerce/customers"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CustomButton
              variant="outlined"
              style={
                location.pathname === "/ecommerce/customers"
                  ? { color: "white", backgroundColor: "#0d47a1" }
                  : {}
              }
            >
              Customers
            </CustomButton>
          </Link>
          <Link
            to="/ecommerce/pincode"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CustomButton
              variant="outlined"
              style={
                location.pathname === "/ecommerce/pincode"
                  ? { color: "white", backgroundColor: "#0d47a1" }
                  : {}
              }
            >
              PinCode
            </CustomButton>
          </Link>
          <Link
            to="/ecommerce/questionnier"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CustomButton
              variant="outlined"
              style={
                location.pathname === "/ecommerce/questionnier"
                  ? { color: "white", backgroundColor: "#0d47a1" }
                  : {}
              }
            >
              Questionnier
            </CustomButton>
          </Link>
          <Link
            to="/ecommerce/contacts"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CustomButton
              variant="outlined"
              style={
                location.pathname === "/ecommerce/contacts"
                  ? { color: "white", backgroundColor: "#0d47a1" }
                  : {}
              }
            >
              Contacts
            </CustomButton>
          </Link>
          <Link
            to="/ecommerce/slider"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CustomButton
              variant="outlined"
              style={
                location.pathname === "/ecommerce/slider"
                  ? { color: "white", backgroundColor: "#0d47a1" }
                  : {}
              }
            >
              Slider
            </CustomButton>
          </Link>
          <Link
            to="/ecommerce/discount"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CustomButton
              variant="outlined"
              style={
                location.pathname === "/ecommerce/discount"
                  ? { color: "white", backgroundColor: "#0d47a1" }
                  : {}
              }
            >
              Discount
            </CustomButton>
          </Link>
          <Link
            to="/ecommerce/cust-group"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CustomButton
              variant="outlined"
              style={
                location.pathname === "/ecommerce/cust-group"
                  ? { color: "white", backgroundColor: "#0d47a1" }
                  : {}
              }
            >
              Cust Group
            </CustomButton>
          </Link>
          <Link
            to="/ecommerce/cust-sub-gp"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CustomButton
              variant="outlined"
              style={
                location.pathname === "/ecommerce/cust-sub-gp"
                  ? { color: "white", backgroundColor: "#0d47a1" }
                  : {}
              }
            >
              Cust Sub-Gp
            </CustomButton>
          </Link>
        </Grid>

        <Outlet />
      </Grid>
    </>
  );
}

export default EcommerceButton;
