import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { Box } from "@mui/system";
const Excle = ({ headCells, data, btn, name, color, select, headCells2,defaultData2 }) => {
  const ref = React.createRef();
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }, [setLoading]);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "data",
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);
  return (
    <>
      {" "}
      {select === "print" ? (
        <Button
          variant="contained"
          onClick={handlePrint}
          sx={{ textTransform: "none" }}
        >
          Print
        </Button>
      ) : select === "Excle" ? (
        <Button
          variant="contained"
          className={color === "transparent" ? "btn" : ""}
          sx={{
            background: color,
          }}
        >
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            table="table-to-xls"
            filename="data"
            sheet="tablexls"
            className="download-table-xls-button"
            buttonText={btn}
          />
        </Button>
      ) : (
        ""
      )}
      <TableContainer component={Paper} style={{ display: "none" }}>
        <Table
          ref={componentRef}
          sx={{ minWidth: 650 }}
          id="table-to-xls"
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {headCells.map((r) => {
                return <TableCell>{r.label}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              return (
                <>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    // style={{ width: "100%" }}
                  >
                    <TableCell align="left">{index + 1}</TableCell>
                    {name === "warehouse" ? (
                      <>
                        <TableCell align="left">{row.warehouse_name}</TableCell>
                        <TableCell align="left">{row.attention}</TableCell>
                        <TableCell align="left">{row.city}</TableCell>
                        <TableCell align="left">{row.zip_code}</TableCell>
                        <TableCell align="left">{row.phone}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                      </>
                    ) : name === "state" ? (
                      <>
                        <TableCell align="left">{row.state}</TableCell>
                        <TableCell align="left">{row.country}</TableCell>
                      </>
                    ) : name === "country" ? (
                      <TableCell align="left">{row.country}</TableCell>
                    ) : name === "city" ? (
                      <>
                        <TableCell align="left">{row.city}</TableCell>
                        <TableCell align="left">{row.state}</TableCell>
                        <TableCell align="left">{row.country}</TableCell>
                        <TableCell align="left">{row.zipcode}</TableCell>
                      </>
                    ) : name === "sourceofsupply" ? (
                      <TableCell align="left">{row.name}</TableCell>
                    ) : name === "paymentterms" ? (
                      <TableCell align="left">{row.name}</TableCell>
                    ) : name === "gst" ? (
                      <TableCell align="left">{row.name}</TableCell>
                    ) : name === "gstClasic" ? (
                      <TableCell align="left">{row.name}</TableCell>
                    ) : name === "bank" ? (
                      <>
                        <TableCell align="left">{row.bank_name}</TableCell>
                        <TableCell align="left">
                          {row.bank_account_no}
                        </TableCell>
                        <TableCell align="left">{row.bank_branch}</TableCell>
                        <TableCell align="left">{row.ifsc_code}</TableCell>
                        <TableCell align="left">{row.is_on}</TableCell>
                      </>
                    ) : name === "dispatch" ? (
                      <>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.mobile}</TableCell>
                        <TableCell align="left">{row.password}</TableCell>
                        <TableCell align="left">{row.user_type}</TableCell>
                      </>
                    ) : name === "module" ? (
                      <TableCell align="left">{row.name}</TableCell>
                    ) : name === "vendor" ? (
                      <>
                        <TableCell align="left" style={{ fontWeight: "600" }}>
                          {row.name}
                        </TableCell>
                        <TableCell align="left" style={{ fontWeight: "600" }}>
                          {row.email}
                        </TableCell>
                        <TableCell align="left" style={{ fontWeight: "600" }}>
                          {row.mobile}
                        </TableCell>
                        <TableCell align="left" style={{ fontWeight: "600" }}>
                          {row.pan_number}
                        </TableCell>
                      </>
                    ) : name === "customer" ? (
                      <>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.mobile}</TableCell>
                        <TableCell align="left">{row.group}</TableCell>
                        <TableCell align="left">{row.gst_treatment}</TableCell>
                        <TableCell align="left">{row.gst_number}</TableCell>
                        <TableCell align="left">{row.source}</TableCell>
                      </>
                    ) : name === "PO" ? (
                      <>
                        <TableCell align="left">
                          {" "}
                          {new Date(row.date).toLocaleDateString("en-GB", 2)}
                        </TableCell>
                        <TableCell align="left">{row.po}</TableCell>
                        <TableCell align="left">{row.vendor}</TableCell>
                        <TableCell align="left">{row.total}</TableCell>
                      </>
                    ) : name === "tax" ? (
                      <>
                        <TableCell align="left">
                          {" "}
                          {new Date(row.date).toLocaleDateString("en-GB", 2)}
                        </TableCell>
                        <TableCell align="left">
                          {row.invoice_number
                            ? row.invoice_number
                            : row.performa_invoice_number}
                        </TableCell>
                        <TableCell align="left">{row.customer}</TableCell>
                        <TableCell align="left">{row.total}</TableCell>
                      </>
                    ) : name === "product" ? (
                      <>
                        <TableCell align="left">{row.product_code}</TableCell>
                        <TableCell align="left">{row.product_title}</TableCell>
                        <TableCell align="left">
                          {row.product_category}
                        </TableCell>
                      </>
                    ) : name === "brand" ? (
                      <TableCell align="left">{row.name}</TableCell>
                    ) : name === "productCategory" ? (
                      <>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.item_group}</TableCell>
                      </>
                    ) : name === "productGroup" ? (
                      <TableCell align="left">{row.name}</TableCell>
                    ) : name === "user" ? (
                      <>
                        <TableCell align="left">{row.username}</TableCell>
                        <TableCell align="left">{row.password}</TableCell>
                      </>
                    ) : (
                      ""
                    )}
                  </TableRow>
                  {name === "customer" || name === "vendor" ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          padding: "5px",
                          fontWeight: "600",
                        }}
                      >
                        <p style={{ marginRight: "8px" }}>Billing</p>
                        <p> Address</p>
                      </div>
                      <TableRow>
                        <TableCell colSpan={11}>
                          <Box sx={{border:"1px solid black"}}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {headCells2.map((r) => {
                                    return <TableCell>{r.label}</TableCell>;
                                  })}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(name === "vendor" ? row.vendor_billing_address : row.customer_billing_address).map(
                                  (row, index) => {
                                    return (
                                      <TableRow>
                                        <TableCell align="left">
                                          {index + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.street_1}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.street_2}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.city}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.state}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.country}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.zip_code}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.phone}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.email}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <div
                        style={{
                          display: "flex",
                          padding: "5px",
                          fontWeight: "600",
                        }}
                      >
                        <p style={{ marginRight: "8px" }}>Shipping</p>
                        <p> Address</p>
                      </div>
                      <TableRow>
                        <TableCell colSpan={11}>
                        <Box sx={{border:"1px solid black"}}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {headCells2.map((r) => {
                                    return <TableCell>{r.label}</TableCell>;
                                  })}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                              {(name === "vendor" ? row.vendor_shipping_address : row.customer_shipping_address).map(
                                  (row, index) => {
                                    return (
                                      <TableRow>
                                        <TableCell align="left">
                                          {index + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.street_1}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.street_2}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.city}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.state}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.country}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.zip_code}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.phone}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.email}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </TableCell>
                      </TableRow>

                      <div
                        style={{
                          display: "flex",
                          padding: "5px",
                          fontWeight: "600",
                        }}
                      >
                        <p style={{ marginRight: "8px" }}>Designation</p>
                      </div>
                      <TableRow>
                        <TableCell colSpan={11}>
                        <Box sx={{border:"1px solid black"}}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {defaultData2.map((r) => {
                                    return <TableCell>{r.label}</TableCell>;
                                  })}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(name === "vendor" ? row.Designation : row.Designation).map(
                                  (row, index) => {
                                    return (
                                      <TableRow>
                                        <TableCell align="left">
                                          {index + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.name}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.email}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.phone}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.department}
                                        </TableCell>
                                        <TableCell align="left">
                                          {row.designation}
                                        </TableCell> 
                                      </TableRow>
                                    );
                                  }
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Excle;
