import { CLEAR_ERRORS } from "../constants/MastersConstants";
import {
    NEW_PERFORMA_INVOICE_FAIL,
    NEW_PERFORMA_INVOICE_REQUEST,
  NEW_PERFORMA_INVOICE_RESET,
  NEW_PERFORMA_INVOICE_SUCCESS,
  NEW_TAX_INVOICE_FAIL,
  NEW_TAX_INVOICE_REQUEST,
  NEW_TAX_INVOICE_RESET,
  NEW_TAX_INVOICE_SUCCESS,
  PERFORMA_INVOICE_FAIL,
  PERFORMA_INVOICE_REQUEST,
  PERFORMA_INVOICE_SUCCESS,
  TAX_INVOICE_FAIL,
  TAX_INVOICE_REQUEST,
  TAX_INVOICE_SUCCESS,
  UPDATE_PERFORMA_INVOICE_FAIL,
  UPDATE_PERFORMA_INVOICE_REQUEST,
  UPDATE_PERFORMA_INVOICE_RESET,
  UPDATE_PERFORMA_INVOICE_SUCCESS,
  UPDATE_TAX_INVOICE_FAIL,
  UPDATE_TAX_INVOICE_REQUEST,
  UPDATE_TAX_INVOICE_RESET,
  UPDATE_TAX_INVOICE_SUCCESS,
} from "../constants/SalesConstant";

export const taxInvoiceReducer = (state = { salesOrder: [] }, action) => {
  switch (action.type) {
    case TAX_INVOICE_REQUEST:
      return { loading: true, salesOrder: [] };
    case TAX_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        salesOrder: action.payload,
      };

    case TAX_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        salesOrder: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const newTaxInvoiceReducer = (state = { newTaxInvoice: {} }, action) => {
  switch (action.type) {
    case NEW_TAX_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_TAX_INVOICE_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        newPurchaseOrder: action.payload.response,
      };
    case NEW_TAX_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_TAX_INVOICE_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const updateTaxInvoiceReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TAX_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_TAX_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };

    case UPDATE_TAX_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_TAX_INVOICE_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};


export const performaInvoiceReducer = (state = { performaInvoices: [] }, action) => {
    switch (action.type) {
      case PERFORMA_INVOICE_REQUEST:
        return { loading: true, performaInvoices: [] };
      case PERFORMA_INVOICE_SUCCESS:
        return {
          ...state,
          loading: false,
          performaInvoices: action.payload,
        };
  
      case PERFORMA_INVOICE_FAIL:
        return {
          ...state,
          loading: false,
          performaInvoices: null,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  export const newPerformaInvoiceReducer = (state = { newPerformaInvoice: {} }, action) => {
    switch (action.type) {
      case NEW_PERFORMA_INVOICE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_PERFORMA_INVOICE_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
          newPerformaInvoice: action.payload.response,
        };
      case NEW_PERFORMA_INVOICE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_PERFORMA_INVOICE_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  export const updatePerformaInvoiceReducer = (state = {}, action) => {
    switch (action.type) {
      case UPDATE_PERFORMA_INVOICE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_PERFORMA_INVOICE_SUCCESS:
        return {
          ...state,
          loading: false,
          isUpdated: action.payload,
        };
  
      case UPDATE_PERFORMA_INVOICE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case UPDATE_PERFORMA_INVOICE_RESET:
        return {
          ...state,
          isUpdated: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
