import { DialogContentText, Grid, TextField } from "@mui/material";
import React from "react";

const DistanceTableRow = ({
  inputData,
  onChangeInput
}) => {
  return (
    <Grid lg={12} md={12} container spacing={1}>
      <Grid lg={3} md={3} sm={12} xs={12} item>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <DialogContentText>Km</DialogContentText>
          <TextField
            autoFocus
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
            sx={{ mt: 1 }}
            value={inputData.km}
            name="km"
            onChange={(e) => onChangeInput(e)}
          />
        </div>
      </Grid>
      <Grid lg={12} md={12} sm={12} xs={12} item>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginRight: "5px",
              }}
            >
              <p>0 to 150 kg</p>
              <TextField
                autoFocus
                size="small"
                margin="dense"
                fullWidth
                variant="outlined"
                sx={{ mt: 1, mr: 1 }}
                value={inputData?.slab1?.rate}
                name="rate"
                onChange={(e) => onChangeInput(e, "slab1")}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <p>Total</p>
              <TextField
               
                size="small"
                margin="dense"
                fullWidth
                value={inputData?.slab1?.total}
				name="total"
			    onChange={(e) => onChangeInput(e, "slab1")}

              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginRight: "5px",
              }}
            >
              <p>150 to 250 kg</p>
              <TextField
                autoFocus
                size="small"
                margin="dense"
                fullWidth
                variant="outlined"
                sx={{ mt: 1, mr: 1 }}
                value={inputData?.slab2?.rate}
                name="rate"
                onChange={(e) => onChangeInput(e, "slab2")}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <p>Total</p>
              <TextField
                 
                size="small"
                margin="dense"
                fullWidth
                value={inputData?.slab2?.total}
				name="total"
				 onChange={(e) => onChangeInput(e, "slab2")}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginRight: "5px",
              }}
            >
              <p>250 to 1220 kg</p>
              <TextField
                autoFocus
                size="small"
                margin="dense"
                fullWidth
                variant="outlined"
                sx={{ mt: 1, mr: 1 }}
                value={inputData?.slab3?.rate}
                name="rate"
                onChange={(e) => onChangeInput(e, "slab3")}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <p>Total</p>
              <TextField
               
                size="small"
                margin="dense"
                fullWidth
                value={inputData?.slab3?.total}
				name="total"
				 onChange={(e) => onChangeInput(e, "slab3")}
              />
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default DistanceTableRow;
