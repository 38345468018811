import {
  DialogContentText,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
} from "@mui/material";
import React from "react";

const PurchaseOrderDropdown = ({ heading, name, setInput, data, size,cu }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <DialogContentText>{heading}</DialogContentText>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        size="small"
        value={name}
        defaultValue={{ label: "Select Dept", value: name }}
        sx={{ mt: 1, width: size }}
        onChange={(e) => cu === "cust" ?  setInput(e) : setInput(e)}
      >
        {data ? data?.map((r) =>
          heading === "Select Vendor" ? (
            <MenuItem value={r?.name}>{r?.name}</MenuItem>
          ) : (
            <MenuItem value={r?.name}>{r?.name}</MenuItem>
          )
        ) : ""}
      </Select>
    </div>
  );
};

export default PurchaseOrderDropdown;
