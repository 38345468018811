import { DialogContentText, TextField } from "@mui/material";
import React from "react";

const PurchaseOrderInput = ({
  heading,
  setInput,
  name,
  placeholder,
  post,
  sales,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <DialogContentText style={{ marginBottom: "5px", fontSize: "14px" }}>
        {heading}
      </DialogContentText>

      {heading === "Purchase Invoice" || heading === "PO#" ? (
        <TextField
          disabled
          id="filled-disabled"
       
          margin="dense"
          defaultValue={post}
          variant="outlined"
          size="small"
          placeholder={placeholder}
          style={{ padding: "0", margin: "0", width: "90%" }}
          sx={{ mt: 2 }}
          value={name}
          onChange={(e) => setInput(e.target.value)}
        />
      ) : heading === "Invoice#" ? (
        <TextField
          disabled
          id="filled-disabled"
         
          defaultValue={post}
          variant="outlined"
          size="small"
          placeholder={placeholder}
          style={{ padding: "0", margin: "0", width: "90%" }}
          sx={{ mt: 2 }}
          value={name}
          onChange={(e) => setInput(e.target.value)}
        />
      ) : heading === "Billing To:" ? (
        <TextField
          size="small"
          type="text"
          disabled={sales ? true : false}
          variant="outlined"
          multiline
          style={{ padding: "0", margin: "0", width: "90%" }}
          sx={{ mt: 2 }}
          value={name}
          onChange={(e) => setInput(e.target.value)}
        />
      ) : heading === "Delivery To:" ? (
        <TextField
          size="small"
          type="text"
          disabled={sales ? true : false}
          variant="outlined"
          multiline
          style={{ padding: "0", margin: "0", width: "90%" }}
          sx={{ mt: 2 }}
          value={name}
          onChange={(e) => setInput(e.target.value)}
        />
      ) : (
        <TextField
          size="small"
          type="text"
          fullWidth
          disabled={sales ? true : false}
          placeholder={placeholder}
          variant="outlined"
          style={{ padding: "0", margin: "0", width: "90%" }}
          sx={{ mt: 2 }}
          value={name}
          onChange={(e) => setInput(e.target.value)}
        />
      )}
    </div>
  );
};

export default PurchaseOrderInput;
