import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Checkbox } from "@mui/material";

const UserTableInner = ({
  heading,
  create,
  remove,
  edit,
  view,
  setUpdatedata,
  data,
  editPage
}) => {
  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <TableCell>{heading}</TableCell>
      <TableCell align="right">
        <Checkbox
          onChange={(event) => setUpdatedata(event, heading, create)}
          name={create}
          checked={editPage ? data[heading][create] : ""}
        />
      </TableCell>
      <TableCell align="right">
        <Checkbox
          onChange={(event) => setUpdatedata(event, heading, remove)}
          name={remove}
          checked={editPage ? data[heading][remove] : ""}
        />
      </TableCell>
      <TableCell align="right">
        <Checkbox
          onChange={(event) => setUpdatedata(event, heading, edit)}
          name={edit}
          checked={editPage ? data[heading][edit] : ""}
        />
      </TableCell>
      <TableCell align="right">
        <Checkbox
          onChange={(event) => setUpdatedata(event, heading, view)}
          name={view}
          checked={editPage ? data[heading][view] : ""}
        />
      </TableCell>
    </TableRow>
  );
};

export default UserTableInner;
