import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import StockReportDrawer from "./StockReportDrawer/StockReportDrawer";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Loader from "../Common/Loader";
import { SwipeableDrawer } from "@mui/material";
import { getStockReport } from "../../Redux/actions/StockReportActions";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "sr",
    numeric: true,
    disablePadding: true,
    label: "Sr.no",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "wh_location",
    numeric: false,
    disablePadding: true,
    label: "Warehouse Location",
  },
  {
    id: "total_received",
    numeric: false,
    disablePadding: true,
    label: "Total Received",
  },
  {
    id: "total_sale",
    numeric: false,
    disablePadding: true,
    label: "Total Sale",
  },
  {
    id: "scan_remain",
    numeric: false,
    disablePadding: true,
    label: "Scan Remain",
  },
  {
    id: "on_dispatch",
    numeric: false,
    disablePadding: true,
    label: "On Dispatch",
  },
  {
    id: "stock_left",
    numeric: false,
    disablePadding: true,
    label: "Stock In Hand",
  },
  {
    id: "summary",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Stock Report
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function StockReportTable(props) {
  console.log(props.Data);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("country");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  const handleClickOpen = (cou, id) => {
    // setOpen(true);
    // setUpdatedata(cou);
  };
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.admin);
  const { loading, stockReport } = useSelector((state) => state.stockReport);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getStockReport());
    }

    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
  }, [dispatch, navigate, isAuthenticated]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = stockReport.map((n) => n.stockReport);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - stockReport.length) : 0;

  var perPageData = [];
  var length = Math.ceil(stockReport.length / 3);

  for (var i = 1; i <= length; i++) {
    perPageData.push(i * 3);
  }

  const [state, setState] = React.useState({
    right: false,
  });
  const [data, setData] = React.useState({ code: "", name: "" });
  const toggleDrawer = (anchor, open, item) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    // setData(item);
  };
  const list = (anchor, item) => (
    <Box
      sx={{ width: 850 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
    >
      <StockReportDrawer
        code={data.code}
        name={data.name}
        toggleDrawer={toggleDrawer}
      />
    </Box>
  );
  const setDataFunc = (code, name) => {
    setData({ ...data, code: code, name: name });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Paper
          // sx={{ width: "100%", mb: 2, boxShadow: "none", border: "none" }}
          >
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={stockReport.length}
                />
                <TableBody>
                  {stableSort(stockReport, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => {
                      const isItemSelected = isSelected(item.product_title);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          // aria-checked={isItemSelected}
                          tabIndex={-1}
                          // key={country.country}
                          // selected={isItemSelected}
                        >
                          <TableCell
                            // component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            // component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {item.name}
                          </TableCell>
                          <TableCell>
                            <TextField
                              size="small"
                              value={item.data.warehouse_location}
                            />
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            <Typography
                              style={{
                                backgroundColor: "pink",
                                color: "white",
                                width: "80%",
                              }}
                            >
                              {`${
                                item.product_codes.length +
                                item.scanned_barcode.length
                              }`}
                            </Typography>
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            <Typography
                              style={{
                                backgroundColor: "green",
                                color: "white",
                                width: "80%",
                              }}
                            >
                              {`${
                                item.product_codes.length +
                                item.scanned_barcode.length -
                                item.stock_and_prices[0].Stocks
                              }`}
                            </Typography>
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            <Typography
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                width: "80%",
                              }}
                            >
                              {item.product_codes.length}
                            </Typography>
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            <Typography
                              style={{
                                backgroundColor: "blue",
                                color: "white",
                                width: "80%",
                              }}
                            >
                              {item.scanned_barcode.length}
                            </Typography>
                          </TableCell>
                          <TableCell id={labelId} scope="row" padding="none">
                            <Typography
                              style={{
                                backgroundColor: "black",
                                color: "white",
                                width: "80%",
                              }}
                            >
                              {item.stock_and_prices[0].Stocks}
                            </Typography>
                          </TableCell>
                          <TableCell
                            // component="th"
                            // id={labelId}
                            scope="row"
                            padding="none"
                          >
                            <div>
                              <Button
                                onClick={toggleDrawer(
                                  "right",
                                  true,
                                  item.product_code_unique
                                )}
                              >
                                <div
                                  onClick={() =>
                                    setDataFunc(
                                      item.product_code_unique,
                                      item.name
                                    )
                                  }
                                >
                                  Summary
                                </div>
                              </Button>
                              <SwipeableDrawer
                                anchor="right"
                                open={state["right"]}
                                onClose={toggleDrawer("right", false)}
                                onOpen={toggleDrawer("right", true)}
                              >
                                {list("right", stockReport)}
                              </SwipeableDrawer>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={perPageData}
              component="div"
              count={stockReport.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  );
}
