import React from "react";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EditIcon from "@mui/icons-material/Edit";
import PurchaseOrderPdfTable from "../../PurchaseOrders/PurchaseOrderPdfTable";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import Pdf from "react-to-pdf";
import { useReactToPrint } from "react-to-print";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import converter from "number-to-words";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PurchaseOrderTableSecond from "../../PurchaseOrders/PurchaseOrderTableSecond";
const PerformaInvoiceDrawer = ({ item, POid, anchor, toggleDrawer }) => {
  const ref = React.createRef();

  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 2000);
    });
  }, [setLoading]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,

    removeAfterPrint: true,
  });
 console.log(item,"item")
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);
  let sum2 =
  item?.item_details?.reduce(
    (a, v) => (a = a + ((v.amount / 100) * v.tax) / 2),
    0
  ) +
  item?.item_details?.reduce(
    (a, v) => (a = a + ((v.amount / 100) * v.tax) / 2),
    0
  );

let qut = item?.item_details?.reduce((a, v) => (a = a + v.quantity), 0);
const cgstFun = () => {
  let TaxableTotal = 0;
  let TotalGst = 0;
  item?.item_details?.map((item) => {
    TaxableTotal += (item.amount * 100) / (100 + item.tax);
  });
  item?.item_details?.map((r) => {
    let myTotal =
      ((Number(item?.forwarding_charges) / TaxableTotal) *
        (r?.amount * 100)) /
        (100 + r?.tax) +
      (r?.amount * 100) / (100 + r?.tax);
    TotalGst += (myTotal * r?.tax) / 100;
  });
  return TotalGst / 2;
};
let cgst = cgstFun();
let sum = item?.item_details?.reduce(
  (a, v) => (a = a + (v.amount * 100) / (100 + v.tax)),
  0
);
let sumLast = sum + cgst + cgst + item?.forwarding_charges;
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "6px 35px",
          fontSize: "18px",
          borderBottom: "1px solid gray",
        }}
      >
        <p>Invoice Details</p>
        <CloseIcon onClick={toggleDrawer("right", false)} />
      </div>
      <div
        style={{
          width: "100%",
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "lightblue",
          margin: "20px 0",
        }}
      >
        <span style={{ fontSize: "23px", textTransform: "capitalize" }}>
          {" "}
          {item?.performa_invoice_number ? item?.performa_invoice_number : item?.invoice_number}
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "20%",
          }}
        >
          <PrintIcon onClick={handlePrint} style={{ fontSize: "30px" }} />
          <Pdf targetRef={ref} filename={item?.po}>
            {({ toPdf }) => (
              <PictureAsPdfIcon
                onClick={toPdf}
                style={{ fontSize: "30px", color: "red" }}
              />
            )}
          </Pdf>
          <Link to={`/purchase-orders/${item?._id}`}>
            <EditIcon color="primary" style={{ fontSize: "30px" }} />
          </Link>
        </div>
      </div>
      <div ref={componentRef} style={{ width: "95%" }}>
        <div ref={ref} style={{ padding: "0 25px " }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              border: "1px solid black",
              flex: "1",
            }}
          >
            <div style={{ flex: ".5", padding: "0 5px" }}>
              <p style={{ fontSize: "12px" }}>
                <span style={{ fontSize: "14px", fontWeight: "600" }}>
                  {" "}
                  Beacon Trade India
                </span>
                <br />A Type 8/10 Opp Nav prerna chs,
                <br />
                Sector 15, Vashi, Navi Mumbai,
                <br />
                Maharashtra 400703
                <br />
                GSTIN 27GNTPS5698A1Z3
              </p>
            </div>
            <div
              style={{
                flex: ".5",
                display: "flex",
                flexWrap: "wrap",
                fontSize: "13px",
              }}
            >
              <span
                style={{
                  width: "50%",
                  border: "1px solid black",
                  padding: "2px",
                }}
              >
                Dated <br />
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  {new Date(item?.date).toLocaleDateString("en-GB", 2)}
                </span>
              </span>
              <span
                style={{
                  width: "50%",
                  border: "1px solid black",
                  padding: "2px",
                }}
              >
                Invoice Number <br />
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  {item?.performa_invoice_number ? item?.performa_invoice_number : item?.invoice_number}
                </span>
              </span>
              <span
                style={{
                  width: "50%",
                  border: "1px solid black",
                  padding: "2px",
                }}
              >
                Delivery Note <br />
                <span style={{ fontWeight: "600" }}>{item?.delivery_note}</span>
              </span>
              <span
                style={{
                  width: "50%",
                  border: "1px solid black",
                  padding: "2px",
                }}
              >
                Mode/Terms of Payment <br />
                <span style={{ fontWeight: "600" }}>{item?.payment_terms}</span>
              </span>
              <span
                style={{
                  width: "50%",
                  border: "1px solid black",
                  padding: "2px",
                }}
              >
                Buyer's Order No. <br />
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  {item?.buyers_order_no}
                </span>
              </span>
              <span
                style={{
                  width: "50%",
                  border: "1px solid black",
                  padding: "2px",
                }}
              >
                Destination <br />
                <span style={{ fontWeight: "600" }}>{item?.destination}</span>
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              border: "1px solid black",
              flex: "1",
            }}
          >
            <div style={{ flex: ".5", padding: "0 5px" }}>
              <p style={{ fontSize: "14px", lineHeight: "10px" }}>Buyer</p>
              <p style={{ fontSize: "12px" }}>
                <span>
                  Comapny Name :{" "}
                  <span style={{ fontSize: "13px", fontWeight: "600" }}>
                    {" "}
                    {item?.company_name}
                  </span>
                </span>
                <br />
                <span>
                  Address :{" "}
                  <span style={{ fontSize: "13px", fontWeight: "600" }}>
                    {item?.billing_to}
                  </span>
                </span>
                <br />
                <span>
                  Customer :{" "}
                  <span style={{ fontSize: "13px", fontWeight: "600" }}>
                    {" "}
                    {item?.customer}
                  </span>
                </span>
                <br />
                <span style={{ fontSize: "13px" }}>
                  Email :{" "}
                  <span style={{ fontWeight: "600" }}>
                    {item?.email}
                    <br />
                  </span>
                </span>{" "}
                <span style={{ fontSize: "13px" }}>
                  Contact Person :{" "}
                  {item?.contact_persons?.map((r) => {
                    return (
                      <span style={{ fontWeight: "600" }}>
                        {r}
                        <br />
                      </span>
                    );
                  })}
                </span>
                <span style={{ fontSize: "13px" }}>
                  GST NO. :{" "}
                  <span style={{ fontWeight: "600" }}>{item?.gst_number}</span>
                </span>{" "}
                <br />
                <span style={{ fontSize: "13px" }}>
                  State Name :{" "}
                  <span style={{ fontWeight: "600" }}> Gujarat, Code : 24</span>
                </span>
              </p>
            </div>
            <div
              style={{
                flex: ".5",
                display: "flex",
                flexWrap: "wrap",
                fontSize: "13px",
              }}
            >
              <span
                style={{
                  width: "50%",
                  border: "1px solid black",
                  padding: "2px",
                }}
              >
                Dated <br />
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  {new Date(item?.date).toLocaleDateString("en-GB", 2)}
                </span>
              </span>

              <span
                style={{
                  width: "50%",
                  border: "1px solid black",
                  padding: "2px",
                }}
              >
                Delivery Note <br />
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  {new Date(item?.delivery_note_date).toLocaleDateString(
                    "en-GB",
                    2
                  )}
                </span>
              </span>
              <span
                style={{
                  width: "50%",
                  border: "1px solid black",
                  padding: "2px",
                }}
              >
                Dispatch Doucment Number <br />
                <span style={{ fontWeight: "600" }}>
                  {item?.dispatch_doc_no}
                </span>
              </span>
              <span
                style={{
                  width: "100%",
                  border: "1px solid black",
                  padding: "2px",
                }}
              >
                Shipping Address <br />
                <span style={{ fontWeight: "600" }}> {item?.delivery_to}</span>
              </span>
            </div>
          </div>
          <PurchaseOrderPdfTable
            item={item}
            cgst={cgst}
            qut={qut}
            sum={sum}
            sumLast={sumLast}
          />

          <PurchaseOrderTableSecond
            item={item}
            sum2={sum2}
            taxSum={cgstFun()}
          />

          <Grid
            container
            lg={12}
            marginBottom="10px"
            border="1px solid black"
            fontSize="15px"
            padding="10px"
          >
            <Grid lg={12} style={{ fontSize: "13px" }} marginBottom="5px" item>
              Tax Amount(in words) :{" "}
              <span style={{ fontWeight: "700", textTransform: "capitalize" }}>
              {cgst*2 ? converter.toWords(Number(cgst*2)) : ""}
              </span>
            </Grid>
            <Grid lg={12} style={{ fontSize: "13px" }} marginBottom="15px" item>
              Company's PAN :{" "}
              <span style={{ fontWeight: "700" }}>AFJPJ3397R</span>
            </Grid>
            <div
              style={{ display: "flex", width: "100%", alignItems: "center" }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                {" "}
                <span style={{ fontWeight: "600" }}>
                  Declaration :{" "}
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "normal",
                      width: "95%",
                    }}
                  >
                    {" "}
                    {item?.terms_and_condition}
                  </p>
                </span>
                <p style={{ fontWeight: "600" }}>
                  Notes:{" "}
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "normal",
                      width: "95%",
                    }}
                  >
                    {" "}
                    {item?.notes}
                  </p>
                </p>
              </div>
              <div style={{ width: "50%" }}>
                <div
                  style={{
                    padding: "5px 0",
                    width: "100%",
                    fontSize: "12px",
                  }}
                >
                  <p style={{ lineHeight: "10px" }}>
                    Date & Time :{" "}
                    <span style={{ fontWeight: "600" }}>
                      {new Date(item?.date).toLocaleDateString("en-GB", 2)}
                    </span>
                  </p>
                  <p style={{ lineHeight: "10px",fontSize:"14px",fontWeight:"600" }}>Comapny's Bank Details </p>
                  {item?.bank_account_details?.split("-").map((r) => (
                    <p style={{lineHeight:"10px",fontWeight:"500",fontSize:"13px"}}>{r}</p>
                  ))}
                  {/* <p style={{ lineHeight: "10px" }}>
                    Bank Name :{" "}
                    <span style={{ fontWeight: "600" }}>Bank of India</span>
                  </p> */}
                  {/* <p style={{ lineHeight: "10px" }}>
                    A/c No. :{" "}
                    <span style={{ fontWeight: "600" }}>
                      000098274983790001
                    </span>
                  </p> */}
                  {/* <p style={{ lineHeight: "10px" }}>
                    Branch & IFS Code :{" "}
                    <span style={{ fontWeight: "600" }}>
                      Vashi, Sec 15 & BKID0000089
                    </span>
                  </p> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    padding: "0 10px",
                    border: "1px solid black",
                    fontSize: "13px",
                  }}
                >
                  <p style={{ fontWeight: "700", lineHeight: "20px" }}>
                    for BEACON TRADE
                  </p>
                  <p style={{ lineHeight: "20px" }}>Authroized Signatory</p>
                </div>
              </div>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default PerformaInvoiceDrawer;

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: "1px solid black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid black",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    border: "1px solid black",
  },
  "&:nth-of-type(even)": {
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    fontWeight: "600",
  },
}));
