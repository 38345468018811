import * as React from "react";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import axios from "axios";
import { useParams } from "react-router-dom";
const GRNQrCode = () => {
  const { id, ind } = useParams();
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [demo, setDemo] = React.useState("");
  React.useEffect(() => {
    const get = async () => {
      const { data } = await axios.get(
        `https://erpapi.flaskitsolutions.com/api/v1/admin/grn/get_a_single_grn/details/${id}`
      );
      setDemo(data.response);
    };
    get();
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 2000);
    });
  }, [setLoading]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "demo",
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);
  return (
    <>
      <span style={{ cursor: "pointer", color: "blue" }} onClick={handlePrint}>
        PRINT
      </span>
      <div ref={componentRef}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexGrow: "1",
          }}
        >
          {demo
            ? demo.item_details[ind].grn_product_codes.map((r) => {
                return (
                  <div
                    style={{
                      border: "1px solid black",
                      width: "182px",
                      height: "148px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "10px",
                      fontWeight: "600",
                      padding: "5px",
                      marginRight: "5px",
                      marginBottom: "15px",
                    }}
                  >
                    <QRCode value={r} size="60" />
                    <span style={{ marginTop: "5px" }}>{r}</span>
                    <span
                      style={{
                        textAlign: "center",
                        fontSize: "9px",
                        wordBreak: "break-word",
                        whiteSpace: "initial",
                      }}
                    >
                      {demo.item_details[ind].item}
                    </span>
                    <span>MRP : {demo.item_details[ind].mrp}</span>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
};

export default GRNQrCode;
