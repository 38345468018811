import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Input,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Link, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import GRNTable from "./GRNTable";
import ReceivedGRN from "./ReceivedGRN";
import { clearErrors } from "../../Redux/actions/MastersActions";
import { getPurchaseOrder } from "../../Redux/actions/PurchaseOrderAction";
import { getGrnNumber } from "../../Redux/actions/GrnActions";
import { getGrnPrint } from "../../Redux/actions/GrnActions";
import { useLocation } from "react-router-dom";
const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const data = [
  {
    value: "Data",
  },
  {
    value: "Data",
  },
  {
    value: "Data",
  },
];
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: center;
      padding: 6px;
    }
  
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
      padding:16px
    }
    `
);

const Grn = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const [name, setName] = React.useState({ po_number: "" });
  const search = useLocation().search;
  const QuearyParams = new URLSearchParams(search).get("po");
  const [grn, setGrn] = React.useState(false);
  const [input, setInput] = React.useState([]);
  const onChangeInput = (e) => {
    e.preventDefault();
    let le = e.target.value.toUpperCase();
    setInput(([e.target.name] = le.split(",")));
  };
  const { isAuthenticated, error } = useSelector((state) => state.admin);
  const { grnNumber } = useSelector((state) => state.grnNumber);
  const { error: adminError, purchaseOrders } = useSelector(
    (state) => state.purchaseOrders
  );
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getGrnNumber());
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
    if (QuearyParams) {
      setGrn(true);
    }
  }, [dispatch, navigate, error, alert, isAuthenticated, QuearyParams]);

  const handleChange = (e) => {
    setName({ ...name, [e.target.name]: e.target.value });
  };

  const handleViewPoNumber = () => {
    dispatch(getPurchaseOrder(name.po_number));
    setGrn(true);
  };

  const submitMultiple = () => {
    let link = "https://erpapi.flaskitsolutions.com/api/v1/admin/";
    for (let i = 0; i < input.length; i++) {
      if (i === 0) {
        link = `${link}grn?grn=${input[i]}`;
      } else {
        link = `${link}&grn=${input[i]}`;
      }
    }
    dispatch(getGrnPrint(link));
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              GRN
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "0 24px", mt: "0" }}>
          {adminError ? (
            <Grid container xs={12} justifyContent="center" alignItems="center">
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "red" }}
              >
                You don't have the required permissions.
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Item>
                <Grid container xs={12} sx={{ mb: 1 }}>
                  <Typography justifyContent="flex-start">
                    PO Number:
                  </Typography>
                </Grid>
                <Grid container xs={12} justifyContent="space-between">
                  <Grid container xs={4}>
                    <Grid container xs={8}>
                      <TextField
                        // justifyContent="flex-start"
                        size="small"
                        name="po_number"
                        placeholder="Enter PO Number"
                        defaultValue={QuearyParams}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid container xs={4}>
                      <Button
                        variant="contained"
                        // justifyContent="flex-start"
                        size="small"
                        onClick={handleViewPoNumber}
                      >
                        View
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container xs={8} justifyContent="space-around">
                    <Grid container xs={8}>
                      <TextField
                        fullWidth
                        size="small"
                        value={input}
                        onChange={(e) => onChangeInput(e)}
                        placeholder="Enter GRN Number (eg. GRN-000001,GRN-000002)"
                      />
                    </Grid>
                    <Grid container xs={3} justifyContent="flex-end">
                      <Link
                        to="/grnMultiple"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          variant="contained"
                          onClick={submitMultiple}
                          color="success"
                        >
                          Print Multiple GRN
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
                {grn ? (
                  <>
                    <GRNTable name={name} />
                    <Root sx={{ mt: 2 }}>
                      <ReceivedGRN />
                    </Root>
                  </>
                ) : (
                  ""
                )}
              </Item>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default Grn;
