import { ContactSupportOutlined } from "@mui/icons-material";
import axios from "axios";
import {
  DELETE_VENDOR_FAIL,
  DELETE_VENDOR_REQUEST,
  DELETE_VENDOR_SUCCESS,
  NEW_VENDOR_FAIL,
  NEW_VENDOR_REQUEST,
  NEW_VENDOR_SUCCESS,
  UPDATE_VENDOR_CONTACT_PERSON_FAIL,
  UPDATE_VENDOR_CONTACT_PERSON_SUCCESS,
  UPDATE_VENDOR_CONTACT_PERSON_REQUEST,
  UPDATE_VENDOR_FAIL,
  UPDATE_VENDOR_REQUEST,
  UPDATE_VENDOR_SUCCESS,
  VENDOR_CONTACT_PERSON_FAIL,
  VENDOR_CONTACT_PERSON_REQUEST,
  VENDOR_CONTACT_PERSON_SUCCESS,
  VENDOR_FAIL,
  VENDOR_REQUEST,
  VENDOR_SUCCESS,
  DELETE_VENDOR_CONTACT_PERSON_REQUEST,
  DELETE_VENDOR_CONTACT_PERSON_SUCCESS,
  DELETE_VENDOR_CONTACT_PERSON_FAIL,
  NEW_VENDOR_CONTACT_PERSON_REQUEST,
  NEW_VENDOR_CONTACT_PERSON_SUCCESS,
  NEW_VENDOR_CONTACT_PERSON_FAIL,
  VENDOR_SHIPPING_REQUEST,
  VENDOR_SHIPPING_SUCCESS,
  VENDOR_SHIPPING_FAIL,
  UPDATE_VENDOR_SHIPPING_REQUEST,
  UPDATE_VENDOR_SHIPPING_SUCCESS,
  UPDATE_VENDOR_SHIPPING_FAIL,
  DELETE_VENDOR_SHIPPING_REQUEST,
  DELETE_VENDOR_SHIPPING_SUCCESS,
  DELETE_VENDOR_SHIPPING_FAIL,
  NEW_VENDOR_SHIPPING_REQUEST,
  NEW_VENDOR_SHIPPING_SUCCESS,
  NEW_VENDOR_SHIPPING_FAIL,
  VENDOR_BILLING_REQUEST,
  VENDOR_BILLING_SUCCESS,
  VENDOR_BILLING_FAIL,
  UPDATE_VENDOR_BILLING_REQUEST,
  UPDATE_VENDOR_BILLING_SUCCESS,
  UPDATE_VENDOR_BILLING_FAIL,
  DELETE_VENDOR_BILLING_REQUEST,
  DELETE_VENDOR_BILLING_SUCCESS,
  DELETE_VENDOR_BILLING_FAIL,
  NEW_VENDOR_BILLING_REQUEST,
  NEW_VENDOR_BILLING_SUCCESS,
  NEW_VENDOR_BILLING_FAIL,
} from "../constants/VendorConstant";

// Get Vendor
export const getVendor = () => async (dispatch) => {
  try {
    dispatch({ type: VENDOR_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor`,
      config
    );
    dispatch({ type: VENDOR_SUCCESS, payload: data.vendor });
  } catch (error) {
    dispatch({ type: VENDOR_FAIL, payload: error.response.data.message });
  }
};

// Update Vendor
export const updateVendor = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_VENDOR_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    console.log(id, Data);
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/update/${id}`,
      Data,
      config
    );
    dispatch({
      type: UPDATE_VENDOR_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_VENDOR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Vendor
export const deleteVendor = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_VENDOR_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    }
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_VENDOR_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_VENDOR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Vendor
export const createVendor = (Data) => async (dispatch) => {
  console.log(Data, "data");
  try {
    dispatch({ type: NEW_VENDOR_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor`,
      Data,
      config
    );
    dispatch({
      type: NEW_VENDOR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_VENDOR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Vendor
export const getVendorContactPerson = (id) => async (dispatch) => {
  try {
    dispatch({ type: VENDOR_CONTACT_PERSON_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/vendor_contact_persons/${id}`,
      config
    );
    dispatch({
      type: VENDOR_CONTACT_PERSON_SUCCESS,
      payload: data.contact_persons,
    });
  } catch (error) {
    dispatch({
      type: VENDOR_CONTACT_PERSON_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Vendor
export const updateVendorContactPerson = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_VENDOR_CONTACT_PERSON_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/vendor_contact_persons/update/${id}`,
      Data.values,
      config
    );

    dispatch({
      type: UPDATE_VENDOR_CONTACT_PERSON_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_VENDOR_CONTACT_PERSON_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Vendor Contact
export const deleteVendorContact = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_VENDOR_CONTACT_PERSON_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    }
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/vendor_contact_persons/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_VENDOR_CONTACT_PERSON_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_VENDOR_CONTACT_PERSON_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Vendor
export const createVendorContact = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_VENDOR_CONTACT_PERSON_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/vendor_contact_persons/${id}`,
      Data.values,
      config
    );

    dispatch({
      type: NEW_VENDOR_CONTACT_PERSON_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_VENDOR_CONTACT_PERSON_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Vendor
export const getVendorShipping = (id) => async (dispatch) => {
  try {
    dispatch({ type: VENDOR_SHIPPING_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/vendor_shipping_address/${id}`,
      config
    );
    dispatch({
      type: VENDOR_SHIPPING_SUCCESS,
      payload: data.shipping_address,
    });
  } catch (error) {
    dispatch({
      type: VENDOR_SHIPPING_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Vendor
export const updateVendorShipping = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_VENDOR_SHIPPING_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/vendor_shipping_address/update/${id}`,
      Data.values,
      config
    );

    dispatch({
      type: UPDATE_VENDOR_SHIPPING_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_VENDOR_SHIPPING_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Vendor Contact
export const deleteVendorShipping = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_VENDOR_SHIPPING_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    }
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/vendor_shipping_address/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_VENDOR_SHIPPING_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_VENDOR_SHIPPING_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Vendor
export const createVendorShipping = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_VENDOR_SHIPPING_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/vendor_shipping_address/${id}`,
      Data.values,
      config
    );

    dispatch({
      type: NEW_VENDOR_SHIPPING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_VENDOR_SHIPPING_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Vendor
export const getVendorBilling = (id) => async (dispatch) => {
  try {
    dispatch({ type: VENDOR_BILLING_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/vendor_billing_address/${id}`,
      config
    );
    dispatch({
      type: VENDOR_BILLING_SUCCESS,
      payload: data.billing_address,
    });
  } catch (error) {
    dispatch({
      type: VENDOR_BILLING_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Vendor
export const updateVendorBilling = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_VENDOR_BILLING_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/vendor_billing_address/update/${id}`,
      Data.values,
      config
    );

    dispatch({
      type: UPDATE_VENDOR_BILLING_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_VENDOR_BILLING_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Vendor Contact
export const deleteVendorBilling = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_VENDOR_BILLING_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    }
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/vendor_billing_address/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_VENDOR_BILLING_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_VENDOR_BILLING_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Vendor
export const createVendorBilling = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_VENDOR_BILLING_REQUEST });

    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/vendor_billing_address/${id}`,
      Data.values,
      config
    );

    dispatch({
      type: NEW_VENDOR_BILLING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_VENDOR_BILLING_FAIL,
      payload: error.response.data.message,
    });
  }
};
