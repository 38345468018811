import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import StockTableRow from "./StockTableRow";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(181,201,255)",
    color: theme.palette.common.white,
    borderRight: "1px solid #fff",
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "2px",
  },
}));
const defaultData = [
  {
    value: "Dis(%)",
  },
  {
    value: "Weight",
  },
  {
    value: "Dimenssion LXBXH(cm)",
    data: [{ value: "L" }, { value: "H" }, { value: "B" }],
  },
  { value: "Volume" },
  {
    value:"Margin"
  }
];
const firstDefault = [
  {
    value: "MRP",
  },
];
export default function StockPrices({
  productSpecification,
  addChild,
  addChildInputField,
  deleteRow,
  setAddChild,
  onchangeInput,
}) {
  return (
    <TableContainer
      component={Paper}
      style={{ width: !productSpecification?.length ? "40%" : "100%" ,     zoom: "65%" }}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {productSpecification?.length
              ? productSpecification.map((item) => (
                  <StyledTableCell align="center">{item}</StyledTableCell>
                ))
              : ""}
            {firstDefault.map((r) => (
              <StyledTableCell align="center">{r.value}</StyledTableCell>
            ))}

            {productSpecification?.length ? (
              <>
                {defaultData.map((item) => (
                  <StyledTableCell align="center">{item.value}</StyledTableCell>
                ))}
                <StyledTableCell align="center">
                  <AddIcon onClick={(e) => addChildInputField(e)} />
                </StyledTableCell>
              </>
            ) : (
              ""
            )}
          </TableRow>
        </TableHead>
        <StockTableRow
          productSpecification={productSpecification}
          deleteRow={deleteRow}
          addChild={addChild}
          setAddChild={setAddChild}
          onchangeInput={onchangeInput}
          defaultData={defaultData}
          firstDefault={firstDefault}
        />
      </Table>
    </TableContainer>
  );
}
