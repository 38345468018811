import {
  Grid,
  Typography,
  Paper,
  Button,
  TextField,
  Autocomplete,
  DialogContentText,
} from "@mui/material";
import React, { useEffect } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Link, useNavigate, useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DrawerErp from "../Layout/DrawerErp";
import Toolbar from "@mui/material/Toolbar";
import { getCustomers } from "../../Redux/actions/CustomerActions";
import Loader from "../Common/Loader";
import {
  getGstTreatment,
  getPaymentTerms,
  getSourceOfSupply,
} from "../../Redux/actions/MastersActions";
import {
  getCustGroup,
  getCustSubGroup,
} from "../../Redux/actions/EcommerceAction";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import { TextFieldComponent } from "../Common/FormikComponent/TextFieldComponent";
import AutoCompleteSelect from "../Common/FormikComponent/AutoCompleteSelect";
import "../Common/style.css";
import CustomerRow from "../Customers/CustomerRow/CustomerRow";
import { NEW_VENDOR_RESET } from "../../Redux/constants/VendorConstant";
import { updateVendor } from "../../Redux/actions/VendorAction";
import axios from "axios";
import TextError from "../Common/FormikComponent/TextError";
import BillingRow from "../Common/BillingRow/BillingRow";
import ShippingRow from "../Common/ShippingRow/ShippingRow";
import DesignationRow from "../Common/DesignationRow/DesignationRow";
import DocumentTable from "../Common/DocumentTable";

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const EditVendor = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const alert = useAlert();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.admin);
  const { gstTreatment } = useSelector((state) => state.GstTreatment);
  const { custGroup } = useSelector((state) => state.custGroup);
  const { custSubGroup } = useSelector((state) => state.custSubGroup);
  const [updatedata, setUpdateData] = React.useState("");
  const { isUpdated } = useSelector((state) => state.updateVendors);
  const [addDocument, setAddDocument] = React.useState([{ value: 1 }]);
  useEffect(() => {
    if (localStorage.getItem('user')) {
      dispatch(getCustomers());
      dispatch(getSourceOfSupply());
      dispatch(getGstTreatment());
      dispatch(getPaymentTerms());
      dispatch(getCustGroup());
      dispatch(getCustSubGroup());
    }
    if (!localStorage.getItem('user')) {
      navigate("/login");
    }
    if (id) {
      const get = async () => {
        const { data } = await axios.get(
          `https://erpapi.flaskitsolutions.com/api/v1/admin/vendor/single_vendor/details/${id}`
        );
        setUpdateData(data.response);
        setAddChild(data.response.vendor_billing_address);
        setShippingTable(data.response.vendor_shipping_address);
        setDesignationTable(data.response.Designation);
        setAddDocument(data.response.identification_doc);
      };
      get();
    }
    if (isUpdated) {
      dispatch({ type: NEW_VENDOR_RESET });
      navigate("/vendor/vendors");
    }
  }, [dispatch, navigate, alert, isAuthenticated, isUpdated, id]);

  const [addChild, setAddChild] = React.useState([{ value: 1 }]);
  const addChildInputField = (event) => {
    const id = addChild.length + 1;
    setAddChild((oldValue) => [...oldValue, { value: id }]);
    event.preventDefault();
  };
  const deleteRow = (id) => {
    let updatedArray = addChild.filter((r, ind) => ind !== id);
    setAddChild(updatedArray);
  };
  const onchangeInput = (val, index, outerIndex) => {
    let updated = addChild.map((r, ind) => {
      return ind === outerIndex
        ? {
            ...r,
            [index]: val.target.value.toUpperCase(),
          }
        : r;
    });
    setAddChild(updated);
  };

  const [shippingTable, setShippingTable] = React.useState([{ value: 1 }]);
  const onchangeInputShipping = (val, index, outerIndex) => {
    let updated = shippingTable.map((r, ind) => {
      return ind === outerIndex
        ? {
            ...r,
            [index]: val.target.value.toUpperCase(),
          }
        : r;
    });
    setShippingTable(updated);
  };

  const [designationTable, setDesignationTable] = React.useState([
    { value: 1 },
  ]);
  const addChildInputFieldDesignation = (event) => {
    const id = designationTable.length + 1;
    setDesignationTable((oldValue) => [...oldValue, { value: id }]);
    event.preventDefault();
  };
  const deleteRowDesignation = (id) => {
    let updatedArray = designationTable.filter((r, ind) => ind !== id);
    setDesignationTable(updatedArray);
  };
  const onchangeInputDesignation = (val, index, outerIndex) => {
    let updated = designationTable.map((r, ind) => {
      return ind === outerIndex
        ? {
            ...r,
            [index]: val.target.value.toUpperCase(),
          }
        : r;
    });
    setDesignationTable(updated);
  };
  const addDocumentInputField = (event) => {
    const id = addDocument.length + 1;
    setAddDocument((oldValue) => [...oldValue, { value: id }]);
    event.preventDefault();
  };
  const deleteRowDocument = (id) => {
    let updatedArray = addDocument.filter((r, ind) => ind !== id);
    setAddDocument(updatedArray);
  };
  const onchangeInputDoucment = (val, index, outerIndex) => {
    if (index === "image") {
      const getImage = async () => {
        let data = new FormData();
        data.append("name", val.name);
        data.append("file", val);
        const image = await fetch(
          `https://erpapi.flaskitsolutions.com/api/v1/upload/user-image`,
          {
            method: "POST",
            body: data,
          }
        );
        const json = await image.json();
        let updated = addDocument.map((r, ind) => {
          return ind === outerIndex
            ? {
                ...r,
                ["doc_image"]: json,
              }
            : r;
        });
        setAddDocument(updated);
      };
      getImage();
    } else {
      let updated = addDocument.map((r, ind) => {
        return ind === outerIndex
          ? {
              ...r,
              [index]: val.target.value.toUpperCase(),
            }
          : r;
      });
      setAddDocument(updated);
    }
  };

  const defaultData = [
    {
      value: "Street 1",
      label: "street_1",
    },
    {
      value: "Street 2",
      label: "street_2",
    },
    {
      value: "City",
      label: "city",
    },
    {
      value: "State",
      label: "state",
    },
    {
      value: "Country",
      label: "country",
    },
    {
      value: "Zip Code",
      label: "zip_code",
    },
    {
      value: "Phone",
      label: "phone",
    },
    {
      value: "Email",
      label: "email",
    },
  ];

  const defaultData2 = [
    {
      value: "Name",
      label: "name",
    },
    {
      value: "Email",
      label: "email",
    },
    {
      value: "Phone",
      label: "phone",
    },
    {
      value: "Designation",
      label: "designation",
    },
    {
      value: "Department",
      label: "department",
    },
  ];
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
    name: Yup.string().required("Required"),
    group: Yup.string(),
    mobile: Yup.string().required("Required").max(10).min(10),
    gst_treatment: Yup.string(),
    gst_number: Yup.string().max(15).min(15),
    pan_number: Yup.string().max(10).min(10),
    password: Yup.string().required("Required"),
    company_name: Yup.string(),
    sub_group: Yup.string(),
    identification_number: Yup.string(),
    identification_proof: Yup.string(),
  });
  const initialValues = {
    name: updatedata.name ? updatedata.name : "",
    address: updatedata.address ? updatedata.address : "",
    email: updatedata.email ? updatedata.email : "",
    group: updatedata.group ? updatedata.group : "",
    mobile: updatedata.mobile ? updatedata.mobile : "",
    company_name: updatedata.company_name ? updatedata.company_name : "",
    gst_treatment: updatedata.gst_treatment ? updatedata.gst_treatment : "",
    gst_number: updatedata.gst_number ? updatedata.gst_number : "",
    pan_number: updatedata.pan_number ? updatedata.pan_number : "",
    payment_terms: updatedata.payment_terms ? updatedata.payment_terms : "",
    password: updatedata.password ? updatedata.password : "",
    source: updatedata.source ? updatedata.source : "",
    sub_group: updatedata.sub_group ? updatedata.sub_group : "",
    identification_number: updatedata.identification_number
      ? updatedata.identification_number
      : "",
    identification_proof: updatedata.identification_proof
      ? updatedata.identification_proof
      : "",
  };

  const onSubmit = (values) => {
    dispatch(
      updateVendor(id, {
        name: values.name.toUpperCase(),
        company_name: values.company_name.toUpperCase(),
        email: values.email.toUpperCase(),
        group: values.group.toUpperCase(),
        mobile: values.mobile,
        gst_treatment: values.gst_treatment.toUpperCase(),
        gst_number: values.gst_number,
        pan_number: values.pan_number,
        password: values.password.toUpperCase(),
        sub_group: values.sub_group.toUpperCase(),
        identification_number: values.identification_number,
        identification_proof: values.identification_proof,
        vendor_billing_address: addChild,
        vendor_shipping_address: shippingTable,
        Designation: designationTable,
        identification_doc: addDocument,
      })
    );
  };
  const updated = gstTreatment.map((r) => r.name.toUpperCase());
  const updatedCust = custGroup.map((r) => r.name.toUpperCase());
  const updatedsubGruop = custSubGroup.map((r) => r.name.toUpperCase());
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DrawerErp />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Vendor
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "0 24px", mt: "0" }}>
          <Grid item xs={12}>
            <Item>
              {!updatedata ? (
                <Loader />
              ) : (
                <Grid container justifyContent="flex-end">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                  >
                    {({
                      errors,
                      handleChange,
                      values,
                      touched,
                      setFieldValue,
                    }) => (
                      <Form>
                        <Grid container spacing={2} mb={2}>
                          <Grid item lg={3}>
                            <TextFieldComponent
                              heading="Comapny Name"
                              name="company_name"
                              type="text"
                              error={
                                errors.company_name && touched.company_name
                              }
                            />
                          </Grid>
                          <Grid item lg={3}>
                            <TextFieldComponent
                              heading="Name"
                              name="name"
                              type="text"
                              error={errors.name && touched.name}
                            />
                          </Grid>
                          <Grid item lg={3}>
                            <TextFieldComponent
                              heading="Email"
                              type="text"
                              name="email"
                              error={errors.email && touched.email}
                            />
                          </Grid>
                          <Grid item lg={3}>
                            <TextFieldComponent
                              heading="Mobile"
                              name="mobile"
                              type="number"
                              error={errors.mobile && touched.mobile}
                            />
                          </Grid>
                          <Grid item lg={3}>
                            <TextFieldComponent
                              heading="Password"
                              type="text"
                              name="password"
                              error={errors.password && touched.password}
                            />
                          </Grid>

                          <Grid
                            item
                            lg={3}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <DialogContentText alignSelf="flex-start">
                              Group
                            </DialogContentText>
                            <Autocomplete
                              name="group"
                              size="small"
                              options={updatedCust}
                              defaultValue={updatedCust.find(
                                (r) => r === values.group
                              )}
                              getOptionLabel={(option) => option}
                              style={{ width: "90%" }}
                              onChange={(event, value) => {
                                setFieldValue("group", value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="dense"
                                  onChange={handleChange}
                                  style={{
                                    width: "100%",
                                  }}
                                  value={values.group}
                                />
                              )}
                            />
                            <ErrorMessage name="group" component={TextError} />
                          </Grid>
                          <Grid
                            item
                            lg={3}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <DialogContentText alignSelf="flex-start">
                              Customer Sub-Group
                            </DialogContentText>
                            <Autocomplete
                              name="sub_group"
                              size="small"
                              options={updatedsubGruop}
                              defaultValue={updatedsubGruop.find(
                                (r) => r === values.sub_group
                              )}
                              getOptionLabel={(option) => option}
                              style={{ width: "90%" }}
                              onChange={(event, value) => {
                                setFieldValue("sub_group", value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="dense"
                                  onChange={handleChange}
                                  style={{
                                    width: "100%",
                                  }}
                                  value={values.sub_group}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="sub_group"
                              component={TextError}
                            />
                          </Grid>

                          <Grid
                            item
                            lg={12}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              alignSelf="flex-start"
                              sx={{ mb: 2, color: "black" }}
                            >
                              Vendor Billing Address
                            </Typography>
                            <BillingRow
                              addChildInputField={addChildInputField}
                              addChild={addChild}
                              defaultData={defaultData}
                              deleteRow={deleteRow}
                              onchangeInput={onchangeInput}
                            />
                          </Grid>

                          <Grid
                            item
                            lg={12}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "15px",
                            }}
                          >
                            <Typography
                              alignSelf="flex-start"
                              sx={{ mb: 2, color: "black" }}
                            >
                              Vendor Shipping Address
                            </Typography>
                            <ShippingRow
                              addChild={shippingTable}
                              defaultData={defaultData}
                              onchangeInput={onchangeInputShipping}
                            />
                          </Grid>

                          <Grid
                            item
                            lg={3}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <DialogContentText alignSelf="flex-start">
                              GST Treatment
                            </DialogContentText>
                            <Autocomplete
                              name="gst_treatment"
                              size="small"
                              options={updated}
                              defaultValue={updated.find(
                                (r) => r === values.gst_treatment
                              )}
                              getOptionLabel={(option) => option}
                              style={{ width: "90%" }}
                              onChange={(event, value) => {
                                setFieldValue("gst_treatment", value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="dense"
                                  onChange={handleChange}
                                  style={{
                                    width: "100%",
                                  }}
                                  value={values.gst_treatment}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="gst_treatment"
                              component={TextError}
                            />
                          </Grid>
                          <Grid item lg={3}>
                            <TextFieldComponent
                              heading="Gst No."
                              name="gst_number"
                              type="text"
                              error={errors.gst_number && touched.gst_number}
                            />
                          </Grid>
                          <Grid item lg={3}>
                            <TextFieldComponent
                              heading="Pan No."
                              name="pan_number"
                              type="text"
                              error={errors.pan_number && touched.pan_number}
                            />
                          </Grid>

                          <Grid
                            item
                            lg={12}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "15px",
                            }}
                          >
                            <Typography
                              alignSelf="flex-start"
                              sx={{ mb: 2, color: "black" }}
                            >
                              Designation
                            </Typography>
                            <DesignationRow
                              addChildInputField={addChildInputFieldDesignation}
                              addChild={designationTable}
                              defaultData={defaultData2}
                              deleteRow={deleteRowDesignation}
                              onchangeInput={onchangeInputDesignation}
                            />
                          </Grid>
                          <Grid item lg={3}>
                            <TextFieldComponent
                              heading="Identification Number"
                              type="text"
                              name="identification_number"
                              error={
                                errors.identification_number &&
                                touched.identification_number
                              }
                            />
                          </Grid>
                          <Grid item lg={3}>
                            <TextFieldComponent
                              heading="Identification Proof"
                              type="text"
                              name="identification_proof"
                              error={
                                errors.identification_proof &&
                                touched.identification_proof
                              }
                            />
                          </Grid>
                          <Grid item lg={12}>
                            <Grid lg={6}>
                              <DocumentTable
                                addDocumentInputField={addDocumentInputField}
                                addDocument={addDocument}
                                deleteRowDocument={deleteRowDocument}
                                onchangeInputDoucment={onchangeInputDoucment}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            variant="contained"
                            type="submit"
                            style={{ marginRight: "20px" }}
                          >
                            Update
                          </Button>
                          <Link
                            to="/vendor/vendors"
                            style={{ textDecoration: "none" }}
                          >
                            <Button variant="contained" color="error">
                              Close
                            </Button>
                          </Link>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              )}
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EditVendor;
