import axios from "axios";
import {
  ADDCUSTOMERS_REQUEST,
  ADDCUSTOMERS_SUCCESS,
  ADDCUSTOMERS_FAIL,
  NEW_ADDCUSTOMERS_REQUEST,
  NEW_ADDCUSTOMERS_SUCCESS,
  NEW_ADDCUSTOMERS_FAIL,
  NEW_ADDCUSTOMERS_RESET,
  UPDATE_ADDCUSTOMERS_REQUEST,
  UPDATE_ADDCUSTOMERS_SUCCESS,
  UPDATE_ADDCUSTOMERS_FAIL,
  UPDATE_ADDCUSTOMERS_RESET,
  DELETE_ADDCUSTOMERS_REQUEST,
  DELETE_ADDCUSTOMERS_SUCCESS,
  DELETE_ADDCUSTOMERS_FAIL,
  DELETE_ADDCUSTOMERS_RESET,
  CUSTOMERCONTACTPERSON_REQUEST,
  CUSTOMERCONTACTPERSON_SUCCESS,
  CUSTOMERCONTACTPERSON_FAIL,
  NEW_CUSTOMERCONTACTPERSON_REQUEST,
  NEW_CUSTOMERCONTACTPERSON_SUCCESS,
  NEW_CUSTOMERCONTACTPERSON_FAIL,
  NEW_CUSTOMERCONTACTPERSON_RESET,
  UPDATE_CUSTOMERCONTACTPERSON_REQUEST,
  UPDATE_CUSTOMERCONTACTPERSON_SUCCESS,
  UPDATE_CUSTOMERCONTACTPERSON_FAIL,
  UPDATE_CUSTOMERCONTACTPERSON_RESET,
  DELETE_CUSTOMERCONTACTPERSON_REQUEST,
  DELETE_CUSTOMERCONTACTPERSON_SUCCESS,
  DELETE_CUSTOMERCONTACTPERSON_FAIL,
  DELETE_CUSTOMERCONTACTPERSON_RESET,
  CUSTOMERSHIPPINGADDRESS_REQUEST,
  CUSTOMERSHIPPINGADDRESS_SUCCESS,
  CUSTOMERSHIPPINGADDRESS_FAIL,
  NEW_CUSTOMERSHIPPINGADDRESS_REQUEST,
  NEW_CUSTOMERSHIPPINGADDRESS_SUCCESS,
  NEW_CUSTOMERSHIPPINGADDRESS_FAIL,
  NEW_CUSTOMERSHIPPINGADDRESS_RESET,
  UPDATE_CUSTOMERSHIPPINGADDRESS_REQUEST,
  UPDATE_CUSTOMERSHIPPINGADDRESS_SUCCESS,
  UPDATE_CUSTOMERSHIPPINGADDRESS_FAIL,
  UPDATE_CUSTOMERSHIPPINGADDRESS_RESET,
  DELETE_CUSTOMERSHIPPINGADDRESS_REQUEST,
  DELETE_CUSTOMERSHIPPINGADDRESS_SUCCESS,
  DELETE_CUSTOMERSHIPPINGADDRESS_FAIL,
  DELETE_CUSTOMERSHIPPINGADDRESS_RESET,
  CUSTOMERBILLINGADDRESS_REQUEST,
  CUSTOMERBILLINGADDRESS_SUCCESS,
  CUSTOMERBILLINGADDRESS_FAIL,
  NEW_CUSTOMERBILLINGADDRESS_REQUEST,
  NEW_CUSTOMERBILLINGADDRESS_SUCCESS,
  NEW_CUSTOMERBILLINGADDRESS_FAIL,
  NEW_CUSTOMERBILLINGADDRESS_RESET,
  UPDATE_CUSTOMERBILLINGADDRESS_REQUEST,
  UPDATE_CUSTOMERBILLINGADDRESS_SUCCESS,
  UPDATE_CUSTOMERBILLINGADDRESS_FAIL,
  UPDATE_CUSTOMERBILLINGADDRESS_RESET,
  DELETE_CUSTOMERBILLINGADDRESS_REQUEST,
  DELETE_CUSTOMERBILLINGADDRESS_SUCCESS,
  DELETE_CUSTOMERBILLINGADDRESS_FAIL,
  DELETE_CUSTOMERBILLINGADDRESS_RESET,
} from "../constants/CustomerConstants";

// Add Customers Actions

// Get Customers
export const getCustomers = () => async (dispatch) => {
  try {
    dispatch({ type: ADDCUSTOMERS_REQUEST });
    const config = { 
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer`,
      config
    );
    dispatch({ type: ADDCUSTOMERS_SUCCESS, payload: data.customers });
  } catch (error) {
    dispatch({ type: ADDCUSTOMERS_FAIL, payload: error.response.data.message });
  }
};

// Update Customer
export const updateCustomers = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ADDCUSTOMERS_REQUEST });
    const config = {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    };
    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/update/${id}`,
      Data,
      config
    );

    dispatch({
      type: UPDATE_ADDCUSTOMERS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ADDCUSTOMERS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Customers
export const deleteCustomers = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ADDCUSTOMERS_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_ADDCUSTOMERS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ADDCUSTOMERS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Customer
export const createCustomers = (Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_ADDCUSTOMERS_REQUEST });
    const config = {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    };
    console.log(Data, "=data");
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer`,
      Data,
      config
    );
    dispatch({
      type: NEW_ADDCUSTOMERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_ADDCUSTOMERS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Customer Contact Person Actions

// Get Customer Contact Person
export const getCustomerContactPerson = (id) => async (dispatch) => {
  try {
    console.log(id);
    dispatch({ type: CUSTOMERCONTACTPERSON_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/customer_contact_persons/${id}`,
      config
    );
    console.log(data);
    dispatch({
      type: CUSTOMERCONTACTPERSON_SUCCESS,
      payload: data.contact_persons,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMERCONTACTPERSON_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Customer Contact Person
export const updateCustomerContactPerson = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CUSTOMERCONTACTPERSON_REQUEST });
    const config = {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    };
    console.log(id, Data);

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/customer_contact_persons/update/${id}`,
      Data.values,
      config
    );

    dispatch({
      type: UPDATE_CUSTOMERCONTACTPERSON_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CUSTOMERCONTACTPERSON_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Customer Contact Person
export const deleteCustomerContactPerson = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CUSTOMERCONTACTPERSON_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/customer_contact_persons/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_CUSTOMERCONTACTPERSON_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CUSTOMERCONTACTPERSON_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Customer
export const createCustomerContactPerson = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_CUSTOMERCONTACTPERSON_REQUEST });

    const config = {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    };
    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/customer_contact_persons/${id}`,
      Data.values,
      config
    );

    dispatch({
      type: NEW_CUSTOMERCONTACTPERSON_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_CUSTOMERCONTACTPERSON_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Customer Shipping Address Actions

// Get Customer Shipping Address
export const getCustomerShippingAddress = (id) => async (dispatch) => {
  try {
    dispatch({ type: CUSTOMERSHIPPINGADDRESS_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/customer_shipping_address/${id}`,
      config
    );
    console.log(data);
    dispatch({
      type: CUSTOMERSHIPPINGADDRESS_SUCCESS,
      payload: data.shipping_address,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMERSHIPPINGADDRESS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Customer Shipping Address
export const updateCustomerShippingAddress = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CUSTOMERSHIPPINGADDRESS_REQUEST });
    const config = {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    };
    console.log(id, Data);

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/customer_shipping_address/update/${id}`,
      Data.values,
      config
    );

    dispatch({
      type: UPDATE_CUSTOMERSHIPPINGADDRESS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CUSTOMERSHIPPINGADDRESS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Customer Shipping Address
export const deleteCustomerShippingAddress = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CUSTOMERSHIPPINGADDRESS_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/customer_shipping_address/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_CUSTOMERSHIPPINGADDRESS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CUSTOMERSHIPPINGADDRESS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Customer
export const createCustomerShippingAddress = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_CUSTOMERSHIPPINGADDRESS_REQUEST });

    const config = {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/customer_shipping_address/${id}`,
      Data.values,
      config
    );

    dispatch({
      type: NEW_CUSTOMERSHIPPINGADDRESS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_CUSTOMERSHIPPINGADDRESS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Customer Billing Address Actions

// Get Customer Billing Address
export const getCustomerBillingAddress = (id) => async (dispatch) => {
  try {
    dispatch({ type: CUSTOMERBILLINGADDRESS_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.get(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/customer_billing_address/${id}`,
      config
    );
    dispatch({
      type: CUSTOMERBILLINGADDRESS_SUCCESS,
      payload: data.billing_address,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMERBILLINGADDRESS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Customer Billing Address
export const updateCustomerBillingAddress = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CUSTOMERBILLINGADDRESS_REQUEST });
    const config = {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    };
    console.log(id, Data);

    const { data } = await axios.put(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/customer_Billing_address/update/${id}`,
      Data.values,
      config
    );

    dispatch({
      type: UPDATE_CUSTOMERBILLINGADDRESS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CUSTOMERBILLINGADDRESS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Customer Billing Address
export const deleteCustomerBillingAddress = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CUSTOMERBILLINGADDRESS_REQUEST });
    const config = { 
      headers: { "Content-Type": "application/json" },
      withCredentials: true // Correct placement for withCredentials
    };
    const { data } = await axios.delete(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/customer_Billing_address/delete/${id}`,
      config
    );

    dispatch({
      type: DELETE_CUSTOMERBILLINGADDRESS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CUSTOMERBILLINGADDRESS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create a Customer
export const createCustomerBillingAddress = (id, Data) => async (dispatch) => {
  try {
    dispatch({ type: NEW_CUSTOMERBILLINGADDRESS_REQUEST });

    const config = {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.post(
      `https://erpapi.flaskitsolutions.com/api/v1/admin/customer/customer_Billing_address/${id}`,
      Data.values,
      config
    );

    dispatch({
      type: NEW_CUSTOMERBILLINGADDRESS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_CUSTOMERBILLINGADDRESS_FAIL,
      payload: error.response.data.message,
    });
  }
};
